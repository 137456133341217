import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from '../../../common/header';
import Footer from '../../../common/footer';
import InterviewCalendar from './interviewCalendar';
import { useEffect } from 'react';
import { getCandidateInterviews } from '../../../actions/userAction';

const Interviews = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { jobId } = location.state || {};
  const lang = useSelector((state) => state.language.lang);

  useEffect(() => {
    dispatch(getCandidateInterviews());
  }, []);

  console.log('applied job ' + jobId);

  return (
    <div className="flex flex-row flex-grow min-h-screen">
      <div className="flex w-full flex-col min-h-screen overflow-y-auto">
        <Header />
        <div className="flex justify-center items-center">
          <div className="max-w-[1200px] flex w-full h-full flex-col min-h-screen p-10 overflow-y-auto bg-white rounded-xl">
            <div className="flex flex-col justify-center px-5 text-5xl font-bold mb-8">{lang.generalInterviewCalendar}</div>
            <div className="min-h-screen px-5">
              <InterviewCalendar jobId={jobId} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Interviews;
