import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const currentId = useSelector((state) => state.currentId.currentId); // Adjust according to your state structure
  const [authUser, setAuthUser] = useState(null);
  
  useEffect(() => {
    if (currentId) {
      setAuthUser({ id: currentId });
    } else {
      setAuthUser(null);
    }
    console.log("Inside useEffect - authUser: ", { id: currentId }); // Log inside useEffect
  }, [currentId]);


  return (
    <AuthContext.Provider value={{ authUser, setAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
};

