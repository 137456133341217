import messageCard from '../../../assets/images/messagecard.png'
import worldCard from '../../../assets/images/worldcard.png'
import rocketCard from '../../../assets/images/rocketcard.png'
import { useSelector } from 'react-redux'

const CardGroup = () => {
  const lang = useSelector((state) => state.language.lang)

  return (
    <div className="flex flex-wrap justify-center gap-2 md:gap-7">
      <div className="shadow-card w-[30%] md:w-[28%] md:min-h-[479px] flex-shrink-0 rounded-xl p-4 md:p-10 flex flex-col justify-between">
        <div>
          <img src={messageCard} alt="messageIcon" className="w-6 h-8 md:w-16 md:h-20" />
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-semibold pt-4 md:pt-10">
            {lang.employeeHomeCard1Title1}
          </div>
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-semibold">
            {lang.employeeHomeCard1Title2}
          </div>
          <div className="outfit-font text-oppty-grey-1 text-[6px] md:text-2xl font-normal py-2 md:py-7 pr-3 lg:pr-1 xl:pr-20">
            {lang.employeeHomeCard1Content}
          </div>
        </div>
        <button className="w-16 md:w-[70%] h-4 md:h-12 text-[6px] md:text-[18px] flex-shrink-0 rounded-md md:rounded-[40px] bg-black text-white mt-2 md:mt-8">
          {lang.generalLearnMore}
        </button>
      </div>
      <div className="shadow-card w-[30%] md:w-[28%] md:min-h-[479px] flex-shrink-0 rounded-xl p-4 md:p-10 flex flex-col justify-between">
        <div>
          <img src={worldCard} alt="rocketIcon" className="w-7 h-7 md:w-20 md:h-20" />
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-semibold pt-4 md:pt-10">
            {lang.employeeHomeCard2Title}
          </div>
          <div className="outfit-font text-oppty-grey-1 text-[6px] md:text-2xl font-normal py-2 md:py-7 pr-3 lg:pr-1 xl:pr-20">
            {lang.employeeHomeCard2Content}
          </div>
        </div>
        <button className="w-16 md:w-[70%] h-4 md:h-12 text-[6px] md:text-[18px] flex-shrink-0 rounded-md md:rounded-[40px] bg-black text-white mt-2 md:mt-8">
          {lang.generalLearnMore}
        </button>
      </div>
      <div className="shadow-card w-[30%] md:w-[28%] md:min-h-[479px] flex-shrink-0 rounded-xl p-4 md:p-10 flex flex-col justify-between">
        <div>
          <img src={rocketCard} alt="rocketIcon" className="w-7 h-7 md:w-20 md:h-20" />
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-semibold pt-4 md:pt-10">
            {lang.employeeHomeCard3Title}
          </div>
          <div className="outfit-font text-oppty-grey-1 text-[6px] md:text-2xl font-normal py-2 md:py-7 pr-3 lg:pr-1 xl:pr-20">
            {lang.employeeHomeCard3Content}
          </div>
        </div>
        <button className="w-16 md:w-[70%] h-4 md:h-12 text-[6px] md:text-[18px] flex-shrink-0 rounded-md md:rounded-[40px] bg-black text-white mt-2 md:mt-8">
          {lang.generalLearnMore}
        </button>
      </div>
    </div>
  )
}

export default CardGroup
