import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Datepicker } from 'flowbite-react';
import { updateApplicantInterviewStatus } from '../../actions/jobAction';

const InterviewDateSelectorModal = ({ applicant, closeModal, status }) => {
  const lang = useSelector((state) => state.language.lang);

  const [value, setValue] = useState({
    date: null,
    time: null,
    interviewLink: null
  });
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleDateValueChange = (newValue) => {
    console.log('New value:', newValue);
    const dateObject = new Date(newValue);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    console.log('Formatted date:', formattedDate);
    setValue({ ...value, date: formattedDate });
  };

  const handleTimeValueChange = (e) => {
    console.log('newValue:', e.target.value);
    setValue({ ...value, time: e.target.value });
  };

  const interviewLinkValueChange = (e) => {
    console.log('newValue:', e.target.value);
    setValue({ ...value, interviewLink: e.target.value });
  };

  const onSubmit = () => {
    const values = {
      applicant: applicant,
      newStatus: status,
      value: value
    };
    console.log('values: ', values);
    if (value?.date && value?.time) {
      const result = dispatch(updateApplicantInterviewStatus(values));
      if (result && !result.error) {
        closeModal();
      }
    } else {
      setError(true);
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-2">
        <div className="bg-white flex flex-col w-full lg:w-[600px] px-5 md:px-10 py-10 rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">{lang.generalInterviewDateAndTime}</h2>
            <button
              onClick={closeModal}
              className="text-lg font-bold hover:bg-red-500 px-2 rounded-md hover:text-white"
            >
              X
            </button>
          </div>
          <div className="mt-5">
            {lang.generalInterviewApplicant}:{' '}
            <span className="font-bold">
              {applicant?.firstName} {applicant?.lastName}
            </span>
          </div>
          <div className="pt-10 w-full flex flex-col items-center justify-center">
            <Datepicker inline onSelectedDateChanged={handleDateValueChange} value={value.date} />
          </div>
          <div className="flex flex-col justify-start items-start px-10 gap-y-5">
            <div className="flex items-center pt-5">
              <label className="block text-xl w-36 font-semibold text-gray-900 dark:text-white">{lang.generalSelectItem}:</label>
              <div className="relative">
                <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="time"
                  id="time"
                  className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  min="09:00"
                  max="18:00"
                  value={value?.time}
                  onChange={handleTimeValueChange}
                  required
                />
              </div>
            </div>
            <div className="w-full flex flex-wrap items-center">
              <div className="text-xl font-semibold leading-normal w-36">{lang.generalInterviewLink}: </div>
              <input
                type="text"
                id="interviewLink"
                name="interviewLink"
                placeholder={lang.generalOptional}
                onChange={interviewLinkValueChange}
                value={value?.interviewLink}
                className="py-2.5 px-5 w-72 leading-tight border focus:outline rounded-md border-gray-300"
              />
            </div>
            <div className="mt-5 w-full flex flex-col justify-center items-center">
              {error && <div className="text-red-500 text-sm">{lang.generalDateTimeRequired}</div>}
              <button
                type="button"
                onClick={onSubmit}
                className="px-6 bg-purple-500 py-2 rounded-lg text-white font-bold text-xl"
              >
                {lang.generalSubmit}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewDateSelectorModal;
