import EducationCard from './educationCard';

const Education = ({ user }) => {
  return (
    <div className="w-full flex flex-col relative">
      <div className="absolute bg-[#FFF6ED] w-full h-1/2 bottom-0" />
      <div className="p-10 lg:p-20 z-10">
        <div className="text-[#604E56] text-center pb-10">
          <div className="text-5xl md:text-[90px] leading-none font-extrabold">Education & Certification</div>
        </div>
        <div className="flex flex-col w-full gap-y-5">
          {user?.educations?.map((education, index) => {
            return <EducationCard key={index} education={education} />;
          })}
          {user?.certifications?.map((certification, index) => {
            return <EducationCard key={index} education={certification} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Education;
