import React from 'react';

const Building = ({ stroke, className, fill, hoverStroke, isHovered }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill={fill}
      className={className}
    >
      <g clipPath="url(#clip0_968_36)">
        <path
          d="M3 21.4355H21"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/profile' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 21.4355V7.43555L13 3.43555V21.4355"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/profile' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 21.4355V11.4355L13 7.43555"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/profile' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 9.43555V9.44555"
          stroke={isHovered ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 12.4355V12.4455"
          stroke={isHovered ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 15.4355V15.4455"
          stroke={isHovered ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 18.4355V18.4455"
          stroke={isHovered ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_968_36">
          <rect width="24" height="24" fill="white" transform="translate(0 0.435547)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Building;
