import { useSelector } from 'react-redux';
import linkOpenArrow from '../assets/images/linkOpenArrow.svg';
import { calculateDuration, jobDescriptionLimit } from '../utils/functions';

const JobCard = ({ key, organization, title, description, time, logo, id }) => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <div
      key={key}
      className="flex flex-col w-40 h-auto md:w-96 md:h-auto bg-oppty-grey-4 p-2 md:p-8 gap-y-2 rounded-[20px] justify-between shadow-lg"
    >
      <div>
        <div className="flex flex-row gap-1 md:gap-6 py-2 md:py-3 items-start">
          <div className="w-10 h-10 md:w-20 md:h-20 bg-gray-400 rounded-lg overflow-hidden">
            <img src={logo} alt="logo" className="w-full h-full object-cover " />
          </div>
          <div className="flex flex-col">
            <div className="text-oppty-black font-nunito font-medium text-xs md:text-2xl">{organization}</div>
            <div className="text-gray-400 text-left font-normal font-nunito text-[10px] md:text-lg">
              {calculateDuration(time)}
            </div>
          </div>
        </div>
        <div className="text-oppty-black font-nunito font-semibold text-xs md:text-[20px] md:leading-6 md:py-6">
          {title}
        </div>
        <div className="text-gray-400 font-normal font-nunito text-xs md:text-[16px] md:leading-[160%]">
          {jobDescriptionLimit(description, 23)}.....
        </div>
      </div>
      <div className="flex gap-4 items-center cursor-pointer" onClick={() => window.open(`/jobs/${id}`, '_blank')}>
        <div className="text-oppty-blue-4 font-nunito font-bold text-xs md:text-[18px] leading-6 md:py-6">
          {lang.generalView}
        </div>
        <img src={linkOpenArrow} alt="linkOpenArrow" className="w-4 h-4" />
      </div>
    </div>
  );
};

export default JobCard;
