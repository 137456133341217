import { useSelector } from 'react-redux';
import SavedJobCard from './savedJobCard';
import { useState } from 'react';

const SavedJobsList = ({ user }) => {
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const lang = useSelector((state) => state.language.lang);

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = user?.savedJobs?.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(user?.savedJobs?.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex flex-col justify-center py-5 lg:py-10 px-2 lg:px-16 rounded-[20px] bg-white overflow-x-auto">
      <div className="flex justify-between items-center pb-5">
        <div className="text-2xl font-nunito font-bold leading-normal text-black">
          {currentEntries.length > 0 ? (
            currentEntries.length === 1 ? (
              lang.generalShowing1Job
            ) : (
              <>
                {lang.generalShowing} {currentEntries.length} <span className="lowercase">{lang.generalSavedJobs}</span>
              </>
            )
          ) : (
            lang.generalShowingNoJob
          )}
        </div>
        <div className="flex items-center font-semibold">
          <label htmlFor="entriesPerPage" className="mr-2">
            {lang.generalShow}
          </label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesPerPageChange}
            className="border border-gray-300 rounded-md p-1 pl-3"
          >
            {[5, 10, 20, 50].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
          <span className="ml-2">{lang.generalEntries}</span>
        </div>
      </div>
      <div className="min-w-[1200px]">
        {currentEntries.map((savedJob, index) => {
          const isApplied = savedJob?.applicants?.some((applicant) => applicant?.user === user?.userId);
          return <SavedJobCard job={savedJob} user={user} key={index} isApplied={isApplied} />;
        })}
      </div>
      <div className="flex py-10">
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              {lang.generalShowing}{' '}
              <span className="font-medium">
                {indexOfFirstEntry + 1} {lang.generalTo} {Math.min(indexOfLastEntry, user?.savedJobs?.length)}
              </span>{' '}
              {lang.generalOf} <span className="font-medium">{user?.savedJobs?.length}</span> {lang.generalResults}
            </p>
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-black bg-white rounded-md font-semibold"
                disabled={currentPage === 1}
              >
                <span className="mr-4">{'<<'}</span>
                <span className="sr-only">{lang.generalPrevious}</span>
                {lang.generalPrevious}
              </button>
              <div>
                {pageNumbers?.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                      pageNumber === currentPage ? 'bg-black text-white rounded-md' : 'text-gray-900 bg-oppty-green-5'
                    }`}
                  >
                    {pageNumber}
                  </button>
                ))}
              </div>
              <button
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-black bg-white rounded-md font-semibold"
                disabled={currentPage === totalPages}
              >
                <span className="mr-4">{lang.generalNext}</span>
                <span className="">{'>>'}</span>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
        // <div className="flex flex-col justify-center py-5 lg:py-10 px-2 lg:px-16 rounded-[20px] bg-white lg:min-w-[1200px] overflow-auto">
    //   <div className="flex justify-between items-center pb-5">
    //     <div className="text-2xl font-nunito font-bold leading-normal text-black">
    //       {currentEntries.length > 0 ? (
    //         currentEntries.length === 1 ? (
    //           lang.generalShowing1Job
    //         ) : (
    //           <>
    //             {lang.generalShowing} {currentEntries.length} <span className="lowercase">{lang.generalSavedJobs}</span>
    //           </>
    //         )
    //       ) : (
    //         lang.generalShowingNoJob
    //       )}
    //     </div>
    //     <div className="flex items-center font-semibold">
    //       <label htmlFor="entriesPerPage" className="mr-2">
    //         {lang.generalShow}
    //       </label>
    //       <select
    //         id="entriesPerPage"
    //         value={entriesPerPage}
    //         onChange={handleEntriesPerPageChange}
    //         className="border border-gray-300 rounded-md p-1 pl-3"
    //       >
    //         {[5, 10, 20, 50].map((num) => (
    //           <option key={num} value={num}>
    //             {num}
    //           </option>
    //         ))}
    //       </select>
    //       <span className="ml-2">{lang.generalEntries}</span>
    //     </div>
    //   </div>
    //   {currentEntries.map((savedJob, index) => {
    //     const isApplied = savedJob?.applicants?.some((applicant) => applicant?.user === user?.userId);
    //     return <SavedJobCard job={savedJob} user={user} key={index} isApplied={isApplied} />;
    //   })}
    //   <div className="flex py-10">
    //     <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
    //       <div>
    //         <p className="text-sm text-gray-700">
    //           {lang.generalShowing}{' '}
    //           <span className="font-medium">
    //             {indexOfFirstEntry + 1} {lang.generalTo} {Math.min(indexOfLastEntry, user?.savedJobs?.length)}
    //           </span>{' '}
    //           {lang.generalOf} <span className="font-medium">{user?.savedJobs?.length}</span> {lang.generalResults}
    //         </p>
    //       </div>
    //       <div>
    //         <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
    //           <button
    //             onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
    //             className="relative inline-flex items-center rounded-l-md px-2 py-2 text-black bg-white rounded-md font-semibold"
    //             disabled={currentPage === 1}
    //           >
    //             <span className="mr-4">{'<<'}</span>
    //             <span className="sr-only">{lang.generalPrevious}</span>
    //             {lang.generalPrevious}
    //           </button>
    //           <div>
    //             {pageNumbers?.map((pageNumber) => (
    //               <button
    //                 key={pageNumber}
    //                 onClick={() => setCurrentPage(pageNumber)}
    //                 className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
    //                   pageNumber === currentPage ? 'bg-black text-white rounded-md' : 'text-gray-900 bg-oppty-green-5'
    //                 }`}
    //               >
    //                 {pageNumber}
    //               </button>
    //             ))}
    //           </div>
    //           <button
    //             onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
    //             className="relative inline-flex items-center rounded-r-md px-2 py-2 text-black bg-white rounded-md font-semibold"
    //             disabled={currentPage === totalPages}
    //           >
    //             <span className="mr-4">{lang.generalNext}</span>
    //             <span className="">{'>>'}</span>
    //           </button>
    //         </nav>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default SavedJobsList;
