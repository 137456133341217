import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { DocumentIcon } from '@heroicons/react/24/outline';
import Template1Pic from '../../../assets/templates/template1/template1.png';
import Template2Pic from '../../../assets/templates/template2/template2.png';
import Template3Pic from '../../../assets/templates/template3/template3.png';
import Template4Pic from '../../../assets/templates/template4/template4.png';
import Template5Pic from '../../../assets/templates/template5/template5.png';
import Template6Pic from '../../../assets/templates/template6/template6.png';
import Template7Pic from '../../../assets/templates/template7/template7.png';
import Template8Pic from '../../../assets/templates/template8/template8.png';
import Template9Pic from '../../../assets/templates/template9/template9.png';
import { updateTemplate } from '../../../actions/userAction';

const Template = () => {
  const dispatch = useDispatch();

  const [selectTemplate, setSelectTemplate] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.user);

  const templates = [
    { title: 'template1', label: 'Template 1', pic: Template1Pic },
    { title: 'template2', label: 'Template 2', pic: Template2Pic },
    { title: 'template3', label: 'Template 3', pic: Template3Pic },
    { title: 'template4', label: 'Template 4', pic: Template4Pic },
    { title: 'template5', label: 'Template 5', pic: Template5Pic },
    { title: 'template6', label: 'Template 6', pic: Template6Pic },
    { title: 'template7', label: 'Template 7', pic: Template7Pic },
    { title: 'template8', label: 'Template 8', pic: Template8Pic },
    { title: 'template9', label: 'Template 9', pic: Template9Pic }
  ];

  const handleSelect = (template) => {
    dispatch(updateTemplate({ template: template.title }, null));
    setSelectTemplate(false);
  };

  return (
    <div id="uploadResume" className="flex flex-col justify-center py-5 lg:py-10 px-2 mx-2 lg:px-16 lg:mx-16 rounded-[20px] bg-white"
>
      <div className="flex flex-row items-center gap-x-5">
        <div className="flex justify-center w-14 h-14 p-4 rounded-full bg-oppty-blue-5">
          {/* <img src={profileExperience} alt="profile icon" className="h-full w-full" /> */}
          <DocumentIcon className="h-full w-full stroke-blue-700" />
        </div>
        <div className="text-2xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {selectTemplate ? lang.profileSelectTemplate : lang.profilePublicTemplate}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {!selectTemplate && (
          <button
            onClick={() => setSelectTemplate(true)}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
          >
            {lang.profileSelect}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 pl-0 md:pl-16">
        <div className="flex flex-col gap-x-5 gap-y-2">
          {user?.template && !selectTemplate && (
            <div className="w-40 h-32 lg:w-60 lg:h-40 hover:shadow-lg flex justify-center items-center rounded-lg">
              <img
                src={templates?.find((template) => template?.title === user?.template)?.pic}
                alt="templatePic"
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          )}
        </div>
        {selectTemplate && (
          <div className="flex flex-col mt-8">
            <div className="flex flex-wrap gap-x-5 lg:gap-x-20 gap-y-10">
              {templates?.map((template, index) => {
                return (
                  <div key={index} className="flex flex-col gap-y-5 cursor-pointer">
                    <div
                      key={index}
                      onClick={() => handleSelect(template)}
                      className="w-40 h-32 lg:w-60 lg:h-40 hover:shadow-lg flex justify-center items-center"                    >
                      <img src={template?.pic} alt="templatePic" className="w-full h-full object-cover rounded-lg" />
                    </div>
                    <div className="text-2xl font-semibold">{template?.label}</div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-end flex-row gap-x-2 py-4">
              <button
                onClick={() => {
                  setSelectTemplate(false);
                }}
                className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
              >
                {lang.profileCancel}
              </button>
              {/* <button
                type="button"
                // onClick={handleUpload}
                className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md"
              >
                Upload
              </button> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Template;
