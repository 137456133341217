const WorkExperienceCard = ({ work }) => {
  return (
    <div className="border-l-[1px] px-10 border-template4-green-2">
      <div className="text-black font-semibold text-xl">{work?.position}</div>
      <div className="text-gray-400 text-base">{work?.companyName}</div>
      <div className="bg-template4-green-4 w-fit px-4 py-2 text-sm font-semibold rounded-2xl my-1">
        {work?.startMonth} {work?.startYear} -{' '}
        {work?.endYear === 99 ? 'Current' : `${work?.startMonth} ${work?.endYear}`}
      </div>
      {/* <div className="font-medium text-sm pt-3">{work?.description}</div> */}
      <div className="w-full pt-3" dangerouslySetInnerHTML={{ __html: work?.description }}></div>
    </div>
  );
};

export default WorkExperienceCard;
