const WorkExperienceCard = ({ work }) => {
  return (
    <div className="border-b-[1px] pb-5 pt-3 -mx-10 px-10 border-template3-blue-1">
      <div className="text-template3-blue-2 font-semibold text-2xl">{work?.position}</div>
      <div className="flex text-black font-medium text-base items-center gap-x-2">
        <div>{work?.companyName}</div>
        <div className="h-2.5 border-r-[1px] border-black" />
        <div>
          {work?.startMonth} {work?.startYear} -{' '}
          {work?.endYear === 99 ? 'Current' : `${work?.startMonth} ${work?.endYear}`}
        </div>
      </div>
      {/* <div className="font-medium text-sm pt-3">{work?.description}</div> */}
      <div className="w-full pt-3" dangerouslySetInnerHTML={{ __html: work?.description }}></div>
    </div>
  );
};

export default WorkExperienceCard;
