import EducationCard from './educationCard';
import bgImage from '../../../assets/templates/template7/template7bg.jpeg';

const Education = ({ user }) => {
  return (
    <div className="w-full flex flex-col shadow-md bg-white">
      <div className="flex flex-col lg:flex-row my-5">
        <div className="hidden lg:flex flex-col w-full lg:w-1/2 p-10">
          <div className="flex-grow flex items-center justify-center">
            <img src={bgImage} alt="bgImage" className="h-full w-full object-cover" />
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-1/2 gap-y-5 p-10 lg:p-10">
          <div className="text-5xl font-bold mb-5 text-black">Education & Certification</div>
          {user?.educations?.map((education, index) => {
            return <EducationCard key={index} education={education} />;
          })}
          {user?.certifications?.length > 0 && (
            <div className="text-2xl font-semibold mt-1 text-black uppercase underline py-4">Certifications</div>
          )}
          {user?.certifications?.map((certification, index) => {
            return <EducationCard key={index} education={certification} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Education;
