import { useSelector } from 'react-redux';
import candidateGirl from '../../../assets/images/candidateGirl.png';
import speakerIcon from '../../../assets/images/speakerPhoneIcon.svg';
// import candidateTool from '../../../assets/images/candidateTool.svg';
// import candidateBonus from '../../../assets/borderLayouts/candidateBonus.svg';
// import rollingCircle from '../../../assets/borderLayouts/circleRollArrow.svg';
// import starGroup from '../../../assets/borderLayouts/candidateImgStarGroup.svg';
import { ReactComponent as MainImage } from '../../../assets/images/candidateLandingPic2.svg';
import { ReactComponent as StarImage } from '../../../assets/images/candidateLandingImg.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FindYourDream = () => {
  const navigate = useNavigate();

  const [jobTitle, setPosition] = useState('');

  const lang = useSelector((state) => state.language.lang);

  // const trends = ['Sales', 'Customer Service', 'Marketing', 'Audit', 'Software'];

  const handleSearch = () => {
    console.log('jobTitle: ', jobTitle);
    navigate(`/search-jobs`, { state: { jobTitle: jobTitle } });
  };

  return (
    <div className="container-fluid w-full pb-0 px-8 md:px-16 lg:pt-2">
      <div className="grid grid-cols-6 gap-10">
        <div className="col-span-2">
          <div className="max-w-full overflow-hidden relative">
            <MainImage />
            <img src={candidateGirl} alt="candidateGirl" className="w-full h-full absolute object-cover top-3 md:top-10 lg:top-20" />
          </div>

          <div className="relative">
            <StarImage />
            <div className="absolute top-0 -right-14  w-10 h-10 md:w-24 md:h-24 rounded-full bg-white shadow-lg items-center justify-center hidden md:flex">
              <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 md:w-14 md:h-14">
                <g clip-path="url(#clip0_119_1872)">
                  <path
                    d="M47.4632 19.1567L39.0632 23.0767L33.6265 17.9667V14.7L39.0632 9.58999L47.4632 13.51C48.3499 13.93 49.3765 13.5333 49.7965 12.67C50.2165 11.7833 49.8199 10.7567 48.9565 10.3367L39.8099 6.06666C39.3761 5.86199 38.8895 5.79697 38.4173 5.88055C37.945 5.96414 37.5103 6.19222 37.1732 6.53333L33.1132 10.2667C32.9037 9.97982 32.63 9.746 32.314 9.58393C31.9979 9.42185 31.6484 9.33603 31.2932 9.33333C30.0099 9.33333 28.9599 10.3833 28.9599 11.6667V14H21.5399C20.4199 10.85 17.2465 8.70333 13.5599 9.49666C10.8532 10.08 8.54319 12.4133 8.05319 15.1667C7.53985 18.2467 9.12652 20.93 11.5065 22.3533L17.4799 42H10.2932V49H40.6265V42H32.1799L20.5832 20.4633C20.9799 19.9033 21.3065 19.32 21.5399 18.6667H28.9599V21C28.9599 22.2833 30.0099 23.3333 31.2932 23.3333C32.0399 23.3333 32.6932 22.96 33.1132 22.4L37.1732 26.1333C37.8732 26.8333 38.9232 27.02 39.8099 26.6L48.9565 22.33C49.8432 21.91 50.2165 20.8833 49.7965 19.9967C49.5978 19.5765 49.2407 19.2522 48.8033 19.0947C48.366 18.9373 47.8841 18.9596 47.4632 19.1567ZM14.9599 18.6667C13.6765 18.6667 12.6265 17.6167 12.6265 16.3333C12.6265 15.05 13.6765 14 14.9599 14C16.2432 14 17.2932 15.05 17.2932 16.3333C17.2932 17.6167 16.2432 18.6667 14.9599 18.6667Z"
                    fill="#D7D0FF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_119_1872">
                    <rect width="56" height="56" fill="white" transform="translate(0.959961)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <div className=" text-center pb-[10px] md:pb-[10px] lg:pb-[0px] text-[22px] md:text-[40px] lg:text-[60px] xl:text-[80px] dm-serif-font font-medium md:leading-[40px] lg:leading-[58px] xl:leading-[96px] tracking-[-2.4px] leading-[25px] text-oppty-black">
           {lang.candidateTitlePart1} <span className="text-oppty-blue"> {lang.candidateTitleDreamJob}</span> {lang.candidateTitlePart2}{' '}
            <span className="text-oppty-yellow-2">{lang.candidateTitleCareerPath}</span> {lang.candidateTitlePart3}
          </div>
          <div className="hidden md:flex w-10 h-10 md:w-20 md:h-20 bg-white rounded-full shadow-lg items-center justify-center mb-4 md:mb-10">
            <img src={speakerIcon} alt="speakerPhone" className="w-6 h-6 md:w-12 md:h-12" />
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder={lang.clientWhatPosition}
              onChange={(e) => setPosition(e.target.value)}
              className="py-2 md:py-6 px-2 md:px-8 rounded-[15px] w-full border-[1px] border-oppty-black-5 pr-12 text-[8px] md:text-[24px]"
            />
            <button
              type="button"
              onClick={() => handleSearch()}
              className="absolute inset-y-0 right-2 md:right-4 top-2 md:top-3 px-3 md:px-10 h-2/4 md:h-3/4 rounded-[15px] bg-oppty-black-5 text-white text-[8px] md:text-[18px] font-normal leading-normal"
            >
              {lang.generalSearch}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindYourDream;
