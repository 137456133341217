import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as RightArrow } from '../../assets/icons/rightArrow.svg';
import ActiveJobCard from './activeJobCard';

const DashboardFooterSection = () => {
  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.employer);

  return (
    <div className="py-6 px-4 md:px-8 flex flex-col bg-white">
      <div className="border-b-[1px]">
        <div className="flex justify-between p-6">
          <div className="text-xl font-nunito font-bold leading-normal text-black">{lang.companyActiveJob}</div>
          <div className="flex items-center gap-x-3">
            <Link className="text-base font-nunito font-bold leading-normal text-oppty-blue">
              {lang.generalViewAll}
            </Link>
            <RightArrow />
          </div>
        </div>
      </div>
      <div className="flex w-full py-5 gap-x-5">
        {employer?.activeJobs?.slice(0, 3).map((job, index) => {
          return (
            <div key={index} className="w-[33%]">
              <ActiveJobCard job={job} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardFooterSection;
