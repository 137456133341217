import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import share from '../../../assets/images/share.svg';
import { calculateDuration, copyToClipboard, getLabelByValue } from '../../../utils/functions';
import { saveJob } from '../../../actions/jobAction';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { ReactComponent as OpptyAiIcon } from '../../../assets/icons/opptyAi.svg';
import { useEffect, useState } from 'react';
import { ToastBar } from '../../../common/toastbar';
import { jobTypeOptions, workPolicyOption } from '../../../constant/dropdownConstOptions';

const linkUrl = process.env.REACT_APP_LINKURL;

const Introduction = () => {
  const dispatch = useDispatch();

  const [isApplied, setIsApplied] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const job = useSelector((state) => state.selectedJob);
  const user = useSelector((state) => state.user);

  const alreadySaved = user?.savedJobs?.some((savedJob) => savedJob._id === job?.job?._id);
  console.log('already: ', alreadySaved);
  console.log('job?.job?._id: ', job?.job?._id);

  const handleSaveJob = () => {
    const exists = user?.savedJobs?.some((obj) => obj?._id === job?.job?._id);
    const inputValue = {
      ...job?.job,
      exist: exists
    };
    dispatch(saveJob(inputValue));
  };

  const handleApplyJob = () => {
    if (user?.userId) {
      const childWindow = window.open('/apply-job', '_blank');

      if (childWindow) {
        childWindow.onload = () => {
          const message = { type: 'JobData', data: job?.job };
          childWindow.postMessage(message, window.location.origin);
        };
      } else {
        alert('Failed to open the new window');
      }
    } else {
      ToastBar.warning('You need to login to apply');
    }
  };

  useEffect(() => {
    const isApplied = job?.job?.applicants?.some((applicant) => applicant?.user === user?.userId);
    setIsApplied(isApplied);
  }, [job, user]);

  console.log('job: ', job);
  console.log('isApplied: ', isApplied);

  return (
    <div className="flex flex-col justify-center py-10 px-16">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <div className="text-4xl font-nunito font-bold leading-6 text-oppty-black">{job?.job?.jobTitle}</div>
          <div
            className="text-2xl font-nunito font-semibold leading-6 text-oppty-blue pt-3 cursor-pointer"
            onClick={() => window.open(`/employers/${job?.job?.employer?._id}`, '_blank')}
          >
            {job?.job?.employer?.companyName}
          </div>
          <div className="text-base font-nunito font-normal leading-6 text-oppty-black pt-2 pb-2 mb-10">
            {job?.job?.employer?.companyLocation}
          </div>
        </div>
        <div className="flex flex-row gap-x-4">
          <div
            className="w-10 h-10 border-[1px] border-gray-300 p-1.5 hover:bg-orange-100 cursor-pointer"
            onClick={handleSaveJob}
          >
            <BookmarkIcon className={`h-full w-full ${alreadySaved && 'fill-orange-800'} stroke-orange-800 stroke-2`} />
          </div>
          <img
            src={share}
            alt="share"
            onClick={() => copyToClipboard(`${linkUrl}/jobs/${job?.job?._id}`, 'Job URL Copied')}
            className="h-10 w-10 hover:bg-orange-100 cursor-pointer"
          />
          <button
            type="button"
            disabled={isApplied}
            className={`bg-black text-white text-base h-10 font-nunito font-semibold leading-normal px-7 hover:bg-blue-gray-800 disabled:bg-black/60`}
            onClick={handleApplyJob}
          >
            {isApplied ? 'Applied' : lang.generalApplyNow}
          </button>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="max-w-[450px] flex flex-col border-[1px] border-black/50 p-5 rounded-2xl gap-y-2 justify-center">
          <div className="text-base font-nunito font-semibold leading-normal text-oppty-grey-1">
            Posted {calculateDuration(job?.job?.createdDate)} ago
          </div>
          <div className="text-xl font-nunito font-medium leading-normal text-oppty-grey-9">
            {job.job?.applicants?.length} {lang.generalApplicant}
          </div>
          <div className="flex gap-x-5">
            <div className="text-base font-nunito font-medium leading-normal text-oppty-grey-9">
              {lang.generalJobCategory}
            </div>
            <Link to="" className="text-base font-nunito font-normal leading-normal text-oppty-blue underline">
              {job.job?.employer?.companyIndustry}
            </Link>
          </div>
        </div>
      </div>
      <div className="flex py-5 my-8 w-3/4">
        <div className="flex-grow">
          <div className="text-base font-nunito font-normal leading-[20px] text-oppty-grey-10">
            {lang.generalExperience}
          </div>
          <div className="text-xl font-nunito font-medium leading-normal text-oppty-grey-9">
            {job?.job?.experienceRequired}
          </div>
        </div>
        <div className="flex-grow">
          <div className="text-base font-nunito font-normal leading-[20px] text-oppty-grey-10">{lang.generalJobType}</div>
          <div className="text-xl font-nunito font-medium leading-normal text-oppty-grey-9">
            {getLabelByValue(job?.job?.jobEmploymentType, jobTypeOptions)}
          </div>
        </div>
        <div className="flex-grow">
          <div className="text-base font-nunito font-normal leading-[20px] text-oppty-grey-10">
            {lang.generalEmployerType}
          </div>
          <div className="text-xl font-nunito font-medium leading-normal text-oppty-grey-9">{getLabelByValue(job?.job?.workPolicy, workPolicyOption)}</div>
        </div>
        <div className="flex-grow">
          <div className="text-base font-nunito font-normal leading-[20px] text-oppty-grey-10">
            {lang.generalOfferSalary} <span className='font-bold capitalize text-gray-400'>({job?.job?.salaryType})</span>
          </div>
          <div className="text-xl font-nunito font-medium leading-normal text-oppty-grey-9">
          {job?.job?.currencyType} {job?.job?.minSalary} <span className={`${job?.job?.maxSalary ? '' : 'hidden'}`}>- {job?.job?.currencyType} {job?.job?.maxSalary}</span>
          </div>
        </div>
        {/* <div className="flex-grow">
          <div className="text-base font-nunito font-normal leading-[20px] text-oppty-grey-10">
            {lang.generalExperienceLevel}
          </div>
          <div className="text-xl font-nunito font-medium leading-normal text-oppty-grey-9">Senior</div>
        </div> */}
      </div>
      <div className="text-4xl font-nunito font-bold leading-normal text-oppty-grey-9">{lang.generalSkills}</div>
      <div className="flex flex-wrap pt-5 gap-4 border-b-[1px] pb-12">
        {job?.job?.skills?.map((skill) => {
          const isSame = user?.skills?.some((userSkill) => userSkill.skill === skill?.value);

          return (
            <div
              className={`px-7 py-2 font-nunito rounded-[60px] text-base ${isSame ? 'bg-green-400 font-bold' : 'bg-oppty-green-2 font-normal'}`}
            >
              {skill?.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Introduction;
