import React from 'react';
import Header from '../../common/header';
import ContactForm from './contactForm';
import Footer from '../../common/footer';

const ContactUs = () => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div className="flex flex-col justify-center items-center">
        <Header />
        <div className="w-full">
          <ContactForm />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
