import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Select from 'react-select';
import { socialOptions } from '../../constant/dropdownConstOptions';
import { addSocial, socialDelete } from '../../actions/employerAction';
import SocialTile from '../../common/socialTile';
import { socialSchema } from '../../validations/validationSchemas';

const SocialsModal = ({ closeModal, employer }) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language.lang);

  const formik = useFormik({
    initialValues: {
      social: '',
      link: ''
    },
    validationSchema: socialSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      dispatch(addSocial(values));
      resetForm();
    }
  });

  const handleDeleteSocial = (index) => {
    const deleteSocial = employer?.socials[index];

    console.log('deleteSocial ', deleteSocial);
    dispatch(socialDelete(deleteSocial));
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white flex flex-col min-w-[400px] w-[1200px] px-5 md:px-10 py-10 rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold"> {lang.companySocials}</h2>
            <button
              onClick={closeModal}
              className="text-lg font-bold hover:bg-red-500 px-2 rounded-md hover:text-white"
            >
              X
            </button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="flex-grow border-t-[1px] pt-3 border-oppty-grey-15"></div>
              <div className="flex flex-col lg:flex-row gap-x-10 pt-5">
                <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.profileSelectSocial}
                  </div>
                  <Select
                    className="basic-single"
                    classNamePrefix={lang.profileSelect}
                    defaultValue={'Select Proficiency'}
                    placeholder={lang.profileSelect}
                    value={socialOptions.find((option) => option.value === formik.values.level)}
                    onChange={(selectedOption) => formik.setFieldValue('social', selectedOption?.value || '')}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    name="social"
                    options={socialOptions}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 54
                      })
                    }}
                  />
                  {formik.touched.social && formik.errors.social ? (
                    <div className="text-red-500 text-sm">{formik.errors.social}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.generalLink}
                  </div>
                  <input
                    type="text"
                    id="link"
                    name="link"
                    onChange={formik.handleChange}
                    value={formik.values.link}
                    className="w-full py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                  />
                  {formik.touched.link && formik.errors.link ? (
                    <div className="text-red-500 text-sm">{formik.errors.link}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex flex-wrap gap-x-5 pt-7 gap-y-2">
                {employer?.socials?.map((social, index) => {
                  return (
                    <SocialTile
                      social={social.social}
                      link={social.link}
                      key={index}
                      onDelete={() => handleDeleteSocial(index)}
                    />
                  );
                })}
              </div>
              <div className="flex justify-end flex-row gap-x-2 pt-10 pb-5">
                <button
                  onClick={() => closeModal()}
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
                >
                  {lang.profileCancel}
                </button>
                <button
                  type="submit"
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md"
                >
                  {lang.profileAdd}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SocialsModal;
