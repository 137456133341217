import sampleImage from '../assets/images/sampleArticleImg.jpg';

const ArticleCard = ({ article }) => {
  return (
    <div
      className="w-80 bg-gray-100 rounded-lg flex flex-col justify-between overflow-hidden cursor-pointer hover:shadow-lg"
      onClick={() => window.open(`/article/${article?._id}`, '_blank')}
    >
      <div>
        <img src={sampleImage} alt={article?.name} className="h-36 w-full object-cover" />
        <div className="p-5 flex flex-col justify-between">
          <div className="bg-purple-600 w-fit px-3 py-1.5 rounded-md text-white font-bold mb-5 text-sm capitalize">
            {article?.category}
          </div>
          <div className="font-bold text-lg">{article?.name}</div>
        </div>
      </div>
      <div className="font-semibold px-5 pb-5">By: OppTy</div>
    </div>
  );
};

export default ArticleCard;
