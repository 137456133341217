import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import BarChartComponent from './barChart';

const JobStatistic = () => {
  const [selectedTab, setSelectedTab] = useState('Candidate');
  const [xLabels, setXLabels] = useState([]);
  const [barChartData, setBarChartData] = useState([]);

  const dashboardDetails = useSelector((state) => state.admin?.dashboardData);

  useEffect(() => {
    if (selectedTab === 'Candidate') {
      let xLabels = dashboardDetails?.usersJoinedMonthly ? Object.keys(dashboardDetails.usersJoinedMonthly) : [];
      const data = dashboardDetails?.usersJoinedMonthly ? Object.values(dashboardDetails.usersJoinedMonthly) : [];
      setXLabels(xLabels);
      setBarChartData(data);
    } else if (selectedTab === 'Employer') {
      let xLabels = dashboardDetails?.employersJoinedMonthly
        ? Object.keys(dashboardDetails.employersJoinedMonthly)
        : [];
      const data = dashboardDetails?.employersJoinedMonthly
        ? Object.values(dashboardDetails.employersJoinedMonthly)
        : [];
      setXLabels(xLabels);
      setBarChartData(data);
    }
  }, [selectedTab, dashboardDetails]);

  return (
    <div className="border-[1px] w-full">
      <div className="flex justify-between items-center px-6 pt-6">
        <div>
          <div className="text-xl font-nunito font-bold leading-normal text-black">User statistic</div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="border-b-[1px]">
          <div className="flex gap-x-10 pt-5 px-6">
            <button
              type="button"
              className={`text-base pb-1.5 font-semibold border-b-4 ${
                selectedTab === 'Candidate'
                  ? 'text-oppty-blue border-oppty-blue rounded-b-md'
                  : 'border-transparent text-oppty-grey-19'
              } hover:text-black hover:border-oppty-blue hover:rounded-b-md`}
              onClick={() => setSelectedTab('Candidate')}
            >
              Candidates Joined
            </button>
            <button
              type="button"
              className={`text-base pb-1.5 font-semibold border-b-4 ${
                selectedTab === 'Employer'
                  ? 'text-oppty-blue border-oppty-blue rounded-b-md'
                  : 'border-transparent text-oppty-grey-19'
              } hover:text-black hover:border-oppty-blue hover:rounded-b-md`}
              onClick={() => setSelectedTab('Employer')}
            >
              Employers Joined
            </button>
          </div>
        </div>
        <div className="flex gap-x-5 py-1 px-6">
          <div className="w-full h-[345px]">
            <BarChartComponent data={barChartData} xLabels={xLabels} label={selectedTab} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobStatistic;
