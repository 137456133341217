import WorkExperienceCard from './workExperienceCard';

const WorkExperience = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-20">
      <div className="text-4xl lg:text-5xl font-bold mb-20 text-white">
        <span className="bg-template9-blue-2">Work</span> Experience
      </div>
      <div className='flex flex-col gap-5'>
        {user?.workExperiences?.map((work, index) => {
          const isFirstIndex = index === 0 ? true : false;
          return <WorkExperienceCard key={index} work={work} isFirstIndex={isFirstIndex}/>;
        })}
      </div>
    </div>
  );
};

export default WorkExperience;
