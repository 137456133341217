const GeneralTile = ({ name, level, uniqueKey, onDelete }) => {
  return (
    <div key={uniqueKey + name} className="flex px-8 py-2 bg-oppty-grey-17 rounded-md gap-x-3">
      <div className="text-lg font-bold">
        {name} {level && <span>({level})</span>}
      </div>
      {onDelete && (
        <button onClick={onDelete} className="text-sm font-bold hover:bg-red-500 px-1">
          X
        </button>
      )}
    </div>
  );
};

export default GeneralTile;
