import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Datepicker from 'react-tailwindcss-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { ToastBar } from '../../../common/toastbar';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';

const DashboardHeaderSection = ({ dateRange, setDateRange }) => {
  const dashboardDetails = useSelector((state) => state.admin?.dashboardData);

  const handleValueChange = (newValue) => {
    console.log('datePickerValue:', newValue);
    setDateRange(newValue);
  };

  console.log('dashboard: ', dashboardDetails);

  const options = { day: 'numeric', month: 'long', year: 'numeric' };

  const downloadExcel = () => {
    try {
      const {
        totalUsers,
        totalEmployers,
        usersJoinedMonthly,
        employersJoinedMonthly,
        usersJoinedInRange,
        employersJoinedInRange,
        totalJobs,
        jobsInRange
      } = dashboardDetails;
      const dateRangeString = `${new Date(dateRange?.startDate).toLocaleDateString('en-US', options)} - ${new Date(dateRange?.endDate).toLocaleDateString('en-US', options)}`;

      const data = [
        ['Total Users', totalUsers],
        ['Total Employers', totalEmployers],
        ['Total Jobs', totalJobs],
        [],
        [],
        ['Date Range', dateRangeString],
        ['Users Joined In Range', usersJoinedInRange],
        ['Employers Joined In Range', employersJoinedInRange],
        ['Jobs In Range', jobsInRange],
        [],
        [],
        ['Users Joined Monthly'],
        ['Month', 'Count'],
        ...Object.entries(usersJoinedMonthly),
        [],
        [],
        ['Employers Joined Monthly'],
        ['Month', 'Count'],
        ...Object.entries(employersJoinedMonthly)
      ];

      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'User Statistics');

      // Adjust column width
      const maxLength = {};
      data.forEach((row) => {
        row.forEach((value, index) => {
          const length = String(value).length;
          maxLength[index] = Math.max(maxLength[index] || 0, length);
        });
      });
      const colWidths = Object.values(maxLength).map((length) => ({ wch: length + 2 })); // +2 for padding
      worksheet['!cols'] = colWidths;

      const now = new Date();
      const formattedDate = format(now, 'yyyy-MM-dd_HH-mm');
      const filename = `User_Statistics_${formattedDate}.xlsx`;

      XLSX.writeFile(workbook, filename);

      ToastBar.success('Excel Download Successful');
    } catch (error) {
      console.log(error);
      ToastBar.error('Error Downloading Excel');
    }
  };

  return (
    <div className="flex flex-col w-full bg-white p-8 gap-y-8">
      <div className="flex justify-between items-center">
        <div>
          <div className="text-base font-nunito font-medium leading-normal text-oppty-grey-19">
            Here is your user statistic report from{' '}
            {new Date(dateRange?.startDate).toLocaleDateString('er-US', options)} -{' '}
            {new Date(dateRange?.endDate).toLocaleDateString('er-US', options)}
          </div>
        </div>
        <div className="flex flex-row gap-x-4">
          <div className="w-72">
            <Datepicker
              primaryColor={'indigo'}
              popoverDirection="down"
              value={dateRange}
              onChange={handleValueChange}
              displayFormat="DD-MM-YYYY"
            />
          </div>
          <button onClick={downloadExcel}>
            <FontAwesomeIcon icon={faFileExcel} size="2x" color="green" />
          </button>
        </div>
      </div>
      <div className="flex gap-x-6">
        <div className="flex-grow border-[1px] border-black flex items-center gap-x-3 p-6">
          <div className="text-5xl font-nunito font-bold leading-normal text-black">
            {dashboardDetails?.usersJoinedInRange}
          </div>
          <div className="text-lg font-nunito font-medium leading-normal text-black">User joined</div>
        </div>
        <div className="flex-grow border-[1px] border-black flex items-center justify-between p-6">
          <div className="flex gap-x-3 items-center">
            <div className="text-5xl font-nunito font-bold leading-normal text-black">
              {dashboardDetails?.employersJoinedInRange}
            </div>
            <div className="text-lg font-nunito font-medium leading-normal text-black">Employers Joined</div>
          </div>
        </div>
        <div className="flex-grow border-[1px] border-black flex items-center gap-x-3 p-6">
          <div className="text-5xl font-nunito font-bold leading-normal text-black">
            {' '}
            {dashboardDetails?.jobsInRange}
          </div>
          <div className="text-lg font-nunito font-medium leading-normal text-black">Jobs created</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeaderSection;
