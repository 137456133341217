import { useSelector } from 'react-redux'
import cus1 from '../../../assets/employersLogo/aspire.png'
import cus2 from '../../../assets/employersLogo/cropped-dnb-sinagpore-190x42.png'
import cus3 from '../../../assets/employersLogo/cy_logo_L.png'
import cus4 from '../../../assets/employersLogo/forvis-mazars-logo 1.png'
import cus5 from '../../../assets/employersLogo/green_long_logo.png'
import cus6 from '../../../assets/employersLogo/header-logo.png'
import cus7 from '../../../assets/employersLogo/images (4).png'
import cus8 from '../../../assets/employersLogo/logo (1).png'
import cus9 from '../../../assets/employersLogo/logo (3).png'
import cus10 from '../../../assets/employersLogo/logo-up 1.png'
import cus11 from '../../../assets/employersLogo/logo.png'
import cus12 from '../../../assets/employersLogo/logo_ci_liebherr_fl.png'

const HappyCustomers = () => {
  const lang = useSelector((state) => state.language.lang)

  const customerLogos = [
    { src: cus1, alt: 'cus1' },
    { src: cus2, alt: 'cus2' },
    { src: cus3, alt: 'cus3' },
    { src: cus4, alt: 'cus4' },
    { src: cus5, alt: 'cus5' },
    { src: cus6, alt: 'cus6' },
    { src: cus7, alt: 'cus7' },
    { src: cus8, alt: 'cus8' },
    { src: cus9, alt: 'cus9' },
    { src: cus10, alt: 'cus10' },
    { src: cus11, alt: 'cus11' },
    { src: cus12, alt: 'cus12' },
  ]

  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <div className="outfit-font lg:text-[40px] font-normal text-oppty-black-3 w-full text-center xl:leading-[50px]">
        {lang.employeeHomeJoinThousand}
      </div>
      <div className="flex flex-wrap gap-10 items-center justify-center w-full px-20 mt-14">
        {customerLogos.map((logo, index) => (
          <img
            key={index}
            className={`h-12`}
            src={logo.src}
            alt={logo.alt}
          />
        ))}
      </div>
    </div>
  )
}

export default HappyCustomers
