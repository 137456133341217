// src/hooks/useListenNotifications.js
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSocketContext } from '../context/socketContext';
import { fetchNotifications } from '../actions/notificationAction';
import { ToastBar } from '../common/toastbar';

const useListenNotifications = () => {
  const { socket } = useSocketContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (socket) {
      const handleNewNotification = (notification) => {
 
        dispatch(fetchNotifications());
        ToastBar.success("New Notification!");
      };

      socket.on('newNotification', handleNewNotification);

      return () => {
        socket.off('newNotification', handleNewNotification);
      };
    }
  }, [socket, dispatch]);
};

export default useListenNotifications;
