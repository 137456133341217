import { useState } from 'react';
import { DocumentIcon, TrashIcon } from '@heroicons/react/24/outline';
import PortfolioImageUploadModal from './portfolioImageUploadModal';
import { useSelector } from 'react-redux';

const UploadPortfolioImage = ({ handleInputImage, handleDeleteImage, pic, setPreviewPortfolioImage }) => {
  const [uploadPortfolioPicModal, setUploadPortfolioPicModal] = useState(false);

  const lang = useSelector((state) => state.language.lang);

  const handleDelete = () => {
    handleDeleteImage();
  };

  return (
    <div className="flex flex-col justify-center rounded-[20px] w-full">
      {!pic ? (
        <div
          className={`drop-area flex flex-col gap-y-5 justify-center items-center h-48 rounded-md w-full bg-oppty-grey-17`}
          onClick={() => setUploadPortfolioPicModal(true)}
        >
          <DocumentIcon className="top-10 stroke-gray-700 w-8 h-8" />
          <button type="button" className="px-5 py-1 rounded-xl bg-gray-800 text-white font-bold cursor-pointer">
            + {lang.generalUploadImage}
          </button>
        </div>
      ) : (
        <div className="mt-3 flex flex-wrap gap-x-4 rounded-b-md">
          <div className="relative w-28 h-28 lg:w-40 lg:h-40 rounded-md hover:border-violet-600 border-2">
            <img src={pic} alt={pic.name} className="w-full h-full object-cover rounded-md" />
            <TrashIcon
              onClick={() => handleDelete(pic)}
              className="w-7 h-7 bg-red-500 stroke-white rounded-md p-1 absolute top-1 right-1 hover:bg-red-600"
            />
          </div>
        </div>
      )}
      {uploadPortfolioPicModal && (
        <PortfolioImageUploadModal
          closeModal={() => setUploadPortfolioPicModal(false)}
          handleInputImage={handleInputImage}
          setPreviewPortfolioImage={setPreviewPortfolioImage}
        />
      )}
    </div>
  );
};

export default UploadPortfolioImage;
