import { useDispatch, useSelector } from 'react-redux';
import profileSkill from '../../../assets/icons/profileSkill.svg';
import GeneralTile from '../../../common/generealTile';
import Select from 'react-select';
import skillOptions from '../../../constant/skills.json';
import { useState } from 'react';
import { useFormik } from 'formik';
import { skillAdd, skillDelete } from '../../../actions/userAction';
import AsyncSelect from 'react-select/async';
import { skillSchema } from '../../../validations/validationSchemas';
import { levelOptions } from '../../../constant/dropdownConstOptions';
import { getLabelByValue, getOptionsByLanguage } from '../../../utils/functions';
import { ToastBar } from '../../../common/toastbar';

const Skills = () => {
  const localLang = localStorage.getItem('lan');

  const dispatch = useDispatch();

  const [addSkill, setAddSkill] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.user);

  const formik = useFormik({
    initialValues: {
      skill: '',
      level: ''
    },
    validationSchema: skillSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      const skillExists = user?.skills?.some((skillObj) => skillObj.skill.toLowerCase() === values.skill.toLowerCase());

      if (skillExists) {
        ToastBar.warning(['This skill already added', '此技能已添加', 'Kemahiran ini sudah ditambahkan'][localLang]);
      } else {
        dispatch(skillAdd(values));
        resetForm();
        setAddSkill(false);
      }
    }
  });

  const handleDeleteSkill = (index) => {
    const deleteSkill = user.skills[index];

    console.log('deleteSkill: ', deleteSkill);
    dispatch(skillDelete(deleteSkill));
  };

  // const handleDeleteSkill = (index) => {
  //   const updatedSkills = [...user.skills];
  //   updatedSkills.splice(index, 1);

  //   console.log('updatedSkills: ', updatedSkills);
  //   dispatch(skillDelete(updatedSkills));
  // };

  const skillLoadOptions = (inputValue, callback) => {
    const filteredOptions = skillOptions
      .filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 10);

    callback(filteredOptions);
  };

  return (
    <div
      id="skills"
      className="flex flex-col justify-center py-5 lg:py-10 px-2 mx-2 lg:px-16 lg:mx-16 rounded-[20px] bg-white "
    >
      <div className="flex flex-row items-center gap-x-5">
        <div className="flex justify-center w-14 h-14 p-4 rounded-full bg-oppty-blue-5">
          <img src={profileSkill} alt="profile icon" className="h-full w-full" />
        </div>
        <div className="text-2xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.generalSkills}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {!addSkill && (
          <button
            onClick={() => setAddSkill(true)}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
          >
            + {lang.profileAdd}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 pl-0 md:pl-16">
        <div className="flex flex-wrap gap-x-5 lg:pb-7 gap-y-2">
          {user?.skills?.map((skill, index) => {
            return (
              <GeneralTile
                name={skill.skill}
                level={getLabelByValue(skill.level, levelOptions)}
                uniqueKey={index}
                key={index}
                onDelete={() => handleDeleteSkill(index)}
              />
            );
          })}
        </div>
        {addSkill && (
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="flex-grow border-t-[1px] pt-3 border-oppty-grey-15"></div>
              <div className="flex flex-col lg:flex-row gap-x-10 gap-y-5">
                <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.profileSelectSkill}
                  </div>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={skillOptions.find((option) => option.value === formik.values.skill)}
                    placeholder={lang.generalSearch}
                    onChange={(selectedOption) => formik.setFieldValue('skill', selectedOption?.value || '')}
                    loadOptions={skillLoadOptions}
                    isDisabled={false}
                    isClearable={true}
                    isSearchable={true}
                    name="skill"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 54
                      }),
                      input: (provided) => ({
                        ...provided,
                        boxShadow: 'none',
                        '& input': {
                          boxShadow: 'none !important',
                          outline: 'none !important'
                        }
                      })
                    }}
                  />
                  {formik.touched.skill && formik.errors.skill ? (
                    <div className="text-red-500 text-sm">{formik.errors.skill}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.profileLevelOfPro}
                  </div>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={'Select Proficiency'}
                    placeholder={lang.profileSelect}
                    value={levelOptions.find((option) => option.value === formik.values.level)?.[localLang]}
                    onChange={(selectedOption) => formik.setFieldValue('level', selectedOption?.value || '')}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    name="proficiency"
                    options={getOptionsByLanguage(levelOptions)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 54
                      })
                    }}
                  />
                  {formik.touched.level && formik.errors.level ? (
                    <div className="text-red-500 text-sm">{formik.errors.level}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-end flex-row gap-x-2 pt-10 pb-5">
                <button
                  onClick={() => setAddSkill(false)}
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
                >
                  {lang.profileCancel}
                </button>
                <button
                  type="submit"
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md"
                >
                  {lang.profileAdd}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Skills;
