import templatePic from '../../../assets/templates/template5/template5bg.jpeg';
import ItemTile from './itemTile';

const AboutMe = ({ user }) => {
  return (
    <div className="w-full flex flex-col lg:flex-row justify-between p-10 lg:p-20 shadow-md bg-white gap-x-10">
      <div className="w-full lg:w-1/3 pb-5">
        {user?.aboutMe && <div className="text-5xl font-bold mb-5">About Me</div>}
        {/* <div className="text-lg font-medium mb-5">{user?.aboutMe}</div> */}
        <div className="w-full mb-5" dangerouslySetInnerHTML={{ __html: user?.aboutMe }}></div>
      </div>
      <img src={templatePic} alt="pic" className="hidden lg:flex lg:w-96 rounded-lg object-cover" />
      <div className="w-full lg:w-1/3">
        {user?.languages?.length > 0 && <div className="text-4xl font-bold mb-5">Languages</div>}
        <div className="flex flex-wrap gap-5">
          {user?.languages?.map((lang, index) => (
            <ItemTile item={lang?.lang} level={lang?.level} key={index} />
          ))}
        </div>
        {user?.skills?.length > 0 && <div className="text-4xl font-bold mt-10 mb-5">Skills</div>}
        <div className="flex flex-wrap gap-5">
          {user?.skills?.map((skill, index) => (
            <ItemTile item={skill?.skill} level={skill?.level} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
