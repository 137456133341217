import React, { useState } from 'react';
import logo from '../../assets/images/headerLogo.svg';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginEmployerSchema } from '../../validations/validationSchemas';
// import GoogleEmployerLoginProvider from './googleEmployerLogin';
import backgroundImg from '../../assets/images/loginbackground.jpeg';
import { employerLogin } from '../../actions/employerAction';
// import Spinner from '../../common/spinner';
import { startProcessing } from '../../actions/processingAction';
import { useAuthContext } from '../../context/authContext';

const EmployerLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setAuthUser } = useAuthContext();
  const lang = useSelector((state) => state.language.lang);
  const processing = useSelector((state) => state.processing);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = (e) => {
    e.preventDefault(); // Prevent form submission when toggling visibility
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginEmployerSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      dispatch(startProcessing());
      dispatch(employerLogin(values, navigate, setAuthUser));
    }
  });

  return (
    <div className="flex flex-col min-h-screen p-2 lg:p-0 bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className="flex flex-col items-center justify-center min-h-screen space-y-12">
        <div className="flex flex-col bg-white p-12 rounded-lg shadow-md w-full max-w-md items-center">
          <img
            src={logo}
            alt="Logo"
            className="w-36 lg:w-40 lg:mt-[-10px] cursor-pointer"
            onClick={() => navigate('/employer-landing')}
          />
          <h2 className="text-left text-2xl font-bold mb-6 text-oppty-black py-5">{lang.loginEmployer}</h2>
          <form onSubmit={formik.handleSubmit} className="w-full">
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {lang.createEmail}
              </label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder={lang.createEmail}
                className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 text-sm">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                {lang.createPassword}
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder={lang.createPassword}
                  className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300 pr-10"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500 text-sm">{formik.errors.password}</div>
                ) : null}
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                  <button
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                    onClick={togglePasswordVisibility}
                  >
                    {!showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full text-right">
              <a href="/employer-forgot-password" className="text-sm text-blue-600 hover:underline text-right">
                {lang.loginForgotPassword}
              </a>
            </div>

            <button type="submit" className="w-full bg-black text-white p-2 rounded-md hover:bg-gray-700 mt-6">
              {lang.loginEmployerContinue}
            </button>
            {/* <div className="mt-4 flex items-center justify-between">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="text-sm text-oppty-black">{lang.createOr}</span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
            <div className="py-3">
              <GoogleEmployerLoginProvider />
            </div> */}
            <div className="mt-4 flex justify-center items-center space-x-2">
              <p className="text-oppty-black font">{lang.generalDontHaveAccount}</p>
              <a href="/employer-sign-up" className="text-sm text-blue-600 hover:underline">
                {lang.generalSignUp}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmployerLogin;
