import { TrashIcon } from '@heroicons/react/24/outline';
import { getLabelByValue } from '../../utils/functions';
import { answerOptions, questionOptions } from '../../constant/dropdownConstOptions';

const QuestionCard = ({ question, onDeleteQuestion }) => {
  return (
    <div className="flex items-center">
      <div>
      {getLabelByValue(question?.question, questionOptions)}{' '}
        <span className={`font-bold ${question?.preferredAnswer === 'Yes' ? 'text-green-500' : 'text-red-500'}`}>
          {getLabelByValue(question?.preferredAnswer, answerOptions)}
        </span>
      </div>
      <button className="w-5 h-5 ml-5" onClick={() => onDeleteQuestion(question)} type="button">
        <TrashIcon className="stroke-red-500 hover:stroke-red-600" />
      </button>
    </div>
  );
};

export default QuestionCard;
