const WorkExperienceCard = ({ work, isFirstIndex }) => {
  return (
    <div className={`flex flex-col bg-template9-gray-1 p-8 ${isFirstIndex && 'border-t-4 border-template9-orange-1'}`}>
      <div className="text-white text-2xl font-semibold">{work?.position}</div>
      <div className="flex flex-col lg:flex-row gap-x-5 text-gray-300 text-lg mt-2">
        <div>{work?.companyName}</div> <span className="hidden lg:flex">|</span> 
        <div>
          {work?.startMonth} {work?.startYear} -{' '}
          {work?.endYear === 99 ? 'Current' : `${work?.startMonth} ${work?.endYear}`}
        </div>
      </div>
      {/* <div className="font-medium text-sm text-gray-300 mt-5 w-full">{work?.description}</div> */}
      <div className="mt-5 w-full text-white" dangerouslySetInnerHTML={{ __html: work?.description }}></div>
    </div>
  );
};

export default WorkExperienceCard;
