import axios from 'axios';

export const LEFT_NAV_BAR_TOGGLE_STATUS = 'LEFT_NAV_BAR_TOGGLE_STATUS';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
export const SET_CANDIDATE_SEARCH = 'SET_CANDIDATE_SEARCH';

const localLang = localStorage.getItem('lan');

export const updateLeftNavBarToggleStatus = (data) => ({
  type: LEFT_NAV_BAR_TOGGLE_STATUS,
  payload: data
});

export const setSelectedTemplate = (data) => ({
  type: SET_SELECTED_TEMPLATE,
  payload: data
});

export const setCandidateSearch = (data) => ({
  type: SET_CANDIDATE_SEARCH,
  payload: data
});

//if dont use, remove when deploy
export const getAllCountry = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`https://api.first.org/data/v1/countries`);
      let countries = [];

      if (response.status === 200) {
        if (response.data.data && typeof response.data.data === 'object') {
          for (const countryKey in response.data.data) {
            if (response.data.data.hasOwnProperty(countryKey)) {
              if (response.data.data[countryKey].hasOwnProperty('country')) {
                countries.push({
                  label: response.data.data[countryKey].country,
                  value: response.data.data[countryKey].country
                });
              }
            }
          }
        } else {
          console.warn('Unexpected data format in API response');
        }
      }
      console.log('countries: ', countries);
      return countries;
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};
