import { useSelector } from 'react-redux';
import { ReactComponent as Phone } from '../../assets/icons/employerPhone.svg';
import { ReactComponent as Envelope } from '../../assets/icons/employerEnvelope.svg';
import defaultAvatar from '../../assets/images/Default_profilepic.png';
import ProfileImageUploadModal from '../../common/profileImageUploadModal';
import { useState } from 'react';

const ProfileStrength = () => {
  const [editProfilePicModal, setEditProfilePicModal] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.employer);
  const dashboardDetails = useSelector((state) => state.employer?.dashboard);

  return (
    <div className="flex flex-col w-full shadow-md bg-white px-2 pb-2 pt-3 rounded-md justify-center">
      <div className="flex w-full items-center justify-center pb-3">
        <div className="relative">
          <img
            src={employer?.companyLogo ? employer.companyLogo : defaultAvatar}
            alt="userPic"
            className="w-24 h-24 p-2 rounded-full object-cover"
          />
          <button
            type="button"
            onClick={() => setEditProfilePicModal(true)}
            className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-xs text-white rounded-md font-semibold px-1 bg-oppty-blue hover:bg-oppty-blue/90 z-30"
          >
            {lang.profileEdit}
          </button>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-24 h-24 rounded-full border-4 border-oppty-blue"></div>
          </div>
        </div>
      </div>
      <div className="text-xl font-nunito font-bold leading-normal text-center text-black">
        {employer.companyName}
      </div>
      <div className="flex justify-center gap-x-8 lg:justify-between items-center py-6 px-2">
        <div className="flex flex-col items-center">
          <div className="text-lg font-nunito font-semibold leading-normal text-black">{dashboardDetails?.totalActiveJobs}</div>
          <div className="text-sm font-nunito font-normal leading-normal text-black">{lang.companyJobsActive}</div>
        </div>
        <div className="flex flex-col items-center">
          <div className="text-lg font-nunito font-semibold leading-normal text-black">
            {dashboardDetails?.totalApplicants}
          </div>
          <div className="text-sm font-nunito font-normal leading-normal text-black">{lang.generalApplicant}</div>
        </div>
      </div>
      <div className="flex flex-col gap-y-3 border-t-[1px] py-4">
        <div className="flex items-center gap-x-3">
          <div className="w-9 h-9">
            <Phone className="bg-blue-100 w-full h-full rounded-xl p-2" />
          </div>
          <div className="text-xs font-nunito font-semibold leading-normal text-black">
            +{employer?.areaCode} {employer?.mobile}
          </div>
        </div>
        <div className="flex items-center gap-x-3">
          <div className="w-9 h-9">
            <Envelope className="bg-blue-100 w-9 h-9 rounded-xl p-2" />
          </div>
          <div className="text-xs font-nunito font-normal leading-normal text-black break-all">{employer?.email}</div>
        </div>
      </div>
      {editProfilePicModal && (
        <ProfileImageUploadModal imageType="profile" closeModal={() => setEditProfilePicModal(false)} />
      )}
    </div>
  );
};

export default ProfileStrength;
