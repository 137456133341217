import { useNavigate } from 'react-router-dom';
import { ReactComponent as Warning } from '../../assets/icons/warning.svg';

const NotActivated = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen items-center bg-white p-16">
      <div className="mb-5">
        <Warning />
      </div>
      <div className="text-3xl font-bold mb-2">Your account is not activated.</div>
      <div className="mb-5">
        Please contact OppTy Administrator at{' '}
        <a href="mailto:admin@oppty.ai" className="text-blue-500 underline">
          admin@oppty.ai
        </a>{' '}
        to activate your account.
      </div>
      <button
        onClick={() => navigate('/')}
        className="bg-oppty-blue hover:bg-oppty-blue/90 text-white w-24 py-2 rounded-md font-bold"
      >
        Home
      </button>
    </div>
  );
};

export default NotActivated;
