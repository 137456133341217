import Footer from '../../common/footer';
import Header from '../../common/header';
import TemplateLists from './templateLists';

const SelectTemplate = () => {
  return (
    <div className="flex flex-col min-h-screen gap-y-8">
      <Header />
      <TemplateLists />
      <Footer />
    </div>
  );
};

export default SelectTemplate;
