import logo from '../../logo.svg'
import '../../App.css'

const Login = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Login</p>
        <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          Learn React
        </a>
        <p className="text-purple-400">Login</p>
      </header>
    </div>
  )
}

export default Login
