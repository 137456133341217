import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastBar } from './toastbar';
import { TrashIcon } from '@heroicons/react/24/outline';
import { addImages } from '../actions/employerAction';
import { getImageFileExtension } from '../utils/functions';
import Spinner from './spinner';

const EmployerImageUploadModal = ({ closeModal, employer }) => {
  const dispatch = useDispatch();

  const [imageSrcList, setImageSrcList] = useState([]);
  const [loader, setLoader] = useState(false);

  const lang = useSelector((state) => state.language.lang);

  const handleSubmit = async () => {
    setLoader(true);
    try {
      const formData = new FormData();

      for (let i = 0; i < imageSrcList.length; i++) {
        const imageData = imageSrcList[i];

        const response = await fetch(imageData);
        const blob = await response.blob();

        const fileExtension = getImageFileExtension(blob.type);

        formData.append('files', blob, `employer_Pic_${i}.${fileExtension}`);
      }

      dispatch(addImages(formData, setImageSrcList, closeModal, setLoader));
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setLoader(false);
    }
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const onFileChange = useCallback(
    async (e) => {
      const maxImages = 10;
      const existingImagesCount = employer?.images?.length || 0;
      const remainingImagesCount = maxImages - existingImagesCount;

      if (remainingImagesCount > 0) {
        if (e.target.files && e.target.files.length > 0) {
          const files = Array.from(e.target.files);
          const newImages = [];

          for (let i = 0; i < Math.min(files.length, remainingImagesCount); i++) {
            const imageDataUrl = await readFile(files[i]);
            newImages.push(imageDataUrl);
          }

          setImageSrcList((prevList) => [...prevList, ...newImages]);
        }
      } else {
        ToastBar.warning('You can upload a maximum of 10 images.');
      }
    },
    [imageSrcList, employer?.images]
  );

  const handleDelete = (index) => {
    const updatedImageList = [...imageSrcList];
    updatedImageList.splice(index, 1);
    setImageSrcList(updatedImageList);
  };

  console.log('imageSrcList: ', imageSrcList);

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 w-full h-full">
        <div className="bg-white w-2/3 px-5 md:px-10 py-10 rounded-lg shadow-lg overflow-y-auto min-h-96 max-h-full">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold mb-2">{lang.generalUploadImages}</h2>
            <button
              type="button"
              onClick={closeModal}
              className="text-lg font-bold hover:bg-red-500 px-2 rounded-md hover:text-white"
            >
              X
            </button>
          </div>
          <div className="w-full h-56 flex justify-center items-center">
            <input type="file" id="file-input" onChange={onFileChange} accept="image/*" multiple style={{ display: 'none' }} />
            <label htmlFor="file-input" className="bg-black px-5 py-2.5 text-white font-semibold text-sm">
            {lang.generalChooseFileOnly}
          </label>
          </div>
          <div className="mt-4 flex flex-wrap gap-5">
            {imageSrcList.map((imageSrc, index) => (
              <div className="max-w-full max-h-[200px] relative" key={index}>
                <img
                  key={index}
                  src={imageSrc}
                  alt={`Uploaded ${index}`}
                  className="max-w-full h-auto mt-2 rounded-md shadow-md"
                  style={{ maxWidth: '100%', maxHeight: '200px' }}
                />
                <TrashIcon
                  onClick={() => handleDelete(index)}
                  className="w-7 h-7 bg-red-500 stroke-white rounded-md p-1 absolute top-4 right-1 hover:bg-red-600"
                />
              </div>
            ))}
          </div>
          {imageSrcList.length > 0 && (
            <div className="flex justify-end flex-row gap-x-2 mt-5">
              <button
                type="button"
                disabled={loader}
                onClick={() => handleSubmit()}
                className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white w-28 py-3 rounded-md"
              >
                {loader ? <Spinner /> : lang.generalUpload}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmployerImageUploadModal;
