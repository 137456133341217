const WorkExperienceCard = ({ work, isLast }) => {
  const isCurrent = work?.endYear === 99 ? 'Current' : work?.endYear;

  return (
    <div
      className={`w-full flex flex-col lg:flex-row ${!isLast && 'border-b-2'} border-black/30 ${!isLast ? 'py-20' : 'pt-20'} items-start justify-start gap-y-8`}
    >
      <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
        <div className="flex w-fit px-5 py-2 bg-template2-orange-1 rounded-md text-2xl">
          {work?.startYear} - {isCurrent}
        </div>
        <div className="text-2xl">{work?.position}</div>
      </div>
      <div className="w-full lg:w-1/2 flex flex-col gap-y-3">
        <div className="flex text-3xl">{work?.companyName}</div>
        {/* <div className="text-base">{work?.description}</div> */}
        <div className="w-full" dangerouslySetInnerHTML={{ __html: work?.description }}></div>
      </div>
    </div>
  );
};

export default WorkExperienceCard;
