const SkillTile = ({ item, level }) => {
  return (
    <div className="px-6 py-1.5 bg-template3-blue-2/20 rounded-3xl flex flex-col w-fit text-sm">
      <span className="font-bold text-lg">{item}</span>
      <span className="font-medium text-sm text-gray-500">{level}</span>
    </div>
  );
};

export default SkillTile;
