import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addToConversation } from '../../actions/messageAction';
import { fetchSearchCandidates } from '../../actions/employerAction';

const FilteredUsersList = ({ setClickedSearch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState({
    key: 'generalFullName',
    direction: 'ascending'
  });

  const lang = useSelector((state) => state.language.lang);
  const searchCandidates = useSelector((state) => state.employer.searchCandidate);

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedApplicants = useMemo(() => {
    if (!searchCandidates) return [];

    let sortableCandidates = [...searchCandidates];
    sortableCandidates.sort((a, b) => {
      if (sortConfig.key === 'generalFullName') {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();

        if (sortConfig.direction === 'ascending') {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      } else {
        return 0;
      }
    });

    return sortableCandidates;
  }, [searchCandidates, sortConfig]);

  const handleViewProfile = (applicantId) => {
    window.open(`/view-profile/${applicantId}`, '_blank');
  };

  const handleAddToConvo = async (applicantId) => {
    console.log('Adding');
    dispatch(addToConversation(applicantId));
  };

  const handleClearResult = () => {
    dispatch(fetchSearchCandidates([]));
    setClickedSearch(false);
  };

  return (
    <div className="flex flex-col justify-center py-5 gap-y-5 lg:px-5">
      <button className="text-right -mb-4 underline hover:text-red-500 text-sm" onClick={() => handleClearResult()}>
        {lang.generalClearResult}
      </button>
      <table className="table-auto bg-white rounded-2xl">
        <thead>
          <tr className="h-12 border-y-[1px] w-full">
            <th onClick={() => handleSort('generalFullName')} className="cursor-pointer text-left pl-5 w-1/2 lg:w-4/6">
              {lang.generalFullName}{' '}
              <FontAwesomeIcon icon={getSortIcon('generalFullName')} size="sm" className="ml-1" />
            </th>
            <th>{lang.generalActions}</th>
            <th>{lang.generalMessages}</th>
          </tr>
        </thead>
        <tbody>
          {sortedApplicants?.map((user, index) => {
            return (
              <tr className="h-20" key={index}>
                <td className="text-sm font-nunito font-semibold leading-normal text-black pl-5">
                  <div className="flex justify-start items-center space-x-2 ">
                    <img src={user?.avatar} alt="profile pic" className="w-12 h-12 rounded-full" />
                    <span>
                      {user?.firstName} {user?.lastName}
                    </span>
                  </div>
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    onClick={() => handleViewProfile(user?._id, user)}
                    className="text-sm font-bold text-oppty-purple-5 bg-oppty-blue-10 border-[1px] border-oppty-purple-5 px-5 py-2 rounded-md hover:bg-indigo-100"
                  >
                    {lang.generalViewProfile}
                  </button>
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    onClick={async () => {
                      if (user?._id) {
                        console.log('click applicant Id: ', user._id);
                        await handleAddToConvo(user);
                        navigate(`/employer/messages`, { state: { receiverId: user._id } });
                      }
                    }}
                    className="text-sm font-bold text-oppty-purple-5 bg-oppty-blue-10 border-[1px] border-oppty-purple-5 px-5 py-2 rounded-md hover:bg-indigo-100"
                  >
                    {lang.generalSendMessage}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FilteredUsersList;
