import { FETCH_LANGUAGE_REQUEST, FETCH_LANGUAGE_SUCCESS, SET_LANGUAGE_INDEX } from '../actions/languageAction'

const initialState = {
  loader: false,
  lang: []
  // lanIndex: 0
}

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LANGUAGE_REQUEST:
      return {
        ...state,
        loader: true
      }

    case FETCH_LANGUAGE_SUCCESS:
      return {
        ...state,
        loader: false,
        lang: action.payload
      }

    // case SET_LANGUAGE_INDEX:
    //   return {
    //     ...state,
    //     lanIndex: action.payload
    //   }

    default:
      return state
  }
}

export default languageReducer
