import PortfolioCard from './portfolioCard';

const Portfolio = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-20">
      <div className="text-5xl font-bold mb-20 text-white">My <span className='bg-template9-blue-2'>Portfolio</span></div>
      <div className="flex flex-wrap w-full gap-5 justify-center">
        {user?.portfolios?.map((portfolio, index) => {
          return <PortfolioCard key={index} portfolio={portfolio} />;
        })}
      </div>
    </div>
  );
};

export default Portfolio;
