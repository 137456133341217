import { ReactComponent as Design } from '../../../assets/templates/template2/educationDesign.svg';
import WorkExperienceCard from './workExperienceCard';

const WorkExperience = ({ user }) => {
  return (
    <div className="relative flex flex-col bg-template2-orange-2">
      <div className="flex flex-col p-10 lg:p-32">
        <div className="w-full flex flex-col gap-y-20">
          <div className="text-[50px] font-bold">Work Experience</div>
        </div>

        {user?.workExperiences?.map((work, index) => {
          const isLast = index === user?.workExperiences?.length - 1 && true;
          return <WorkExperienceCard key={index} work={work} isLast={isLast} />;
        })}
      </div>
      <div className="absolute h-[300px] w-[400px] -right-40 bottom-0">
        <Design className="w-full h-full hidden lg:flex" />
      </div>
    </div>
  );
};

export default WorkExperience;
