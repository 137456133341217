import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useSelector } from 'react-redux';
import EditEventModal from './editEventModal';
import { useState } from 'react';

const locales = {
  'en-US': enUS
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

const ScheduleCalendar = () => {
  const [editModal, setEditModal] = useState(false);
  const [selectEvent, setSelectEvent] = useState({});
  const interviews = useSelector((state) => state?.employer?.interviews);
  console.log('interviews: ', interviews);

  const events = interviews.flatMap((item) =>
    item.applicants
      .filter((applicant) => applicant.status === 'Interview')
      .map((applicant) => {
        console.log(applicant);
        const interviewDate = new Date(applicant?.interviewDate);
        const endDate = new Date(interviewDate);
        endDate.setHours(interviewDate.getHours() + 1);
        return {
          title: `Interview with ${applicant?.user?.firstName} ${applicant?.user?.lastName}`,
          id: applicant?._id,
          start: interviewDate,
          end: endDate,
          desc: `Job Title: ${item.jobTitle}`,
          jobId: item?._id,
          userId: applicant?.user?._id,
          status: applicant?.status,
          interviewLink: applicant?.interviewLink
        };
      })
  );
  console.log('events: ', events);

  const handleEventClick = (event) => {
    console.log('Event clicked:', event);
    setSelectEvent(event);
    setEditModal(true);
  };

  return (
    <div className="w-full h-full">
      <div className="w-full h-full">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={handleEventClick}
        />
      </div>
      {editModal && <EditEventModal event={selectEvent} closeModal={() => setEditModal(false)} />}
    </div>
  );
};

export default ScheduleCalendar;
