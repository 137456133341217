import { useSelector } from 'react-redux';
import findPerfect from '../../../assets/images/findperfect.png';

const FindPerfectPerson = () => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <div className="relative">
      <img src={findPerfect} alt="findPerfect" className="w-full" />
      <div className="absolute top-1/4 left-[5%] right-0 bottom-0 flex flex-col items-start justify-start space-y-2 md:space-y-8">
        <div className="outfit-font text-[14px] md:text-[40px] lg:text-[45px] font-normal text-oppty-black-3 w-[40%] lg:leading-[50px]">
          {lang.employeeHomeFindThePerfect}
        </div>
        <a
          href="/employer-sign-up"
          className="w-20 md:w-[215px] h-6 md:h-[58px] flex-shrink-0 rounded-[5px] md:rounded-[7px] border border-solid bg-oppty-yellow shadow-find-perfect-sm md:shadow-find-perfect text-[8px] md:text-lg font-normal flex items-center justify-center"
        >
          {lang.generalGetStarted}
        </a>
      </div>
    </div>
  );
};

export default FindPerfectPerson;
