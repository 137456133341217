import {
  FETCH_ALL_JOBS_SUCCESS,
  FETCH_CANDIDATES_BY_JOB_SUCCESS,
  FETCH_SELECTED_JOB_SUCCESS,
  SET_APPLY_JOB_DETAILS
} from '../actions/jobAction';
import vector1 from '../assets/images/vector.png';
import vector2 from '../assets/images/vector2.png';
import vector3 from '../assets/images/vector3.png';
import vector4 from '../assets/images/vector4.png';
import vector5 from '../assets/images/vector5.png';

const initialState = {
  allJobs: [],
  applicantsByJob: [],
  applyJobDetails: {}
};

const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_JOBS_SUCCESS:
      return {
        ...state,
        allJobs: action.payload?.jobs
      };
    case FETCH_CANDIDATES_BY_JOB_SUCCESS:
      return {
        ...state,
        applicantsByJob: action.payload?.users
      };
    case SET_APPLY_JOB_DETAILS:
      return {
        ...state,
        applyJobDetails: action.payload
      };
    default:
      return state;
  }
};

export default jobReducer;

const selectedJobInitialState = {
  job: {},
  otherJobs: [],
  hardCodeImage: [vector1, vector2, vector3, vector4, vector5]
};

export const selectedJobReducer = (state = selectedJobInitialState, action) => {
  switch (action.type) {
    case FETCH_SELECTED_JOB_SUCCESS:
      return {
        ...state,
        job: action.payload?.job,
        otherJobs: action.payload?.otherJobs
      };
    default:
      return state;
  }
};
