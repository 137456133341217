import AboutMe from "./aboutMe";
import Education from "./education";
import HeaderSection from "./headerSection";
import Portfolio from "./portfolio";
import Testimonial from "./testimonial";
import Video from "./video";
import WorkExperience from "./workExperience";

const Template9 = ({ user }) => {
  console.log('user: ', user);
  return (
    <div className="flex flex-col min-w-80 w-full overflow-hidden bg-template9-blue-1">
      <HeaderSection user={user} />
      {(user?.aboutMe || user?.languages?.length > 0 || user?.skills?.length > 0) && <AboutMe user={user} />}
      {(user?.educations?.length > 0 || user?.certifications?.length > 0) && <Education user={user} />}
      {user?.workExperiences?.length > 0 && <WorkExperience user={user} />}
      {user?.portfolios?.length > 0 && <Portfolio user={user} />}
      {user?.testimonial?.length > 0 && <Testimonial user={user} />}
      {user?.video && <Video user={user} />}
    </div>
  );
};

export default Template9;
