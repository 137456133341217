import { ArrowDownTrayIcon, ChatBubbleOvalLeftIcon, MapPinIcon } from '@heroicons/react/24/outline';
import SkillTile from './skillTile';
import { ReactComponent as Facebook } from '../../../assets/icons/icons8-facebook.svg';
import { ReactComponent as Instagram } from '../../../assets/icons/icons8-insta.svg';
import { ReactComponent as Twitter } from '../../../assets/icons/icons8-twitterx.svg';
import { ReactComponent as Linkedin } from '../../../assets/icons/icons8-linkedin.svg';
import { ReactComponent as Github } from '../../../assets/icons/icons8-github.svg';
import { ReactComponent as Pinterest } from '../../../assets/icons/icons8-pinterest.svg';
import { ReactComponent as Youtube } from '../../../assets/icons/icons8-youtube.svg';
import defaultPic from '../../../assets/images/Default_profilepic.png';

const HeaderSection = ({ user }) => {
  const handleOpenLink = (link) => {
    console.log('open');

    if (!link.includes('http://') && !link.includes('https://')) {
      link = 'https://' + link;
    }

    window.open(link);
  };

  return (
    <div className="flex flex-col lg:flex-row w-full h-full p-5 lg:p-10 border-2 border-template3-blue-1 rounded-xl shadow-md gap-y-5">
      <div className="relative w-full flex flex-col h-full">
        <div className="absolute lg:top-1/2 lg:right-0 lg:transform lg:-translate-y-1/2 w-72 hidden lg:flex items-center justify-center">
          <div className="border-2 border-template3-blue-1 p-5 w-full max-w-96 flex flex-col justify-center items-center  rounded-md shadow-md">
            <button
              disabled={!user?.resume}
              onClick={() => window.open(user?.resume?.resumeUrl)}
              className="flex  disabled:bg-gray-400 items-center justify-center gap-x-2 bg-template3-blue-2 hover:bg-indigo-600 w-full px-5 lg:px-10 py-2 text-white font-semibold rounded-md"
            >
              <ArrowDownTrayIcon className="w-5 h-5 stroke-2" />
              {user?.resume ? 'Download CV' : 'No CV Uploaded'}
            </button>
            <div className="flex gap-x-2 pt-2 justify-center flex-wrap items-center">
              {user?.socials?.map((social, index) => {
                return (
                  <div key={index}>
                    {social?.social === 'Linkedin' && (
                      <Linkedin
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-8 h-8 cursor-pointer"
                      />
                    )}
                    {social?.social === 'Facebook' && (
                      <Facebook
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-8 h-8 cursor-pointer"
                      />
                    )}
                    {social?.social === 'Instagram' && (
                      <Instagram
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-8 h-8 cursor-pointer"
                      />
                    )}
                    {social?.social === 'X' && (
                      <Twitter
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-8 h-8 cursor-pointer"
                      />
                    )}
                    {social?.social === 'Github' && (
                      <Github
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-8 h-8 cursor-pointer"
                      />
                    )}
                    {social?.social === 'Pinterest' && (
                      <Pinterest
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-8 h-8 cursor-pointer"
                      />
                    )}
                    {social?.social === 'Youtube' && (
                      <Youtube
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-9 h-9 cursor-pointer"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-x-5 pb-10">
          <div className="hidden lg:flex w-60 h-60">
            <img
              src={user?.avatar ? user?.avatar : defaultPic}
              alt="pic"
              className="w-full h-full rounded-full object-cover"
            />
          </div>
          <div className="flex flex-col gap-y-1">
            <div className="text-6xl font-bold">{user?.title}</div>
            <div className="text-4xl font-bold">
              {user?.firstName} {user?.lastName}
            </div>
            <div className="flex flex-wrap gap-x-5 items-center text-lg">
              {user?.country && (
                <div className="flex gap-x-2 items-center">
                  <MapPinIcon className="w-5 h-5" /> {user?.country}
                </div>
              )}
              {user?.languages?.length > 0 && (
                <div className="flex gap-x-2 items-center">
                  <ChatBubbleOvalLeftIcon className="w-5 h-5" /> {user?.languages?.map((lang) => lang?.lang).join(', ')}
                </div>
              )}
            </div>
          </div>
        </div>
        {user?.aboutMe && <div className="text-4xl font-bold pb-3">About me</div>}
        {/* <div className="text-base font-medium w-full lg:w-4/6 pb-10">{user?.aboutMe}</div> */}
        <div className="w-full lg:w-4/6 pb-1" dangerouslySetInnerHTML={{ __html: user?.aboutMe }}></div>
        {user?.skills?.length > 0 && <div className="text-4xl font-bold pt-6 py-3">Skills</div>}
        <div className="flex flex-wrap w-full lg:w-2/3 gap-x-4 gap-y-2">
          {user?.skills?.map((skill, index) => (
            <SkillTile key={index} skill={skill?.skill} />
          ))}
        </div>
        <div className="w-full flex lg:hidden items-center justify-center mt-5">
          <div className="w-full flex lg:hidden items-center justify-center">
            <div className="border-2 border-template3-blue-1 p-5 w-full max-w-96 flex flex-col justify-center items-center  rounded-md shadow-md">
              <button
                disabled={!user?.resume}
                onClick={() => window.open(user?.resume?.resumeUrl)}
                className="flex  disabled:bg-gray-400 items-center justify-center gap-x-2 bg-template3-blue-2 hover:bg-indigo-600 w-full px-5 lg:px-10 py-2 text-white font-semibold rounded-md"
              >
                <ArrowDownTrayIcon className="w-5 h-5 stroke-2" />
                {user?.resume ? 'Download CV' : 'No CV Uploaded'}
              </button>
              <div className="flex gap-x-2 pt-2 justify-center flex-wrap items-center">
                {user?.socials?.map((social, index) => {
                  return (
                    <div key={index}>
                      {social?.social === 'Linkedin' && (
                        <Linkedin
                          onClick={() => handleOpenLink(social.link)}
                          className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-8 h-8 cursor-pointer"
                        />
                      )}
                      {social?.social === 'Facebook' && (
                        <Facebook
                          onClick={() => handleOpenLink(social.link)}
                          className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-8 h-8 cursor-pointer"
                        />
                      )}
                      {social?.social === 'Instagram' && (
                        <Instagram
                          onClick={() => handleOpenLink(social.link)}
                          className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-8 h-8 cursor-pointer"
                        />
                      )}
                      {social?.social === 'X' && (
                        <Twitter
                          onClick={() => handleOpenLink(social.link)}
                          className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-8 h-8 cursor-pointer"
                        />
                      )}
                      {social?.social === 'Github' && (
                        <Github
                          onClick={() => handleOpenLink(social.link)}
                          className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-8 h-8 cursor-pointer"
                        />
                      )}
                      {social?.social === 'Pinterest' && (
                        <Pinterest
                          onClick={() => handleOpenLink(social.link)}
                          className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-8 h-8 cursor-pointer"
                        />
                      )}
                      {social?.social === 'Youtube' && (
                        <Youtube
                          onClick={() => handleOpenLink(social.link)}
                          className="fill-template3-blue-2 hover:fill-template3-blue-2/90 w-9 h-9 cursor-pointer"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
