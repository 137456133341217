const EducationCard = ({ education }) => {
  return (
    <div className="border-b-[1px] py-7 border-gray-300 flex flex-col lg:flex-row gap-5">
      <div className="w-full lg:w-1/2">
        <div className="text-white font-bold text-3xl">
          {education?.qualification} in {education?.course}
        </div>
        <div className="bg-template6-blue-3 border-[1px] border-template6-purple-1 w-fit px-4 py-2 text-sm font-semibold text-template6-purple-1 rounded-2xl mt-2">
          {education?.startMonth} {education?.startYear} -{' '}
          {education?.endYear === 99 ? 'Current' : `${education?.startMonth} ${education?.endYear}`}
        </div>
      </div>
      <div className="w-full lg:w-1/2">
        <div className="text-white text-2xl font-bold">{education?.institution}</div>
        {/* <div className="font-medium text-sm text-white/70">{education?.description}</div> */}
        <div className="w-full pt-3 text-white" dangerouslySetInnerHTML={{ __html: education?.description }}></div>
      </div>
    </div>
  );
};

export default EducationCard;
