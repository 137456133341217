const PortfolioTile = ({ image, highlight, link, onDelete, onOpenLink }) => {
  console.log('image: ', image);
  return (
    <div className="flex px-8 py-2 w-full bg-oppty-grey-17 rounded-md gap-x-10 justify-between items-start border-[1px]">
      <div className="flex flex-wrap w-full gap-x-10">
        <div className="w-40 h-40">
          <img src={image} alt="pic" className="w-full h-full object-cover rounded-md" onClick={onOpenLink} />
        </div>
        <div className="mt-1 w-full lg:w-4/6">
          <div className="text-lg">{highlight}</div>
          <div
            onClick={onOpenLink}
            className="cursor-pointer text-lg text-template3-blue-2 hover:text-template3-blue-2/80 mt-2"
          >
            {link}
          </div>
        </div>
      </div>

      {onDelete && (
        <button onClick={onDelete} className="text-sm font-bold hover:bg-red-500 px-1 rounded-md">
          X
        </button>
      )}
    </div>
  );
};

export default PortfolioTile;
