import { useDispatch } from 'react-redux';
import Footer from '../../common/footer';
import Header from '../../common/header';
import { useEffect } from 'react';
import ArticleList from './articleList';
import { getCandidateArticles } from '../../actions/publicAction';
import ArticleFilter from './articleFilter';

const Articles = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCandidateArticles({}));
  }, []);
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="w-full flex flex-col justify-center items-center">
        <div className="lg:min-w-[1000px] max-w-[1200px] bg-white rounded-xl m-10">
          <ArticleFilter />
          <ArticleList />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Articles;
