const CertificationCard = ({ certificate }) => {
  return (
    <div className="border-b-[1px] pb-5 pt-3 -mx-10 px-10 border-template3-blue-1">
      <div className="text-template3-blue-2 font-semibold text-2xl">{certificate?.institution}</div>
      <div className="flex text-black font-medium text-base items-center gap-x-2">
        <div>
          {certificate?.qualification} {certificate?.course}
        </div>
        <div className="h-2.5 border-r-[1px] border-black" />
        <div>
          {certificate?.startMonth} {certificate?.startYear} -{' '}
          {certificate?.endYear === 99 ? 'Current' : `${certificate?.startMonth} ${certificate?.endYear}`}
        </div>
      </div>
      {/* <div className="font-medium text-sm pt-3">{certificate?.description}</div> */}
      <div className="w-full pt-3" dangerouslySetInnerHTML={{ __html: certificate?.description }}></div>
    </div>
  );
};

export default CertificationCard;
