import { FETCH_END_PROCESSING, FETCH_START_PROCESSING } from "../actions/processingAction";

const initialProcessingState = false;

export const processingReducer = (state = initialProcessingState, action) => {
    switch (action.type) {
      case FETCH_START_PROCESSING:
        return true;
  
      case FETCH_END_PROCESSING:
        return false;
  
      default:
        return state;
    }
  };
  
  export default processingReducer;