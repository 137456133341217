import EducationCard from './educationCard';
import BackgroundImg from '../../../assets/templates/template4/template4bg.jpeg';

const Education = ({ user }) => {
  return (
    <div className="w-full flex flex-col lg:flex-row relative justify-center items-center">
      <img src={BackgroundImg} alt="bg" className="w-full h-full absolute object-cover" />
      <div className="w-full h-full absolute bg-template4-green-1/50"></div>
      <div className="z-10 flex flex-col lg:flex-row w-5/6 mt-14 lg:mt-28">
        <div className="text-4xl lg:text-5xl font-bold lg:w-1/2 h-1/2 lg:pb-40 bg-template4-green-1 text-white p-16">
          <div className="border-t-4 border-template4-green-3 w-10 pb-2"></div>
          <div>Education & Certification</div>
        </div>
        <div className="flex flex-col w-full gap-y-5 bg-white p-10 lg:p-16">
          {user?.educations?.map((education, index) => {
            return <EducationCard key={index} education={education} />;
          })}
          {user?.certifications?.map((education, index) => {
            return <EducationCard key={index} education={education} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Education;
