import ReactPlayer from 'react-player';

const Video = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-20 shadow-md bg-template6-blue-1 border-l-[16px] border-template6-green-1">
      <div className="text-5xl font-bold text-white">Video</div>
      <div className="text-xl mb-10 text-white">Check out my video</div>
      <div className="flex flex-col pt-5 justify-center w-full items-center">
        <div className="bg-white/70 w-full lg:w-[550px] border-template5-blue-1 border-2">
          <ReactPlayer url={user?.video?.videoUrl} controls={true} width="100%" />
        </div>
      </div>
    </div>
  );
};

export default Video;
