import { useParams } from 'react-router-dom';
import Certification from './certification';
import Education from './education';
import HeaderSection from './headerSection';
import Portfolio from './portfolio';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserById } from '../../actions/userAction';
import Video from './video';
import Testimonial from './testimonial';
import WorkExperience from './workExperience';

const ViewProfile = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const user = useSelector((state) => state.selectedUser.user);

  useEffect(() => {
    dispatch(getUserById(id));
  }, []);

  console.log('user: ', user);
  return (
    <div className='flex flex-col justify-center items-center w-full'>
      <div className="flex flex-col w-full h-full bg-template3-gray-1 font-raleway overflow-hidden p-5 lg:p-10 gap-y-5 max-w-[1200px]">
        <HeaderSection user={user} />
        {user?.workExperiences?.length > 0 && <WorkExperience user={user} />}
        {user?.educations?.length > 0 && <Education user={user} />}
        {user?.certifications?.length > 0 && <Certification user={user} />}
        {user?.portfolios?.length > 0 && <Portfolio user={user} />}
        {user?.testimonial?.length > 0 && <Testimonial user={user} />}
        {user?.video && <Video user={user} />}
      </div>
    </div>
  );
};

export default ViewProfile;
