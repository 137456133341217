const LangTile = ({ item, level }) => {
  return (
    <div className="px-8 py-2.5 bg-oppty-blue/20 rounded-3xl flex flex-col w-fit text-lg">
      <span className="font-bold text-lg text-template6-purple-1">{item}</span>
      <span className="font-medium text-sm text-gray-500">{level}</span>
    </div>
  );
};

export default LangTile;
