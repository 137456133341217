import { useSelector } from 'react-redux';
import salaryIcon from '../../../assets/icons/salary.svg';
import { getLabelByValue } from '../../../utils/functions';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { salaryTypeOptions, statusOptions } from '../../../constant/dropdownConstOptions';
import DefaultPic from '../../../assets/images/defaultCompany.png';

const AppliedJobsList = ({ jobId }) => {
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({
    key: 'jobDateApplied',
    direction: 'descending'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const lang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.user);

  const handleInterview = (jobId) => {
    console.log('go to interview');
    console.log(jobId);
    navigate('/profile/interviews', { state: { jobId } });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedAppliedJobs = useMemo(() => {
    if (!user.appliedJobs) return [];

    let sortableAppliedJobs = [...user?.appliedJobs];
    sortableAppliedJobs.sort((a, b) => {
      if (sortConfig.key === 'companyName') {
        const nameA = `${a?.job?.employer?.companyName}`.toLowerCase();
        const nameB = `${b?.job?.employer?.companyName}`.toLowerCase();

        if (sortConfig.direction === 'ascending') {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      } else if (sortConfig.key === 'status') {
        const stageA = a?.status || '';
        const stageB = b?.status || '';

        if (sortConfig.direction === 'ascending') {
          return stageA.localeCompare(stageB);
        } else {
          return stageB.localeCompare(stageA);
        }
      } else if (sortConfig.key === 'jobDateApplied') {
        const dateA = new Date(a?.appliedDate).getTime();
        const dateB = new Date(b?.appliedDate).getTime();

        if (sortConfig.direction === 'ascending') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      } else if (sortConfig.key === 'position') {
        const nameA = `${a?.job?.jobTitle}`.toLowerCase();
        const nameB = `${b?.job?.jobTitle}`.toLowerCase();

        if (sortConfig.direction === 'ascending') {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      } else {
        return 0;
      }
    });

    return sortableAppliedJobs;
  }, [user, sortConfig]);

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedAppliedJobs.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedAppliedJobs.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex flex-col justify-center py-5 lg:py-10 px-2 mx-2 lg:px-5 rounded-[20px] bg-white min-w-[1200px] overflow-auto">
      <div className="flex justify-between items-center">
        <div className="text-2xl font-nunito font-bold leading-normal text-black">
          {currentEntries.length > 0
            ? currentEntries.length === 1
              ? lang.generalShowing1Job
              : `${lang.generalShowing} ${currentEntries?.length} ${lang.generalJobApplications}`
            : lang.generalShowingNoJob}
        </div>
        <div className="flex items-center font-semibold">
          <label htmlFor="entriesPerPage" className="mr-2">
            {lang.generalShow}
          </label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesPerPageChange}
            className="border border-gray-300 rounded-md p-1 pl-3"
          >
            {[5, 10, 20, 50].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
          <span className="ml-2">{lang.generalEntries}</span>
        </div>
      </div>

      <table className="table-auto bg-white rounded-2xl overflow-auto w-full">
        <thead>
          <tr className="h-20 border-b-[1px] text-lg">
            <th onClick={() => handleSort('jobDateApplied')} className="cursor-pointer">
              {lang.jobDateApplied}
              <FontAwesomeIcon icon={getSortIcon('jobDateApplied')} size="sm" className="ml-1" />
            </th>
            <th onClick={() => handleSort('companyName')}>
              {lang.jobCompany}
              <FontAwesomeIcon icon={getSortIcon('companyName')} size="sm" className="ml-1" />
            </th>
            <th>{lang.generalSalary}</th>
            <th onClick={() => handleSort('position px-4')}>
              {lang.generalPosition}
              <FontAwesomeIcon icon={getSortIcon('position')} size="sm" className="ml-1" />
            </th>
            <th>{lang.jobInterviewDate}</th>
            <th onClick={() => handleSort('status')}>
              {lang.jobStatus}
              <FontAwesomeIcon icon={getSortIcon('status')} size="sm" className="ml-1" />
            </th>
          </tr>
        </thead>
        <tbody>
          {currentEntries?.map((appliedJob, index) => {
            const formattedInterviewDate = !Number.isNaN(new Date(appliedJob?.interviewDate).getTime())
              ? format(new Date(appliedJob?.interviewDate), 'dd-MM-yyyy HH:mm')
              : 'Invalid Date';

            const formattedAppliedDate = !Number.isNaN(new Date(appliedJob?.appliedDate).getTime())
              ? format(new Date(appliedJob?.appliedDate), 'dd-MM-yyyy HH:mm')
              : 'Invalid Date';

            let notifyJob = null;

            console.log('jobId: ', jobId);
            console.log(appliedJob);
            if (jobId) {
              notifyJob = appliedJob?.job?._id === jobId;
            }
            return (
              <tr className={`min-h-20 ${notifyJob && 'bg-gray-200'}`} key={index}>
                <td className="text-sm font-nunito font-normal leading-normal text-black text-center ">
                  {formattedAppliedDate}
                  {/* //{timestampToDate(appliedJob?.appliedDate)} */}
                </td>
                <td className="h-20 max-w-60 px-2">
                  <div className="flex justify-start items-center">
                    <div className="flex flex-row gap-x-2">
                      <div className="h-10 w-10">
                        {appliedJob?.job?.employer?.companyLogo ? (
                          <img
                            src={appliedJob?.job?.employer?.companyLogo}
                            alt="companyPic"
                            className="object-cover h-full w-full"
                          />
                        ) : (
                          <img src={DefaultPic} alt="DefaultPic" className="object-cover h-full w-full " />
                        )}
                      </div>
                      <div>
                        <div className="text-base font-nunito font-bold leading-normal text-black">
                          {appliedJob?.job?.employer?.companyName}
                        </div>
                        <div className="text-sm font-nunito font-normal leading-normal text-black">
                          {appliedJob?.job?.employer?.companyIndustry}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="h-20">
                  <div className="flex justify-start items-center">
                    <div className="flex gap-x-3">
                      <div className="h-9 w-9 bg-yellow-800">
                        <img src={salaryIcon} alt="salary" className="object-cover h-full w-full p-2" />
                      </div>
                      <div className="flex flex-col">
                        <div className="text-[15px] font-nunito font-semibold leading-normal text-black">
                          {appliedJob?.job?.currencyType} {appliedJob?.job?.minSalary}{' '}
                          <span className={`${appliedJob.job?.maxSalary ? '' : 'hidden'}`}>
                            - {appliedJob?.job?.currencyType} {appliedJob?.job?.maxSalary}
                          </span>
                        </div>
                        <div className="text-xs font-nunito font-normal leading-normal text-oppty-grey-1 capitalize">
                          {getLabelByValue(appliedJob?.job?.salaryType, salaryTypeOptions)} {lang.generalSalary}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-sm font-nunito font-semibold leading-normal text-black text-center px-2 max-w-60">
                  {appliedJob?.job?.jobTitle}
                </td>
                <td className="text-center text-oppty-blue underline text-sm font-nunito font-normal leading-normal">
                  <div
                    onClick={() => handleInterview(appliedJob.job._id)}
                    className="w-full h-full"
                    style={{ cursor: 'pointer' }}
                  >
                    {appliedJob?.interviewDate ? formattedInterviewDate : ''}
                  </div>
                </td>
                <td className="h-20">
                  <div className="flex justify-center items-center">
                    <button
                      className={`rounded-md border-[1px] w-28 py-3 cursor-default ${
                        appliedJob.status === 'Hired'
                          ? 'bg-green-600 border-green-500 hover:bg-green-300 text-white text-sm font-nunito font-bold leading-normal'
                          : appliedJob.status === 'Applied'
                            ? 'bg-transparent border-gray-500 hover:bg-gray-300 text-gray-500 text-sm font-nunito font-bold leading-normal'
                            : appliedJob.status === 'Viewed'
                              ? 'bg-transparent border-gray-500 hover:bg-gray-300 text-black-500 text-sm font-nunito font-bold leading-normal'
                              : appliedJob.status === 'Interview'
                                ? 'bg-transparent border-orange-500 hover:bg-orange-200 text-orange-500 text-sm font-nunito font-bold leading-normal'
                                : appliedJob.status === 'Interviewed'
                                  ? 'bg-transparent border-blue-500 hover:bg-blue-50 text-blue-200 text-sm font-nunito font-bold leading-normal'
                                  : appliedJob.status === 'Not Suitable'
                                    ? 'bg-transparent border-red-500 hover:bg-red-50 text-red-500 text-sm font-nunito font-bold leading-normal'
                                    : appliedJob.status === 'Shortlisted'
                                      ? 'bg-transparent border-indigo-500 hover:bg-indigo-50 text-indigo-400 text-sm font-nunito font-bold leading-normal'
                                      : ''
                      }`}
                    >
                      {getLabelByValue(appliedJob?.status, statusOptions)}
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex py-10">
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-base text-gray-700">
              {lang.generalShowing}{' '}
              <span className="font-medium">
                {indexOfFirstEntry + 1} {lang.generalTo} {Math.min(indexOfLastEntry, sortedAppliedJobs.length)}
              </span>{' '}
              {lang.generalOf} <span className="font-medium">{sortedAppliedJobs.length}</span> {lang.generalResults}
            </p>
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-black bg-white rounded-md font-semibold"
                disabled={currentPage === 1}
              >
                <span className="mr-4">{'<<'}</span>
                <span className="sr-only">{lang.generalPrevious}</span>
                {lang.generalPrevious}
              </button>
              <div>
                {pageNumbers?.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                      pageNumber === currentPage ? 'bg-black text-white rounded-md' : 'text-gray-900 bg-oppty-green-5'
                    }`}
                  >
                    {pageNumber}
                  </button>
                ))}
              </div>
              <button
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-black bg-white rounded-md font-semibold"
                disabled={currentPage === totalPages}
              >
                <span className="mr-4">{lang.generalNext}</span>
                <span className="">{'>>'}</span>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppliedJobsList;
