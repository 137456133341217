import { createMessageNotification } from "./notificationAction";
import { UPDATE_UNREAD_COUNT } from "./messageAction";

export const updateNotificationUnreadCount = (senderId) => (dispatch, getState) => {
  const state = getState();
  const selectedConversationId = state.message.selectedConversation?._id;
  const conversation = state.message.useConversations.allUsers.find(conv => conv._id === senderId);

  console.log(senderId)
  console.log(conversation._id)
  console.log(selectedConversationId)

  if (senderId === conversation._id && conversation._id !== selectedConversationId) {

    dispatch(createMessageNotification(senderId));
  }
};