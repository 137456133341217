import axios from 'axios';
import { ToastBar } from '../common/toastbar';
import { fetchUserPreRegComplete } from './userPreRegisterAction';
import { fetchAllJobsSuccess } from './jobAction';
import { createApplyJobNotification } from './notificationAction';
import { getOtpData } from './publicAction';

const apiUrl = process.env.REACT_APP_APIURL;
const localLang = localStorage.getItem('lan');

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_USER_GOOGLE_SUCCESS = 'FETCH_USER_GOOGLE_SUCCESS';
export const FETCH_USER_LOGIN = 'FETCH_USER_LOGIN';
export const FETCH_USER_LOGOUT = 'FETCH_USER_LOGOUT';
export const FETCH_USER_INIT = 'FETCH_USER_INIT';
export const FETCH_USER_BY_ID = 'FETCH_USER_BY_ID';
export const SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID';
export const SET_CURRENT_USER_ID_NULL = 'SET_CURRENT_USER_ID_NULL';
export const FETCH_ALL_INTERVIEWS = 'FETCH_ALL_INTERVIEWS';

export const fetchUserRequest = () => ({ type: FETCH_USER_REQUEST });

export const fetchUserSuccess = (data) => ({
  type: FETCH_USER_SUCCESS,
  payload: data
});

export const fetchUserLogin = () => ({
  type: FETCH_USER_LOGIN
});

export const fetchUserError = (error) => ({
  type: FETCH_USER_FAILURE,
  payload: error
});

export const fetchUserLogout = () => ({
  type: FETCH_USER_LOGOUT
});

export const fetchUserInit = () => ({
  type: FETCH_USER_INIT
});

export const fetchUserById = (data) => ({
  type: FETCH_USER_BY_ID,
  payload: data
});

export const setCurrentId = (id) => ({
  type: SET_CURRENT_USER_ID,
  payload: id
});

export const setCurrentIdNull = () => ({
  type: SET_CURRENT_USER_ID_NULL
});

export const fetchAllInterviews = (data) => ({
  type: FETCH_ALL_INTERVIEWS,
  payload: data
});

export const verifyOtp = (values, navigate, setAuthUser) => {
  const inputData = {
    userCreate: {
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName
    },
    pin: values.pin,
    otpData: values.otpData
  };

  console.log(inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/verifyotp`, inputData);
      console.log('otp res: ', response.data);

      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        dispatch(fetchUserSuccess(response.data));
        dispatch(fetchUserPreRegComplete());
        dispatch(fetchUserLogin());
        dispatch(setCurrentId(response.data.user._id));
        console.log('candidate login id');
        console.log(response.data.user._id);
        setAuthUser(response.data.user._id);
        navigate('/search-jobs');
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const candidateLogin = (values, navigate, setAuthUser) => {
  console.log('login');
  const inputData = {
    email: values.email,
    password: values.password
  };
  console.log(inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/userlogin`, inputData);
      if (response.status === 200) {
        console.log(response.data)
        console.log("CHECKING ")
        localStorage.setItem('token', response.data.token);
        // dispatch(fetchUserSuccess(response.data));
        dispatch(getCurrentUser());
        dispatch(fetchUserLogin());
        dispatch(setCurrentId(response.data.user._id));
        console.log('candidate login id');
        console.log(response.data.user._id);
        setAuthUser(response.data.user._id);
        //setAuthUser()
        navigate('/search-jobs');
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
        if (error.response.data.status === 0) {
          navigate('/not-activated');
        } else if (error.response.data.status === 3) {
          navigate('/suspended');
        }
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const googleLogin = (values, navigate, setAuthUser) => {
  const inputData = {
    googleCreate: {
      email: values.email,
      firstName: values.given_name,
      lastName: values.family_name,
      avatar: values.picture
    }
  };
  console.log(inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/googlelogin`, inputData);

      if (response.status === 200) {
        dispatch(fetchUserSuccess(response.data));
        dispatch(fetchUserLogin());
        dispatch(setCurrentId(response.data.user._id));
        setAuthUser(response.data.user._id);
        localStorage.setItem('token', response.data.token);
        ToastBar.success(response.data.successMsg[localLang]);
        navigate('/search-jobs');
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
        if (error.response.data.status === 0) {
          navigate('/not-activated');
        } else if (error.response.data.status === 3) {
          navigate('/suspended');
        }
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

// export const userLogout = (navigate) => {
//   return async (dispatch) => {
//     localStorage.removeItem('token');
//     dispatch(fetchUserLogout());
//     dispatch(fetchUserInit());
//     ToastBar.success('successfully logged out');
//     navigate('/candidate-landing');
//   };
// };

export const userLogout = (navigate, setAuthUser) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${apiUrl}/user/logout`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );

      if (response.status === 200) {
        navigate('/');
        setTimeout(() => {
          localStorage.removeItem('token');
          dispatch(fetchUserLogout());
          dispatch(fetchUserInit());
          setAuthUser(null);
          dispatch(setCurrentIdNull());
          ToastBar.success(response.data.successMsg[localLang]);
        }, 0);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const dataExampleLoader = () => {
  return async (dispatch) => {
    dispatch(fetchUserRequest());

    try {
      const response = await axios.get(`${apiUrl}/user`);
      console.log('res: ', response.data.users);

      dispatch(fetchUserSuccess({ users: response.data.users }));
    } catch (error) {
      dispatch(fetchUserError(error));
    }
  };
};

export const generalDetailAdd = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/updategeneral`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const educationAdd = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/addeducation`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchUserSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const educationEdit = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/editeducation`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('edit resp: ', response.data);
        dispatch(fetchUserSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const certificationAdd = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/addcertification`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const certificationEdit = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/editcertification`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log('edit resp: ', response.data);
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const experienceAdd = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/addworkexperince`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchUserSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const experienceEdit = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/editworkexperince`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchUserSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const skillAdd = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/addskill`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchUserSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const languageAdd = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/addlanguage`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchUserSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const uploadResume = (values, setAddUploadCv, setLoading) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/uploadresume`, inputData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
        setLoading(false);
        setAddUploadCv(false)
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteResume = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/deleteresume`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const uploadVideo = (values, setAddUploadVideo, setLoading) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/uploadvideo`, inputData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
        setLoading(false);
        setAddUploadVideo(false)
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const deleteVideo = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/deletevideo`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const skillDelete = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/deleteskill`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        dispatch(fetchUserSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const languageDelete = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/deletelanguage`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        dispatch(fetchUserSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const educationDelete = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/deleteeducation`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        dispatch(fetchUserSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const experienceDelete = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/deleteworkexperince`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        dispatch(fetchUserSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const certificationDelete = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/deletecertification`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const applyJob = (values, navigate) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/applyjob`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      console.log('response.data: ', response.data);
      if (response.status === 200) {
        dispatch(fetchUserSuccess(response.data));
        dispatch(fetchAllJobsSuccess(response.data));
        dispatch(createApplyJobNotification(values));
        ToastBar.success(response.data.successMsg[localLang]);
        navigate('/profile/applied-jobs');
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getCurrentUser = () => {
  console.log('check2');
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/user/getcurrentuser`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      console.log('api res: ', response);

      if (response.status === 200) {
        console.log('current user api res: ', response.data);
        dispatch(fetchUserSuccess(response.data));
        return response.data;
      }
    } catch (error) {
      // Handle error response
      console.log('api error: ', error);

      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const updateTemplate = (values, navigate) => {
  console.log('input value: ', values);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/updatetemplate`, values, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      console.log('response.data: ', response.data);
      if (response.status === 200) {
        dispatch(getCurrentUser());
        if (navigate) navigate('/profile/cv-website/build');
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const socialAdd = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/addsocial`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const socialDelete = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/deletesocial`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const portfolioAdd = (formData, resetForm, setAddPortfolio, setLoading) => {
  console.log(formData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/addportfolio`, formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 200) {
        console.log(response.data);
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
        setLoading(false);
        resetForm();
        setAddPortfolio(false);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const portfolioDelete = (values) => {
  const inputData = values;
  console.log('input value: ', inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/deleteportfolio`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const editProfilePic = (values, closeModal) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/user/editprofilepic`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getCurrentUser());
        closeModal();
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const getUserById = (values) => {
  const inputData = values;

  console.log('input param: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/user/getuserbyid`, {
        params: { userId: inputData },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('user by id: ', response.data);
        dispatch(fetchUserById(response.data));
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getCandidateInterviews = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/user/getcandidateinterviewdates`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('interview res: ', response.data);
        dispatch(fetchAllInterviews(response.data));
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const testimonialDelete = (values) => {
  console.log('input value: ', values);
  return async (dispatch) => {
    try {
      const response = await axios.delete(`${apiUrl}/user/delete-testimonial`, {
        data: values,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};
