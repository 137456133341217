import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Template1 from '../templates/template1/template1';
import Template2 from '../templates/template2/template2';
import { updateTemplate } from '../../actions/userAction';
import { useNavigate } from 'react-router-dom';
import Template3 from '../templates/template3/template3';

const TemplatePreviewModal = ({ template, closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedTemplate = useSelector((state) => state.general?.selectedTemplate);
  const user = useSelector((state) => state.user);

  return (
    <>
      <div className="fixed -inset-10 bg-black bg-opacity-50 px-5 flex flex-col justify-start items-center z-30">
        <div className="bg-white py-5 w-full px-7 mt-10 rounded-lg shadow-lg overflow-y-auto">
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-bold">{template?.label}</h2>
            <div className="flex gap-x-5">
              <button
                onClick={() => dispatch(updateTemplate({ template: template.title }, navigate))}
                className="text-lg font-bold bg-black/80 hover:bg-black px-5 py-2 rounded-md text-white"
              >
                Use this template
              </button>
              <button
                onClick={closeModal}
                className="text-lg font-bold hover:bg-red-500 px-4 py-2 rounded-md hover:text-white"
              >
                X
              </button>
            </div>
          </div>
          <div className="px-10 pt-10">
            {selectedTemplate ? (
              <div>
                {selectedTemplate?.title === 'template1' && <Template1 user={user} />}
                {selectedTemplate?.title === 'template2' && <Template2 user={user} />}
                {selectedTemplate?.title === 'template3' && <Template3 user={user} />}
              </div>
            ) : (
              <div className="flex w-full h-screen justify-center items-center">
                <div className="text-3xl font-semibold">Please select the template</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplatePreviewModal;
