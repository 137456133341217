import React from 'react';
import { Checkbox } from '@material-tailwind/react';

const CheckboxGroup = ({ data, onChange, localLang }) => {
  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;
    onChange(item, isChecked);
  };

  return (
    <div className="flex flex-wrap">
      {data.map((item, index) => (
        <div key={index} className="w-1/2">
          <Checkbox
            label={<div className="text-black text-base font-normal">{item.label[localLang]}</div>}
            key={item.id}
            color="blue-gray"
            text={item.label[localLang]}
            checked={item.checked || false}
            onChange={(e) => handleCheckboxChange(e, item)}
            className="w-4 h-4"
          />
        </div>
      ))}
    </div>
  );
};

export default CheckboxGroup;
