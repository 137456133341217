const SearchTile = ({ name, level, uniqueKey, onDelete }) => {
  return (
    <div key={uniqueKey + name} className="flex px-4 py-2.5 bg-blue-100 rounded-md gap-x-3">
      <div className="text-sm font-bold capitalize">
        {name} {level && <span>({level})</span>}
      </div>
      {onDelete && (
        <button onClick={onDelete} className="text-sm font-bold hover:bg-red-500 px-1">
          X
        </button>
      )}
    </div>
  );
};

export default SearchTile;
