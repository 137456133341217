import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import GeneralTile from '../../common/generealTile';
import CheckboxGroup from '../../common/checkboxGroup';
import { workPolicyData } from '../../constant/checkboxData';
import { updateEmployerWorkPolicy } from '../../actions/employerAction';

const EmployerWorkPolicy = () => {
  const localLang = localStorage.getItem('lan');
  const dispatch = useDispatch();

  const [addWorkPolicy, setAddWorkPolicy] = useState(false);
  const [workPolicyCheckBoxData, setWorkPolicyCheckBoxData] = useState(workPolicyData);

  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.employer);

  const checkWorkPolicyFromDB = () => {
    const updatedWorkPolicy = workPolicyCheckBoxData?.map((policy) => {
      const isChecked = employer.workPolicy.some((data) => data.workPolicyId === policy.workPolicyId);

      if (isChecked) {
        return { ...policy, checked: true };
      } else {
        return policy;
      }
    });

    setWorkPolicyCheckBoxData(updatedWorkPolicy);
  };

  useEffect(() => {
    checkWorkPolicyFromDB();
  }, []);

  const handleWorkPolicyCheckboxChange = (item, isChecked) => {
    setWorkPolicyCheckBoxData((prevData) =>
      prevData.map((prevItem) =>
        prevItem.workPolicyId === item.workPolicyId ? { ...prevItem, checked: isChecked } : prevItem
      )
    );
    item.checked = isChecked;
    const updatedWorkPolicy = isChecked
      ? [...formik.values.companyWorkPolicy, item]
      : formik.values.companyWorkPolicy.filter((benefit) => benefit.workPolicyId !== item.workPolicyId);

    formik.setValues({ ...formik.values, companyWorkPolicy: updatedWorkPolicy });
  };

  const initialValues = {
    companyWorkPolicy: employer?.workPolicy || ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      console.log('value: ', values);
      const result = await dispatch(updateEmployerWorkPolicy(values));
      if (result && !result.error) {
        setAddWorkPolicy(false);
      }
    }
  });

  return (
    <div className="flex flex-col justify-center pt-5 px-0 lg:px-8 rounded-md2 bg-white ">
      <div className="flex flex-row items-center gap-x-5">
        <div className="text-xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.companyWorkPolicy}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {!addWorkPolicy && (
          <button
            onClick={() => setAddWorkPolicy(true)}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
          >
            + {lang.profileAdd}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 lg:px-5">
        <div className="flex flex-wrap gap-x-2 lg:gap-x-5 pb-7 gap-y-2">
          {employer?.workPolicy?.map((benefit, index) => {
            return <GeneralTile name={benefit.label[localLang]} uniqueKey={index} key={index} />;
          })}
        </div>
        {addWorkPolicy && (
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="border rounded-md">
                <CheckboxGroup
                  data={workPolicyCheckBoxData}
                  onChange={handleWorkPolicyCheckboxChange}
                  localLang={localLang}
                />
              </div>
              <div className="flex justify-end flex-row gap-x-2 pt-10 pb-5">
                <button
                  onClick={() => setAddWorkPolicy(false)}
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
                >
                  {lang.profileCancel}
                </button>
                <button
                  type="submit"
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md"
                >
                  {lang.profileAdd}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployerWorkPolicy;
