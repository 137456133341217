import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { salutationOptions } from '../../constant/dropdownConstOptions';
import { submitTestimonial } from '../../actions/publicProfileAction';
import { createTestimonialSchema } from '../../validations/validationSchemas';

const TestimonialForm = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lang = useSelector((state) => state.language.lang);

  const formik = useFormik({
    initialValues: {
      salutation: '',
      fullName: '',
      position: '',
      organization: '',
      testimonial: ''
    },
    validationSchema: createTestimonialSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      dispatch(submitTestimonial(values, navigate, user?.publicId));
    }
  });

  return (
    <div className="flex flex-col p-10 w-full items-center">
      <h2 className="text-xl md:text-2xl lg:text-2xl font-bold mb-4 lg:mb-6 text-oppty-black">
        Testimonial Submission of {user?.firstName} {user?.lastName}
      </h2>
      <div className="w-full flex flex-col items-center">
        <form onSubmit={formik.handleSubmit} className="max-w-[550px] w-full border-2 p-5 md:p-10 rounded-md">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{lang.generalSalutation}</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={'Select Salutation'}
              value={salutationOptions.find((option) => option.value === formik.values.level)}
              onChange={(selectedOption) => formik.setFieldValue('salutation', selectedOption?.value || '')}
              isDisabled={false}
              isLoading={false}
              isClearable={false}
              isRtl={false}
              isSearchable={false}
              name="salutation"
              options={salutationOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  height: 35
                })
              }}
            />
            {formik.touched.salutation && formik.errors.salutation ? (
              <div className="text-red-500 text-sm">{formik.errors.salutation}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{lang.generalFullName}</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              placeholder={lang.generalFullName}
              className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300"
              onChange={formik.handleChange}
              value={formik.values.fullName}
            />
            {formik.touched.fullName && formik.errors.fullName ? (
              <div className="text-red-500 text-sm">{formik.errors.fullName}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{lang.generalPosition}</label>
            <input
              type="text"
              id="position"
              name="position"
              placeholder={lang.generalPosition}
              className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300"
              onChange={formik.handleChange}
              value={formik.values.position}
            />
            {formik.touched.position && formik.errors.position ? (
              <div className="text-red-500 text-sm">{formik.errors.position}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{lang.generalOrganization}</label>
            <input
              type="text"
              id="organization"
              name="organization"
              placeholder={lang.generalOrganization}
              className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300"
              onChange={formik.handleChange}
              value={formik.values.organization}
            />
            {formik.touched.organization && formik.errors.organization ? (
              <div className="text-red-500 text-sm">{formik.errors.organization}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{lang.profileTestimonial}</label>
            <textarea
              type="text"
              id="testimonial"
              name="testimonial"
              rows={6}
              placeholder={'Type here...'}
              className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300"
              onChange={formik.handleChange}
              value={formik.values.testimonial}
            />
            <div
              className={`text-xs font-semibold ml-1 ${formik.values.testimonial.length > 200 ? 'text-red-500' : 'text-black'}`}
            >
              {formik.values.testimonial.length}/200 Words
            </div>
            {formik.touched.testimonial && formik.errors.testimonial ? (
              <div className="text-red-500 text-sm">{formik.errors.testimonial}</div>
            ) : null}
          </div>
          <button
            type="submit"
            className="w-full bg-oppty-blue text-white font-semibold p-2 rounded-md hover:bg-oppty-blue/90 flex items-center justify-between"
          >
            <span className="flex-grow text-center">Submit</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default TestimonialForm;
