import { useDispatch, useSelector } from 'react-redux';
import share from '../../../assets/images/share.svg';
import { calculateDuration, calculateMatchingPercentage, copyToClipboard, getLabelByValue } from '../../../utils/functions';
import { saveJob } from '../../../actions/jobAction';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { jobTypeOptions } from '../../../constant/dropdownConstOptions';

const linkUrl = process.env.REACT_APP_LINKURL;

const JobCard = ({ job, user, onClick, isApplied }) => {
  const dispatch = useDispatch();

  const [matchingPercentage, setMatchingPercentage] = useState(0);

  const lang = useSelector((state) => state.language.lang);
  const isUserLogin = useSelector((state) => state.userLogin);
  const isEmployerLogin = useSelector((state) => state.employerLogin);
  const alreadySaved = user?.savedJobs?.some((savedJob) => savedJob._id === job._id);

  const handleClick = () => {
    if (onClick) {
      onClick(job);
    }
  };

  const jobDescriptionLimit = (html, limit) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    const text = tempElement.innerText;
    const words = text.split(' ');
    const truncatedWords = words.slice(0, limit);
    return truncatedWords.join(' ');
  };

  const handleSaveJob = () => {
    const exists = user?.savedJobs?.some((obj) => obj?._id === job?._id);
    const inputValue = {
      ...job,
      exist: exists
    };
    dispatch(saveJob(inputValue));
  };

  useEffect(() => {
    if (isUserLogin || isEmployerLogin) setMatchingPercentage(calculateMatchingPercentage(user?.skills, job?.skills));
  }, [isUserLogin, isEmployerLogin]);

  return (
    <div
      id={job.id}
      className="cursor-pointer flex flex-col border-[1px] border-black/40 p-7 rounded-[4px] min-w-72"
      onClick={handleClick}
    >
      <div className="flex justify-between">
        <div className="text-2xl font-nunito font-bold leading-6 text-oppty-black">{job?.jobTitle}</div>
        <div className="flex gap-x-3 flex-shrink-0">
          <div
            className="w-8 h-8 border-[1px] border-gray-300 p-1.5 hover:bg-orange-100 cursor-pointer flex-shrink-0"
            onClick={handleSaveJob}
          >
            <BookmarkIcon className={`h-full w-full ${alreadySaved && 'fill-orange-800'} stroke-orange-800 stroke-2`} />
          </div>
          <img
            src={share}
            alt="share"
            onClick={() => copyToClipboard(`${linkUrl}/jobs/${job?._id}`, 'Job URL Copied')}
            className="h-8 w-8 hover:bg-orange-100 cursor-pointer"
          />
        </div>
      </div>
      <div className="text-sm font-nunito font-semibold leading-6 py-1">
        <span
          onClick={() => window.open(`/employers/${job?.employer?._id}`, '_blank')}
          className="cursor-pointer text-oppty-blue hover:text-blue-900 text-lg"
        >
          {job?.employer?.companyName}
        </span>
      </div>
      <div className="text-base font-nunito font-normal leading-6 text-oppty-black pb-2">
        {job?.employer?.companyLocation}
      </div>
      <div className="flex gap-x-2">
        <div className="bg-oppty-green-4 px-[10px] py-1 text-base font-nunito font-semibold leading-5 text-oppty-black mb-2 rounded capitalize">
          {job?.currencyType} {job.minSalary} <span className={`${job?.maxSalary ? '' : 'hidden'}`}>- {job.maxSalary}</span> / {job?.salaryType}
        </div>
        <div className="bg-oppty-green-4 px-6 py-1 text-base font-nunito font-semibold leading-5 text-oppty-black mb-2 rounded">
          {getLabelByValue(job.jobEmploymentType, jobTypeOptions)}
        </div>
      </div>
      <div className="text-base font-nunito font-normal leading-6 text-oppty-grey-8 py-3">
        {jobDescriptionLimit(job?.jobDescription, 23)}.....
      </div>
      <div className="w-full flex justify-between">
        <div className="text-sm font-nunito font-normal leading-normal text-oppty-grey-1 flex flex-col gap-y-1">
          {(isEmployerLogin || isUserLogin) && (
            <span
              className={`font-semibold ${matchingPercentage > 50 ? 'text-green-500' : matchingPercentage > 0 ? 'text-blue-500' : 'text-red-500'}`}
            >
              {matchingPercentage}% {lang.generalSkilledMatch}
            </span>
          )}
          <div>{calculateDuration(job?.createdDate)}</div>
        </div>
        {isApplied && (
          <div className="px-2 h-7 bg-blue-100 text-base text-blue-500 font-bold rounded-sm flex justify-center items-center">
            {lang.generalApplied}
          </div>
        )}
      </div>
    </div>
  );
};

export default JobCard;
