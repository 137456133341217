import { useSelector } from "react-redux";

const CoverPageArrangement = ({ employer, setShowModal }) => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <>
      {employer?.employer?.images?.length >= 5 ? (
        <div className="flex flex-row gap-x-1 h-96 pb-10">
          <div className="w-1/2">
            <img src={employer?.employer?.images[0]} alt="victor" className="h-full w-full object-cover p-1" />
          </div>
          <div className="relative w-1/2 h-full flex flex-wrap">
            {employer?.employer?.images?.slice(1, 5).map((pic, index) => (
              <img key={index} src={pic} alt="pic" className="h-1/2 w-1/2 object-cover p-1" />
            ))}
            <div className="absolute top-0 left-0 w-full h-full flex items-end justify-end p-4">
              <button
                className=" bg-deep-purple-400 hover:bg-deep-purple-500 hover:shadow-lg text-white active:bg-deep-purple-200 font-bold uppercase text-[8px] px-5 py-2 rounded transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
              >
                {lang.generalShowAllMedia}
              </button>
            </div>
          </div>
        </div>
      ) : employer?.employer?.images?.length === 4 ? (
        <div className="flex flex-row gap-x-1 h-96 pb-10">
          <div className="w-1/2">
            <img src={employer?.employer?.images[0]} alt="victor" className="h-full w-full object-cover p-1" />
          </div>
          <div className="relative w-1/2 h-full flex flex-wrap">
            <img src={employer?.employer?.images[1]} alt="pic" className="h-1/2 w-full object-cover p-1" />
            <img src={employer?.employer?.images[2]} alt="pic" className="h-1/2 w-1/2 object-cover p-1" />
            <img src={employer?.employer?.images[3]} alt="pic" className="h-1/2 w-1/2 object-cover p-1" />
            <div className="absolute top-0 left-0 w-full h-full flex items-end justify-end p-4">
              <button
                className=" bg-deep-purple-400 hover:bg-deep-purple-500 hover:shadow-lg text-white active:bg-deep-purple-200 font-bold uppercase text-[8px] px-5 py-2 rounded transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
              >
                {lang.generalShowAllMedia}
              </button>
            </div>
          </div>
        </div>
      ) : employer?.employer?.images?.length === 3 ? (
        <div className="flex flex-row gap-x-1 h-96 pb-10">
          <div className="w-1/2">
            <img src={employer?.employer?.images[0]} alt="victor" className="h-full w-full object-cover p-1" />
          </div>
          <div className="relative w-1/2 h-full flex flex-wrap">
            <img src={employer?.employer?.images[1]} alt="pic" className="h-full w-1/2 object-cover p-1" />
            <img src={employer?.employer?.images[2]} alt="pic" className="h-full w-1/2 object-cover p-1" />
            <div className="absolute top-0 left-0 w-full h-full flex items-end justify-end p-4">
              <button
                className=" bg-deep-purple-400 hover:bg-deep-purple-500 hover:shadow-lg text-white active:bg-deep-purple-200 font-bold uppercase text-[8px] px-5 py-2 rounded transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
              >
                {lang.generalShowAllMedia}
              </button>
            </div>
          </div>
        </div>
      ) : employer?.employer?.images?.length === 2 ? (
        <div className="flex flex-row gap-x-1 h-96 pb-10">
          <div className="w-1/2">
            <img src={employer?.employer?.images[0]} alt="victor" className="h-full w-full object-cover p-1" />
          </div>
          <div className="relative w-1/2 h-full flex flex-wrap">
            <img src={employer?.employer?.images[1]} alt="pic" className="h-full w-full object-cover p-1" />
            <div className="absolute top-0 left-0 w-full h-full flex items-end justify-end p-4">
              <button
                className=" bg-deep-purple-400 hover:bg-deep-purple-500 hover:shadow-lg text-white active:bg-deep-purple-200 font-bold uppercase text-[8px] px-5 py-2 rounded transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
              >
                {lang.generalShowAllMedia}
              </button>
            </div>
          </div>
        </div>
      ) : employer?.employer?.images?.length === 1 ? (
        <div className="flex flex-row gap-x-1 h-96 pb-10">
          <div className="relative w-full h-full flex flex-wrap">
            <img src={employer?.employer?.images[0]} alt="pic" className="h-full w-full object-cover p-1" />
            <div className="absolute top-0 left-0 w-full h-full flex items-end justify-end p-4">
              <button
                className=" bg-deep-purple-400 hover:bg-deep-purple-500 hover:shadow-lg text-white active:bg-deep-purple-200 font-bold uppercase text-[8px] px-5 py-2 rounded transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
              >
                {lang.generalShowAllMedia}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CoverPageArrangement;
