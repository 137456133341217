import axios from 'axios';
import { ToastBar } from '../common/toastbar';

const apiUrl = process.env.REACT_APP_APIURL;
const localLang = localStorage.getItem('lan');

export const FETCH_ALL_COMPANY_LOGO = 'FETCH_ALL_COMPANY_LOGO';
export const FETCH_ALL_ARTICLES = 'FETCH_ALL_ARTICLES';
export const FETCH_RECENT_JOBS = 'FETCH_RECENT_JOBS';
export const FETCH_GET_OTP_DATA_SUCCESS = 'FETCH_GET_OTP_DATA_SUCCESS';

export const fetchAllCompanyLogo = (data) => ({
  type: FETCH_ALL_COMPANY_LOGO,
  payload: data
});

export const fetchAllArticles = (data) => ({
  type: FETCH_ALL_ARTICLES,
  payload: data
});

export const fetchRecentJobs = (data) => ({
  type: FETCH_RECENT_JOBS,
  payload: data
});

export const getOtpData = (data) => ({
  type: FETCH_GET_OTP_DATA_SUCCESS,
  payload: data
});

export const getCompanyLogos = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/public/get-employers-logo`);

      if (response.status === 200) {
        console.log('company logo res: ', response.data);
        dispatch(fetchAllCompanyLogo(response.data));
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getCandidateArticles = (inputData) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/public/get-candidate-articles`, {
        params: inputData
      });

      if (response.status === 200) {
        console.log('article res: ', response.data);
        dispatch(fetchAllArticles(response.data));
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getEmployerArticles = (inputData) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/public/get-employer-articles`, {
        params: inputData
      });

      if (response.status === 200) {
        console.log('article res: ', response.data);
        dispatch(fetchAllArticles(response.data));
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getArticleById = (inputData, setArticle) => {
  return async () => {
    try {
      const response = await axios.get(`${apiUrl}/public/get-article-byid`, {
        params: inputData
      });
      if (response.status === 200) {
        console.log('getbyId res: ', response.data);
        setArticle(response.data?.article);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getRecentJobs = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/public/get-latest-jobs`);
      if (response.status === 200) {
        console.log('get jobs res: ', response.data);
        dispatch(fetchRecentJobs(response.data))
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const userEmailCheck = (email) => {
  return async () => {
    try {
      const response = await axios.get(`${apiUrl}/general/check-user-email-exist`, {
        params: { email: email }
      });

      if (response.status === 200) {
        console.log('check res: ', response.data);
        return response.data?.exist;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        return false;
      } else {
        console.log('check error: ', error.response?.data);
        return error.response?.data?.exist;
      }
    }
  };
};

export const getUserForgetPasswordOTP = (values, navigate) => {
  console.log('input values: ', values);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/general/create-user-otp`, values);

      if (response.status === 200) {
        console.log('check res: ', response.data);
        dispatch(getOtpData(response.data));
        if(navigate) navigate('/user-verify-forgot-password', { state: { email: values?.email } });
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const userVerifyForgetPasswordOTP = (values, navigate, setLoading) => {
  console.log('input values: ', values);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/general/verify-user-otp`, values);

      if (response.status === 200) {
        console.log('check res: ', response.data);
        setLoading(false);
        navigate('/user-create-new-password', { state: { email: values?.email } });
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0])
        setLoading(false);
      } else {
        // Handle other errors
        console.log(error.message);
        setLoading(false);
      }
      return { error: error.message };
    }
  };
};

export const userCreateNewPassword = (values, navigate, setLoading) => {
  console.log('input values: ', values);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/general/update-user-password`, values);

      if (response.status === 200) {
        console.log('check res: ', response.data);
        setLoading(false)
        navigate('/candidate-login');
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const employerEmailCheck = (email) => {
  return async () => {
    try {
      const response = await axios.get(`${apiUrl}/general/check-employer-email-exist`, {
        params: { email: email }
      });

      if (response.status === 200) {
        console.log('check res: ', response.data);
        return response.data?.exist;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        return false;
      } else {
        console.log('check error: ', error.response?.data);
        return error.response?.data?.exist;
      }
    }
  };
};

export const getEmployerForgetPasswordOTP = (values, navigate) => {
  console.log('input values: ', values);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/general/create-employer-otp`, values);

      if (response.status === 200) {
        console.log('check res: ', response.data);
        ToastBar.success(response.data.successMsg[localLang])
        dispatch(getOtpData(response.data));
        if(navigate) navigate('/employer-verify-forgot-password', { state: { email: values?.email } });
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};


export const employerVerifyForgetPasswordOTP = (values, navigate, setLoading) => {
  console.log('input values: ', values);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/general/verify-employer-otp`, values);

      if (response.status === 200) {
        console.log('check res: ', response.data);
        setLoading(false);
        navigate('/employer-create-new-password', { state: { email: values?.email } });
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
        ToastBar.error(error.response.data.errorMsg[0])
        setLoading(false);
      } else {
        // Handle other errors
        console.log(error.message);
        setLoading(false);
      }
      return { error: error.message };
    }
  };
};

export const employerCreateNewPassword = (values, navigate, setLoading) => {
  console.log('input values: ', values);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/general/update-employer-password`, values);

      if (response.status === 200) {
        console.log('check res: ', response.data);
        setLoading(false)
        navigate('/employer-login');
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const addSubscriber = (values) => {
  console.log('input values: ', values);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/public/add-subscribe`, values);

      if (response.status === 200) {
        console.log('check res: ', response.data);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[0]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const submitContactUs = (values, setLoader, resetForm, navigate) => {
  console.log('input values: ', values);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/public/contact-us`, values);

      if (response.status === 200) {
        console.log('check res: ', response.data);
        ToastBar.success(response.data.successMsg[localLang]);
        setLoader(false)
        resetForm()
        navigate('/')
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      setLoader(false)
      return { error: error.message };
    }
  };
};

