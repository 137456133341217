import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const Tab = ({ label, onClick, isActive }) => {
  return (
    <div
      className={`cursor-pointer text-[10px] md:text-2xl px-3 md:px-9 md:py-1 rounded-[15px] md:rounded-[40px] ${
        isActive ? 'bg-oppty-green-1 text-white' : ''
      }`}
      onClick={onClick}
    >
      {label}
    </div>
  )
}

const TabPanel = ({ children, isActive }) => {
  return isActive ? <div className="w-[80%]">{children}</div> : null
}

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0)

  const lang = useSelector((state) => state.language.lang)

  const handleTabClick = (index) => {
    setActiveTab(index)
  }

  return (
    <div className="w-full flex flex-col items-center">
      <div className="outfit-font text-[18px] md:text-[40px] lg:text-[60px] font-normal text-oppty-black-3 w-[100%] lg:leading-[50px] flex justify-center mb-10">
        {lang.employeeHomeQAndA}
      </div>
      {/* <div className="flex py-5 md:py-16 space-x-2 justify-center">
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} onClick={() => handleTabClick(index)} isActive={activeTab === index} />
        ))}
      </div> */}
      {tabs.map((tab, index) => (
        <TabPanel key={index} isActive={activeTab === index}>
          {tab.component}
        </TabPanel>
      ))}
    </div>
  )
}

export default Tabs
