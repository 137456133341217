import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import DashboardLayout from '../../layout/dashboardLayout';
import {
  getSearchCandidate,
  suspendCandidate,
  activateCandidate,
  fetchSearchCandidate
} from '../../actions/adminAction';
import { ToastBar } from '../../common/toastbar';
import defaultImage from '../../assets/images/Default_profilepic.png';

const AdminSearchCandidate = () => {
  const dispatch = useDispatch();
  const searchCandidate = useSelector((state) => state.admin.searchCandidate);

  console.log('searchCandidate: ', searchCandidate);

  const [email, setEmail] = useState('');

  useEffect(() => {
    dispatch(fetchSearchCandidate({}));
  }, []);

  const handleSearch = () => {
    if (email) {
      dispatch(getSearchCandidate(email));
    } else {
      ToastBar.error('Please enter an email');
    }
  };

  const handleActivateCandidate = () => {
    dispatch(activateCandidate(searchCandidate?._id));
  };

  const handleSuspendCandidate = () => {
    dispatch(suspendCandidate(searchCandidate?._id));
  };

  const handleUnsuspendCandidate = () => {
    dispatch(activateCandidate(searchCandidate?._id));
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return { label: 'Active', color: 'text-green-500' };
      case 0:
        return { label: 'Not Active', color: 'text-orange-500' };
      case 3:
        return { label: 'Suspended', color: 'text-red-500' };
      default:
        return { label: 'Unknown', color: 'text-gray-500' };
    }
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col justify-center p-5 gap-y-5">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="text-sm font-nunito font-bold leading-normal text-oppty-grey-16">Email</div>
            <input
              type="text"
              id="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300 ml-4"
            />
            <button onClick={handleSearch} type="button" className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-md">
              Search
            </button>
          </div>
        </div>

        <div className="text-lg font-nunito font-bold leading-normal text-black mt-4">
          {searchCandidate ? 'Showing 1 Candidate' : 'No candidates available'}
        </div>

        {searchCandidate && (
          <>
            <table className="table-auto bg-white rounded-2xl mt-4">
              <thead>
                <tr className="h-20 border-b-[1px]">
                  <th className="px-4">Avatar</th>
                  <th>Salutation</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Joined Date</th>
                  <th>Last Login</th>
                  <th>Email</th>
                  <th className="px-4">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-20">
                  {/* <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    <img src={searchCandidate?.avatar} alt="profile pic" className="w-12 h-12 rounded-full" />
                  </td> */}
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    <div className="flex justify-center items-center">
                      <img
                        src={searchCandidate?.avatar ? searchCandidate?.avatar : defaultImage}
                        alt="profile pic"
                        className="w-12 h-12 rounded-full"
                      />
                    </div>
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {searchCandidate?.salutation}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {searchCandidate?.firstName}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {searchCandidate?.lastName}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {!Number.isNaN(new Date(searchCandidate?.createdDate).getTime())
                      ? format(new Date(searchCandidate?.createdDate), 'dd-MM-yyyy HH:mm')
                      : 'Invalid Date'}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {!Number.isNaN(new Date(searchCandidate?.lastLogin).getTime())
                      ? format(new Date(searchCandidate?.lastLogin), 'dd-MM-yyyy HH:mm')
                      : 'Invalid Date'}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {searchCandidate?.email}
                  </td>
                  <td
                    className={`text-[13px] font-nunito font-bold leading-normal text-center ${getStatusLabel(searchCandidate?.status)?.color}`}
                  >
                    {getStatusLabel(searchCandidate?.status)?.label}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="flex justify-center gap-4 mt-4">
              {searchCandidate.status === 0 && (
                <button onClick={handleActivateCandidate} className="px-4 py-2 bg-green-500 text-white rounded-md">
                  Activate Candidate
                </button>
              )}
              {searchCandidate.status === 1 && (
                <button onClick={handleSuspendCandidate} className="px-4 py-2 bg-red-500 text-white rounded-md">
                  Suspend Candidate
                </button>
              )}
              {searchCandidate.status === 3 && (
                <button onClick={handleUnsuspendCandidate} className="px-4 py-2 bg-orange-500 text-white rounded-md">
                  Unsuspend Candidate
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default AdminSearchCandidate;
