import { useEffect } from 'react';
import Header from '../../../common/header';
import SavedJobsList from './savedJobsList';
import Footer from '../../../common/footer';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../../actions/userAction';

const SavedJobs = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="sticky top-0 z-50">
        <Header />
      </div>
      <div className="flex-1 overflow-auto my-5 flex justify-center items-start w-full">
        <SavedJobsList user={user} />
      </div>
      <Footer />
    </div>
    // <div className="flex flex-col min-h-screen">
    //   <div className="sticky top-0 z-50">
    //     <Header />
    //   </div>
    //   <div className="flex-1 overflow-autoflex justify-center items-start">
    //     <div className="w-full">
    //       <SavedJobsList user={user} />
    //     </div>
    //   </div>
    //   <Footer />
    // </div>
  );
};

export default SavedJobs;
