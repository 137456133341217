import { useDispatch, useSelector } from 'react-redux';
import { updateLeftNavBarToggleStatus } from '../../actions/generalAction';
import LeftNavigationBar from '../../common/leftNavigationBar';
import EmployerHeader from '../../common/employerHeader';
import EditJobForm from './editJobForm';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const EditJob = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const job = location.state?.job;

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isCollapsed = useSelector((state) => state?.general?.leftNavBarToggle);
  const employer = useSelector((state) => state?.employer);

  const toggleNavbar = () => dispatch(updateLeftNavBarToggleStatus({ leftNavBarToggle: !isCollapsed }));
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    dispatch(updateLeftNavBarToggleStatus({ leftNavBarToggle: false }));
  };
  return (
    <div className="flex flex-row min-h-screen gap-y-8 bg-white">
      <div
      className={`${
        isMobileMenuOpen ? 'block' : 'hidden lg:block'
      } fixed z-50 w-60 bg-white shadow-lg lg:w-60 lg:bg-transparent lg:shadow-none lg:block transition-transform duration-300`}
      style={{ transform: isMobileMenuOpen ? 'translateX(0)' : 'translateX(-100%) lg:translateX(0)' }}
    >
        <LeftNavigationBar
          isCollapsed={isCollapsed}
          toggleNavbar={toggleNavbar}
          toggleMobileMenu={toggleMobileMenu}
          isMobileMenuOpen={isMobileMenuOpen}
        />
      </div>

      <div className={`flex flex-col flex-grow min-h-screen
         ${isCollapsed ? 'ml-0 lg:ml-16' : 'lg:ml-60'}`}>
        <div className={`flex w-full fixed top-0 left-0 ${isCollapsed ? 'pl-0 lg:pl-16' : 'lg:pl-60'} z-40`}>
          <EmployerHeader toggleMobileMenu={toggleMobileMenu} />
        </div>
        <div className="mt-20">
          <EditJobForm job={job} employer={employer} />
        </div>
      </div>
    </div>
  );
};

export default EditJob;
