import { useState } from 'react';
import { DocumentIcon, TrashIcon } from '@heroicons/react/24/outline';
import EmployerImageUploadModal from './employerImageUploadModal';
import { useSelector } from 'react-redux';

const UploadPEmployerImage = ({ employer }) => {
  const [uploadPicModal, setUploadPicModal] = useState(false);

  const lang = useSelector((state) => state.language.lang);

  return (
    <div className="flex flex-col justify-center rounded-[20px] w-full">
      <div
        className={`drop-area flex flex-col gap-y-5 justify-center items-center h-48 rounded-md w-full bg-oppty-grey-17`}
        onClick={() => setUploadPicModal(true)}
      >
        <DocumentIcon className="top-10 stroke-gray-700 w-8 h-8" />
        <button type="button" className="px-5 py-1 rounded-xl bg-gray-800 text-white font-bold cursor-pointer">
          + {lang.generalUploadImage}
        </button>
      </div>
      {uploadPicModal && <EmployerImageUploadModal employer={employer} closeModal={() => setUploadPicModal(false)} />}
    </div>
  );
};

export default UploadPEmployerImage;
