const EducationCard = ({ education }) => {
  return (
    <div className="flex flex-col">
      <div className="text-white text-2xl font-semibold">
        {education?.qualification} in {education?.course}
      </div>
      <div className="text-gray-300 text-lg font-semibold">{education?.institution}</div>
      <div className="text-base text-template9-orange-1 bg-template9-gray-2 w-fit px-4 py-2 mt-2">
        {education?.startMonth} {education?.startYear} -{' '}
        {education?.endYear === 99 ? 'Current' : `${education?.startMonth} ${education?.endYear}`}
      </div>
      {/* <div className="font-normal text-xs text-gray-200 mt-2">{education?.description}</div> */}
      <div className="w-full mt-3 text-white" dangerouslySetInnerHTML={{ __html: education?.description }}></div>
    </div>
  );
};

export default EducationCard;
