import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InterviewDateSelectorModal from './interviewDateSelectorModal';
import { addToConversation } from '../../actions/messageAction';
import StatusConfirmationModal from './statusConfirmationModal';
import ApplicantStatusDropdown from './applicantStatusDropdown';
import CandidateQuestionnaireModal from './candidateQuestionnaireModal';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateViewedApplicantStatus } from '../../actions/jobAction';
import { calculateMatchingPercentage } from '../../utils/functions';

const AllApplicants = ({ candidateId, jobTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [interviewModal, setInterviewModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [quesModal, setQuesModal] = useState(false);
  const [clickedApplicant, setClickedApplicant] = useState(null);
  const [clickedQuestionnaire, setClickedQuestionnaire] = useState(null);
  const [clickedEmpQuestionnaire, setClickedEmpQuestionnaire] = useState(null);
  const [status, setStatus] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: 'generalFullName',
    direction: 'ascending'
  });
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const lang = useSelector((state) => state.language.lang);
  const applicants = useSelector((state) => state.job.applicantsByJob);

  const handleAddToConvo = async (applicantId) => {
    console.log('Adding');
    dispatch(addToConversation(applicantId));
  };

  const closeModal = () => {
    setInterviewModal(false);
    setConfirmModal(false);
    setQuesModal(false);
  };

  console.log('applicants: ', applicants);

  const getPercentageOfAnswerMatching = (candidateQuestions, employerQuestions) => {
    if (
      !candidateQuestions ||
      !employerQuestions ||
      candidateQuestions?.length === 0 ||
      employerQuestions?.length === 0
    ) {
      return 0;
    }

    let matchCount = 0;

    candidateQuestions.forEach((candidateQuestion) => {
      const employerQuestion = employerQuestions.find(
        (employerQuestion) => employerQuestion.question === candidateQuestion.question
      );

      if (employerQuestion && employerQuestion.preferredAnswer === candidateQuestion.candidateAnswer) {
        matchCount++;
      }
    });

    const percentage = (matchCount / employerQuestions.length) * 100;
    return parseFloat(percentage.toFixed(2));
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedApplicants = useMemo(() => {
    if (!applicants) return [];

    let sortableApplicants = [...applicants];
    sortableApplicants.sort((a, b) => {
      if (sortConfig.key === 'generalFullName') {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();

        if (sortConfig.direction === 'ascending') {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      } else if (sortConfig.key === 'jobHiringStage') {
        const stageA = a.appliedJobs[0]?.status || '';
        const stageB = b.appliedJobs[0]?.status || '';

        if (sortConfig.direction === 'ascending') {
          return stageA.localeCompare(stageB);
        } else {
          return stageB.localeCompare(stageA);
        }
      } else if (sortConfig.key === 'jobDateApplied') {
        const dateA = new Date(a.appliedJobs[0]?.appliedDate).getTime();
        const dateB = new Date(b.appliedJobs[0]?.appliedDate).getTime();

        if (sortConfig.direction === 'ascending') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      } else if (sortConfig.key === 'Questionnaire') {
        const candidateQuestionsA = a?.appliedJobs[0]?.questionsAns || [];
        const employerQuestionsA = a?.appliedJobs[0]?.job?.questions || [];
        const questionnairePercentageA = getPercentageOfAnswerMatching(candidateQuestionsA, employerQuestionsA);

        const candidateQuestionsB = b?.appliedJobs[0]?.questionsAns || [];
        const employerQuestionsB = b?.appliedJobs[0]?.job?.questions || [];
        const questionnairePercentageB = getPercentageOfAnswerMatching(candidateQuestionsB, employerQuestionsB);

        if (sortConfig.direction === 'ascending') {
          return questionnairePercentageA - questionnairePercentageB;
        } else {
          return questionnairePercentageB - questionnairePercentageA;
        }
      } else if (sortConfig.key === 'skillsMatch') {
        const aMatch = calculateMatchingPercentage(a.skills, a.appliedJobs[0]?.job?.skills);
        const bMatch = calculateMatchingPercentage(b.skills, b.appliedJobs[0]?.job?.skills);
        return sortConfig.direction === 'ascending' ? aMatch - bMatch : bMatch - aMatch;
      } else {
        return 0;
      }
    });

    return sortableApplicants;
  }, [applicants, sortConfig]);

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const handleViewProfile = (applicantId, applicant) => {
    if (applicant.appliedJobs[0].status == 'Applied') {
      console.log('Viewed');
      dispatch(updateViewedApplicantStatus(applicant));
    }
    navigate(`/view-profile/${applicantId}`);
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedApplicants.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedApplicants.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex-col justify-center py-6 px-4 lg:px-16 gap-y-5 w-full h-full">
      <div className="text-2xl font-nunito font-bold leading-normal text-black pb-2">{jobTitle}</div>
      <div className="flex justify-between items-center">
        <div className="text-lg font-nunito font-bold leading-normal text-black">
          {lang.jobTotalApplicants}: {currentEntries?.length}
        </div>
        <div className="flex items-center font-semibold">
          <label htmlFor="entriesPerPage" className="mr-2">
            {lang.generalShow}
          </label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesPerPageChange}
            className="border border-gray-300 rounded-md p-1 pl-3"
          >
            {[5, 10, 20, 50].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
          <span className="ml-2">{lang.generalEntries}</span>
        </div>
      </div>
      <table class="table-auto bg-white rounded-2xl w-full h-full">
        <thead>
          <tr className="h-20 border-b-[1px]">
            <th onClick={() => handleSort('generalFullName')} className="cursor-pointer">
              {lang.generalFullName}{' '}
              <FontAwesomeIcon icon={getSortIcon('generalFullName')} size="sm" className="ml-1" />
            </th>
            <th onClick={() => handleSort('skillsMatch')} className="cursor-pointer">
              {lang.generalSkillsMatch} <FontAwesomeIcon icon={getSortIcon('skillsMatch')} size="sm" className="ml-1" />
            </th>
            <th onClick={() => handleSort('jobHiringStage')} className="cursor-pointer">
              {lang.jobHiringStage} <FontAwesomeIcon icon={getSortIcon('jobHiringStage')} size="sm" className="ml-1" />
            </th>
            <th onClick={() => handleSort('jobDateApplied')} className="cursor-pointer">
              {lang.jobDateApplied} <FontAwesomeIcon icon={getSortIcon('jobDateApplied')} size="sm" className="ml-1" />
            </th>
            <th onClick={() => handleSort('Questionnaire')} className="cursor-pointer">
              {lang.generalQuestionnaire}{' '}
              <FontAwesomeIcon icon={getSortIcon('Questionnaire')} size="sm" className="ml-1" />
            </th>
            <th>{lang.generalActions}</th>
            <th>{lang.generalMessages}</th>
          </tr>
        </thead>
        <tbody>
          {currentEntries?.map((applicant, index) => {
            const candidateQuestions = applicant?.appliedJobs[0]?.questionsAns;
            const employerQuestions = applicant?.appliedJobs[0]?.job?.questions;

            const questionnairePercentage = getPercentageOfAnswerMatching(candidateQuestions, employerQuestions);

            let notifyCandidate = null;

            if (candidateId) {
              notifyCandidate = applicant?._id === candidateId;
            }

            console.log('applicant?.skills: ', applicant?.skills);
            console.log('applicant?.appliedJobs[0]?.job?.skills: ', applicant?.appliedJobs[0]?.job?.skills);

            const matchingPercentage = calculateMatchingPercentage(
              applicant?.skills,
              applicant?.appliedJobs[0]?.job?.skills
            );

            return (
              <tr className={`h-20 ${notifyCandidate && 'bg-gray-200'}`} key={index}>
                <td className="text-sm font-nunito font-semibold leading-normal text-black pl-5">
                  <div className="flex justify-start items-center space-x-2 ">
                    <img src={applicant?.avatar} alt="profile pic" className="w-12 h-12 rounded-full object-cover" />
                    <span>
                      {applicant?.firstName} {applicant?.lastName}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="flex justify-center items-center space-x-2">
                    <span
                      className={`mt-0.5 font-semibold ${matchingPercentage > 50 ? 'text-green-500' : matchingPercentage > 0 ? 'text-blue-500' : 'text-red-500'}`}
                    >
                      {matchingPercentage}%
                    </span>
                  </div>
                </td>
                <td className="h-20 flex justify-center items-center relative">
                  <ApplicantStatusDropdown
                    applicant={applicant}
                    setClickedApplicant={setClickedApplicant}
                    setStatus={setStatus}
                    setInterviewModal={setInterviewModal}
                    setConfirmModal={setConfirmModal}
                  />
                </td>
                <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                  {format(new Date(applicant?.appliedJobs[0]?.appliedDate), 'dd-MM-yyyy HH:mm')}
                </td>
                <td
                  onClick={() => {
                    if (applicant?.appliedJobs[0]?.job?.questionnaire === 1) {
                      setClickedQuestionnaire(candidateQuestions);
                      setClickedEmpQuestionnaire(employerQuestions);
                      setQuesModal(true);
                    }
                  }}
                  className={`text-center cursor-pointer font-bold ${questionnairePercentage === 100 ? 'text-green-500' : questionnairePercentage >= 50 ? 'text-blue-500' : 'text-red-500'}`}
                >
                  {applicant?.appliedJobs[0]?.job?.questionnaire === 1 ? (
                    `${questionnairePercentage}%`
                  ) : (
                    <span className="text-black">{lang.generalNotSet}</span>
                  )}
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    onClick={() => handleViewProfile(applicant?._id, applicant)}
                    className="text-sm font-bold text-oppty-purple-5 bg-oppty-blue-10 border-[1px] border-oppty-purple-5 px-5 py-2 rounded-md hover:bg-indigo-100"
                  >
                    {lang.generalViewProfile}
                  </button>
                </td>
                <td className="text-center px-2">
                  <button
                    type="button"
                    onClick={async () => {
                      if (applicant?._id) {
                        console.log('click applicant Id: ', applicant._id);
                        await handleAddToConvo(applicant);
                        navigate(`/employer/messages`, { state: { receiverId: applicant._id } });
                      }
                    }}
                    className="text-sm font-bold text-oppty-purple-5 bg-oppty-blue-10 border-[1px] border-oppty-purple-5 px-5 py-2 rounded-md hover:bg-indigo-100"
                  >
                    {lang.generalSendMessage}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex pb-10 pt-2">
        <div className="flex flex-1 items-center justify-between">
          <div>
            <p className="text-sm text-gray-700">
              {lang.generalShowing}{' '}
              <span className="font-medium">
                {indexOfFirstEntry + 1} {lang.generalTo} {Math.min(indexOfLastEntry, sortedApplicants.length)}
              </span>{' '}
              {lang.generalOf} <span className="font-medium">{sortedApplicants.length}</span> {lang.generalResults}
            </p>
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-black bg-white rounded-md font-semibold"
                disabled={currentPage === 1}
              >
                <span className="mr-4">{'<<'}</span>
                <span className="sr-only">{lang.generalPrevious}</span>
                {lang.generalPrevious}
              </button>
              <div>
                {pageNumbers?.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                      pageNumber === currentPage ? 'bg-black text-white rounded-md' : 'text-gray-900 bg-oppty-green-5'
                    }`}
                  >
                    {pageNumber}
                  </button>
                ))}
              </div>
              <button
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-black bg-white rounded-md font-semibold"
                disabled={currentPage === totalPages}
              >
                <span className="mr-4">{lang.generalNext}</span>
                <span className="">{'>>'}</span>
              </button>
            </nav>
          </div>
        </div>
      </div>
      {interviewModal && (
        <InterviewDateSelectorModal closeModal={closeModal} applicant={clickedApplicant} status={status} />
      )}
      {confirmModal && <StatusConfirmationModal closeModal={closeModal} applicant={clickedApplicant} status={status} />}
      {quesModal && (
        <CandidateQuestionnaireModal
          closeModal={closeModal}
          candidateQuestions={clickedQuestionnaire}
          employerQuestions={clickedEmpQuestionnaire}
        />
      )}
    </div>
  );
};

export default AllApplicants;
