const PrivacyBodyChinese = () => {
  return (
    <div className="bg-white text-gray-800">
      <div className="container mx-auto p-14">
        <h1 className="text-3xl font-bold mb-4">Oppty.ai 隐私政策</h1>
        <p className="text-sm text-gray-600 mb-6">最后更新日期：2024年8月14日</p>

        <section className="mb-6">
          <p className="text-base leading-relaxed mb-4">
            Oppty.ai（“我们”或“我们的”）致力于保护您的隐私。本隐私政策解释了我们如何收集、使用、披露和保护您在访问我们的网站
            [Oppty.ai URL]
            （“网站”）和使用我们的服务（“服务”）时的信息。使用本网站和服务即表示您同意根据本隐私政策收集和使用您的信息。
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">1. 我们收集的信息</h2>
          <p className="text-base leading-relaxed mb-4">我们可能会收集以下类型的信息：</p>
          <h3 className="text-lg font-medium mb-1">1.1 个人信息：</h3>
          <p className="text-base leading-relaxed mb-4">
            当您注册账户、创建个人资料、申请职位或发布职位时，我们可能会收集您的个人信息，包括但不限于：
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>姓名</li>
            <li>电子邮件地址</li>
            <li>电话号码</li>
            <li>出生日期</li>
            <li>性别</li>
            <li>国籍</li>
            <li>教育背景</li>
            <li>工作经验</li>
            <li>简历/CV</li>
            <li>工作偏好</li>
            <li>公司信息（适用于雇主）</li>
            <li>付款信息（适用于付费服务）</li>
          </ul>
          <h3 className="text-lg font-medium mb-1">1.2 非个人信息：</h3>
          <p className="text-base leading-relaxed mb-4">我们还可能收集无法直接识别您身份的非个人信息，如：</p>
          <ul className="list-disc list-inside mb-4">
            <li>IP 地址</li>
            <li>浏览器类型和版本</li>
            <li>操作系统</li>
            <li>引用网站</li>
            <li>访问页面</li>
            <li>访问日期和时间</li>
            <li>设备信息</li>
          </ul>
          <h3 className="text-lg font-medium mb-1">1.3 Cookie 和跟踪技术：</h3>
          <p className="text-base leading-relaxed mb-4">
            我们使用 Cookie 和类似的跟踪技术来追踪您在我们网站上的活动并保存某些信息。Cookie
            是包含少量数据的文件，可能包括匿名的唯一标识符。您可以指示浏览器拒绝所有 Cookie 或在发送 Cookie
            时提醒您。然而，如果您不接受 Cookie，您可能无法使用我们服务的某些部分。
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">2. 我们如何使用您的信息</h2>
          <p className="text-base leading-relaxed mb-4">我们收集的信息可能用于以下用途：</p>
          <ul className="list-disc list-inside mb-4">
            <li>提供服务：促进求职者与雇主之间的职位匹配，处理申请并管理您的账户。</li>
            <li>沟通：与您沟通，包括回复您的询问、发送更新、通知和提供客户支持。</li>
            <li>个性化：个性化您的网站体验，展示符合您偏好的内容、职位列表和广告。</li>
            <li>分析和改进：监控和分析使用趋势，跟踪营销效果，改进我们的网站和服务，并开发新功能。</li>
            <li>合规和法律义务：遵守法律义务，解决争议，执行我们的协议。</li>
            <li>
              营销：向您发送我们认为您可能感兴趣的促销材料、新闻通讯或其他关于我们服务的信息。您可以随时通过通信中的取消订阅说明选择退出这些通信。
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">3. 我们如何共享您的信息</h2>
          <ul className="list-disc list-inside mb-4">
            <li>与雇主共享：如果您是求职者，我们可能会将您的信息与雇主和招聘人员共享，以便进行求职申请。</li>
            <li>
              服务提供商：我们可能会将您的信息共享给代表我们提供服务的第三方服务提供商，例如支付处理、数据分析、电子邮件发送、托管服务和客户服务。
            </li>
            <li>法律要求：如果法律要求或响应公共当局的有效请求（例如法院或政府机构），我们可能会披露您的信息。</li>
            <li>
              业务转移：如果我们参与合并、收购或资产出售，您的信息可能会作为该交易的一部分被转移。在您的个人信息被转移并受不同隐私政策的约束之前，我们将通知您。
            </li>
            <li>您的同意：我们可能会在您的同意或指示下与其他第三方共享您的信息。</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">4. 数据保留</h2>
          <p className="text-base leading-relaxed mb-4">
            我们只会在本隐私政策所述目的所必需的时间内保留您的个人信息。我们将保留和使用您的个人信息，以便在必要的范围内履行我们的法律义务、解决争议和执行我们的法律协议和政策。
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">5. 您信息的安全性</h2>
          <p className="text-base leading-relaxed mb-4">
            我们采取合理措施保护您的个人信息的安全。然而，互联网传输方式或电子存储方式并非百分之百安全，我们无法保证其绝对安全。您有责任对您的账户信息保密。
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">6. 您的权利和选择</h2>
          <ul className="list-disc list-inside mb-4">
            <li>
              访问和更正：您有权访问并更正我们持有的您的个人信息。您可以通过登录您的账户并更新您的个人资料或直接与我们联系来进行操作。
            </li>
            <li>
              数据可携性：您可以请求我们持有的关于您的个人数据的副本，副本将以结构化的、常用的和机器可读的格式提供。
            </li>
            <li>
              删除：您可以请求我们删除您的个人信息，法律规定的某些例外情况除外。我们将采取合理步骤从我们的记录中删除您的信息，但我们可能需要保留某些信息以满足法律或运营需求。
            </li>
            <li>退出营销通信：您可以通过按照通信中的取消订阅说明操作或直接联系我们来选择不再接收我们的营销通信。</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">7. 第三方链接</h2>
          <p className="text-base leading-relaxed mb-4">
            我们的网站可能包含第三方网站的链接。我们不对这些网站的隐私惯例或内容负责。我们鼓励您查看您访问的第三方网站的隐私政策。
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">8. 儿童隐私</h2>
          <p className="text-base leading-relaxed mb-4">
            我们的服务不针对18岁以下的个人。我们不会故意收集18岁以下儿童的个人信息。如果我们发现我们已经收集了18岁以下儿童的个人信息，我们将采取步骤删除这些信息。
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">9. 国际传输</h2>
          <p className="text-base leading-relaxed mb-4">
            您的信息可能会被传输到位于您的州、省、国家或其他政府管辖区之外的计算机上进行存储或维护，在那里，数据保护法可能与您的管辖区不同。使用我们的服务即表示您同意将您的信息传输至新加坡及我们运营的其他管辖区。
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">10. 本隐私政策的变更</h2>
          <p className="text-base leading-relaxed mb-4">
            我们可能会不时更新我们的隐私政策。我们将通过在此页面上发布新的隐私政策并更新“最后更新”日期来通知您任何更改。我们建议您定期查看本隐私政策以了解任何更改。
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyBodyChinese;
