import { useSelector } from 'react-redux';
import pic from '../../../assets/images/cvWebsitePic.svg';
import { useNavigate } from 'react-router-dom';

const CvWebsiteCard = () => {
  const navigate = useNavigate();

  const lang = useSelector((state) => state.language.lang);

  return (
    <div className="flex flex-col justify-center pt-7 px-16 mx-16">
      <div className="text-4xl font-extrabold">
        Where the <span className="text-oppty-blue">magic happens.</span> Get your personal website
        <span className="text-oppty-blue"> generated</span>
      </div>
      <div className="w-full flex bg-white mt-14 rounded-md gap-x-16 p-10">
        <div className="w-1/3 h-full flex justify-end">
          <img src={pic} alt="pic" />
        </div>
        <div className="w-2/3 p-20">
          <div className="text-3xl font-bold pb-7">{lang.profileWeHaveGot}</div>
          <div className="text-lg font-normal mb-10">{lang.profileBoostYourJob}</div>
          <button
            onClick={() => navigate('/profile/cv-website/select')}
            className="bg-black text-white px-8 py-3 rounded-md font-bold hover:bg-gray-900"
          >
            {lang.profileSetUpMyWebsite}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CvWebsiteCard;
