import { useDispatch, useSelector } from 'react-redux';
import { EnvelopeIcon, ChatBubbleBottomCenterIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/headerLogo.svg';
import { employerEmailCheck, getEmployerForgetPasswordOTP } from '../../actions/publicAction';

const EmployerForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [isExist, setIsExist] = useState(null);
  const [isValid, setIsValid] = useState(null);
  const [touch, setTouch] = useState(null);

  console.log('email: ', email);
  console.log('isExist: ', isExist);
  console.log('isValid: ', isValid);

  const localLang = localStorage.getItem('lan');
  const lang = useSelector((state) => state.language.lang);

  const validEmailCheck = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    setIsValid(isValidEmail);
    const check = await dispatch(employerEmailCheck(email));
    console.log('check: ', check);
    setTouch(true);
    setIsExist(check);
  };

  const onSubmit = () => {
    dispatch(getEmployerForgetPasswordOTP({ email: email }, navigate));
  };
  return (
    <div className="h-screen relative">
      <div
        className="absolute left-0 top-0 flex-col justify-center items-center p-10 cursor-pointer"
        onClick={() => navigate('/')}
      >
        <img src={logo} alt="Logo" className="hidden md:block w-36 lg:w-[150px]" />
      </div>
      <div className="flex flex-col h-full py-10 justify-center items-center px-5 md:px-0">
        <div className="bg-white shadow-xl w-full md:w-[500px] px-5 md:px-20 py-10 rounded-2xl">
          <div className="text-3xl font-semibold pb-8">{lang.loginForgotPassword}</div>
          <div className="flex flex-col gap-y-5">
            <div className="relative">
              <input
                type="text"
                placeholder={lang.createEmail}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={validEmailCheck}
                className="w-full h-12 bg-violet-100  rounded-xl pl-16 pr-10 text-base font-medium placeholder:font-semibold"
              />
              <EnvelopeIcon className="w-7 h-7 absolute left-3 top-2.5" />
              {touch && isExist ? (
                <CheckIcon className="w-7 h-7 absolute right-3 top-3 stroke-green-400 stroke-2" />
              ) : (
                touch && <XMarkIcon className="w-7 h-7 absolute right-3 top-3 stroke-red-400 stroke-2" />
              )}
              {touch && !isValid && (
                <div className="text-red-400 font-semibold text-xs">
                  {['Email is not valid', '邮箱无效', 'Email tidak sah'][localLang]}
                </div>
              )}
              {touch && isValid && !isExist && (
                <div className="text-red-400 font-semibold text-xs">
                  {['Email is not registered', '邮箱未注册', 'Email tidak berdaftar'][localLang]}
                </div>
              )}
            </div>
            <button
              disabled={!isExist}
              type="button"
              onClick={onSubmit}
              className="bg-oppty-blue disabled:bg-gray-400 h-12 rounded-xl text-white font-bold hover:bg-violet-500 shadow-xl flex justify-center items-center"
            >
              <ChatBubbleBottomCenterIcon className="w-5 h-5 mr-2 stroke-2" />
              {lang.generalGetOtp}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerForgotPassword;
