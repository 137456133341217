import React from 'react';

const Doc = ({ stroke, className, fill, hoverStroke, isHovered }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill={fill}
      className={className}
    >
      <g clipPath="url(#clip0_968_99)">
        <path
          d="M9 5.43555H7C6.46957 5.43555 5.96086 5.64626 5.58579 6.02133C5.21071 6.39641 5 6.90511 5 7.43555V19.4355C5 19.966 5.21071 20.4747 5.58579 20.8498C5.96086 21.2248 6.46957 21.4355 7 21.4355H17C17.5304 21.4355 18.0391 21.2248 18.4142 20.8498C18.7893 20.4747 19 19.966 19 19.4355V7.43555C19 6.90511 18.7893 6.39641 18.4142 6.02133C18.0391 5.64626 17.5304 5.43555 17 5.43555H15"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/job-listing' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 3.43555H11C9.89543 3.43555 9 4.33098 9 5.43555C9 6.54012 9.89543 7.43555 11 7.43555H13C14.1046 7.43555 15 6.54012 15 5.43555C15 4.33098 14.1046 3.43555 13 3.43555Z"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/job-listing' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 12.4355H9.01"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/job-listing' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 12.4355H15"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/job-listing' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 16.4355H9.01"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/job-listing' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 16.4355H15"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/job-listing' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_968_99">
          <rect width="24" height="24" fill="white" transform="translate(0 0.435547)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Doc;
