import { useEffect, useState } from 'react';
import Footer from '../../common/footer';
import Header from '../../common/header';
import TestimonialForm from './testimonialForm';
import { useDispatch, useSelector } from 'react-redux';
import { getPublicProfile } from '../../actions/publicProfileAction';
import { useParams } from 'react-router-dom';
import SkeletonLoader from '../../common/skeletonLoader';

const Testimonial = () => {
  const dispatch = useDispatch();
  const { publicId } = useParams();

  const [loader, setLoader] = useState(true);

  const user = useSelector((state) => state.publicProfile?.user);
  console.log('user: ', user);

  useEffect(() => {
    dispatch(getPublicProfile(publicId));
    setLoader(false);
  }, [publicId]);

  return loader || !user ? (
    <SkeletonLoader />
  ) : (
    <div className="flex flex-col justify-between min-h-screen bg-white">
      <Header />
      <TestimonialForm user={user} />
      <Footer />
    </div>
  );
};

export default Testimonial;
