import { useSelector } from 'react-redux';
import ArticleCard from '../../common/articleCard';
import { useState } from 'react';

const ArticleList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(12);

  const lang = useSelector((state) => state.language.lang);
  const articles = useSelector((state) => state.public?.articles);

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = articles?.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(articles?.length / entriesPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="p-10">
      <div className="flex flex-wrap gap-12 justify-center">
        {currentEntries?.map((article, index) => {
          return article?.url && <ArticleCard key={index} article={article} />;
        })}
      </div>
      {currentEntries?.length > 0 && (
        <div className="flex pt-10 lg:px-20">
          <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between flex-wrap gap-2">
            <div>
              <p className="text-base text-gray-700">
                {lang.generalShowing}{' '}
                <span className="font-medium">
                  {indexOfFirstEntry + 1} {lang.generalTo} {Math.min(indexOfLastEntry, articles?.length)}
                </span>{' '}
                {lang.generalOf} <span className="font-medium">{articles?.length}</span> {lang.generalResults}
              </p>
            </div>
            <div>
              <nav className="isolate inline-flex -space-x-px flex-wrap rounded-md gap-x-3" aria-label="Pagination">
                <button
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  className="relative md:inline-flex items-center rounded-l-md px-2 py-2 text-black bg-white rounded-md font-semibold hidden"
                  disabled={currentPage === 1}
                >
                  <span className="mr-4">{'<<'}</span>
                  <span className="sr-only">{lang.generalPrevious}</span>
                  {lang.generalPrevious}
                </button>
                <div>
                  {pageNumbers?.map((pageNumber) => (
                    <button
                      key={pageNumber}
                      onClick={() => setCurrentPage(pageNumber)}
                      className={`relative inline-flexinline-flex items-center px-4 py-2 text-sm font-semibold ${
                        pageNumber === currentPage ? 'bg-black text-white rounded-md' : 'text-gray-900 bg-oppty-green-5'
                      }`}
                    >
                      {pageNumber}
                    </button>
                  ))}
                </div>
                <button
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  className="relative hidden md:inline-flex items-center rounded-r-md px-2 py-2 text-black bg-white rounded-md font-semibold"
                  disabled={currentPage === totalPages}
                >
                  <span className="mr-4">{lang.generalNext}</span>
                  <span className="">{'>>'}</span>
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleList;
