import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full justify-center items-center p-20">
      <h2 className="text-3xl font-bold">404 Not Found!</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
      <button
        onClick={() => navigate('/')}
        className="mt-5 w-40 py-2 rounded-md bg-oppty-blue text-white font-bold hover:bg-oppty-blue/90 hover:shadow-md"
      >
        Go to Home
      </button>
    </div>
  );
};

export default NotFound;
