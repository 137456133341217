import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { PencilIcon, TrashIcon, StopCircleIcon, PlayIcon, PlayCircleIcon } from '@heroicons/react/24/outline';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { resumeJob, stopJob } from '../../actions/jobAction';

const AllJobList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.employer);

  const [sortConfig, setSortConfig] = useState({
    key: 'createdDate',
    direction: 'descending'
  });

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedJobs = useMemo(() => {
    if (!employer?.jobs) return [];
    let sortableJobs = [...employer.jobs];
    sortableJobs.sort((a, b) => {
      if (sortConfig.key === 'applicants?.length') {
        console.log('check');
        const aCount = a.applicants ? a.applicants.length : 0;
        const bCount = b.applicants ? b.applicants.length : 0;
        return sortConfig.direction === 'ascending' ? aCount - bCount : bCount - aCount;
      } else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
    });
    return sortableJobs;
  }, [employer?.jobs, sortConfig]);

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const handleResumeJob = (jobId) => {
    console.log('resume jobId: ', jobId);
    const input = {
      _id: jobId
    };
    dispatch(resumeJob(input));
  };

  const handleStopJob = (jobId) => {
    console.log('stop jobId: ', jobId);
    const input = {
      _id: jobId
    };
    dispatch(stopJob(input));
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedJobs.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedJobs.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex flex-col justify-start lg:justify-start py-5 px-2 lg:px-8 gap-y-5 bg-white w-full">
      <div className="flex justify-between items-center">
        <div className="text-2xl font-nunito font-bold leading-normal text-black">
          {currentEntries.length > 0
            ? currentEntries.length === 1
              ? lang.generalShowing1Job
              : `${lang.generalShowing} ${currentEntries?.length} ${lang.generalJobApplications}`
            : lang.generalShowingNoJob}
        </div>
        <div className="flex items-center font-semibold">
          <label htmlFor="entriesPerPage" className="mr-2">
          {lang.generalShow}
          </label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesPerPageChange}
            className="border border-gray-300 rounded-md p-1 pl-3"
          >
            {[5, 10, 20, 50].map((num) => (
              <option key={num} value={num}>
                {num}
              </option>
            ))}
          </select>
          <span className="ml-2">{lang.generalEntries}</span>
        </div>
      </div>

      <table class="table-auto bg-white rounded-2xl w-full">
        <thead>
          <tr className="h-20 border-b-[1px]">
            <th onClick={() => handleSort('createdDate')} className="cursor-pointer">
              {lang.generalDateCreated} <FontAwesomeIcon icon={getSortIcon('createdDate')} size="sm" className="ml-1" />
            </th>
            <th onClick={() => handleSort('jobTitle')} className="cursor-pointer">
              {lang.generalJobRole} <FontAwesomeIcon icon={getSortIcon('jobTitle')} size="sm" className="ml-1" />
            </th>
            <th onClick={() => handleSort('applicants?.length')} className="cursor-pointer">
              {lang.generalNumOfApplicants}{' '}
              <FontAwesomeIcon icon={getSortIcon('applicants?.length')} size="sm" className="ml-1" />
            </th>
            <th>{lang.generalApplicant}</th>
            <th>{lang.generalActions}</th>
          </tr>
        </thead>
        <tbody>
          {currentEntries?.map((job, index) => {
            const formattedCreatedDate = !Number.isNaN(new Date(job?.createdDate).getTime())
              ? format(new Date(job?.createdDate), 'dd-MM-yyyy HH:mm')
              : 'Invalid Date';
            return (
              <tr className="h-20" key={index}>
                <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                  {formattedCreatedDate}
                </td>
                <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                  {job?.jobTitle}
                </td>
                <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                  {job?.applicants?.length}
                </td>
                <td className="flex justify-center items-center h-20">
                  <button
                    type="button"
                    onClick={() => navigate(`/employer/applicants/${job?._id}`, { state: { jobTitle: job?.jobTitle } })}
                    className="text-sm font-bold text-oppty-purple-5 bg-oppty-blue-10 border-[1px] border-oppty-purple-5 px-5 py-2 rounded-md"
                  >
                    {lang.generalSeeApplicants}
                  </button>
                </td>
                <td>
                  <div className="flex justify-center gap-x-2 items-center">
                    <div
                      className="w-8 h-8 hover:bg-green-400 p-1 rounded-md"
                      onClick={() => navigate('/employer/edit-job', { state: { job: job } })}
                      title="Edit"
                    >
                      <PencilIcon />
                    </div>
                    {job?.status === 1 ? (
                      <div
                        onClick={() => handleStopJob(job?._id)}
                        className="w-8 h-8 hover:bg-red-400 p-0.5 rounded-md cursor-pointer"
                        title="Stop"
                      >
                        <StopCircleIcon />
                      </div>
                    ) : (
                      <div
                        onClick={() => handleResumeJob(job?._id)}
                        className="w-8 h-8 hover:bg-blue-400 p-0.5 rounded-md cursor-pointer"
                        title="Resume"
                      >
                        <PlayCircleIcon />
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex pb-10">
        <div className="flex flex-1 items-center justify-between">
          <div>
            <p className="text-sm text-gray-700">
            {lang.generalShowing}{' '}
              <span className="font-medium">
                {indexOfFirstEntry + 1} {lang.generalTo} {Math.min(indexOfLastEntry, sortedJobs.length)}
              </span>{' '}
              {lang.generalOf} <span className="font-medium">{sortedJobs.length}</span> {lang.generalResults}
            </p>
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md gap-x-3" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-black bg-white rounded-md font-semibold"
                disabled={currentPage === 1}
              >
                <span className="mr-4">{'<<'}</span>
                <span className="sr-only">{lang.generalPrevious}</span>
                {lang.generalPrevious}
              </button>
              <div>
                {pageNumbers?.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                      pageNumber === currentPage ? 'bg-black text-white rounded-md' : 'text-gray-900 bg-oppty-green-5'
                    }`}
                  >
                    {pageNumber}
                  </button>
                ))}
              </div>
              <button
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-black bg-white rounded-md font-semibold"
                disabled={currentPage === totalPages}
              >
                <span className="mr-4">{lang.generalNext}</span>
                <span className="">{'>>'}</span>
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllJobList;
