import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon, StarIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import { statusOptions } from '../../constant/dropdownConstOptions';
import { getLabelByValue } from '../../utils/functions';

const ApplicantStatusDropdown = ({ applicant, setClickedApplicant, setStatus, setInterviewModal, setConfirmModal }) => {
  const localLang = localStorage.getItem('lan');

  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(null);

  const handleUpdateApplicantStatus = async (event, applicant, status) => {
    event.stopPropagation();
    if (applicant?.appliedJobs[0]?.status !== status) {
      setClickedApplicant(applicant);
      setStatus(status);
      if (status === 'Interview') {
        console.log('interview');
        setInterviewModal(true);
      } else {
        setConfirmModal(true);
      }
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(true)}
        className={`rounded-md border-[1px] w-28 py-3 flex px-2 justify-between items-center ${applicant?.appliedJobs[0]?.status === 'Hired'
          ? 'bg-green-600 border-green-500 hover:bg-green-300 text-white text-[13px] font-nunito font-bold leading-normal'
          : applicant?.appliedJobs[0]?.status === 'Shortlisted'
            ? 'bg-transparent border-indigo-500 hover:bg-indigo-100 text-indigo-500 text-[13px] font-nunito font-bold leading-normal'
            : applicant?.appliedJobs[0]?.status === 'Interview'
              ? 'bg-transparent border-orange-500 hover:bg-orange-200 text-orange-500 text-[13px] font-nunito font-bold leading-normal'
              : applicant?.appliedJobs[0]?.status === 'Interviewed'
                ? 'bg-transparent border-blue-500 hover:bg-blue-50 text-blue-500 text-[13px] font-nunito font-bold leading-normal'
                : applicant?.appliedJobs[0]?.status === 'Not Suitable'
                  ? 'bg-transparent border-red-500 hover:bg-red-50 text-red-500 text-[13px] font-nunito font-bold leading-normal'
                  : applicant?.appliedJobs[0]?.status === 'Applied'
                    ? 'bg-transparent border-gray-500 hover:bg-gray-300 text-gray-500 text-[13px] font-nunito font-bold leading-normal'
                    : applicant?.appliedJobs[0]?.status === 'Viewed'
                      ? 'bg-transparent border-gray-500 hover:bg-gray-300 text-black text-[13px] font-nunito font-bold leading-normal'
                      : ''
          }`}
      >
        {getLabelByValue(applicant?.appliedJobs[0]?.status, statusOptions)}{' '}
        {isOpen ? <ChevronUpIcon className="w-4 h-4" /> : <ChevronDownIcon className="w-4 h-4" />}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1 text-left" role="menu" aria-orientation="vertical">
            {statusOptions.slice(0, 4)?.map((status, index) => (
              <div
                key={index}
                onClick={(event) => handleUpdateApplicantStatus(event, applicant, status.value)}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointers"
              >
                {status?.label[localLang]}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicantStatusDropdown;
