import { RouterProvider } from 'react-router-dom'
import './App.css'
import router from './navigation/routes'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { languageLoader } from './actions/languageAction'
import 'react-quill/dist/quill.snow.css';

function App() {
  const dispatch = useDispatch()

  const localLang = localStorage.getItem('lan')

  useEffect(() => {
    if (localLang === null) {
      localStorage.setItem('lan', 0)
      dispatch(languageLoader(0))
    } else {
      dispatch(languageLoader(localLang))
    }
  }, [dispatch, localLang])

  return <RouterProvider router={router} />
}

export default App
