import React from 'react';

const Message = ({ stroke, fill, className, hoverStroke, isHovered }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill={fill}
      className={className}
    >
      <g clipPath="url(#clip0_968_65)">
        <path
          d="M11.9999 21.4355L8.3999 17.8355H5.9999C5.04512 17.8355 4.12945 17.4563 3.45432 16.7811C2.77919 16.106 2.3999 15.1903 2.3999 14.2355V7.03555C2.3999 6.08077 2.77919 5.16509 3.45432 4.48996C4.12945 3.81483 5.04512 3.43555 5.9999 3.43555H17.9999C18.9547 3.43555 19.8704 3.81483 20.5455 4.48996C21.2206 5.16509 21.5999 6.08077 21.5999 7.03555V14.2355C21.5999 15.1903 21.2206 16.106 20.5455 16.7811C19.8704 17.4563 18.9547 17.8355 17.9999 17.8355H15.5999L11.9999 21.4355Z"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/messages' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.2002 8.23535H16.8002"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/messages' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.2002 13.0352H14.4002"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/messages' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_968_65">
          <rect width="24" height="24" fill="white" transform="translate(0 0.435547)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Message;
