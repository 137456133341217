import DashboardLayout from '../../../layout/dashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getAdminDashboard } from '../../../actions/adminAction';
import DashboardHeaderSection from './dashboardHeaderSection';
import DashboardMiddleSection from './dashboardMiddleSection';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const today = new Date();
  const fourteenDaysBefore = new Date();
  fourteenDaysBefore.setDate(today.getDate() - 14);

  const [dateRange, setDateRange] = useState({
    startDate: fourteenDaysBefore,
    endDate: today
  });

  useEffect(() => {
    dispatch(getAdminDashboard(dateRange));
  }, [dispatch, dateRange]);

  return (
    <DashboardLayout>
      <DashboardHeaderSection setDateRange={setDateRange} dateRange={dateRange} />
      <DashboardMiddleSection />
    </DashboardLayout>
  );
};

export default AdminDashboard;
