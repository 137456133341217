import EducationCard from './educationCard';

const Education = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-20 shadow-md bg-template5-butter-1">
      <div className="text-5xl font-bold lg:w-1/2 mb-10">Education & Certification</div>
      <div className="flex flex-col w-full gap-y-5">
        {user?.educations?.map((education, index) => {
          return <EducationCard key={index} education={education} />;
        })}
        {user?.certifications?.map((certification, index) => {
          return <EducationCard key={index} education={certification} />;
        })}
      </div>
    </div>
  );
};

export default Education;
