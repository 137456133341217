import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/headerLogo.svg';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { submitRegister } from '../../actions/userPreRegisterAction';
import { useNavigate } from 'react-router-dom';
import { loginCandidateSchema } from '../../validations/validationSchemas';
import GoogleLoginProvider from './googleLogin';
import { candidateLogin } from '../../actions/userAction';
import { useAuthContext } from '../../context/authContext';

const CandidateLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setAuthUser } = useAuthContext();
  const lang = useSelector((state) => state.language.lang);
  const [showPassword, setShowPassword] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const slidesData = [
    {
      header: 'Building the Future',
      paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit...'
    },
    {
      header: 'Innovating for Tomorrow',
      paragraph: 'Curabitur sit amet quam vel turpis faucibus...'
    },
    {
      header: 'Shaping a Better World',
      paragraph: 'Vestibulum ullamcorper mauris at ligula...'
    }
  ];

  useEffect(() => {
    const transition = setInterval(() => {
      setCurrentSlide((current) => (current + 1) % slidesData.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(transition);
  }, []);

  console.log(lang);
  const togglePasswordVisibility = (e) => {
    e.preventDefault(); // Prevent form submission when toggling visibility
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginCandidateSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      // dispatch(submitRegister(values)).then(navigate('/verifyOTP'))
      dispatch(candidateLogin(values, navigate, setAuthUser));

      //   try {
      //     await dispatch(submitRegister(values))
      //     navigate('/verifyOTP')
      //   } catch (error) {
      //     console.error('Registration failed:', error)
      //   }
    }
  });

  return (
    <div className="flex flex-col lg:flex-row min-h-screen ">
      {/* Logo */}
      <div className="flex-col justify-center items-center p-10 cursor-pointer" onClick={() => navigate('/')}>
        <img src={logo} alt="Logo" className="hidden lg:block w-36 lg:w-[180px]" />
      </div>

      {/* Left and Right Components */}
      <div className="hidden lg:flex flex-1 flex-col lg:flex-row">
        {/* Left Component */}
        <div className="flex-1 flex items-center justify-center">
          <div className="w-full max-w-lg p-4">
            <div className="flex flex-col items-center">
              {slidesData.map((slide, index) => (
                <div key={index} className={`${index === currentSlide ? 'block' : 'hidden'} w-full`}>
                  <h2 className="text-2xl font-bold text-oppty-black md:text-4xl text-center">{slide.header}</h2>
                  <p className="mt-4 px-6 text-gray-700 text-sm md:px-12 md:text-base text-center">{slide.paragraph}</p>
                </div>
              ))}
            </div>

            <div className="py-4 flex justify-center gap-3">
              {slidesData.map((_, index) => (
                <div
                  key={index}
                  className={`cursor-pointer ${index === currentSlide ? 'bg-blue-500' : 'bg-gray-500'} w-8 h-1`}
                  onClick={() => setCurrentSlide(index)}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Right Component */}
      <div className="flex-1 flex items-center justify-center p-2 lg:p-0">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
          <div className="flex w-full justify-center pb-5 lg:hidden cursor-pointer" onClick={() => navigate('/')}>
            <img src={logo} alt="Logo" className=" w-36 lg:w-[220px]" />
          </div>
          <p className="text-left text-oppty-black font">{lang.loginWelcome}</p>
          <h2 className="text-left text-2xl font-bold mb-6 text-oppty-black">{lang.loginAccount}</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {lang.createEmail}
              </label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder={lang.createEmail}
                className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 text-sm">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                {lang.createPassword}
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder={lang.createPassword}
                  className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300 pr-10"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500 text-sm">{formik.errors.password}</div>
                ) : null}
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                  <button
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                    onClick={togglePasswordVisibility}
                  >
                    {!showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between mt-2 pb-4">
              <label className="flex items-center">
                <input type="checkbox" className="form-checkbox" />
                <span className="ml-2 text-sm text-gray-600">{lang.loginRemember}</span>
              </label>
              <a href="/user-forgot-password" className="text-sm text-blue-600 hover:underline">
                {lang.loginForgotPassword}
              </a>
            </div>
            <button type="submit" className="w-full bg-black text-white p-2 rounded-md hover:bg-gray-700">
              {lang.loginEmployerContinue}
            </button>
            <div className="mt-4 flex items-center justify-between">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="text-sm text-oppty-black">{lang.createOr}</span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
            <div className="py-3">
              <GoogleLoginProvider />
            </div>
            <div className="mt-4 flex justify-center items-center space-x-2">
              <p className="text-oppty-black font">{lang.loginNewUser}</p>
              <a href="/candidate-sign-up" className="text-sm text-blue-600 hover:underline">
                {lang.loginSignup}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CandidateLogin;
