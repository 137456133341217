import { useSelector } from 'react-redux'
import crown from '../../../assets/images/crown.svg'
import cube from '../../../assets/images/3dcube.svg'

const WeHelpJobSeekers = () => {
  const lang = useSelector((state) => state.language.lang)

  return (
    <div className="bg-oppty-blue py-8 md:py-16 w-full px-8 md:px-20 flex gap-2 md:gap-10 items-center">
      <div className="md:text-[20px] text-[10px] lg:text-[32px] font-raleway font-bold lg:leading-[46px] text-white w-[45%]">
        {lang.clientWeHelpJobSeekers}
      </div>
      <div>
        <div className="flex gap-1 md:gap-4">
          <div className="h-6 w-10 md:h-8 md:w-12 lg:w-12 lg:h-12 rounded-full bg-oppty-green-3 justify-center items-center flex">
            <img src={crown} alt="crown" className="w-3 h-3 md:w-4 md:h-4 lg:w-6 lg:h-6" />
          </div>
          <div>
            <div className="text-white font-bold font-nunito text-[10px] md:text-[34px] lg:text-[42px] md:leading-[46px]">
              2,390
            </div>
            <div className="text-[10px] md:text-[12px] lg:text-[18px] font-raleway font-normal lg:leading-[32px] text-white">
              {lang.clientHappyJobSeekers}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex gap-1 md:gap-4">
          <div className="h-6 w-10 md:h-8 md:w-12 lg:w-12 lg:h-12 rounded-full bg-oppty-green-3 justify-center items-center flex">
            <img src={cube} alt="crown" className="w-3 h-3 md:w-4 md:h-4 lg:w-6 lg:h-6" />
          </div>
          <div>
            <div className="text-white font-bold font-nunito text-[10px] md:text-[34px] lg:text-[42px] md:leading-[46px]">
              2,390
            </div>
            <div className="text-[10px] md:text-[12px] lg:text-[18px] font-raleway font-normal lg:leading-[32px] text-white">
              {lang.clientCompaniesJobs}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeHelpJobSeekers
