import React from 'react';
import { useSelector } from 'react-redux';

const CandidateQuestionnaireModal = ({ closeModal, candidateQuestions, employerQuestions }) => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-30">
        <div className="bg-white flex flex-col w-[700px] px-5 md:px-10 py-10 rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">{lang.generalCandidateAnsweredQues}</h2>
            <button
              onClick={closeModal}
              className="text-lg font-bold hover:bg-red-500 px-2 rounded-md hover:text-white"
            >
              X
            </button>
          </div>
          <div className="py-5 gap-y-1 flex flex-col">
            {candidateQuestions?.map((ques, index) => {
              const employerQuestion = employerQuestions?.find((empQues) => empQues.question === ques.question);
              console.log(employerQuestion);

              const isAnswerMatching =
                employerQuestion && employerQuestion.preferredAnswer === ques.candidateAnswer ? true : false;
              return (
                <div className="text-lg font-medium" key={index}>
                  {index + 1}. {ques?.question}{' '}
                  <span className={`font-bold ${isAnswerMatching ? 'text-green-500' : 'text-red-500'}`}>
                    {ques?.candidateAnswer}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateQuestionnaireModal;
