import { useSelector } from 'react-redux';
import companyField from '../../assets/icons/companyField.svg';
import companySize from '../../assets/icons/companySize.svg';
import companyAddress from '../../assets/icons/companyAddress.svg';
import { useState } from 'react';
import { Carousel } from '@material-tailwind/react';
import CoverPageArrangement from '../../common/coverPageArrangement';

const Introduction = () => {
  const [showModal, setShowModal] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.selectedEmployer);

  console.log('selected employer: ', employer);

  return (
    <div className="flex flex-col justify-center py-10 px-16">
      <div className="flex flex-row gap-x-4 h-auto pb-10">
        <div className="bg-oppty-grey-4 h-20">
          <img src={employer?.employer?.companyLogo} alt="logo" className="h-20 object-cover" />
        </div>
        <div className="flex flex-col justify-center items-start">
          <div className="text-[32px] font-nunito font-bold leading-10 text-black pb-2">
            {employer?.employer?.companyName}
          </div>
          <div className="text-2xl font-nunito font-normal text-black/70 pb-2">
            {employer?.employer?.companyLocation}
          </div>
        </div>
      </div>
      <CoverPageArrangement employer={employer} setShowModal={setShowModal} />
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="flex flex-col items-end w-[1000px] h-[500px]">
              <button
                className="text-white bg-red-500 rounded font-extrabold px-2 py-1 text-center text-sm mr-1 mb-1 transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                x
              </button>
              <Carousel className="rounded-xl">
                {employer?.employer?.images?.map((image) => (
                  <div className="w-full h-full bg-black justify-center flex">
                    <img src={image} alt="slider" className="h-full w-fit" />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <div className="w-full flex pb-10">
        <div className="w-3/4">
          <div className="pb-10">
            <div className="text-4xl font-nunito font-bold text-black pb-8">{lang.generalAboutUs}</div>
            <div
              className="text-black"
              dangerouslySetInnerHTML={{ __html: employer?.employer?.companyDescription }}
            ></div>
          </div>
          {/* <div className="pb-10">
            <div className="text-4xl font-nunito font-bold text-black pb-8">{lang.generalWhatWeDo}</div>
            <div className="text-lg font-nunito font-normal text-black">{employer.whatWeDo}</div>
          </div> */}
        </div>
      </div>
      <div className="flex flex-col gap-y-6">
        <div className="flex items-center">
          <div className="w-10 h-10 flex justify-center items-center bg-oppty-green-2">
            <img src={companyField} alt="companyField" />
          </div>
          <div className="text-[22px] font-nunito font-bold text-black pl-4 w-72">{lang.companyField}</div>
          <div className="text-[22px] font-nunito font-normal text-black">{employer?.employer?.companyIndustry}</div>
        </div>
        <div className="flex items-center">
          <div className="w-10 h-10 flex justify-center items-center bg-oppty-green-2">
            <img src={companySize} alt="companySize" />
          </div>
          <div className="text-[22px] font-nunito font-bold text-black w-72 pl-4">{lang.companySize}</div>
          <div className="text-[22px] font-nunito font-normal text-black">
            {employer?.employer?.companySize} {lang.generalEmployees}
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-10 h-10 flex justify-center items-center bg-oppty-green-2">
            <img src={companyAddress} alt="companyAddress" />
          </div>
          <div className="text-[22px] font-nunito font-bold text-black w-72 pl-4">{lang.companyAddress}</div>
          <div className="text-[22px] font-nunito font-normal text-black">{employer?.employer?.companyLocation}</div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
