import EducationCard from './educationCard';

const Education = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 border-2 border-template3-blue-1 rounded-xl shadow-md">
      <div className="text-4xl font-bold">Education</div>
      <div className="flex flex-col">
        {user?.educations?.map((education, index) => {
          return <EducationCard key={index} education={education} />;
        })}
      </div>
    </div>
  );
};

export default Education;
