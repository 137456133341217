import AboutMeSection from './aboutMeSection';
import Education from './education';
import HeaderSection from './headerSection';
import Portfolio from './portfolio';
import RecommendationsSection from './recommendationsSection';
import Video from './video';
import WorkExperience from './workExperience';

const Template1 = ({ user }) => {
  return (
    <div className="flex flex-col min-w-[320px] w-full h-full bg-white">
      {user?.firstName && <HeaderSection user={user} />}
      {user?.aboutMe && <AboutMeSection user={user} />}
      {(user?.educations?.length > 0 || user?.certifications?.length > 0) && <Education user={user} />}
      {user?.workExperiences?.length > 0 && <WorkExperience user={user} />}
      {user?.portfolios?.length > 0 && <Portfolio user={user} />}
      {user?.testimonial?.length > 0 && <RecommendationsSection user={user} />}
      {user?.video && <Video user={user} />}
    </div>
  );
};

export default Template1;
