import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import defaultAvatar from '../assets/images/Default_profilepic.png';
import notification from '../assets/images/notification-bing.svg';
import { languageLoader } from '../actions/languageAction';
import { useNavigate } from 'react-router-dom';
import Notifications from '../notifications/notifications';
import { SocketContextProvider } from '../context/socketContext';
import useListenNotifications from '../reducers/listenNotifications';
import { Bars4Icon } from '@heroicons/react/24/outline';
import { ToastBar } from './toastbar';

const EmployerHeader = ({ toggleMobileMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.employer);
  const notifications = useSelector((state) => state.notifications.notifications);

  console.log(notifications);

  const unreadCount = notifications?.filter((notification) => !notification.isRead).length;

  useListenNotifications();

  const localLang = localStorage.getItem('lan');

  const handleLanguageChange = (e) => {
    dispatch(languageLoader(e.target.value));
    localStorage.setItem('lan', e.target.value);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handlePostJob = () => {
    if(employer?.companyIndustry) navigate('/employer/create-job')
    else ToastBar.warning(["Please Complete Company Profile", "请完成公司简介", "Sila Lengkapkan Profil Syarikat"][localLang])
  };

  return (
    <SocketContextProvider>
      <div className="flex justify-between bg-white w-full border-b-[2px] py-4 px-4 lg:px-8">
        <div className="flex gap-x-2 lg:gap-x-4 items-center">
          <Bars4Icon
            className="h-5 w-5 text-gray-400 cursor-pointer z-50 lg:hidden"
            onClick={() => toggleMobileMenu()}
          />
          <img
            src={employer?.companyLogo ? employer.companyLogo : defaultAvatar}
            alt="userPic"
            className="w-8 h-8 lg:w-12 lg:h-12 rounded-full object-cover"
          />
          <div className="font-nunito text-sm lg:text-xl font-semibold leading-normal text-black">
            {employer.companyName}
          </div>
        </div>
        <div className="flex lg:gap-x-8">
          <nav className="flex lg:gap-x-5 justify-center items-center">
            <select onChange={handleLanguageChange} value={localLang} className="text-black/50 text-sm hidden lg:flex">
              <option value={0}>English</option>
              <option value={1}>中文</option>
              <option value={2}>Melayu</option>
            </select>
            <select
              onChange={handleLanguageChange}
              value={localLang}
              style={{
                paddingRight: '16px'
              }}
              className="text-black/50 text-sm lg:hidden"
            >
              <option value={0}>En</option>
              <option value={1}>中文</option>
              <option value={2}>My</option>
            </select>
            <div className="relative w-8 ml-2" ref={dropdownRef}>
              <div className="relative cursor-pointer" onClick={toggleDropdown}>
                <img src={notification} alt="notification" className="w-5 h-5 lg:w-6 lg:h-6" />
                {unreadCount > 0 && (
                  <span className="absolute -top-1 lg:-top-2 left-2 inline-flex items-center justify-center px-1 py-0.5 lg:px-1.5 lg:py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                    {unreadCount}
                  </span>
                )}
              </div>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2">
                  <Notifications onClose={closeDropdown} />
                </div>
              )}
            </div>
          </nav>
          {window.location.pathname !== '/employer/create-job' && (
            <button
              type="button"
              onClick={() => handlePostJob()}
              className={`w-14 lg:w-[165px] h-10 lg:h-[50px] flex-shrink-0 border border-solid border-black shadow-oppty-header-btn text-xs lg:text-base font-bold flex items-center justify-center ${!employer?.companyIndustry ? 'bg-gray-500 text-white' : 'bg-oppty-yellow'}`}
            >
               <span className="block lg:hidden">+ {lang.generalJob}</span>
               <span className="hidden lg:block">+ {lang.companyPostAJob}</span> 
            </button>
          )}
        </div>
      </div>
    </SocketContextProvider>
  );
};

export default EmployerHeader;
