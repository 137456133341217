import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageCropper from '../components/imageCrop/imageCropper';
import { editProfilePic } from '../actions/userAction';
import { employerEditProfilePic } from '../actions/employerAction';

const ProfileImageUploadModal = ({ closeModal, imageType }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const employer = useSelector((state) => state.employer);

  console.log('user: ', user);
  console.log('employer: ', employer);

  const handleCropComplete = async (imageData) => {
    console.log('imageData: ', imageData);
    const response = await fetch(imageData);
    const blob = await response.blob();

    const formData = new FormData();
    formData.append('file', blob, `profilePic_${user?.id}`);

    if (user?.userId) dispatch(editProfilePic(formData, closeModal));
    if (employer?.employerId) dispatch(employerEditProfilePic(formData, closeModal));
  };
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 w-full">
        <div className="bg-white w-2/3 px-5 md:px-10 py-10 rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold mb-2">Edit profile picture</h2>
            <button
              type="button"
              onClick={closeModal}
              className="text-lg font-bold hover:bg-red-500 px-2 rounded-md hover:text-white"
            >
              X
            </button>
          </div>
          <ImageCropper imageType={imageType} croppedPicDataHandling={handleCropComplete} />
        </div>
      </div>
    </>
  );
};

export default ProfileImageUploadModal;
