import { useEffect, useState } from 'react';
import Footer from '../../../common/footer';
import Header from '../../../common/header';
import JobFilter from './jobFilter';
import Jobs from './jobs';
import { useDispatch } from 'react-redux';
import { getAllJob } from '../../../actions/jobAction';
import { useLocation } from 'react-router-dom';

const SearchJobs = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const jobTitle = location.state?.jobTitle;

  const [filters, setFilters] = useState({ jobTitle: jobTitle });

  useEffect(() => {
    dispatch(getAllJob(filters));
  }, [filters]);

  return (
    <div className="flex flex-col min-h-screen items-center w-full">
      <Header />
      <JobFilter filters={filters} setFilters={setFilters} />
      <Jobs />
      <Footer />
    </div>
  );
};

export default SearchJobs;
