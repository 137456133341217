import { useSelector } from 'react-redux';
import topCandidate from '../../../assets/images/topCandidate.png';
import { Link } from 'react-router-dom';

const MoreTopCandidates = () => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <div className="flex flex-col items-center justify-center px-10">
      <div className="outfit-font text-[25px] lg:text-[70px] xl:text-[90px] font-normal text-oppty-black-3 text-center leading-[25px] lg:leading-[70px] xl:leading-[100px]">
        {lang.employeeHomeMoreTopCandidates}
      </div>
      <div className="pt-[10px] lg:pt-[30px] xl:pt-[37px] outfit-font text-[8px] lg:text-2xl xl:text-4xl font-normal text-oppty-black-3 text-center leading-[10px] xl:leading-[46px]">
        {lang.employeeHomeUserFriendly}
      </div>
      <img className="py-[20px] md:py-[60px]" src={topCandidate} alt="topCandidate" />
      <div className="outfit-font text-[15px] xl:text-[35px] font-normal text-oppty-black-3 min-w-[60%] text-center xl:leading-[46px]">
        {lang.employeeHomeReadyToPost}
      </div>
      <div className="outfit-font text-[10px] md:text-2xl font-normal text-oppty-black-3 min-w-[50%] text-center xl:leading-[34px] xl:pt-[10px]">
        {lang.employeeHomeCreateYourJob}
      </div>
      <a
        href="/employer-sign-up"
        className="w-32 md:w-[219px] my-[10px] md:my-[30px] h-10 md:h-[62px] flex-shrink-0 rounded-[12px] border-2 border-solid border-black bg-[#D9FC50] text-lg font-normal flex items-center justify-center"
      >
        {lang.generalGetStarted}
      </a>
      <div className="outfit-font text-[10px] lg:text-lg xl:text-2xl font-normal text-oppty-black-3 min-w-[40%] text-center xl:leading-[34px]">
        {lang.generalAlreadyHaveAnAccount}{' '}
        <Link to={'/employer-login'} className="text-oppty-blue-2">
          {lang.generalLogin}
        </Link>
      </div>
    </div>
  );
};

export default MoreTopCandidates;
