import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import logo from '../../assets/images/headerLogo.svg';
import { createNewPasswordSchema } from '../../validations/validationSchemas';
import { employerCreateNewPassword } from '../../actions/publicAction';

const EmployerCreateNewPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [verifyShowPassword, setVerifyShowPassword] = useState(false);

  const { state } = location;

  const email = state?.email;
  const lang = useSelector((state) => state.language.lang);

  console.log('email: ', email);

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const toggleVerifyPasswordVisibility = (e) => {
    e.preventDefault();
    setVerifyShowPassword(!verifyShowPassword);
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      verifyPassword: '',
      email: email
    },
    validationSchema: createNewPasswordSchema,
    onSubmit: async (values) => {
      setLoading(true);

      const { password, email } = values;
      dispatch(employerCreateNewPassword({ password, email }, navigate, setLoading));
    }
  });

  return (
    <div className="h-screen relative">
      <div
        className="absolute left-0 top-0 flex-col justify-center items-center p-10 cursor-pointer"
        onClick={() => navigate('/')}
      >
        <img src={logo} alt="Logo" className="hidden md:block w-36 lg:w-[150px]" />
      </div>
      <div className="flex flex-col h-full justify-center bg-gray-50 py-12 text-center space-y-12">
        <div className="bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl flex flex-col gap-y-10">
          <div className="flex flex-col items-center justify-center text-center space-y-2">
            <div className="font-semibold text-3xl">{lang.generalCreateNewPassword}</div>
            <div className="flex flex-row text-sm font-medium text-gray-400">
              <p>
                {lang.generalEmail}: {email}
              </p>
            </div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col space-y-12">
              <div className="flex flex-col items-lef justify-between w-full gap-y-6 px-5 mb-3">
                <div className="relative h-14">
                  <input
                    name="password"
                    className="w-full h-full text-left px-4 outline-none rounded-xl border border-gray-400 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                    type={showPassword ? 'text' : 'password'}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    placeholder={lang.generalNewPassword}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-red-500 text-sm w-full flex justify-start">{formik.errors.password}</div>
                  ) : null}
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <button
                      className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                      onClick={togglePasswordVisibility}
                    >
                      {!showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
                <div className="relative h-14">
                  <input
                    name="verifyPassword"
                    className="w-full h-full text-left px-4 outline-none rounded-xl border border-gray-400 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                    type={verifyShowPassword ? 'text' : 'password'}
                    onChange={formik.handleChange}
                    value={formik.values.verifyPassword}
                    placeholder={lang.generalVerifyPassword}
                  />
                  {formik.touched.verifyPassword && formik.errors.verifyPassword ? (
                    <div className="text-red-500 text-sm w-full flex justify-start">{formik.errors.verifyPassword}</div>
                  ) : null}
                  <p className="flex items-center gap-1 font-sans text-xs italic antialiased font-normal leading-normal text-gray-700">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-4 h-4 -mt-px"
                    >
                      <path
                        // fill-rule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        // clip-rule="evenodd"
                      ></path>
                    </svg>
                    {lang.createUse}
                  </p>
                  <button
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center text-sm leading-5"
                    onClick={toggleVerifyPasswordVisibility}
                  >
                    {!verifyShowPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>

              <div className="flex flex-col space-y-5">
                <button
                  disabled={loading}
                  type="submit"
                  className="w-full py-5 text-center disabled:bg-gray-400 text-sm font-semibold text-white bg-oppty-blue hover:bg-oppty-blue/90 rounded-xl border-none shadow-sm"
                >
                  {lang.generalCreateNewPassword}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmployerCreateNewPassword;
