import { useEffect } from 'react';
import Header from '../../../common/header';
import AppliedJobsList from './appliedJobsList';
import Footer from '../../../common/footer';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from '../../../actions/userAction';
import { useLocation } from 'react-router-dom';

const AppliedJobs = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const jobId = location.state?.jobId;

  useEffect(() => {
    console.log('check');
    dispatch(getCurrentUser());
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="sticky top-0 z-50">
        <Header />
      </div>
      <div className="flex-1 overflow-auto my-5 flex justify-center">
        <AppliedJobsList jobId={jobId} />
      </div>
      <Footer />
    </div>
    // <div className="flex flex-col min-h-screen justify-center items-center w-full">
    //   <div className="sticky top-0 z-50 w-full">
    //     <Header />
    //   </div>
    //   <div className="flex-1 overflow-auto flex justify-center items-center max-w-[1200px]">
    //     <div className="w-full flex justify-center items-center">
    //       <AppliedJobsList jobId={jobId} />
    //     </div>
    //   </div>
    //   <Footer />
    // </div>
  );
};

export default AppliedJobs;
