import { useSelector } from 'react-redux';
import benefitsIcon from '../../utils/companyBenefits';

const localLang = localStorage.getItem('lan');

const Benefits = () => {
  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.selectedEmployer);

  return (
    <div className="flex flex-col justify-center py-10 px-16">
      <div className="flex items-center gap-x-7 pb-10 border-t-2 border-oppty-grey-6 pt-10">
        <div className="text-4xl font-nunito font-bold text-black">{lang.companyBenefits}</div>
      </div>
      <div className="flex flex-wrap border-b-2 border-oppty-grey-6 pb-10 gap-5">
        {employer?.employer?.benefits?.map((benefit) => {
          console.log('benefit: ', benefit)
          return (
            <div className="flex gap-x-6 w-96 items-center">
              <div className="h-12 w-12 bg-oppty-blue flex justify-center items-center rounded">
                {benefitsIcon(benefit.label)}
              </div>
              <div className="text-xl font-nunito font-normal text-black">{benefit.label[localLang]}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Benefits;
