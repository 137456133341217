import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import UploadEmployerImage from '../../common/uploadEmployerImage';
import { imageDelete } from '../../actions/employerAction';
import { Carousel } from '@material-tailwind/react';

const EmployerImages = () => {
  const dispatch = useDispatch();

  const [addImages, setAddImages] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.employer);

  console.log('employer: ', employer);

  const handleDeleteImage = (image) => {
    const imageUrlParts = image.split('/');
    const picId = imageUrlParts[imageUrlParts.length - 1];

    dispatch(imageDelete({ picId: picId }));
  };

  const handleCancelClick = () => {
    setAddImages(false);
  };

  return (
    <div id="portfolios" className="flex flex-col justify-center pt-5 px-0 lg:px-8 bg-white rounded-md">
      <div className="flex flex-row items-center gap-x-5">
        <div className="text-xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.generalImages}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {!addImages && (
          <button
            onClick={() => setAddImages(true)}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
          >
            + {lang.generalUpload}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 pl-0 lg:pl-16">
        <div className="flex flex-wrap gap-x-5 lg:pb-7 gap-y-2">
          {employer?.images?.map((image, index) => {
            return (
              <div
                key={index}
                className="h-32 w-40 lg:h-40 lg:w-48 rounded-lg relative hover:shadow-xl"
                onClick={() => setShowModal(true)}
              >
                <img src={image} alt={image?.name} className="w-full h-full rounded-lg object-cover" />
                <TrashIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteImage(image);
                  }}
                  className="w-7 h-7 bg-red-500 stroke-white rounded-md p-1 absolute top-1 right-1 hover:bg-red-600"
                />
              </div>
            );
          })}
        </div>
        {addImages && (
          <div>
            <div className="flex-grow lg:pt-3"></div>
            <div className="flex flex-row gap-x-10 pt-5">
              <div className="w-full flex flex-col gap-y-2">
                <UploadEmployerImage employer={employer} />
              </div>
            </div>
            <div className="flex justify-end flex-row gap-x-2 pt-10 pb-5">
              <button
                type="button"
                onClick={handleCancelClick}
                className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
              >
                {lang.profileCancel}
              </button>
            </div>
          </div>
        )}
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="flex flex-col items-end w-[1000px] h-[500px]">
                <button
                  className="text-white bg-red-500 rounded font-extrabold px-2 py-1 text-center text-sm mr-1 mb-1 transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  x
                </button>
                <Carousel className="rounded-xl">
                  {employer?.images?.map((image) => (
                    <div className="w-full h-full bg-black justify-center flex">
                      <img src={image} alt="slider" className="h-full w-fit" />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default EmployerImages;
