import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import EventDetailModal from './eventDetailModal';

const locales = {
  'en-US': enUS
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

const InterviewCalendar = ({ jobId }) => {
  const [modal, setModal] = useState(false);
  const [selectEvent, setSelectEvent] = useState({});
  const modalOpenedRef = useRef(false);

  const interviews = useSelector((state) => state?.user?.interviews);
  console.log('interviews: ', interviews);

  const events = interviews?.flatMap((item) =>
    item.applicants
      .filter((applicant) => applicant.status === 'Interview')
      .map((applicant) => {
        console.log(applicant);
        const interviewDate = new Date(applicant?.interviewDate);
        const endDate = new Date(interviewDate);
        endDate.setHours(interviewDate.getHours() + 1);
        return {
          title: `Interview with ${item?.employer?.companyName}`,
          id: applicant?._id,
          start: interviewDate,
          end: endDate,
          desc: `Job Title: ${item.jobTitle}`,
          jobId: item?._id,
          userId: applicant?.user?._id,
          status: applicant?.status,
          interviewLink: applicant?.interviewLink
        };
      })
  );

  useEffect(() => {
    console.log(modalOpenedRef.current)
    if (jobId && !modalOpenedRef.current) {
      console.log("calendar jobId " + jobId)
      const eventToOpen = events?.find(event => event.jobId === jobId);
      console.log(eventToOpen)
      if (eventToOpen) {
        console.log(eventToOpen)
        setSelectEvent(eventToOpen);
        setModal(true);
       // modalOpenedRef.current = true; // Mark modal as opened automatically
      }
    }
  }, [jobId]);

  const handleEventClick = (event) => {
    console.log('Event clicked:', event);
    setSelectEvent(event);
    setModal(true);
  };

  const closeModal = () => {
    console.log("close")
    setModal(false);
    modalOpenedRef.current = true; // Reset modal opened state when closed
  };

  console.log('events: ', events);

  return (
    <div className="w-full h-full">
      <div className="w-full h-full">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={handleEventClick}
        />
      </div>
      {modal && <EventDetailModal event={selectEvent} closeModal={closeModal} />}
    </div>
  );
};

export default InterviewCalendar;
