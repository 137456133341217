import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import GeneralTile from '../../common/generealTile';
import CheckboxGroup from '../../common/checkboxGroup';
import { companyBestData } from '../../constant/checkboxData';
import { updateEmployerCulture } from '../../actions/employerAction';

const EmployerCulture = () => {
  const localLang = localStorage.getItem('lan');
  const dispatch = useDispatch();

  const [addCulture, setAddCulture] = useState(false);
  const [cultureCheckBoxData, setCultureCheckBoxData] = useState(companyBestData);

  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.employer);

  const checkCultureFromDB = () => {
    const updatedCultures = cultureCheckBoxData?.map((culture) => {
      const isChecked = employer.culture.some((data) => data.cultureId === culture.cultureId);

      if (isChecked) {
        return { ...culture, checked: true };
      } else {
        return culture;
      }
    });

    setCultureCheckBoxData(updatedCultures);
  };

  useEffect(() => {
    checkCultureFromDB();
  }, []);

  const handleCultureCheckboxChange = (item, isChecked) => {
    setCultureCheckBoxData((prevData) =>
      prevData.map((prevItem) =>
        prevItem.cultureId === item.cultureId ? { ...prevItem, checked: isChecked } : prevItem
      )
    );
    item.checked = isChecked;
    const updatedCulture = isChecked
      ? [...formik.values.companyCulture, item]
      : formik.values.companyCulture.filter((culture) => culture.cultureId !== item.cultureId);

    formik.setValues({ ...formik.values, companyCulture: updatedCulture });
  };

  const initialValues = {
    companyCulture: employer?.culture || ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      console.log('value: ', values);
      const result = await dispatch(updateEmployerCulture(values));
      if (result && !result.error) {
        setAddCulture(false);
      }
    }
  });

  return (
    <div className="flex flex-col justify-center pt-5 px-0 lg:px-8 rounded-md2 bg-white ">
      <div className="flex flex-row items-center gap-x-5">
        <div className="text-xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.generalCulture}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {!addCulture && (
          <button
            onClick={() => setAddCulture(true)}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
          >
            + {lang.profileAdd}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 lg:px-5">
        <div className="flex flex-wrap gap-x-2 lg:gap-x-5 pb-7 gap-y-2">
          {employer?.culture?.map((benefit, index) => {
            return <GeneralTile name={benefit.label[localLang]} uniqueKey={index} key={index} />;
          })}
        </div>
        {addCulture && (
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="border rounded-md">
                <CheckboxGroup
                  data={cultureCheckBoxData}
                  onChange={handleCultureCheckboxChange}
                  localLang={localLang}
                />
              </div>
              <div className="flex justify-end flex-row gap-x-2 pt-10 pb-5">
                <button
                  onClick={() => setAddCulture(false)}
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
                >
                  {lang.profileCancel}
                </button>
                <button
                  type="submit"
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md"
                >
                  {lang.profileAdd}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployerCulture;
