import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DashboardLayout from '../../layout/dashboardLayout';
import { getSearchEmployer, activateEmployer, fetchSearchEmployer, suspendEmployer, unSuspendEmployer } from '../../actions/adminAction';
import { ToastBar } from '../../common/toastbar';
import defaultImage from '../../assets/images/Default_profilepic.png';

const AdminSearchEmployer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchEmployer = useSelector((state) => state.admin.searchEmployer);

  console.log(searchEmployer);

  useEffect(() => {
    dispatch(fetchSearchEmployer({}));
  }, []);

  const [email, setEmail] = useState('');

  const handleSearch = () => {
    if (email) {
      dispatch(getSearchEmployer(email));
    } else {
      ToastBar.error('Please enter an email');
    }
  };

  const handleActivateEmployer = () => {
    dispatch(activateEmployer(searchEmployer?._id));
  };

  const handleSuspendEmployer = () => {
      dispatch(suspendEmployer(searchEmployer?._id))
  };

  const handleUnsuspendEmployer = () => {
    dispatch(unSuspendEmployer(searchEmployer?._id));
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return { label: 'Active', color: 'text-green-500' };
      case 0:
        return { label: 'Not Active', color: 'text-orange-500' };
      case 3:
        return { label: 'Suspended', color: 'text-red-500' };
      default:
        return { label: 'Unknown', color: 'text-gray-500' };
    }
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col justify-center p-5 gap-y-5">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="text-sm font-nunito font-bold leading-normal text-oppty-grey-16">Email</div>
            <input
              type="text"
              id="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300 ml-4"
            />
            <button onClick={handleSearch} className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-md">
              Search
            </button>
          </div>
        </div>

        <div className="text-lg font-nunito font-bold leading-normal text-black mt-4">
          {searchEmployer ? 'Showing 1 Employer' : 'No employers available'}
        </div>

        {searchEmployer && (
          <>
            <table className="table-auto bg-white rounded-2xl mt-4">
              <thead>
                <tr className="h-20 border-b-[1px]">
                  <th className="px-4">Logo</th>
                  <th>Company Name</th>
                  <th>Email</th>
                  <th>Joined Date</th>
                  <th>Last Login</th>
                  <th className="px-4">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-20">
                  {/* <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    <img src={searchEmployer?.companyLogo} alt="profile pic" className="w-12 h-12 rounded-full" />
                  </td> */}
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    <div className="flex justify-center items-center">
                      <img
                        src={searchEmployer?.companyLogo ? searchEmployer?.companyLogo : defaultImage}
                        alt="profile pic"
                        className="w-12 h-12 rounded-full"
                      />
                    </div>
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {searchEmployer?.companyName}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {searchEmployer?.email}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {!Number.isNaN(new Date(searchEmployer?.createdDate).getTime())
                      ? format(new Date(searchEmployer?.createdDate), 'dd-MM-yyyy HH:mm')
                      : 'Invalid Date'}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {!Number.isNaN(new Date(searchEmployer?.lastLogin).getTime())
                      ? format(new Date(searchEmployer?.lastLogin), 'dd-MM-yyyy HH:mm')
                      : 'Invalid Date'}
                  </td>
                  <td
                    className={`text-[13px] font-nunito font-bold leading-normal text-center ${getStatusLabel(searchEmployer?.status)?.color}`}
                  >
                    {getStatusLabel(searchEmployer?.status)?.label}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="flex justify-center gap-4 mt-4">
              {searchEmployer.status === 0 && (
                <button onClick={handleActivateEmployer} className="px-4 py-2 bg-green-500 text-white rounded-md">
                  Activate Employer
                </button>
              )}
              {searchEmployer.status === 1 && (
                                <button
                                    onClick={handleSuspendEmployer}
                                    className="px-4 py-2 bg-red-500 text-white rounded-md"
                                >
                                    Suspend Employer
                                </button>
                            )}
                            {searchEmployer.status === 3 && (
                                <button
                                    onClick={handleUnsuspendEmployer}
                                    className="px-4 py-2 bg-orange-500 text-white rounded-md"
                                >
                                    Unsuspend Employer
                                </button>
                            )}
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default AdminSearchEmployer;
