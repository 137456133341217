import {
  FETCH_ALL_ARTICLES,
  FETCH_ALL_COMPANY_LOGO,
  FETCH_GET_OTP_DATA_SUCCESS,
  FETCH_RECENT_JOBS
} from '../actions/publicAction';

const initialState = {
  companyLogos: [],
  articles: [],
  recentJobs: [],
  otpData: ''
};

export const publicReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_COMPANY_LOGO:
      return {
        ...state,
        companyLogos: action.payload?.employers
      };

    case FETCH_ALL_ARTICLES:
      return {
        ...state,
        articles: action.payload?.articles
      };

    case FETCH_RECENT_JOBS:
      return {
        ...state,
        recentJobs: action.payload?.jobs
      };

    case FETCH_GET_OTP_DATA_SUCCESS:
      return {
        ...state,
        otpData: action.payload?.otpData
      };

    default:
      return state;
  }
};

export default publicReducer;
