import ItemTile from './itemSkill';

const AboutMe = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-20">
      {user?.skills?.length > 0 && (
        <div className="text-4xl lg:text-5xl font-bold mb-10 lg:mb-20 text-white">
          <span className="bg-template9-blue-2">My</span> Skills
        </div>
      )}
      {user?.skills?.length > 0 && (
        <div className="text-base font-medium w-full pt-5 lg:pt-0 mt-1 flex flex-wrap gap-5 justify-center lg:justify-start">
          {user?.skills?.map((skill, index) => {
            const isFirstIndex = index === 0 ? true : false;
            return <ItemTile item={skill?.skill} level={skill?.level} key={index} isFirstIndex={isFirstIndex} />;
          })}
        </div>
      )}
      {user?.languages?.length > 0 && (
        <div className="text-4xl lg:text-5xl font-bold mb-10 lg:mb-20 text-white mt-20">
          Languages
        </div>
      )}
      {user?.languages?.length > 0 && (
        <div className="text-base font-medium w-full pt-5 lg:pt-0 mt-1 flex flex-wrap gap-5 justify-center lg:justify-start">
          {user?.languages?.map((lang, index) => {
            const isFirstIndex = index === 0 ? true : false;
            return <ItemTile item={lang?.lang} level={lang?.level} key={index} isFirstIndex={isFirstIndex} />;
          })}
        </div>
      )}
    </div>
  );
};

export default AboutMe;
