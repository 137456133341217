import {
  FETCH_ALL_INTERVIEWS,
  FETCH_USER_BY_ID,
  FETCH_USER_INIT,
  FETCH_USER_LOGIN,
  FETCH_USER_LOGOUT,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS
} from '../actions/userAction';
import { sortByDate } from '../utils/functions';

const initialState = {
  userId: '',
  title: '',
  firstName: '',
  lastName: '',
  salutation: '',
  email: '',
  address: '',
  areaCode: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  mobile: '',
  role: '',
  avatar: '',
  template: null,
  availability: false,
  skills: [],
  languages: [],
  educations: [],
  workExperiences: [],
  savedJobs: [],
  appliedJobs: [],
  socials: [],
  portfolios: [],
  resume: {},
  video: {},
  interview: [],
  testimonial: [],
  isNewLogin: null,
};

const initialLoginState = false;

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state
      };
    case FETCH_USER_SUCCESS:
      console.log('user payload: ', action.payload);
      return {
        ...state,
        userId: action.payload?.user?._id,
        firstName: action.payload?.user?.firstName,
        lastName: action.payload?.user?.lastName,
        salutation: action.payload?.user?.salutation,
        email: action.payload?.user?.email,
        role: action.payload?.user?.role,
        avatar: action.payload?.user?.avatar,
        isNewLogin: action.payload?.isNewLogin,
        areaCode: action.payload?.user?.areaCode,
        mobile: action.payload?.user?.mobile,
        availability: action.payload?.user?.availability,
        address: action.payload?.user?.address,
        state: action.payload?.user?.state,
        city: action.payload?.user?.city,
        country: action.payload?.user?.country,
        postal: action.payload?.user?.postal,
        aboutMe: action.payload?.user?.aboutMe,
        skills: action.payload?.user?.skills,
        languages: action.payload?.user?.languages,
        educations: sortByDate(action.payload?.user?.educations),
        workExperiences: sortByDate(action.payload?.user?.workExperiences),
        savedJobs: action.payload?.user?.savedJobs,
        appliedJobs: action.payload?.user?.appliedJobs,
        template: action.payload?.user?.template,
        socials: action.payload?.user?.socials,
        portfolios: action.payload?.user?.portfolios,
        resume: action.payload?.user?.resume,
        video: action.payload?.user?.video,
        title: action.payload?.user?.title,
        certifications: action.payload?.user?.certifications,
        publicId: action.payload?.user?.publicId,
        testimonial: action.payload?.user?.testimonial,
        industry: action.payload?.user?.industry
      };
    case FETCH_USER_INIT:
      return {
        state: initialState
      };
    case FETCH_ALL_INTERVIEWS:
      return {
        ...state,
        interviews: action.payload?.jobsWithInterviews
      };
    default:
      return state;
  }
};

// export const userLoginReducer = (state = initialLoginState, action) => {
//   switch (action.type) {
//     case FETCH_USER_LOGIN:
//       return {
//         ...state,
//         isUserLogin: true
//       }
//     default:
//       return state
//   }
// }

export const userLoginReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case FETCH_USER_LOGIN:
      return true;

    case FETCH_USER_LOGOUT:
      return false;

    default:
      return state;
  }
};

export default userReducer;

const selectedUserInitialState = {
  user: {}
};

export const selectedUserReducer = (state = selectedUserInitialState, action) => {
  switch (action.type) {
    case FETCH_USER_BY_ID:
      return {
        ...state,
        user: action.payload?.user
      };

    default:
      return state;
  }
};
