import { useEffect, useState } from 'react';
import EmployerHeader from '../../common/employerHeader';
import LeftNavigationBar from '../../common/leftNavigationBar';
import EmployerBenefits from './employerBenefits';
import EmployerCulture from './employerCulture';
import EmployerGeneralDetails from './employerGeneralDetails';
import EmployerWorkPolicy from './employerWorkPolicy';
import ProfileStrength from './profileStrength';
import Socials from './socials';
import { useDispatch, useSelector } from 'react-redux';
import { updateLeftNavBarToggleStatus } from '../../actions/generalAction';
import EmployerImages from './EmployerImages';
import { getDashboardDetails, getOwnEmployerData } from '../../actions/employerAction';

const CompanyProfile = () => {
  const dispatch = useDispatch();

  const today = new Date();
  const sevenDaysBefore = new Date();
  sevenDaysBefore.setDate(today.getDate() - 7);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(1990, 0, 1),
    endDate: today
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const employer = useSelector((state) => state.employer);
  const isCollapsed = useSelector((state) => state?.general?.leftNavBarToggle);
  const toggleNavbar = () => dispatch(updateLeftNavBarToggleStatus({ leftNavBarToggle: !isCollapsed }));
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    dispatch(updateLeftNavBarToggleStatus({ leftNavBarToggle: false }));
  };
  useEffect(() => {
    dispatch(getOwnEmployerData(employer?.employerId));
  }, []);

  useEffect(() => {
    dispatch(getDashboardDetails(dateRange));
  }, [dateRange]);

  return (
    <div className="flex flex-row min-h-screen gap-y-8 bg-white">
    <div
      className={`${
        isMobileMenuOpen ? 'block' : 'hidden lg:block'
      } fixed z-50 w-60 bg-white shadow-lg lg:w-60 lg:bg-transparent lg:shadow-none lg:block transition-transform duration-300`}
      style={{ transform: isMobileMenuOpen ? 'translateX(0)' : 'translateX(-100%) lg:translateX(0)' }}
    >
      <LeftNavigationBar
        isCollapsed={isCollapsed}
        toggleNavbar={toggleNavbar}
        toggleMobileMenu={toggleMobileMenu}
        isMobileMenuOpen={isMobileMenuOpen}
      />
    </div>

    <div className={`flex flex-col flex-grow min-h-screen
       ${isCollapsed ? 'ml-0 lg:ml-16' : 'lg:ml-60'}`}>
      <div className={`flex w-full fixed top-0 left-0 ${isCollapsed ? 'pl-0 lg:pl-16' : 'lg:pl-60'} z-40`}>
        <EmployerHeader toggleMobileMenu={toggleMobileMenu} />
      </div>
        <div className="mt-20 w-full">
          <div className="p-8 flex flex-col lg:flex-row w-full gap-x-5">
            <div className="w-full lg:w-72 flex flex-col gap-y-6">
              <ProfileStrength />
              <Socials />
            </div>
            <div className="w-full flex flex-col gap-y-5">
              <EmployerGeneralDetails />
              <EmployerImages />
              <EmployerBenefits />
              <EmployerWorkPolicy />
              <EmployerCulture />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
