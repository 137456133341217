const PrivacyBodyMalay = () => {
  return (
    <div className="bg-white text-gray-800">
      <div className="container mx-auto p-14">
        <h1 className="text-3xl font-bold mb-4">Dasar Privasi Oppty.ai</h1>
        <p className="text-sm text-gray-600 mb-6">Tarikh Dikemas Kini: 14 Ogos 2024</p>

        <section className="mb-6">
          <p className="text-base leading-relaxed mb-4">
            Oppty.ai ("kami," "kita," atau "kami") komited untuk melindungi privasi anda. Dasar Privasi ini menerangkan
            bagaimana kami mengumpul, menggunakan, mendedahkan, dan melindungi maklumat anda semasa anda melawat laman
            web kami [Oppty.ai URL] ("Laman Web") dan menggunakan perkhidmatan kami ("Perkhidmatan"). Dengan menggunakan
            Laman Web dan Perkhidmatan, anda bersetuju dengan pengumpulan dan penggunaan maklumat menurut Dasar Privasi
            ini.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">1. Maklumat yang Kami Kumpul</h2>
          <p className="text-base leading-relaxed mb-4">
            Kami mungkin mengumpul jenis maklumat berikut daripada pengguna Laman Web dan Perkhidmatan kami:
          </p>
          <h3 className="text-lg font-medium mb-1">1.1 Maklumat Peribadi:</h3>
          <p className="text-base leading-relaxed mb-4">
            Apabila anda mendaftar akaun, membuat profil, memohon pekerjaan, atau menyiarkan senarai pekerjaan, kami
            mungkin mengumpul maklumat peribadi anda, termasuk tetapi tidak terhad kepada:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Nama</li>
            <li>Alamat emel</li>
            <li>Nombor telefon</li>
            <li>Tarikh lahir</li>
            <li>Jantina</li>
            <li>Kewarganegaraan</li>
            <li>Kelayakan pendidikan</li>
            <li>Pengalaman kerja</li>
            <li>Resume/CV</li>
            <li>Keutamaan pekerjaan</li>
            <li>Maklumat syarikat (untuk majikan)</li>
            <li>Maklumat pembayaran (untuk perkhidmatan berbayar)</li>
          </ul>
          <h3 className="text-lg font-medium mb-1">1.2 Maklumat Bukan Peribadi:</h3>
          <p className="text-base leading-relaxed mb-4">
            Kami juga mungkin mengumpul maklumat bukan peribadi yang tidak dapat mengenal pasti anda secara langsung,
            seperti:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Alamat IP</li>
            <li>Jenis dan versi pelayar</li>
            <li>Sistem operasi</li>
            <li>Laman web rujukan</li>
            <li>Halaman yang dilihat</li>
            <li>Tarikh dan masa akses</li>
            <li>Maklumat peranti</li>
          </ul>
          <h3 className="text-lg font-medium mb-1">1.3 Kuki dan Teknologi Penjejakan:</h3>
          <p className="text-base leading-relaxed mb-4">
            Kami menggunakan kuki dan teknologi penjejakan yang serupa untuk menjejaki aktiviti anda di Laman Web kami
            dan menyimpan maklumat tertentu. Kuki adalah fail kecil dengan sedikit data yang mungkin termasuk pengecam
            unik yang tidak dikenali. Anda boleh mengarahkan pelayar anda untuk menolak semua kuki atau untuk
            menunjukkan apabila kuki dihantar. Walau bagaimanapun, jika anda tidak menerima kuki, anda mungkin tidak
            dapat menggunakan beberapa bahagian Perkhidmatan kami.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">2. Bagaimana Kami Menggunakan Maklumat Anda</h2>
          <p className="text-base leading-relaxed mb-4">
            Kami menggunakan maklumat yang kami kumpul untuk pelbagai tujuan, termasuk:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              Menyediakan Perkhidmatan: Untuk memudahkan pencocokan pekerjaan antara pencari kerja dan majikan,
              memproses permohonan, dan menguruskan akaun anda.
            </li>
            <li>
              Komunikasi: Untuk berkomunikasi dengan anda, termasuk menjawab pertanyaan anda, menghantar kemas kini,
              pemberitahuan, dan menyediakan sokongan pelanggan.
            </li>
            <li>
              Personalisasi: Untuk memperibadikan pengalaman anda di Laman Web dengan menunjukkan kandungan, senarai
              pekerjaan, dan iklan yang sesuai dengan keutamaan anda.
            </li>
            <li>
              Analitik dan Peningkatan: Untuk memantau dan menganalisis trend penggunaan, menjejaki keberkesanan usaha
              pemasaran kami, memperbaiki Laman Web dan Perkhidmatan kami, dan mengembangkan ciri baru.
            </li>
            <li>
              Pematuhan dan Kewajipan Undang-Undang: Untuk mematuhi kewajipan undang-undang, menyelesaikan pertikaian,
              dan menguatkuasakan perjanjian kami.
            </li>
            <li>
              Pemasaran: Untuk menghantar bahan promosi, buletin, atau maklumat lain mengenai Perkhidmatan kami yang
              kami percaya mungkin menarik minat anda. Anda boleh memilih untuk tidak menerima komunikasi ini pada
              bila-bila masa dengan mengikuti arahan berhenti melanggan yang disediakan dalam komunikasi.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">3. Bagaimana Kami Berkongsi Maklumat Anda</h2>
          <ul className="list-disc list-inside mb-4">
            <li>
              Dengan Majikan: Jika anda seorang pencari kerja, kami mungkin berkongsi maklumat anda dengan majikan dan
              perekrut untuk memudahkan proses permohonan kerja.
            </li>
            <li>
              Penyedia Perkhidmatan: Kami mungkin berkongsi maklumat anda dengan penyedia perkhidmatan pihak ketiga yang
              melakukan perkhidmatan bagi pihak kami, seperti pemprosesan pembayaran, analisis data, penghantaran emel,
              perkhidmatan hosting, dan sokongan pelanggan.
            </li>
            <li>
              Keperluan Undang-Undang: Kami mungkin mendedahkan maklumat anda jika diperlukan oleh undang-undang atau
              sebagai tindak balas kepada permintaan yang sah oleh pihak berkuasa awam (misalnya, mahkamah atau agensi
              kerajaan).
            </li>
            <li>
              Pemindahan Perniagaan: Jika kami terlibat dalam penggabungan, pemerolehan, atau penjualan aset, maklumat
              anda mungkin dipindahkan sebagai sebahagian daripada transaksi tersebut. Kami akan memberitahu anda
              sebelum maklumat peribadi anda dipindahkan dan tertakluk kepada dasar privasi yang berbeza.
            </li>
            <li>
              Dengan Persetujuan Anda: Kami mungkin berkongsi maklumat anda dengan pihak ketiga lain dengan persetujuan
              anda atau atas arahan anda.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">4. Penyimpanan Data</h2>
          <p className="text-base leading-relaxed mb-4">
            Kami akan menyimpan maklumat peribadi anda hanya selama yang diperlukan untuk tujuan yang dinyatakan dalam
            Dasar Privasi ini. Kami akan menyimpan dan menggunakan maklumat peribadi anda setakat yang diperlukan untuk
            mematuhi kewajipan undang-undang kami, menyelesaikan pertikaian, dan menguatkuasakan perjanjian dan dasar
            undang-undang kami.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">5. Keselamatan Maklumat Anda</h2>
          <p className="text-base leading-relaxed mb-4">
            Kami mengambil langkah-langkah yang munasabah untuk melindungi keselamatan maklumat peribadi anda. Walau
            bagaimanapun, tiada kaedah penghantaran melalui internet atau kaedah penyimpanan elektronik adalah 100%
            selamat, dan kami tidak dapat menjamin keselamatannya secara mutlak. Anda bertanggungjawab untuk menjaga
            kerahsiaan maklumat akaun anda.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">6. Hak dan Pilihan Anda</h2>
          <ul className="list-disc list-inside mb-4">
            <li>
              Akses dan Pembetulan: Anda mempunyai hak untuk mengakses dan membetulkan maklumat peribadi anda yang kami
              simpan. Anda boleh melakukannya dengan log masuk ke akaun anda dan mengemas kini profil anda atau dengan
              menghubungi kami secara langsung.
            </li>
            <li>
              Kebolehangkatan Data: Anda boleh meminta salinan data peribadi yang kami simpan mengenai anda dalam format
              yang tersusun, yang biasa digunakan, dan boleh dibaca mesin.
            </li>
            <li>
              Penghapusan: Anda boleh meminta supaya kami memadam maklumat peribadi anda, tertakluk kepada pengecualian
              tertentu yang disediakan oleh undang-undang. Kami akan mengambil langkah-langkah yang munasabah untuk
              memadam maklumat anda daripada rekod kami, tetapi kami mungkin perlu menyimpan maklumat tertentu untuk
              tujuan undang-undang atau operasi.
            </li>
            <li>
              Pilih Keluar daripada Komunikasi Pemasaran: Anda boleh memilih untuk tidak menerima komunikasi pemasaran
              daripada kami dengan mengikuti arahan berhenti melanggan yang disediakan dalam komunikasi atau dengan
              menghubungi kami secara langsung.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">7. Pautan Pihak Ketiga</h2>
          <p className="text-base leading-relaxed mb-4">
            Laman Web kami mungkin mengandungi pautan ke laman web pihak ketiga. Kami tidak bertanggungjawab ke atas
            amalan privasi atau kandungan laman web tersebut. Kami menggalakkan anda untuk menyemak dasar privasi
            mana-mana laman pihak ketiga yang anda lawati.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">8. Privasi Kanak-Kanak</h2>
          <p className="text-base leading-relaxed mb-4">
            Perkhidmatan kami tidak bertujuan untuk individu di bawah umur 18 tahun. Kami tidak dengan sengaja mengumpul
            maklumat peribadi daripada kanak-kanak di bawah umur 18 tahun. Jika kami mendapati bahawa kami telah
            mengumpul maklumat peribadi daripada kanak-kanak di bawah umur 18 tahun, kami akan mengambil langkah untuk
            memadam maklumat tersebut.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">9. Pemindahan Antarabangsa</h2>
          <p className="text-base leading-relaxed mb-4">
            Maklumat anda mungkin dipindahkan ke, dan disimpan di, komputer yang terletak di luar negeri anda, provinsi
            anda, negara anda, atau bidang kuasa pemerintah lain di mana undang-undang perlindungan data mungkin berbeza
            daripada yang ada di bidang kuasa anda. Dengan menggunakan Perkhidmatan kami, anda bersetuju dengan
            pemindahan maklumat anda ke Singapura dan bidang kuasa lain di mana kami beroperasi.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">10. Perubahan kepada Dasar Privasi Ini</h2>
          <p className="text-base leading-relaxed mb-4">
            Kami mungkin mengemas kini Dasar Privasi kami dari semasa ke semasa. Kami akan memberitahu anda tentang
            sebarang perubahan dengan menyiarkan Dasar Privasi baru di halaman ini dan mengemas kini tarikh "Dikemas
            Kini Terakhir". Anda dinasihatkan untuk menyemak Dasar Privasi ini secara berkala untuk sebarang perubahan.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyBodyMalay;
