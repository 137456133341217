import AsyncSelect from 'react-select/async';
import { useFormik } from 'formik';
import { articleCategoryOptions } from '../../constant/dropdownConstOptions';
import { useDispatch, useSelector } from 'react-redux';
import SearchTile from '../../common/searchTile';
import { useEffect } from 'react';
import { getCandidateArticles } from '../../actions/publicAction';

const ArticleFilter = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language.lang);

  const formik = useFormik({
    initialValues: {
      name: '',
      category: []
    }
  });

  useEffect(() => {
    dispatch(getCandidateArticles(formik.values));
    console.log('filter value: ', formik.values);
  }, [formik.values]);

  const articleLoadOptions = (inputValue, callback) => {
    const filteredOptions = articleCategoryOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    callback(filteredOptions);
  };

  const handleDeleteCategory = (index) => {
    const updatedCategory = formik.values.category.filter((_, i) => i !== index);
    formik.setFieldValue('category', updatedCategory);
  };

  const handleClear = () => {
    formik.setValues({
      name: '',
      category: []
    });
  };

  return (
    <div className="pt-10">
      <div className="text-5xl font-bold text-center">{lang?.generalArticles}</div>
      <form onSubmit={formik.handleSubmit} className="flex flex-wrap flex-row gap-x-5 gap-y-2 pt-5 w-full justify-center">
        <div className="flex w-96">
          <AsyncSelect
            cacheOptions
            defaultOptions
            value={articleCategoryOptions.find((option) => option.value === formik.values.category)}
            onChange={(selectedOption) => {
              const selectedValue = selectedOption || '';
              if (selectedValue) formik.setFieldValue('category', [...formik.values.category, selectedValue.value]);
            }}
            loadOptions={articleLoadOptions}
            isDisabled={false}
            isClearable={true}
            isSearchable={false}
            name="category"
            id="category"
            menuPlacement="bottom"
            styles={{
              control: (provided) => ({
                ...provided,
                height: 45
              })
            }}
            placeholder={lang.generalSelectType}
            className="w-full"
          />
        </div>
        <div className="flex w-96">
          <input
            type="text"
            id="name"
            name="name"
            placeholder={lang.generalArticleSearchByTitle}
            onBlur={(e) => {
              formik.setFieldValue('name', e.target.value);
              document.getElementById('name').value = '';
            }}
            className="py-2.5 w-full leading-tight border focus:outline rounded-md border-gray-300 px-2.5"
          />
        </div>
      </form>
      <div className="flex justify-center">
        {formik.values?.category?.length > 0 || formik.values?.title || formik.values?.country || formik.values?.name ? (
          <div className="flex flex-wrap gap-3 pt-2 items-center">
            {formik.values?.category?.map((cate, index) => {
              return (
                <SearchTile name={cate} uniqueKey={index} key={index} onDelete={() => handleDeleteCategory(index)} />
              );
            })}
            {formik.values?.name && (
              <SearchTile name={formik.values?.name} onDelete={() => formik.setFieldValue('name', '')} />
            )}
            <div className="text-sm font-semibold underline cursor-pointer" onClick={() => handleClear()}>
              {lang.generalClearResult}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ArticleFilter;
