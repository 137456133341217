import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import DashboardLayout from '../../layout/dashboardLayout';
import { getAllCandidates } from '../../actions/adminAction';
import { ToastBar } from '../../common/toastbar';
import defaultImage from '../../assets/images/Default_profilepic.png';

const AdminCandidates = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.admin.users);
  console.log('users: ', users);

  useEffect(() => {
    dispatch(getAllCandidates());
  }, [dispatch]);

  const [sortConfig, setSortConfig] = useState({ key: 'createdDate', direction: 'descending' });
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = useMemo(() => {
    if (!users) return [];
    let sortableUsers = [...users];
    sortableUsers.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortableUsers;
  }, [users, sortConfig]);

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return faSort;
    }
    return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return { label: 'Active', color: 'text-green-500' };
      case 0:
        return { label: 'Not Active', color: 'text-orange-500' };
      case 3:
        return { label: 'Suspended', color: 'text-red-500' };
      default:
        return { label: 'Unknown', color: 'text-gray-500' };
    }
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedUsers.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(sortedUsers.length / entriesPerPage);

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const downloadExcel = () => {
    try {
      const formattedUsers = users.map((user) => ({
        Salutation: user.salutation || '',
        'First Name': user.firstName || '',
        'Last Name': user.lastName || '',
        'Joined Date': !Number.isNaN(new Date(user.createdDate).getTime())
          ? format(new Date(user.createdDate), 'dd-MM-yyyy HH:mm')
          : 'Invalid Date',
        'Last Login': !Number.isNaN(new Date(user.lastLogin).getTime())
          ? format(new Date(user.lastLogin), 'dd-MM-yyyy HH:mm')
          : 'Invalid Date',
        Email: user.email || '',
        Status: getStatusLabel(user.status).label || ''
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedUsers);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Candidates');

      // Adjust column width
      const maxLength = {};
      formattedUsers.forEach((row) => {
        Object.entries(row).forEach(([key, value]) => {
          const length = String(value).length;
          maxLength[key] = Math.max(maxLength[key] || 0, length);
        });
      });
      const colWidths = Object.values(maxLength).map((length) => ({ wch: length + 2 })); // +2 for padding
      worksheet['!cols'] = colWidths;

      const now = new Date();
      const formattedDate = format(now, 'yyyy-MM-dd_HH-mm');
      const filename = `candidates_${formattedDate}.xlsx`;

      XLSX.writeFile(workbook, filename);
      ToastBar.success('Excel Download Successful');
    } catch (error) {
      ToastBar.error('Error Downloading Excel');
    }
  };
  return (
    <DashboardLayout>
      <div className="flex flex-col justify-center p-5 gap-y-5">
        <div className="flex justify-between items-center">
          <div className="text-lg font-nunito font-bold leading-normal text-black">
            {users?.length > 0
              ? users?.length === 1
                ? 'Showing 1 Candidate'
                : `Showing ${users?.length} Candidates`
              : 'No candidates available'}
          </div>
          <div className="flex items-center font-semibold">
            <label htmlFor="entriesPerPage" className="mr-2">
              Show
            </label>
            <select
              id="entriesPerPage"
              value={entriesPerPage}
              onChange={handleEntriesPerPageChange}
              className="border border-gray-300 rounded-md p-1"
            >
              {[5, 10, 20, 50].map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
            <span className="ml-2">Entries</span>
            <button onClick={downloadExcel} className="ml-4">
              <FontAwesomeIcon icon={faFileExcel} size="2x" color="green" />
            </button>
          </div>
        </div>

        <table className="table-auto bg-white rounded-2xl">
          <thead>
            <tr className="h-20 border-b-[1px]">
              <th className="cursor-pointer px-4">Avatar</th>
              <th className="cursor-pointer px-2">Salutation</th>
              <th onClick={() => handleSort('firstName')} className="cursor-pointer">
                First Name <FontAwesomeIcon icon={getSortIcon('firstName')} size="sm" className="ml-1" />
              </th>
              <th onClick={() => handleSort('lastName')} className="cursor-pointer">
                Last Name <FontAwesomeIcon icon={getSortIcon('lastName')} size="sm" className="ml-1" />
              </th>
              <th onClick={() => handleSort('createdDate')} className="cursor-pointer">
                Joined Date <FontAwesomeIcon icon={getSortIcon('createdDate')} size="sm" className="ml-1" />
              </th>
              <th onClick={() => handleSort('lastLogin')} className="cursor-pointer">
                Last Login <FontAwesomeIcon icon={getSortIcon('lastLogin')} size="sm" className="ml-1" />
              </th>
              <th onClick={() => handleSort('email')} className="cursor-pointer">
                Email <FontAwesomeIcon icon={getSortIcon('email')} size="sm" className="ml-1" />
              </th>
              <th onClick={() => handleSort('status')} className="cursor-pointer px-4">
                Status <FontAwesomeIcon icon={getSortIcon('status')} size="sm" className="ml-1" />
              </th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((user, index) => {
              const formattedCreatedDate = !Number.isNaN(new Date(user?.createdDate).getTime())
                ? format(new Date(user?.createdDate), 'dd-MM-yyyy HH:mm')
                : 'Invalid Date';

              const formattedLastLoginDate = !Number.isNaN(new Date(user?.lastLogin).getTime())
                ? format(new Date(user?.lastLogin), 'dd-MM-yyyy HH:mm')
                : 'Invalid Date';

              const { label, color } = getStatusLabel(user?.status);

              return (
                <tr className="h-20" key={index}>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    <div className="flex justify-center items-center">
                      <img
                        src={user?.avatar ? user?.avatar : defaultImage}
                        alt="profile pic"
                        className="w-12 h-12 rounded-full"
                      />
                    </div>
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {user?.salutation}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {user?.firstName}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {user?.lastName}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {formattedCreatedDate}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {formattedLastLoginDate}
                  </td>
                  <td className="text-[13px] font-nunito font-normal leading-normal text-black text-center">
                    {user?.email}
                  </td>
                  <td className={`text-[13px] font-nunito font-bold leading-normal text-center ${color}`}>{label}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="flex justify-between items-center mt-4 text-sm">
          <span>
            Showing {indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, sortedUsers.length)} of {sortedUsers.length}{' '}
            entries
          </span>
          <div className="flex items-center">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-gray-300 rounded-md"
            >
              Previous
            </button>
            <ul className="flex list-none">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`mx-1 px-3 py-1 border rounded-md ${currentPage === number ? 'bg-gray-300' : ''}`}
                >
                  <button onClick={() => setCurrentPage(number)}>{number}</button>
                </li>
              ))}
            </ul>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="px-4 py-2 bg-gray-300 rounded-md"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AdminCandidates;
