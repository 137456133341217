import TestimonialCard from './testimonialCard';

const Testimonial = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-20 shadow-md bg-white">
      <div className="text-5xl font-bold lg:w-1/2 mb-20">Testimonials</div>
      <div className="flex flex-wrap w-full gap-8 justify-center">
        {user?.testimonial?.map((testimonial, index) => {
          return <TestimonialCard key={index} testimonial={testimonial} />;
        })}
      </div>
    </div>
  );
};

export default Testimonial;
