const Home = ({ stroke, fill, className, hoverStroke, isHovered }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill={fill}
      className={className}
    >
      <g clipPath="url(#clip0_968_52)">
        <path
          d="M5 12.4355H3L12 3.43555L21 12.4355H19"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/dashboard' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 12.4355V19.4355C5 19.966 5.21071 20.4747 5.58579 20.8498C5.96086 21.2248 6.46957 21.4355 7 21.4355H17C17.5304 21.4355 18.0391 21.2248 18.4142 20.8498C18.7893 20.4747 19 19.966 19 19.4355V12.4355"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/dashboard' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_968_52">
          <rect width="24" height="24" fill="white" transform="translate(0 0.435547)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Home;
