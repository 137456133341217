import Cafeteria from '../assets/icons/cafeteria.svg';
import BikeFriendly from '../assets/icons/bike.svg';
import ExtraDaysOff from '../assets/icons/extraDays.svg';
import TeaAndCoffee from '../assets/icons/teaCoffee.svg';
import MentalHealthDays from '../assets/icons/mentalHealth.svg';
import FlexibleWorkTime from '../assets/icons/flexible.svg';
import WorkLifeBalance from '../assets/icons/workBalance.svg';
import Innovations from '../assets/icons/innovations.svg';
import FlatStructure from '../assets/icons/flatStructure.svg';
import Hackatons from '../assets/icons/hackatons.svg';
import PetFriendly from '../assets/icons/pet.svg';
import CompanyRetreat from '../assets/icons/companyRetreat.svg';

const benefitsIcon = (benefit) => {
  switch (benefit) {
    case 'Cafeteria':
      return <img src={Cafeteria} alt="Cafeteria" />;
    case 'Bike friendly':
      return <img src={BikeFriendly} alt="BikeFriendly" />;
    case 'Extra days off':
      return <img src={ExtraDaysOff} alt="ExtraDaysOff" />;
    case 'Tea and coffee':
      return <img src={TeaAndCoffee} alt="TeaAndCoffee" />;
    case 'Mental health days':
      return <img src={MentalHealthDays} alt="MentalHealthDays" />;
    case 'Flexible work time':
      return <img src={FlexibleWorkTime} alt="FlexibleWorkTime" />;
    case 'Work-life balance':
      return <img src={WorkLifeBalance} alt="WorkLifeBalance" />;
    case 'Innovations':
      return <img src={Innovations} alt="Innovations" />;
    case 'Flat structure':
      return <img src={FlatStructure} alt="FlatStructure" />;
    case 'Hackatons':
      return <img src={Hackatons} alt="Hackatons" />;
    case 'Pet friendly':
      return <img src={PetFriendly} alt="PetFriendly" />;
    case 'Company retreat':
      return <img src={CompanyRetreat} alt="CompanyRetreat" />;
    default:
      return <img src={Hackatons} alt="Hackatons" />;
  }
};

export default benefitsIcon;
