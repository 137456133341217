import JobStatistic from './jobStatistics';
import JobsApplicantsStatus from './jobsApplicantsStatus';

const DashboardMiddleSection = () => {
  return (
    <div className="flex justify-between bg-white p-8 h-full">
      <div className="w-[69%]">
        <JobStatistic />
      </div>
      <div className="w-[29%]">
        <JobsApplicantsStatus />
      </div>
    </div>
  );
};

export default DashboardMiddleSection;
