import { ToastBar } from '../common/toastbar';

export const calculateDateDuration = (startYear, startMonth, endYear, endMonth) => {
  const startDate = new Date(startYear, getMonthIndex(startMonth));
  let endDate;

  if (endYear === 99 && endMonth === '99') {
    endDate = new Date();
  } else {
    endDate = new Date(endYear, getMonthIndex(endMonth));
  }

  const diffMonths = endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear());
  const years = Math.floor(diffMonths / 12);
  const months = diffMonths % 12;

  return { years, months };
};

const getMonthIndex = (monthName) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  return months.indexOf(monthName);
};

export const sortByDate = (experiences) => {
  if (!experiences || experiences.length === 0) {
    return [];
  }
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const customSort = (a, b) => {
    const isAAtTop = a.endYear === 99 && a.endMonth === '99';
    const isBAtTop = b.endYear === 99 && b.endMonth === '99';

    if (isAAtTop && !isBAtTop) {
      return -1;
    } else if (!isAAtTop && isBAtTop) {
      return 1;
    }

    const endYearA = a.endYear === 99 && a.endMonth === '99' ? currentYear : a.endYear;
    const endYearB = b.endYear === 99 && b.endMonth === '99' ? currentYear : b.endYear;

    if (endYearA !== endYearB) {
      return endYearB - endYearA;
    }

    const monthOrder = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    if (endYearA === currentYear) {
      if (a.startYear !== b.startYear) {
        return b.startYear - a.startYear;
      }
      return monthOrder.indexOf(b.startMonth) - monthOrder.indexOf(a.startMonth);
    }

    const endMonthA = a.endMonth === '99' ? currentMonth : monthOrder.indexOf(a.endMonth) + 1;
    const endMonthB = b.endMonth === '99' ? currentMonth : monthOrder.indexOf(b.endMonth) + 1;

    if (endMonthA !== endMonthB) {
      return endMonthB - endMonthA;
    }

    return endYearB - endYearA;
  };

  return experiences.sort(customSort);
};

export const calculateDuration = (timestamp) => {
  const localLang = localStorage.getItem('lan');

  const date = new Date(timestamp);
  const currentDate = new Date();

  const differenceInTime = currentDate.getTime() - date.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
  const differenceInHours = Math.floor(differenceInTime / (1000 * 3600));

  if (differenceInDays >= 365) {
    const years = Math.floor(differenceInDays / 365);
    const remainingDays = differenceInDays % 365;
    return `${years} ${years > 1 ? `${['years', '年', 'tahun'][localLang]}` : `${['year', '年', 'tahun'][localLang]}`} ${['and', '和', 'dan'][localLang]} ${remainingDays} ${remainingDays > 1 ? `${['days', '天', 'hari'][localLang]}` : `${['day', '天', 'hari'][localLang]}`}`;
  } else if (differenceInDays >= 30) {
    const months = Math.floor(differenceInDays / 30);
    const remainingDays = differenceInDays % 30;
    return `${months} ${months > 1 ? `${['months', '月', 'bulan'][localLang]}` : `${['month', '月', 'bulan'][localLang]}`} ${['and', '和', 'dan'][localLang]} ${remainingDays} ${remainingDays > 1 ? `${['days', '天', 'hari'][localLang]}` : `${['day', '天', 'hari'][localLang]}`}` ;
  } else if (differenceInDays > 0) {
    return `${differenceInDays} ${differenceInDays > 1 ? `${['days', '天', 'hari'][localLang]}` : `${['day', '天', 'hari'][localLang]}`} ${['ago', '前', 'lepas'][localLang]}`;
  } else if (differenceInHours >= 1) {
    return `${differenceInHours} ${differenceInHours > 1 ? `${['hours', '小时', 'jam'][localLang]}` : `${['hour', '小时', 'jam'][localLang]}`} ${['ago', '前', 'lepas'][localLang]}`;
  } else {
    const differenceInMinutes = Math.floor(differenceInTime / (1000 * 60));
    return `${differenceInMinutes} ${differenceInMinutes > 1 ? `${['minutes', '分钟', 'minit'][localLang]}` : `${['minute', '分钟', 'minit'][localLang]}`} ${['ago', '前', 'lepas'][localLang]}`;
  }
};

export const timestampToDate = (timestamp) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${month} ${day}, ${year}, ${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const formatFileSize = (sizeInBytes) => {
  if (sizeInBytes < 1024) {
    return sizeInBytes + ' B';
  } else if (sizeInBytes < 1024 * 1024) {
    const sizeInKB = (sizeInBytes / 1024).toFixed(2);
    return sizeInKB + ' KB';
  } else {
    const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
    return sizeInMB + ' MB';
  }
};

export const calculateMatchingPercentage = (arr1, arr2) => {
  if (!arr1?.length || !arr2?.length) {
    return 0;
  }

  const array1 = [...new Set(arr1.map((item) => item.skill))];
  const array2 = [...new Set(arr2.map((item) => item.value))];
  const matchingCount = array1.filter((element) => array2.includes(element))?.length;
  const percentage = Math.round((matchingCount / array2?.length) * 100);
  return percentage;
};

export const getImageFileExtension = (mime) => {
  switch (mime) {
    case 'image/jpeg':
      return 'jpg';
    case 'image/png':
      return 'png';
    case 'image/gif':
      return 'gif';
    default:
      return 'jpg';
  }
};

export const copyToClipboard = async (copyText, msg) => {
  try {
    await navigator.clipboard.writeText(copyText);
    ToastBar.success(msg);
  } catch (error) {
    ToastBar.error('Failed to copy');
    console.error('Failed to copy:', error);
  }
};

export const jobDescriptionLimit = (html, limit) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  const text = tempElement.innerText;
  const words = text.split(' ');
  const truncatedWords = words.slice(0, limit);
  return truncatedWords.join(' ');
};

export const getOptionsByLanguage = (options) => {;
  const localLang = localStorage.getItem('lan');

  return options.map(option => ({
    value: option.value,
    label: option.label[localLang]
  }));
};


export const getLabelByValue = (value, options) => {
  const localLang = localStorage.getItem('lan');
  const option = options.find(option => option.value === value);
  return option ? option.label[localLang] : value;
};
