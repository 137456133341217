import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Datepicker from 'react-tailwindcss-datepicker';

const DashboardHeaderSection = ({ dateRange, setDateRange }) => {
  const lang = useSelector((state) => state.language.lang);
  const dashboardDetails = useSelector((state) => state.employer?.dashboard);

  const handleValueChange = (newValue) => {
    console.log('datePickerValue:', newValue);
    setDateRange(newValue);
  };

  console.log('dashboard: ', dashboardDetails);

  const options = { day: 'numeric', month: 'long', year: 'numeric' };

  return (
    <div className="flex flex-col w-full bg-white p-4 md:p-8 gap-y-8">
      <div className="flex flex-wrap justify-between items-center gap-y-2">
        <div>
          <div className="text-base font-nunito font-medium leading-normal text-oppty-grey-19">
            {lang.companyHereIsYourJob} {new Date(dateRange?.startDate).toLocaleDateString('er-US', options)} -{' '}
            {new Date(dateRange?.endDate).toLocaleDateString('er-US', options)}
          </div>
        </div>
        <div className="w-72">
          <Datepicker
            primaryColor={'indigo'}
            popoverDirection="down"
            value={dateRange}
            onChange={handleValueChange}
            displayFormat="DD-MM-YYYY"
            // containerClassName="relative py-1 text-black flex items-center justify-end"
            // inputClassName="border-2 px-3 py-2 text-black pr-10"
            // toggleClassName={'absolute h-full w-10 h- items-center text-oppty-blue ml-[-32px]'}
            // toggleClassName={'absolute text-oppty-blue mr-5'}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-6">
        <div className="flex-grow border-[1px] border-black flex items-center gap-x-3 p-6">
          <div className="text-5xl font-nunito font-bold leading-normal text-black">
            {dashboardDetails?.newCandidates}
          </div>
          <div className="text-lg font-nunito font-medium leading-normal text-black">
            {lang.companyNewCandidatesToReview}
          </div>
        </div>
        <div className="flex-grow border-[1px] border-black flex items-center justify-between p-6">
          <div className="flex gap-x-3 items-center">
            <div className="text-5xl font-nunito font-bold leading-normal text-black">
              {dashboardDetails?.interviewSchedule}
            </div>
            <div className="text-lg font-nunito font-medium leading-normal text-black">{lang.generalInterviewSchedule}</div>
          </div>
        </div>
        <div className="flex-grow border-[1px] border-black flex items-center gap-x-3 p-6">
          <div className="text-5xl font-nunito font-bold leading-normal text-black">
            {' '}
            {dashboardDetails?.viewsCount}
          </div>
          <div className="text-lg font-nunito font-medium leading-normal text-black">{lang.generalJobView}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeaderSection;
