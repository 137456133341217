import { ReactComponent as Quote } from '../../../assets/icons/quote.svg';
import { StarIcon } from '@heroicons/react/24/outline';

const TestimonialCard = ({ testimonial }) => {
  const hardcodeStart = [1, 2, 3, 4, 5];

  return (
    <div className="w-[420px] py-10 pr-14 border-e-2 border-[#F84A94] text-[#604E56]">
      <div className="flex justify-between items-start">
        <div className="">
          <div className="text-4xl font-bold">{testimonial?.position}</div>
          <div>
          {testimonial?.salutation} {testimonial?.fullName} at <span className="italic font-semibold">{testimonial?.organization}</span>
          </div>
        </div>
        <Quote />
      </div>
      <div className="pt-5 lg:pr-20">{testimonial?.testimonial}</div>
      <div className="flex pt-5">
        {hardcodeStart?.map((star, index) => {
          return <StarIcon key={index} className="w-7 h-7 fill-[#F84A94] stroke-none" />;
        })}
      </div>
    </div>
  );
};

export default TestimonialCard;
