const ItemTile = ({ item, level, isFirstIndex }) => {
  return (
    <div className={`p-7 min-w-56 bg-template9-gray-2 flex flex-col text-base ${isFirstIndex && 'border-t-4 border-template9-orange-1'}`}>
      <span className="font-bold text-white text-lg">{item}</span>
      <span className="font-medium text-sm text-gray-400 mt-1">{level}</span>
    </div>
  );
};

export default ItemTile;
