// export const benefitsData = [
//   { benefitsId: 1, label: 'Competitive Salary', checked: false },
//   { benefitsId: 2, label: 'Childcare Assistance', checked: false },
//   { benefitsId: 3, label: 'Medical Insurance', checked: false },
//   { benefitsId: 4, label: 'Dental Insurance', checked: false },
//   { benefitsId: 5, label: 'THR/ Bonus system', checked: false },
//   { benefitsId: 6, label: 'Period Leave', checked: false },
//   { benefitsId: 7, label: 'Transportation', checked: false },
//   { benefitsId: 8, label: 'Free Lunch', checked: false },
//   { benefitsId: 9, label: 'Free Food/ Snacks', checked: false },
//   { benefitsId: 10, label: 'International Exposure', checked: false },
//   { benefitsId: 11, label: 'Team Building Activity', checked: false },
//   { benefitsId: 12, label: 'Self- development Allowance', checked: false },
//   { benefitsId: 13, label: 'Casual Dress Code', checked: false },
//   { benefitsId: 14, label: 'Company Outings', checked: false },
//   { benefitsId: 15, label: 'Wellness Program', checked: false },
//   { benefitsId: 16, label: 'Employee Discounts', checked: false },
//   { benefitsId: 17, label: 'Gym Membership', checked: false },
//   { benefitsId: 18, label: 'Paid Maternity/ Paternity Leave', checked: false },
//   { benefitsId: 19, label: 'Paid Sick Days', checked: false },
//   { benefitsId: 20, label: 'Pet-friendly Office', checked: false },
//   { benefitsId: 21, label: 'Professional Development', checked: false },
//   { benefitsId: 22, label: 'Recreational Area', checked: false },
//   { benefitsId: 23, label: 'Vacation Time', checked: false }
// ];

export const benefitsData = [
  { benefitsId: 1, value: 'Competitive Salary', label: ['Competitive Salary', '有竞争力的薪水', 'Gaji Kompetitif'], checked: false },
  { benefitsId: 2, value: 'Childcare Assistance', label: ['Childcare Assistance', '育儿援助', 'Bantuan Penjagaan Anak'], checked: false },
  { benefitsId: 3, value: 'Medical Insurance', label: ['Medical Insurance', '医疗保险', 'Insurans Perubatan'], checked: false },
  { benefitsId: 4, value: 'Dental Insurance', label: ['Dental Insurance', '牙科保险', 'Insurans Pergigian'], checked: false },
  { benefitsId: 5, value: 'THR/ Bonus system', label: ['THR/ Bonus system', '节假日/奖金制度', 'Sistem THR/Bonus'], checked: false },
  { benefitsId: 6, value: 'Period Leave', label: ['Period Leave', '例假假期', 'Cuti Haid'], checked: false },
  { benefitsId: 7, value: 'Transportation', label: ['Transportation', '交通', 'Pengangkutan'], checked: false },
  { benefitsId: 8, value: 'Free Lunch', label: ['Free Lunch', '免费午餐', 'Makan Tengahari Percuma'], checked: false },
  { benefitsId: 9, value: 'Free Food/ Snacks', label: ['Free Food/ Snacks', '免费食品/零食', 'Makanan/ Snek Percuma'], checked: false },
  { benefitsId: 10, value: 'International Exposure', label: ['International Exposure', '国际曝光', 'Pendedahan Antarabangsa'], checked: false },
  { benefitsId: 11, value: 'Team Building Activity', label: ['Team Building Activity', '团队建设活动', 'Aktiviti Pembinaan Pasukan'], checked: false },
  { benefitsId: 12, value: 'Self-development Allowance', label: ['Self-development Allowance', '自我发展津贴', 'Elaun Pembangunan Diri'], checked: false },
  { benefitsId: 13, value: 'Casual Dress Code', label: ['Casual Dress Code', '休闲着装规范', 'Kod Pakaian Kasual'], checked: false },
  { benefitsId: 14, value: 'Company Outings', label: ['Company Outings', '公司郊游', 'Lawatan Syarikat'], checked: false },
  { benefitsId: 15, value: 'Wellness Program', label: ['Wellness Program', '健康计划', 'Program Kesihatan'], checked: false },
  { benefitsId: 16, value: 'Employee Discounts', label: ['Employee Discounts', '员工折扣', 'Diskaun Pekerja'], checked: false },
  { benefitsId: 17, value: 'Gym Membership', label: ['Gym Membership', '健身房会员', 'Keahlian Gim'], checked: false },
  { benefitsId: 18, value: 'Paid Maternity/ Paternity Leave', label: ['Paid Maternity/ Paternity Leave', '带薪产假/陪产假', 'Cuti Bersalin/Bersalin Berbayar'], checked: false },
  { benefitsId: 19, value: 'Paid Sick Days', label: ['Paid Sick Days', '带薪病假', 'Hari Sakit Berbayar'], checked: false },
  { benefitsId: 20, value: 'Pet-friendly Office', label: ['Pet-friendly Office', '宠物友好办公室', 'Pejabat Mesra Haiwan'], checked: false },
  { benefitsId: 21, value: 'Professional Development', label: ['Professional Development', '职业发展', 'Pembangunan Profesional'], checked: false },
  { benefitsId: 22, value: 'Recreational Area', label: ['Recreational Area', '休闲区', 'Kawasan Rekreasi'], checked: false },
  { benefitsId: 23, value: 'Vacation Time', label: ['Vacation Time', '休假时间', 'Masa Percutian'], checked: false }
];

// export const workPolicyData = [
//   { workPolicyId: 1, label: 'Remote Work', checked: false },
//   { workPolicyId: 2, label: 'On-Site', checked: false },
//   { workPolicyId: 3, label: 'Hybrid', checked: false }
// ];

export const workPolicyData = [
  { workPolicyId: 1, value: 'Remote Work', label: ['Remote Work', '远程工作', 'Kerja Jauh'], checked: false },
  { workPolicyId: 2, value: 'On-Site', label: ['On-Site', '现场', 'Di Tempat'], checked: false },
  { workPolicyId: 3, value: 'Hybrid', label: ['Hybrid', '混合', 'Hibrid'], checked: false }
];

export const companyBestData = [
  { cultureId: 1, value: 'Innovative', label: ['Innovative', '创新的', 'Inovatif'], checked: false },
  { cultureId: 2, value: 'Compassionate', label: ['Compassionate', '有同情心的', 'Penyayang'], checked: false },
  { cultureId: 3, value: 'Sustainable', label: ['Sustainable', '可持续的', 'Lestari'], checked: false },
  { cultureId: 4, value: 'Vibrant', label: ['Vibrant', '充满活力的', 'Bertenaga'], checked: false },
  { cultureId: 5, value: 'Fast-Paced', label: ['Fast-Paced', '节奏快的', 'Pantas'], checked: false },
  { cultureId: 6, value: 'Structured', label: ['Structured', '有结构的', 'Bersistematik'], checked: false },
  { cultureId: 7, value: 'Collaborative', label: ['Collaborative', '合作的', 'Kolaboratif'], checked: false },
  { cultureId: 8, value: 'International', label: ['International', '国际的', 'Antarabangsa'], checked: false },
  { cultureId: 9, value: 'Flexible', label: ['Flexible', '灵活的', 'Fleksibel'], checked: false },
  { cultureId: 10, value: 'Results-Oriented', label: ['Results-Oriented', '以结果为导向的', 'Berorientasikan Hasil'], checked: false },
  { cultureId: 11, value: 'Transparent', label: ['Transparent', '透明的', 'Telus'], checked: false },
  { cultureId: 12, value: 'Creative', label: ['Creative', '有创意的', 'Kreatif'], checked: false },
  { cultureId: 13, value: 'Strategic', label: ['Strategic', '战略性的', 'Strategik'], checked: false },
  { cultureId: 14, value: 'Industry Leader', label: ['Industry Leader', '行业领导者', 'Peneraju Industri'], checked: false },
  { cultureId: 15, value: 'Efficiency Driven', label: ['Efficiency Driven', '效率驱动的', 'Didorong oleh Kecekapan'], checked: false },
  { cultureId: 16, value: 'Socially Responsible', label: ['Socially Responsible', '有社会责任感的', 'Bertanggungjawab Sosial'], checked: false },
  { cultureId: 17, value: 'Learning Oriented', label: ['Learning Oriented', '学习导向的', 'Berorientasikan Pembelajaran'], checked: false },
  { cultureId: 18, value: 'Agile', label: ['Agile', '敏捷的', 'Lincah'], checked: false },
  { cultureId: 19, value: 'Community-Minded', label: ['Community-Minded', '有社区意识的', 'Berfikir untuk Komuniti'], checked: false },
  { cultureId: 20, value: 'Ethical', label: ['Ethical', '伦理的', 'Beretika'], checked: false }
];

