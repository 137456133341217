import { useSelector } from 'react-redux'
import chro1 from '../../../assets/images/employeechroc1.jpeg'
import chro2 from '../../../assets/images/employeechroc2.jpeg'

const ChroniclesHiring = () => {
  const lang = useSelector((state) => state.language.lang)

  return (
    <div className="flex flex-rows gap-2 mx-[3%] p-7 md:mx-[5%] md:p-20 bg-oppty-blue-3">
      <div className="flex flex-col justify-between items-start w-[30%] md:w-[40%]">
        <div className="roboto-font text-[12px] md:text-[30px] lg:text-[60px] font-normal text-oppty-black-3 w-[80%] md:leading-[40px] lg:leading-[80px] tracking-[2px]">
          {lang.employeeHomeChroniclesHiring}
        </div>
        <button className="w-16 h-4 md:w-44 md:h-10 lg:w-64 lg:h-20 flex-shrink-0 rounded-md md:rounded-[200px] text-white text-[8px] md:text-[15px] lg:text-2xl bg-oppty-black-4">
          {lang.generalVisitBlog}
        </button>
      </div>
      <div className="w-[70%] md:w-[60%] space-y-4 md:space-y-20">
        <div className="flex gap-2">
          <img src={chro1} alt="chro1" className="w-[40%] h-[40%] md:w-[50%] md:h-[50%] lg:w-[270px] lg:h-[180px]" />
          <div className="flex flex-col justify-between">
            <div className="roboto-font text-[6px] md:text-[12px] lg:text-[15px] lg:leading-[25px]">
              {lang.generalInsight}
            </div>
            <div className="max-w-[80%] md:max-w-[90%] lg:max-w-[80%] roboto-font text-[8px] md:text-[15px] lg:text-[30px] leading-[11px] md:leading-[20px] lg:leading-[30px] lg:tracking-[0.64px]">
              How Hybrid and Remote Recruiting Are Disrupting Traditional HR
            </div>
            <div className="roboto-font text-[6px] md:text-[12px] lg:text-[15px] lg:leading-[25px]">
              {lang.generalReadMore}
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <img src={chro2} alt="chro1" className="w-[40%] h-[40%] md:w-[50%] md:h-[50%] lg:w-[270px] lg:h-[180px]" />
          <div className="flex flex-col justify-between">
            <div className="roboto-font text-[6px] md:text-[12px] lg:text-[15px] lg:leading-[25px]">
              {lang.generalNews}
            </div>
            <div className="max-w-[80%] md:max-w-[90%] lg:max-w-[80%] roboto-font text-[8px] md:text-[15px] lg:text-[30px] leading-[11px] md:leading-[20px] lg:leading-[30px] lg:tracking-[0.64px]">
              Charting New Terrain: The Paradigm Shift in Hybrid Recruitment
            </div>
            <div className="roboto-font text-[6px] md:text-[12px] lg:text-[15px] lg:leading-[25px]">
              {lang.generalReadMore}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChroniclesHiring
