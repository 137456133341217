import EducationCard from './educationCard';

const Education = ({ user }) => {
  return (
    <div className="w-full flex flex-col bg-template9-gray-1">
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col w-full gap-y-8 p-10 lg:p-20">
          <div className="text-5xl font-bold mb-5 text-white">Education & Certification</div>
          {user?.educations?.map((education, index) => {
            return <EducationCard key={index} education={education} />;
          })}
          {user?.certifications?.length > 0 && <div className="text-xl font-semibold mt-1 text-white uppercase underline">Certifications</div>}
          {user?.certifications?.map((certification, index) => {
            return <EducationCard key={index} education={certification} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Education;
