import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { applyJob } from '../../actions/userAction';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/24/solid';
import { ReactComponent as OpptyAiIcon } from '../../assets/icons/opptyAi.svg';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { setApplyJobDetails } from '../../actions/jobAction';
import QuestionCard from './questionCard';
import { ToastBar } from '../../common/toastbar';

const publicUrl = process.env.REACT_APP_LINKURL;

const ApplyJobCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [jennaResponse, setJennaResponse] = useState('');
  const [job, setJob] = useState({});

  const lang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.user);
  const jobPre = useSelector((state) => state.job.applyJobDetails);

  useEffect(() => {
    const newQuestions = jobPre?.questions?.map((ques) => {
      return { question: ques?.question, candidateAnswer: null };
    });

    setJob({ ...jobPre, questionsAns: newQuestions });
  }, [jobPre]);

  useEffect(() => {
    const getJob = (event) => {
      if (event.origin === window.location.origin) {
        if (event.data.type && event.data.type === 'JobData') {
          dispatch(setApplyJobDetails(event.data.data));
        }
      }
    };

    window.addEventListener('message', getJob);

    const fetchOpenAiCompletion = async () => {
      try {
        const response = await axios({
          method: 'post',
          url: 'https://api.openai.com/v1/chat/completions',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json'
          },
          data: {
            model: 'gpt-3.5-turbo',
            messages: [
              {
                role: 'user',
                content: `In 100 words, is the candidate with the following profile suitable for 
              the job described below?
              \n\nCandidate's Profile: ${user?.aboutMe}
              \nCandidate's Education: ${user?.educations}
              \nCandidate's Work Experience:${user?.workExperiences}
              \nCandidate's Skills: ${user?.skills} 
              \n\nJob Description: ${job?.jobDescription}, 
              \nJob Responsibiles: ${job?.jobResponsibilities},
              \nJob Requirements: ${job?.jobRequirements},
              \nJob Skills: ${job?.skills}`
              }
            ]
          }
        });
        setJennaResponse(response.data.choices[0].message.content);
        // You could update state or do other actions with response here
      } catch (error) {
        console.error('Error fetching data from OpenAI:', error);
        // Handle error appropriately here
      }
    };

    fetchOpenAiCompletion();

    return () => {
      window.removeEventListener('message', getJob);
    };
  }, []);

  const handleAnswerChange = (index, answer) => {
    const updatedJob = { ...job };
    updatedJob.questionsAns[index].candidateAnswer = answer;
    setJob(updatedJob);
  };

  const handleApplyJobSubmit = () => {
    console.log('appliedJob: ', job);
    if (job?.questionsAns?.length > 0) {
      const hasUnansweredQuestions = job?.questionsAns?.some((question) => question?.candidateAnswer === null);
      if (!hasUnansweredQuestions) dispatch(applyJob(job, navigate));
      else ToastBar.warning('Answer all the questions');
    } else {
      dispatch(applyJob(job, navigate));
    }
  };

  console.log('job: ', job);

  return (
    <div className="flex flex-col min-h-screen justify-start items-start py-10 px-5 md:px-20 xl:px-40 max-w-[1200px] bg-white rounded-xl">
      <div className="text-base text-gray-500 font-semibold pb-8">
        {job?.jobTitle} / {lang.generalApply}
      </div>
      <div className="text-4xl font-bold mb-1">{job?.jobTitle}</div>
      <div className="text-base text-gray-500 font-semibold">{job?.employer?.companyName}</div>
      <div className="border-b-2 w-full my-10"></div>
      <div className="flex flex-col xl:flex-row flex-wrap w-full gap-y-5 xl:gap-y-0">
        <div className="w-full xl:w-1/2 flex flex-col items-start">
          <div className="flex justify-center items-center space-x-3 pb-10">
            <div className="text-2xl font-bold">{lang?.jobYourInformation}</div>
            <PencilIcon className="w-6 h-6 fill-blue-800" />
          </div>
          <div className="text-xl font-bold">{lang?.generalFullName}</div>
          <div className="text-lg font-medium mb-4">
            {user?.firstName} {user?.lastName}
          </div>
          <div className="text-xl font-bold">{lang?.generalEmail}</div>
          <div className="text-lg font-medium mb-4">{user?.email}</div>
          <div className="text-xl font-bold">{lang?.generalContactNumber}</div>
          <div className="text-lg font-medium mb-4">
            +{user?.areaCode}
            {user?.mobile}
          </div>
          <div className="text-xl font-bold">{lang?.jobYourResumePage}</div>
          <div
            className="text-lg font-medium mb-4 underline cursor-pointer"
            onClick={() => window.open(`${publicUrl}/public/${user?.publicId}`, '_blank')}
          >{`${publicUrl}/public/${user?.publicId}`}</div>
          {/* <div className="text-3xl font-bold mb-1">{lang?.jobYourApplicationVideo}</div>
          <div className="text-base font-semibold w-5/6 pb-3">
            {lang?.jobThisEmployerHasRequire} <span className="text-blue-700">{lang?.jobFindOut}</span>
          </div>
          <button type="button" className=" bg-indigo-500 px-10 py-2 rounded-3xl text-white font-semibold mb-10">
            {lang?.jobRecordYourVideo}
          </button> */}
          {job?.questions?.length > 0 && (
            <div>
              <div className="text-xl font-bold">{lang?.jobPleaseAnswer}</div>
              <div className="flex flex-col gap-y-2 pt-2">
                {job?.questions?.map((ques, index) => (
                  <QuestionCard
                    question={ques}
                    key={index}
                    index={index}
                    onChangeHandler={(answer) => handleAnswerChange(index, answer?.value)}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="w-full ml-2 xl:w-[49%] overflow-hidden">
          <div className="flex gap-x-8 border-[1px] border-black p-5 rounded-2xl">
            <div className="w-10 h-10 bg-oppty-yellow rounded-xl">
              <OpptyAiIcon className="w-10 h-10 p-2" />
            </div>
            <div className="flex flex-col gap-y-3">
              <div className="h-10 w-full rounded-3xl bg-oppty-orange-3 flex items-center px-14 font-extrabold text-xl">
                {lang.generalJennasHelp}:
              </div>
              <div className="text-sm">
                {/* Unicorn is a leading tele-comm company, dedicated to delivering innovative solutions and motivated and
                results-oriented Marketing Sales Representative to join us in achieving our ambitious sales targets and
                further strengthening our market presence. Unicorn is a leading tele-comm company, dedicated to
                delivering innovative solutions and motivated and results-oriented Marketing Sales Representative to. */}
                {jennaResponse}
              </div>
              <div className="flex flex-col gap-y-3 gap-x-10">
                <div className="flex justify-between flex-wrap items-center">
                  <div className="text-xl font-bold">{lang.generalPersonalAdvice}</div>
                  <button className="flex items-center gap-x-2 text-oppty-blue font-bold">
                    {lang.generalViewAll} <ArrowUpRightIcon className="w-4 h-4 stroke-2" />
                  </button>
                </div>
                <div className="flex items-center gap-x-5">
                  <div className="w-8 h-8 bg-indigo-100 rounded-xl">
                    <OpptyAiIcon className="w-8 h-8 p-2" />
                  </div>
                  <div>{lang.questionIsYourProfile}</div>
                </div>
                <div className="flex items-center gap-x-5">
                  <div className="w-8 h-8 bg-light-blue-200 rounded-xl">
                    <OpptyAiIcon className="w-8 h-8 p-2" />
                  </div>
                  <div>{lang.questionHowToAce}</div>
                </div>
                <div className="flex items-center gap-x-5">
                  <div className="w-8 h-8 bg-indigo-400 rounded-xl">
                    <OpptyAiIcon className="w-8 h-8 p-2" />
                  </div>
                  <div>{lang.questionWhatAdditionalSkills}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center mt-10">
        <button
          type="button"
          onClick={handleApplyJobSubmit}
          className="bg-black/90 w-full xl:w-96 py-2 text-white font-bold hover:bg-black/70 rounded-md"
        >
          {lang?.generalApplyNow}
        </button>
      </div>
    </div>
  );
};

export default ApplyJobCard;
