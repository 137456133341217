const TermsBodyMalay = () => {
  return (
    <body class="bg-white text-gray-800">
      <div class="container mx-auto p-14">
        <h1 class="text-3xl font-bold mb-4">Terma Perkhidmatan Oppty.ai</h1>
        <p class="text-sm text-gray-600 mb-6">Kemas kini terakhir: 14 Ogos 2024</p>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">1. Penerimaan Terma</h2>
          <p class="text-base leading-relaxed mb-4">
            Dengan mengakses atau menggunakan laman web dan perkhidmatan kami, anda bersetuju untuk mematuhi terma ini
            dan dasar privasi kami. Jika anda tidak bersetuju dengan mana-mana bahagian terma ini, anda tidak boleh
            menggunakan laman web atau perkhidmatan.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">2. Kelayakan</h2>
          <h3 class="text-lg font-medium mb-1">2.1 Pencari Kerja:</h3>
          <p class="text-base leading-relaxed mb-4">
            Untuk mencipta profil pencari kerja atau memohon kerja di Oppty.ai, anda mesti berumur sekurang-kurangnya 18
            tahun. Dengan menggunakan laman web sebagai pencari kerja, anda menyatakan dan menjamin bahawa anda memenuhi
            syarat umur ini dan bahawa mana-mana maklumat yang diberikan dalam profil atau permohonan anda adalah tepat,
            terkini, dan lengkap.
          </p>
          <h3 class="text-lg font-medium mb-1">2.2 Majikan:</h3>
          <p class="text-base leading-relaxed mb-4">
            Majikan dan perekrut yang menggunakan perkhidmatan kami untuk menyiarkan iklan kerja atau mencari calon
            mesti memastikan bahawa mereka mempunyai kuasa untuk mengikat syarikat atau organisasi mereka kepada
            perjanjian yang boleh dikuatkuasakan.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">3. Pendaftaran dan Keselamatan Akaun</h2>
          <h3 class="text-lg font-medium mb-1">3.1 Penciptaan Akaun:</h3>
          <p class="text-base leading-relaxed mb-4">
            Untuk mengakses ciri tertentu perkhidmatan, termasuk iklan kerja dan carian calon, anda mungkin perlu
            mencipta akaun. Anda bersetuju untuk memberikan maklumat yang tepat, terkini, dan lengkap semasa proses
            pendaftaran dan untuk mengemas kini maklumat tersebut jika perlu supaya ia tetap tepat, terkini, dan
            lengkap.
          </p>
          <h3 class="text-lg font-medium mb-1">3.2 Keselamatan Akaun:</h3>
          <p class="text-base leading-relaxed mb-4">
            Anda bertanggungjawab untuk menjaga kerahsiaan maklumat log masuk akaun anda dan untuk semua aktiviti yang
            berlaku di bawah akaun anda. Anda bersetuju untuk memberitahu kami dengan segera tentang sebarang penggunaan
            tidak sah akaun anda atau sebarang pelanggaran keselamatan yang lain.
          </p>
          <h3 class="text-lg font-medium mb-1">3.3 Penamatan Akaun:</h3>
          <p class="text-base leading-relaxed mb-4">
            Kami berhak untuk menggantung atau menamatkan akaun anda jika mana-mana maklumat yang diberikan semasa
            pendaftaran atau selepasnya didapati tidak tepat, palsu, atau mengelirukan, atau jika anda didapati
            melanggar terma-terma ini.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">4. Tabiat Pengguna</h2>
          <h3 class="text-lg font-medium mb-1">4.1 Pencari Kerja:</h3>
          <ul class="list-disc list-inside mb-4">
            <li>Gunakan perkhidmatan hanya untuk tujuan mencari peluang pekerjaan.</li>
            <li>Pastikan semua maklumat dalam profil dan permohonan kerja anda adalah benar, tepat, dan terkini.</li>
            <li>Elakkan memohon pekerjaan bagi pihak orang lain atau berkongsi akaun anda dengan orang lain.</li>
          </ul>
          <h3 class="text-lg font-medium mb-1">4.2 Majikan:</h3>
          <ul class="list-disc list-inside mb-4">
            <li>Gunakan perkhidmatan hanya untuk tujuan sah berkaitan pengambilan dan pekerjaan.</li>
            <li>Siarkan iklan kerja yang menerangkan pekerjaan dan kelayakan yang diperlukan dengan tepat.</li>
            <li>Jangan terlibat dalam amalan diskriminasi dalam iklan kerja atau semasa proses pengambilan.</li>
            <li>
              Jangan meminta pencari kerja untuk menyertai aktiviti yang tidak berkaitan dengan mengisi peluang
              pekerjaan yang sah.
            </li>
          </ul>
          <h3 class="text-lg font-medium mb-1">4.3 Larangan Umum:</h3>
          <ul class="list-disc list-inside mb-4">
            <li>
              Gunakan laman web atau perkhidmatan untuk tujuan yang menyalahi undang-undang atau melanggar mana-mana
              undang-undang atau peraturan tempatan, kebangsaan, atau antarabangsa.
            </li>
            <li>
              Siarkan, sampaikan, atau kongsi apa-apa kandungan yang memfitnah, lucah, obscen, atau boleh dipertikaikan.
            </li>
            <li>Terlibat dalam apa-apa tingkah laku yang merosakkan, penipuan, menipu, atau mengelirukan.</li>
            <li>
              Cuba mengganggu, merosakkan integriti atau keselamatan, atau menyahkunci mana-mana kandungan yang dihantar
              ke atau dari pelayan yang menjalankan laman web.
            </li>
            <li>
              Naik taraf atau sampaikan (atau cuba untuk naik taraf atau sampaikan) virus, perisian hasad, atau apa-apa
              bahan yang mengganggu operasi normal laman web atau perkhidmatan.
            </li>
            <li>
              Kumpulkan, panen, atau dedahkan apa-apa maklumat mengenai pengguna lain, termasuk data peribadi atau
              butiran hubungan, tanpa persetujuan mereka.
            </li>
          </ul>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">5. Iklan Kerja dan Permohonan</h2>
          <h3 class="text-lg font-medium mb-1">5.1 Iklan Kerja:</h3>
          <p class="text-base leading-relaxed mb-4">
            Majikan bertanggungjawab sepenuhnya untuk kandungan iklan kerja mereka. Oppty.ai tidak menjamin ketepatan,
            kelengkapan, atau kesahihan iklan kerja. Kami berhak untuk mengeluarkan mana-mana iklan kerja yang melanggar
            terma ini atau yang dianggap tidak sesuai.
          </p>
          <h3 class="text-lg font-medium mb-1">5.2 Permohonan Kerja:</h3>
          <p class="text-base leading-relaxed mb-4">
            Pencari kerja bertanggungjawab sepenuhnya untuk kandungan permohonan mereka. Oppty.ai tidak menjamin bahawa
            memohon pekerjaan melalui laman web akan menghasilkan temu duga atau pekerjaan.
          </p>
          <h3 class="text-lg font-medium mb-1">5.3 Tiada Jaminan Pekerjaan:</h3>
          <p class="text-base leading-relaxed mb-4">
            Oppty.ai tidak menjamin bahawa pencari kerja akan memperoleh pekerjaan atau bahawa majikan akan mengisi
            kekosongan pekerjaan melalui penggunaan perkhidmatan kami.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">6. Harta Intelek</h2>
          <h3 class="text-lg font-medium mb-1">6.1 Hak Milik:</h3>
          <p class="text-base leading-relaxed mb-4">
            Kandungan, reka bentuk, grafik, dan bahan lain di laman web adalah milik Oppty.ai atau pemberi lesen kami
            dan dilindungi oleh undang-undang harta intelek. Anda bersetuju untuk tidak menyalin, mengedarkan, mengubah,
            atau mencipta karya terbitan daripada kandungan laman web tanpa kebenaran bertulis kami terlebih dahulu.
          </p>
          <h3 class="text-lg font-medium mb-1">6.2 Cap Dagangan:</h3>
          <p class="text-base leading-relaxed mb-4">
            Semua cap dagangan, tanda perkhidmatan, logo, dan nama dagangan yang dipaparkan di laman web adalah milik
            Oppty.ai atau pihak ketiga yang sesuai. Penggunaan mana-mana cap dagangan ini tanpa kebenaran bertulis
            adalah dilarang.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">7. Privasi dan Data Peribadi</h2>
          <p class="text-base leading-relaxed mb-4">
            Kami komited untuk melindungi privasi anda. Sila rujuk Dasar Privasi kami untuk maklumat lanjut mengenai
            bagaimana kami mengumpul, menggunakan, dan melindungi data peribadi anda.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">8. Bayaran dan Pembayaran</h2>
          <p class="text-base leading-relaxed mb-4">
            Kecuali dinyatakan dalam dasar bayaran balik kami, semua yuran adalah tidak boleh dikembalikan.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">9. Perkhidmatan dan Pautan Pihak Ketiga</h2>
          <h3 class="text-lg font-medium mb-1">9.1 Perkhidmatan Pihak Ketiga:</h3>
          <p class="text-base leading-relaxed mb-4">
            Laman web dan perkhidmatan mungkin termasuk pautan ke laman web atau perkhidmatan pihak ketiga
            (“Perkhidmatan Pihak Ketiga”) yang tidak dimiliki atau dikawal oleh Oppty.ai. Kami tidak menyokong atau
            menganggap sebarang tanggungjawab untuk Perkhidmatan Pihak Ketiga.
          </p>
          <h3 class="text-lg font-medium mb-1">9.2 Pautan Pihak Ketiga:</h3>
          <p class="text-base leading-relaxed mb-4">
            Laman web kami mungkin mengandungi pautan ke laman web atau sumber pihak ketiga. Anda mengakui dan bersetuju
            bahawa Oppty.ai tidak bertanggungjawab atau liabil untuk ketersediaan atau ketepatan laman web atau sumber
            tersebut atau kandungan, produk, atau perkhidmatan di atau dari laman web atau sumber tersebut.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">10. Penafian dan Had Liabiliti</h2>
          <h3 class="text-lg font-medium mb-1">10.1 Penafian:</h3>
          <p class="text-base leading-relaxed mb-4">
            Laman web dan perkhidmatan disediakan berdasarkan “seperti sedia ada” dan “seperti tersedia” tanpa sebarang
            jaminan, sama ada secara nyata atau tersirat. Kami tidak menjamin bahawa laman web atau perkhidmatan akan
            tanpa gangguan, tanpa ralat, atau selamat.
          </p>
          <h3 class="text-lg font-medium mb-1">10.2 Had Liabiliti:</h3>
          <p class="text-base leading-relaxed mb-4">
            Sejauh mana yang dibenarkan oleh undang-undang, Oppty.ai dan sekutu-sekutu kami tidak akan bertanggungjawab
            atas sebarang kerosakan tidak langsung, sampingan, khas, akibat, atau punitif, atau sebarang kehilangan
            keuntungan atau pendapatan, sama ada langsung atau tidak langsung, yang timbul daripada penggunaan laman web
            atau perkhidmatan anda.
          </p>
          <h3 class="text-lg font-medium mb-1">10.3 Pampasan:</h3>
          <p class="text-base leading-relaxed mb-4">
            Anda bersetuju untuk mengganti rugi dan memegang Oppty.ai, sekutu-sekutu, pengarah, pegawai, pekerja, dan
            ejen-ejennya tidak terkecuali daripada sebarang tuntutan, liabiliti, kerosakan, kerugian, dan perbelanjaan,
            termasuk yuran peguam yang munasabah, yang timbul daripada penggunaan laman web atau perkhidmatan anda,
            pelanggaran terma ini, atau pelanggaran hak orang lain.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">11. Penamatan</h2>
          <h3 class="text-lg font-medium mb-1">11.1 Penamatan oleh Oppty.ai:</h3>
          <p class="text-base leading-relaxed mb-4">
            Kami boleh menamatkan atau menggantung akses anda ke laman web dan perkhidmatan pada bila-bila masa, dengan
            atau tanpa sebab, dan dengan atau tanpa notis. Kami berhak untuk menolak perkhidmatan kepada sesiapa sahaja
            pada bila-bila masa.
          </p>
          <h3 class="text-lg font-medium mb-1">11.2 Penamatan oleh Anda:</h3>
          <p class="text-base leading-relaxed mb-4">
            Anda boleh menamatkan akaun anda pada bila-bila masa dengan mengikuti arahan di laman web. Setelah
            penamatan, hak anda untuk menggunakan perkhidmatan akan berhenti serta-merta.
          </p>
          <h3 class="text-lg font-medium mb-1">11.3 Kelangsungan:</h3>
          <p class="text-base leading-relaxed mb-4">
            Semua peruntukan yang secara semula jadi perlu terus berkuat kuasa selepas penamatan terma ini akan terus
            terpakai, termasuk tetapi tidak terhad kepada peruntukan hak milik, penafian jaminan, pampasan, dan had
            liabiliti.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">12. Undang-Undang yang Mengawal dan Penyelesaian Pertikaian</h2>
          <h3 class="text-lg font-medium mb-1">12.1 Undang-Undang yang Mengawal:</h3>
          <p class="text-base leading-relaxed mb-4">
            Terma ini akan dikawal dan ditafsirkan mengikut undang-undang Singapura, tanpa mengambil kira prinsip
            konflik undang-undangnya.
          </p>
          <h3 class="text-lg font-medium mb-1">12.2 Penyelesaian Pertikaian:</h3>
          <p class="text-base leading-relaxed mb-4">
            Sebarang pertikaian yang timbul daripada atau berkaitan dengan terma ini atau penggunaan laman web atau
            perkhidmatan akan diselesaikan melalui timbang tara yang mengikat yang dijalankan di Singapura, tetapi
            mana-mana pihak boleh memohon relief injunctive atau relief ekuiti lain di mahkamah yang berkuasa.
          </p>
          <h3 class="text-lg font-medium mb-1">12.3 Tiada Tindakan Kelas:</h3>
          <p class="text-base leading-relaxed mb-4">
            Anda bersetuju bahawa sebarang pertikaian akan diselesaikan berdasarkan individu, dan anda tidak akan
            membawa atau menyertai sebarang tindakan kelas, tindakan konsolidasi, atau tindakan wakil.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">13. Pengubahsuaian Terma</h2>
          <p class="text-base leading-relaxed mb-4">
            Kami berhak untuk mengubah terma ini pada bila-bila masa. Kami akan memberitahu anda mengenai sebarang
            perubahan dengan menyiarkan terma yang dikemas kini di laman web dan mengemas kini tarikh “Kemas Kini
            Terakhir”. Penggunaan berterusan laman web dan perkhidmatan selepas sebarang perubahan menunjukkan
            penerimaan anda terhadap terma yang baru.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">14. Pelbagai</h2>
          <h3 class="text-lg font-medium mb-1">14.1 Keterpisahan:</h3>
          <p class="text-base leading-relaxed mb-4">
            Jika mana-mana peruntukan terma ini didapati tidak sah atau tidak boleh dikuatkuasakan, peruntukan yang
            tinggal akan terus berkuat kuasa sepenuhnya.
          </p>
        </section>
      </div>
    </body>
  );
};

export default TermsBodyMalay;
