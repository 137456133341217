import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const BarChartComponent = ({ data, xLabels, label }) => {
  return (
    <div className="flex flex-col items-center w-full max-w-screen-md h-full rounded-lg">
      <BarChart
        series={[{ data: data, label: label, color: '#4864E1' }]}
        xAxis={[
          {
            data: xLabels,
            scaleType: 'band',
            hideTooltip: true
          }
        ]}
        yAxis={[
          {
            tickLabelStyle: { display: 'none' }
          }
        ]}
        margin={{
          left: 10,
          right: 10
        }}
        slotProps={{ legend: { hidden: true } }}
      />
    </div>
  );
};

export default BarChartComponent;
