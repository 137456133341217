import JobStatistic from './jobStatistics';
import JobsApplicantsStatus from './jobsApplicantsStatus';

const DashboardMiddleSection = () => {
  return (
    <div className="flex flex-wrap justify-between bg-white px-4 md:px-8">
      <div className="w-full lg:w-[69%]">
        <JobStatistic />
      </div>
      <div className="lg:w-[29%]">
        <JobsApplicantsStatus />
      </div>
    </div>
  );
};

export default DashboardMiddleSection;
