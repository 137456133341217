import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EmployerRoute = () => {
  const isLogin = useSelector((state) => state?.employerLogin);
  return isLogin ? <Outlet /> : <Navigate to="/access-denied" />;
};

export default EmployerRoute;
