const PortfolioCard = ({ portfolio }) => {
  return (
    <div className="border-b-[1px] pb-5 pt-3 -mx-10 px-10 border-template3-blue-1">
      <div className="flex flex-col lg:flex-row w-full gap-x-10">
        <div className="w-40 h-40">
          <img
            src={portfolio.image}
            alt="pic"
            className="w-full h-full object-cover rounded-md cursor-pointer"
            onClick={() => window.open(portfolio?.link)}
          />
        </div>
        <div className="mt-1 w-full lg:w-5/6 flex flex-col">
          <div className="text-lg">{portfolio?.highlight}</div>
          <div className="mt-5">
            <button
              onClick={() => window.open(portfolio?.link)}
              className="flex bg-template3-blue-2/20 text-template3-blue-2 hover:bg-template3-blue-2/30 px-5 py-1 font-medium rounded-md text-lg"
            >
              View Project
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioCard;
