import React from 'react';
import { useSelector } from 'react-redux';
import ImageCropper from '../components/imageCrop/imageCropper';

const PortfolioImageUploadModal = ({ closeModal, handleInputImage, setPreviewPortfolioImage }) => {
  const user = useSelector((state) => state.user.user);
  const lang = useSelector((state) => state.language.lang);

  const handleCropComplete = async (imageData) => {
    console.log('imageData: ', imageData);
    const response = await fetch(imageData);
    const blob = await response.blob();

    const formData = new FormData();
    formData.append('file', blob, `portfolioPic_${user?.id}`);

    const fileName = `portfolioPic_${user?.id}`

    const previewBlob = new Blob([formData.get('file')]);
    const imageUrl = URL.createObjectURL(previewBlob);

    console.log(formData)

    handleInputImage(blob, fileName);
    setPreviewPortfolioImage(imageUrl);
    closeModal();
  };
  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-30 w-full">
        <div className="bg-white w-2/3 px-5 md:px-10 py-10 rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold mb-2">{lang.profileUploadPortfolioImage}</h2>
            <button
              type="button"
              onClick={closeModal}
              className="text-lg font-bold hover:bg-red-500 px-2 rounded-md hover:text-white"
            >
              X
            </button>
          </div>
          <ImageCropper imageType="profile" croppedPicDataHandling={handleCropComplete} />
        </div>
      </div>
    </>
  );
};

export default PortfolioImageUploadModal;
