import React from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google'
import { googleCliendId } from '../../validations/validationSchemas'
import { googleEmployerLogin } from '../../actions/employerAction'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../common/spinner'
import { startProcessing, endProcessing } from '../../actions/processingAction'
import { useAuthContext } from '../../context/authContext'


const GoogleEmployerLogin = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { setAuthUser } = useAuthContext();
  const lang = useSelector((state) => state.language.lang)
  const processing = useSelector((state) => state.processing);

  console.log(googleCliendId)

  const handleClick = () => {
    dispatch(startProcessing());
    login();
  }

  const login = useGoogleLogin({

    onSuccess: async (tokenResponse) => {


      console.log(tokenResponse)

      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
        })
        .then((res) => {
          dispatch(googleEmployerLogin(res.data, navigate, setAuthUser))
        })
    },
    // onFailure: (errorResponse) => {
    //   console.log("FAIL");
    //   dispatch(endProcessing());
    // },
    onError: (errorResponse) => console.log(errorResponse)
  })

  return (
    <GoogleOAuthProvider clientId={googleCliendId}>
      <div>
        <button
          onClick={() => handleClick()}
          type="button"
          disabled={processing}
          className="w-full bg-gray-100 text-oppty-black p-2 rounded-md hover:bg-gray-200 flex items-center justify-between"
        >
          {processing ? (<Spinner />) : (
            <>
              <img src="https://www.svgrepo.com/show/475656/google-color.svg" alt="Google" className="h-5 w-5 ml-2" />
              <span className="flex-grow text-center">{lang.loginGoogle}</span>
              <span className="h-5 w-5 mr-2"></span>
            </>
          )}
        </button>
      </div>
    </GoogleOAuthProvider>
  )
}

const GoogleEmployerLoginProvider = () => {
  return (
    <GoogleOAuthProvider clientId={googleCliendId}>
      <GoogleEmployerLogin />
    </GoogleOAuthProvider>
  )
}

export default GoogleEmployerLoginProvider;
