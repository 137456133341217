import WorkExperienceCard from './workExperienceCard';

const WorkExperience = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 border-2 border-template3-blue-1 rounded-xl shadow-md">
      <div className="text-4xl font-bold">Work Experience</div>
      <div className="flex flex-col">
        {user?.workExperiences?.map((work, index) => {
          return <WorkExperienceCard key={index} work={work} />;
        })}
      </div>
    </div>
  );
};

export default WorkExperience;
