import { ReactComponent as Facebook } from '../../../assets/icons/icons8-facebook.svg';
import { ReactComponent as Instagram } from '../../../assets/icons/icons8-insta.svg';
import { ReactComponent as Twitter } from '../../../assets/icons/icons8-twitterx.svg';
import { ReactComponent as Linkedin } from '../../../assets/icons/icons8-linkedin.svg';
import { ReactComponent as Github } from '../../../assets/icons/icons8-github.svg';
import { ReactComponent as Pinterest } from '../../../assets/icons/icons8-pinterest.svg';
import { ReactComponent as Youtube } from '../../../assets/icons/icons8-youtube.svg';
import { ChatBubbleOvalLeftIcon, MapPinIcon } from '@heroicons/react/24/outline';

const HeaderSection = ({ user }) => {
  const handleOpenLink = (link) => {
    console.log('open');

    if (!link.includes('http://') && !link.includes('https://')) {
      link = 'https://' + link;
    }

    window.open(link);
  };

  return (
    <div className="flex flex-col lg:flex-row w-full bg-template5-butter-1 relative">
      <div className="w-full lg:w-1/2 p-7 lg:p-20 flex flex-col justify-between">
        <div>
          <div className="text-3xl lg:text-6xl font-bold text-black">
            {user?.firstName} {user?.lastName}
          </div>
          <div className="text-base lg:text-2xl font-bold text-gray-500 ml-1">{user?.title}</div>
          <div className="flex flex-wrap gap-x-5 items-center mt-2 text-black text-sm lg:text-base">
            {user?.country && (
              <div className="flex gap-x-2 items-center text-lg">
                <MapPinIcon className="w-5 h-5" /> {user?.country}
              </div>
            )}
            {user?.languages?.length > 0 && (
              <div className="flex gap-x-2 items-center">
                <ChatBubbleOvalLeftIcon className="w-5 h-5" /> {user?.languages?.map((lang) => lang?.lang).join(', ')}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-wrap items-center gap-5 mt-10 lg:mt-0">
          <button
            disabled={!user?.resume}
            onClick={() => window.open(user?.resume?.resumeUrl)}
            className="px-6 py-3 disabled:bg-gray-400 bg-template5-blue-1 hover:bg-template5-blue-1/90 hover:shadow-lg text-white text-sm font-semibold"
          >
            {user?.resume ? 'Download CV' : 'No CV Uploaded'}
            </button>
          <div className="flex flex-wrap gap-x-2 items-center">
            {user?.socials?.map((social, index) => {
              return (
                <div key={index}>
                  {social?.social === 'Linkedin' && (
                    <Linkedin
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template5-blue-1 hover:fill-template5-blue-1/90 w-8 h-8 cursor-pointer"
                    />
                  )}
                  {social?.social === 'Facebook' && (
                    <Facebook
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template5-blue-1 hover:fill-template5-blue-1/90 w-8 h-8 cursor-pointer"
                    />
                  )}
                  {social?.social === 'Instagram' && (
                    <Instagram
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template5-blue-1 hover:fill-template5-blue-1/90 w-8 h-8 cursor-pointer"
                    />
                  )}
                  {social?.social === 'X' && (
                    <Twitter
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template5-blue-1 hover:fill-template5-blue-1/90 w-8 h-8 cursor-pointer"
                    />
                  )}
                  {social?.social === 'Github' && (
                    <Github
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template5-blue-1 hover:fill-template5-blue-1/90 w-8 h-8 cursor-pointer"
                    />
                  )}
                  {social?.social === 'Pinterest' && (
                    <Pinterest
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template5-blue-1 hover:fill-template5-blue-1/90 w-8 h-8 cursor-pointer"
                    />
                  )}
                  {social?.social === 'Youtube' && (
                    <Youtube
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template5-blue-1 hover:fill-template5-blue-1/90 w-9 h-9 cursor-pointer"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/2 p-10 lg:p-16 h-full">
        {user?.avatar ? (
          <img src={user?.avatar} alt="pic" className="w-full h-[550px] object-cover rounded-lg" />
        ) : (
          <div className="h-full flex justify-center p-10">
            <svg width="610" height="400" viewBox="0 0 610 502" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.908691 493V232.222C0.908691 228.478 3.22667 225.125 6.72934 223.802L597.824 0.599048C603.711 -1.62389 610.003 2.72616 610.003 9.01875V493C610.003 497.971 605.974 502 601.003 502H9.90869C4.93813 502 0.908691 497.971 0.908691 493Z"
                fill="url(#paint0_linear_0_1)"
              />
              <path
                d="M305.458 203C334.336 203 362.031 193.096 382.451 175.468C402.871 157.84 414.343 133.93 414.343 109C414.343 84.0696 402.871 60.1604 382.451 42.532C362.031 24.9035 334.336 15 305.458 15C276.58 15 248.884 24.9035 228.465 42.532C208.045 60.1604 196.573 84.0696 196.573 109C196.573 133.93 208.045 157.84 228.465 175.468C248.884 193.096 276.58 203 305.458 203ZM266.582 238.25C122.066 238.25 114.906 407.353 114.906 479.689C114.906 491.733 126.22 501.5 140.171 501.5H470.739C484.689 501.5 496.003 491.733 496.003 479.689C496.003 407.353 470.739 238.25 344.333 238.25H266.582Z"
                fill="#E5E4E0"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_0_1"
                  x1="661.502"
                  y1="-53"
                  x2="28.5025"
                  y2="502"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#14256D" />
                  <stop offset="1" stop-color="#5976F9" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderSection;
