import Header from '../../common/header';
import ApplyJobCard from './applyJobCard';
import Footer from '../../common/footer';

const ApplyJob = () => {
  return (
    <div className="flex flex-col min-h-screen items-center">
      <Header />
      <ApplyJobCard />
      <Footer />
    </div>
  );
};

export default ApplyJob;
