import PortfolioCard from './portfolioCard';

const Portfolio = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 border-2 border-template3-blue-1 rounded-xl shadow-md">
      <div className="text-4xl font-bold">Portfolio</div>
      <div className="text-base">Check out my past projects.</div>
      <div className="flex flex-col pt-5">
        {user?.portfolios?.map((portfolio, index) => {
          return <PortfolioCard key={index} portfolio={portfolio} />;
        })}
      </div>
    </div>
  );
};

export default Portfolio;
