import { ReactComponent as Facebook } from '../../../assets/icons/icons8-facebook.svg';
import { ReactComponent as Instagram } from '../../../assets/icons/icons8-insta.svg';
import { ReactComponent as Twitter } from '../../../assets/icons/icons8-twitterx.svg';
import { ReactComponent as Linkedin } from '../../../assets/icons/icons8-linkedin.svg';
import { ReactComponent as Github } from '../../../assets/icons/icons8-github.svg';
import { ReactComponent as Pinterest } from '../../../assets/icons/icons8-pinterest.svg';
import { ReactComponent as Youtube } from '../../../assets/icons/icons8-youtube.svg';
import { ArrowDownTrayIcon, MapPinIcon } from '@heroicons/react/24/outline';

const HeaderSection = ({ user }) => {
  const handleOpenLink = (link) => {
    console.log('open');

    if (!link.includes('http://') && !link.includes('https://')) {
      link = 'https://' + link;
    }

    window.open(link);
  };

  console.log('user: ', user);

  return (
    <div className="relative min-h-[750px] overflow-hidden w-full flex flex-col lg:flex-row">
      <div className="absolute w-4/5 h-full rounded-r-full bg-[#FFF6ED]" />
      <div className="flex flex-col w-full lg:w-4/5 justify-center items-start p-5 lg:p-20 z-10 h-[750px]">
        <div className="flex flex-col text-6xl lg:text-[100px] lg:leading-[100px] font-extrabold">
          <div className="flex text-[#604E56]">Hello I'm</div>
          <div className="flex text-[#F84A94]">{user?.firstName}</div>
        </div>
        <div className="flex flex-wrap gap-x-5 items-center mb-5 text-black text-sm lg:text-base">
            {user?.country && (
              <div className="flex gap-x-2 items-center text-lg font-semibold">
                <MapPinIcon className="w-5 h-5" /> {user?.country}
              </div>
            )}
          </div>
        <div className="w-full" dangerouslySetInnerHTML={{ __html: user?.aboutMe }}></div>
        <button
          disabled={!user?.resume}
          onClick={() => window.open(user?.resume?.resumeUrl)}
          className="flex items-center gap-x-3 mt-3 lg:mt-5 px-6 py-3 disabled:bg-gray-400 disabled:text-white bg-[#F84A94] text-white text-sm font-semibold rounded-2xl"
        >
          {user?.resume ? 'Download CV' : 'No CV Uploaded'}
          <ArrowDownTrayIcon className="w-5 h-5 stroke-2" />
        </button>
      </div>
      <div className="flex w-full lg:w-1/5 -mt-3 lg:mt-0 h-full justify-start lg:justify-end items-start lg:items-center p-5 lg:p-10 z-10 lg:min-h-[750px]">
        <div className="flex flex-row lg:flex-col gap-5 justify-center h-fit lg:py-10 items-center bg-[#FFF6ED] w-fit lg:w-12 rounded-2xl">
          {user?.socials?.map((social, index) => {
            return (
              <div key={index}>
                {social?.social === 'Linkedin' && (
                  <Linkedin
                    onClick={() => handleOpenLink(social.link)}
                    className="fill-pink-500 hover:fill-pink-600 w-8 h-8 cursor-pointer"
                  />
                )}
                {social?.social === 'Facebook' && (
                  <Facebook
                    onClick={() => handleOpenLink(social.link)}
                    className="fill-pink-500 hover:fill-pink-600 w-8 h-8 cursor-pointer"
                  />
                )}
                {social?.social === 'Instagram' && (
                  <Instagram
                    onClick={() => handleOpenLink(social.link)}
                    className="fill-pink-500 hover:fill-pink-600 w-8 h-8 cursor-pointer"
                  />
                )}
                {social?.social === 'Youtube' && (
                  <Youtube
                    onClick={() => handleOpenLink(social.link)}
                    className="fill-pink-500 hover:fill-pink-600 w-8 h-8 cursor-pointer"
                  />
                )}
                {social?.social === 'X' && (
                  <Twitter
                    onClick={() => handleOpenLink(social.link)}
                    className="fill-pink-500 hover:fill-pink-600 w-8 h-8 cursor-pointer"
                  />
                )}
                {social?.social === 'Github' && (
                  <Github
                    onClick={() => handleOpenLink(social.link)}
                    className="fill-pink-500 hover:fill-pink-600 w-8 h-8 cursor-pointer"
                  />
                )}
                {social?.social === 'Pinterest' && (
                  <Pinterest
                    onClick={() => handleOpenLink(social.link)}
                    className="fill-pink-500 hover:fill-pink-600 w-8 h-8 cursor-pointer"
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
