import React from 'react';

const Calender = ({ stroke, className, fill, hoverStroke, isHovered }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill={fill}
      className={className}
    >
      <g clipPath="url(#clip0_968_81)">
        <path
          d="M18 5.43555H6C4.89543 5.43555 4 6.33098 4 7.43555V19.4355C4 20.5401 4.89543 21.4355 6 21.4355H18C19.1046 21.4355 20 20.5401 20 19.4355V7.43555C20 6.33098 19.1046 5.43555 18 5.43555Z"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/schedule' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 3.43555V7.43555"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/schedule' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 3.43555V7.43555"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/schedule' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 11.4355H20"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/schedule' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 15.4355H8V17.4355H10V15.4355Z"
          stroke={isHovered ? hoverStroke : window.location.pathname === '/employer/schedule' ? hoverStroke : stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_968_81">
          <rect width="24" height="24" fill="white" transform="translate(0 0.435547)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Calender;
