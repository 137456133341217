import { getYear } from 'date-fns';

export const monthOptions = [
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
  { value: 'July', label: 'July' },
  { value: 'August', label: 'August' },
  { value: 'September', label: 'September' },
  { value: 'October', label: 'October' },
  { value: 'November', label: 'November' },
  { value: 'December', label: 'December' }
];

const currentYear = getYear(new Date());

const startYear = 1950;
const endYear = currentYear;

export const yearOptions = Array.from({ length: endYear - startYear + 1 }, (_, index) => ({
  value: startYear + index,
  label: `${startYear + index}`
})).reverse();

export const companySizeOptions = [
  { value: '0 - 10', label: '0 - 10' },
  { value: '10 - 50', label: '10 - 50' },
  { value: '50 - 100', label: '50 - 100' },
  { value: '100 - 500', label: '100 - 500' },
  { value: '500 - 1000', label: '500 - 1000' },
  { value: '1000 - 5000', label: '1000 - 5000' },
  { value: '5000 - 10000', label: '5000 - 10000' },
  { value: '10000+', label: '10000+' }
];

export const datePostedOptions = [
  { label: 'Any Time', value: null },
  { label: 'Today', value: 0 },
  { label: 'Last 3 days', value: 2 },
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 14 days', value: 14 },
  { label: 'Last 30 days', value: 30 }
];

export const salaryOptions = [
  { label: '2K', value: 2000 },
  { label: '3K', value: 3000 },
  { label: '4K', value: 4000 },
  { label: '5K', value: 5000 },
  { label: '6K', value: 6000 },
  { label: '7K', value: 7000 },
  { label: '8K', value: 8000 },
  { label: '10K', value: 10000 },
  { label: '15K', value: 15000 },
  { label: '20K', value: 20000 },
  { label: '25K', value: 25000 },
  { label: '30K', value: 30000 },
  { label: '30K+', value: 999 }
];

// export const jobTypeOptions = [
//   { label: 'Full-time', value: 'Full-time' },
//   { label: 'Part-time', value: 'Part-time' },
//   { label: 'Contract', value: 'Contract' },
//   { label: 'Temporary', value: 'Temporary' },
//   { label: 'Freelance', value: 'Freelance' },
//   { label: 'Internship', value: 'Internship' }
// ];

export const jobTypeOptions = [
  { value: 'Full-time', label: ['Full-time', '全职', 'Sepenuh Masa'] },
  { value: 'Part-time', label: ['Part-time', '兼职', 'Separuh Masa'] },
  { value: 'Contract', label: ['Contract', '合同', 'Kontrak'] },
  { value: 'Temporary', label: ['Temporary', '临时', 'Sementara'] },
  { value: 'Freelance', label: ['Freelance', '自由职业', 'Bebas'] },
  { value: 'Internship', label: ['Internship', '实习', 'Latihan'] }
];

export const experienceOptions = [
  { value: 'Minimum 1 Year', label: ['Entry Level', '入门级', 'Tahap Permulaan'] },
  { value: '1 - 3 Years', label: ['Mid Level', '中级', 'Tahap Pertengahan'] },
  { value: '3 - 5 Years', label: ['Senior Level', '高级', 'Tahap Senior'] },
  { value: '5 - 10 Years', label: ['Lead', '领导', 'Ketua'] },
  { value: '10+ Years', label: ['Manager', '经理', 'Pengurus'] }
];
// export const experienceOptions = [
//   { label: 'Entry Level', value: 'Minimum 1 Year' },
//   { label: 'Mid Level', value: '1 - 3 Years' },
//   { label: 'Senior Level', value: '3 - 5 Years' },
//   { label: 'Lead', value: '5 - 10 Years' },
//   { label: 'Manager', value: '10+ Years' }
// ];

export const jobExperienceOption = [
  { label: 'Minimum 1 Year', value: 'Minimum 1 Year' },
  { label: '1 - 3 Years', value: '1 - 3 Years' },
  { label: '3 - 5 Years', value: '3 - 5 Years' },
  { label: '5 - 10 Years', value: '5 - 10 Years' },
  { label: '10+ Years', value: '10+ Years' }
];

export const workPolicyOption = [
  { value: 'Onsite', label: ['Onsite', '现场', 'Di Tempat'] },
  { value: 'Remote', label: ['Remote', '远程', 'Jauh'] },
  { value: 'Hybrid', label: ['Hybrid', '混合', 'Hibrid'] }
];
// export const workPolicyOption = [
//   { label: 'Onsite', value: 'Onsite' },
//   { label: 'Remote', value: 'Remote' },
//   { label: 'Hybrid', value: 'Hybrid' }
// ];

export const currencyTypeOption = [
  { label: 'USD', value: 'USD' },
  { label: 'SGD', value: 'SGD' },
  { label: 'MYR', value: 'MYR' }
];

export const levelOptions = [
  { value: 'Beginner', label: ['Beginner', '初学者', 'Pemula'] },
  { value: 'Intermediate', label: ['Intermediate', '中级', 'Pertengahan'] },
  { value: 'Expert', label: ['Expert', '专家', 'Pakar'] }
];

export const socialOptions = [
  { value: 'Linkedin', label: 'Linkedin' },
  { value: 'Github', label: 'Github' },
  { value: 'Pinterest', label: 'Pinterest' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'X', label: 'X' },
  { value: 'Youtube', label: 'Youtube' }
];

// export const questionOptions = [
//   { value: 'Are you a Singaporean or PR? ', label: 'Are you a Singaporean or PR?' },
//   { value: 'Do you require a visa to work in Singapore?', label: 'Do you require a visa to work in Singapore?' },
//   { value: 'Are you are willing to travel?', label: 'Are you are willing to travel?' }
// ];

export const questionOptions = [
  { value: 'Are you a Singaporean or PR?', label: ['Are you a Singaporean or PR?', '你是新加坡人或永久居民吗？', 'Adakah anda seorang Singapura atau PR?'] },
  { value: 'Do you require a visa to work in Singapore?', label: ['Do you require a visa to work in Singapore?', '你需要签证在新加坡工作吗？', 'Adakah anda memerlukan visa untuk bekerja di Singapura?'] },
  { value: 'Are you willing to travel?', label: ['Are you willing to travel?', '你愿意出差吗？', 'Adakah anda bersedia untuk melakukan perjalanan?'] }
];

export const answerOptions = [
  { value: 'Yes', label: ['Yes', '是', 'Ya'] },
  { value: 'No', label: ['No', '否', 'Tidak'] }
];

// export const answerOptions = [
//   { value: 'Yes', label: 'Yes' },
//   { value: 'No', label: 'No' }
// ];

export const salutationOptions = [
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Ms.', label: 'Ms.' },
  { value: 'Mdm.', label: 'Mdm.' },
  { value: 'Dr.', label: 'Dr.' },
  { value: 'Prof.', label: 'Prof.' },
  { value: 'A/P', label: 'A/P' }
];

export const articleCategoryOptions = [
  { label: 'Accounting', value: 'accounting' },
  { label: 'Advertising', value: 'advertising' },
  { label: 'Brand Management', value: 'brand Management' },
  { label: 'General', value: 'general' },
  { label: 'Marketing', value: 'marketing' },
  { label: 'Public', value: 'public' }
];

export const statusOptions = [
  { value: 'Shortlisted', label: ['Shortlisted', '入围', 'Disenarai Pendek'] },
  { value: 'Not Suitable', label: ['Not Suitable', '不合适', 'Tidak sesuai'] },
  { value: 'Hired', label: ['Hired', '已聘用', 'Diambil Bekerja'] },
  { value: 'Interview', label: ['Interview', '面试', 'Temuduga'] },
  { value: 'Applied', label: ['Applied', '已申请', 'Memohon'] },
  { value: 'Viewed', label: ['Viewed', '已查看', 'Dilihat'] },
  { value: 'Interviewed', label: ['Interviewed', '已面试', 'Telah Ditemuduga'] }
];

export const proficiencyOptions = [
  { value: 'Beginner', label: ['Beginner', '初学者', 'Pemula'] },
  { value: 'Intermediate', label: ['Intermediate', '中级', 'Pertengahan'] },
  { value: 'Advanced', label: ['Advanced', '高级', 'Maju'] },
  { value: 'Fluent', label: ['Fluent', '流利', 'Fasih'] },
  { value: 'Native', label: ['Native', '母语', 'Asli'] }
];

export const salaryTypeOptions = [
  { value: 'monthly', label: ['Monthly', '每月', 'Bulanan'] },
  { value: 'hourly', label: ['Hourly', '每小时', 'Per Jam'] }
];
