import React, { useState } from 'react';

const SkillComponent = ({ skill, percentage }) => {
  const bigCircleRadius = 80;
  const smallCircleRadius = 62;

  const circumference = 2 * (22 / 7) * 80;

  return (
    <main className="grid place-content-center">
      <div className="relative flex items-center justify-center" x-data="{ circumference: 2 * Math.PI * 100 }">
        <svg className="transform -rotate-90 w-48 h-48">
          {/* Bigger circle */}
          <circle
            cx="90"
            cy="90"
            r={bigCircleRadius}
            stroke="currentColor"
            strokeWidth="20"
            fill="transparent"
            strokeDasharray={circumference}
            strokeDashoffset={circumference - (percentage / 100) * circumference}
            className="text-black"
          />
          {/* Smaller circle */}
          <circle
            cx="90"
            cy="90"
            r={smallCircleRadius}
            stroke="none" // No stroke for the smaller circle
            fill="currentColor"
            className="text-template2-orange-1"
          />
        </svg>
        <span className="absolute w-[86px] h-[90px] mt-3 left-12 flex justify-center items-center text-base font-bold text-center">
          {skill?.skill}
        </span>
      </div>
    </main>
  );
};

export default SkillComponent;

// import React, { useState } from 'react';

// const SkillComponent = () => {
//   const [currentSkill, setCurrentSkill] = useState({ title: 'react', percent: 50 });
//   const circumference = 2 * (22 / 7) * 60;

//   return (
//     <main className="grid w-full place-content-center">
//       <div className="flex items-center justify-center" x-data="{ circumference: 2 * 22 / 7 * 100 }">
//         <svg className="transform -rotate-90 w-48 h-48">
//           <circle
//             cx="80"
//             cy="80"
//             r="60"
//             stroke="currentColor"
//             strokeWidth="20"
//             fill="transparent"
//             className="text-transparent"
//           />
//           <circle
//             cx="80"
//             cy="80"
//             r="60"
//             stroke="currentColor"
//             strokeWidth="20"
//             fill="#FEA999"
//             strokeDasharray={circumference}
//             strokeDashoffset={circumference - (currentSkill.percent / 100) * circumference}
//             className="text-blue-500"
//           />
//         </svg>
//         <span className="absolute text-3xl">{`${currentSkill.percent}%`}</span>
//       </div>
//     </main>
//   );
// };

// export default SkillComponent;
