import profileDelete from '../assets/icons/trashCan.svg';

const UploadVideoCard = ({ video, onDelete }) => {
  return (
    <div className="flex justify-between border rounded-md border-gray-300 bg-oppty-grey-17 p-5">
      <div className="flex flex-col">
        <div className="text-base font-semibold">{video?.fileName}</div>
        <div className="text-sm">{video?.fileSize}</div>
      </div>
      <button onClick={onDelete} className="flex justify-center w-8 h-8 p-2 rounded-md hover:bg-red-600">
        <img src={profileDelete} alt="profile icon" className="h-full w-full" />
      </button>
    </div>
  );
};

export default UploadVideoCard;
