import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import share from '../../../assets/images/share.svg';
import { calculateDuration, copyToClipboard, getLabelByValue } from '../../../utils/functions';
import { saveJob } from '../../../actions/jobAction';
import { BookmarkIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { ToastBar } from '../../../common/toastbar';
import { jobTypeOptions, workPolicyOption } from '../../../constant/dropdownConstOptions';

const linkUrl = process.env.REACT_APP_LINKURL;

const JobDetailCard = ({ job, user, setSelectedJob }) => {
  console.log('useruser: ', user);

  const dispatch = useDispatch();
  const lang = useSelector((state) => state.language.lang);

  const alreadySaved = user?.savedJobs?.some((savedJob) => savedJob._id === job._id);

  const handleSaveJob = () => {
    const exists = user?.savedJobs?.some((obj) => obj?._id === job?._id);
    const inputValue = {
      ...job,
      exist: exists
    };
    dispatch(saveJob(inputValue));
  };

  const handleApplyJob = () => {
    if (user?.userId) {
      const childWindow = window.open('/apply-job', '_blank');

      if (childWindow) {
        childWindow.onload = () => {
          const message = { type: 'JobData', data: job };
          childWindow.postMessage(message, window.location.origin);
        };
      } else {
        alert('Failed to open the new window');
      }
    } else {
      ToastBar.warning('You need to login to apply');
    }
  };

  return (
    <>
      <div className="flex flex-col border-[1px] border-black/40 p-7 rounded-t-[4px]">
        <div className='flex justify-between items-start'>
          <div className='flex flex-col'>
            <div className="text-2xl font-nunito font-bold leading-6 text-oppty-black">{job?.jobTitle}</div>
            <div className="text-lg font-nunito font-semibold leading-6 py-1 ">
              <span
                onClick={() => window.open(`/employers/${job?.employer?._id}`, '_blank')}
                className="cursor-pointer text-oppty-blue hover:text-blue-900 "
              >
                {job?.employer?.companyName}
              </span>
            </div>
            <div className="text-base font-nunito font-normal leading-6 text-oppty-black pb-2 mb-10 ">
              {job?.employer?.companyLocation}
            </div>
          </div>
          <div onClick={() => setSelectedJob(null)} className='md:hidden flex items-center text-sm gap-x-1 font-bold cursor-pointer'>
            <ChevronLeftIcon className='w-5 h-5 stroke-2' /> <span>Back</span>
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-3 justify-between">
          <div className="flex flex-col gap-y-1">
            <div className="flex gap-x-4">
              <div className="text-base font-nunito font-normal leading-normal text-oppty-grey-1">
                {calculateDuration(job?.createdDate)}
              </div>
              <div className="text-base font-nunito font-medium leading-normal text-oppty-grey-9">
                {job?.applicants?.length} {lang.generalApplicant}
              </div>
            </div>
            <div className="flex gap-x-4">
              <div className="text-sm font-nunito font-medium leading-normal text-oppty-grey-9">
                {lang.generalJobCategory}
              </div>
              <Link to="" className="text-sm font-nunito font-normal leading-normal text-oppty-blue underline">
                {job?.employer?.companyIndustry}
              </Link>
            </div>
          </div>
          <div className="flex gap-x-4">
            <button
              type="button"
              disabled={job?.isApplied}
              className={`bg-black text-white text-sm font-nunito font-semibold leading-normal px-7 py-2 hover:bg-blue-gray-800 disabled:bg-black/60`}
              onClick={handleApplyJob}
            >
              {job?.isApplied ? lang.generalApplied : lang.generalApplyNow}
            </button>
            <div
              className="w-10 h-10 border-[1px] border-gray-300 p-1.5 hover:bg-orange-100 cursor-pointer"
              onClick={handleSaveJob}
            >
              <BookmarkIcon
                className={`h-full w-full ${alreadySaved && 'fill-orange-800'} stroke-orange-800 stroke-2`}
              />
            </div>
            <img
              src={share}
              onClick={() => copyToClipboard(`${linkUrl}/jobs/${job?._id}`, 'Job URL Copied')}
              alt="share"
              className="h-10 w-10 hover:bg-orange-100 cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col border-black/40 border-b-[1px] border-l-[1px] border-r-[1px] p-7 rounded-b-[4px]">
        <div className="text-4xl font-nunito font-bold leading-6 text-oppty-black pt-4">{lang.generalJobDetails}</div>
        <div className="flex flex-wrap bg-oppty-green-4 py-5 px-10 my-8 gap-4">
          <div className="flex-grow">
            <div className="text-sm font-nunito font-normal leading-[20px] text-oppty-grey-10">
              {lang.generalExperience}
            </div>
            <div className="text-sm font-nunito font-bold leading-normal text-oppty-grey-9">
              {job?.experienceRequired}
            </div>
          </div>
          <div className="flex-grow">
            <div className="text-sm font-nunito font-normal leading-[20px] text-oppty-grey-10">
              {lang.generalJobType}
            </div>
            <div className="text-sm font-nunito font-bold leading-normal text-oppty-grey-9">
              {getLabelByValue(job?.jobEmploymentType, jobTypeOptions)}
            </div>
          </div>
          <div className="flex-grow">
            <div className="text-sm font-nunito font-normal leading-[20px] text-oppty-grey-10">
              {lang.generalEmployerType}
            </div>
            <div className="text-sm font-nunito font-bold leading-normal text-oppty-grey-9">
              {getLabelByValue(job?.workPolicy, workPolicyOption)}
            </div>
          </div>
          <div className="flex-grow">
            <div className="text-sm font-nunito font-normal leading-[20px] text-oppty-grey-10">
              {lang.generalOfferSalary} <span className="font-bold capitalize">({job?.salaryType})</span>
            </div>
            <div className="text-sm font-nunito font-bold leading-normal text-oppty-grey-9">
              {job?.currencyType} {job?.minSalary}{' '}
              <span className={`${job?.maxSalary ? '' : 'hidden'}`}>
                - {job?.currencyType} {job?.maxSalary}
              </span>
            </div>
          </div>
        </div>
        <div className="text-2xl font-nunito font-bold leading-normal text-oppty-grey-9">
          {lang.generalJobDescription}
        </div>
        <div
          className="text-oppty-grey-8 py-4"
          dangerouslySetInnerHTML={{ __html: job?.jobDescription }}
        />
        <div className="text-2xl font-nunito font-bold leading-normal text-oppty-grey-9">
          {lang.generalResponsibilities}
        </div>
        <div className="flex flex-col my-4 gap-y-3" dangerouslySetInnerHTML={{ __html: job?.jobResponsibilities }} />
        <div className="text-2xl font-nunito font-bold leading-normal text-oppty-grey-9">{lang.jobRequirements}</div>
        <div className="flex flex-col my-4 gap-y-3" dangerouslySetInnerHTML={{ __html: job?.jobRequirements }} />
        <div className="text-2xl font-nunito font-bold leading-normal text-oppty-grey-9">{lang.generalSkills}</div>
        <div className="flex flex-wrap pt-5 gap-4 pb-5">
          {job?.skills?.map((skill) => {
            const isSame = user?.skills?.some((userSkill) => userSkill.skill === skill?.value);
            return (
              <div
                className={`px-7 py-2 font-nunito rounded-[60px] text-base ${isSame ? 'bg-green-400 font-bold' : 'bg-oppty-green-2 font-normal'}`}
              >
                {skill.label}
              </div>
            );
          })}
        </div>
        <div className="flex flex-wrap flex-row border-solid border-t-[0.6px] p-5 mt-10 gap-3 items-center justify-center">
          <div className="text-2xl font-nunito font-bold leading-normal text-black">{lang.jobInterested}</div>
          <div className="flex gap-x-4">
            <button
              className="bg-black text-white text-base font-nunito font-semibold leading-normal px-5 py-2 hover:bg-blue-gray-800"
              onClick={() => window.open(`/jobs/${job?._id}`, '_blank')}
            >
              {lang.generalViewDetails}
            </button>
            <button
              className="bg-oppty-grey-11 text-oppty-grey-8 text-base font-nunito font-semibold leading-normal px-5 py-2 hover:bg-gray-400"
              onClick={() => window.open(`/employers/${job?.employer?._id}`, '_blank')}
            >
              {lang.generalCompanyProfile}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetailCard;
