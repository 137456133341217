const EducationCard = ({ education }) => {
  return (
    <div className="flex flex-col border-l-2 border-blue-200 pl-5">
      <div className="text-black text-2xl font-semibold border-l-2 border-blue-600 -ml-[22px] pl-[17px]">
        {education?.qualification} in {education?.course}
      </div>
      <div className="text-gray-400 text-lg font-semibold">{education?.institution}</div>
      <div className="text-base text-black rounded-2xl bg-template8-green-2 w-fit px-4 py-2 mt-2">
        {education?.startMonth} {education?.startYear} -{' '}
        {education?.endYear === 99 ? 'Current' : `${education?.startMonth} ${education?.endYear}`}
      </div>
      {/* <div className="font-normal text-xs text-gray-600 mt-2">{education?.description}</div> */}
      <div className="w-full mt-3" dangerouslySetInnerHTML={{ __html: education?.description }}></div>
    </div>
  );
};

export default EducationCard;
