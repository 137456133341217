import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/headerLogo.svg';
import { getUserForgetPasswordOTP, userVerifyForgetPasswordOTP } from '../../actions/publicAction';

const VerifyForgotPasswordOTP = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { state } = location;

  const email = state?.email;
  const lang = useSelector((state) => state.language.lang);
  const otpData = useSelector((state) => state.public.otpData);

  console.log('email: ', email);

  const [otp, setOtp] = useState(Array(6).fill(''));
  const [loading, setLoading] = useState(false);
  const inputsRef = useRef([]);

  const focusNextInput = (index, value) => {
    const numericValue = value.replace(/[^0-9]/g, '');

    if (numericValue.length === 1 && index < 5) {
      inputsRef.current[index + 1].focus();
    }
    const newOtp = [...otp];
    newOtp[index] = numericValue;
    setOtp(newOtp);
  };

  const focusPrevInput = (index, key) => {
    if (key === 'Backspace' && index > 0 && otp[index] === '') {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleResend = () => {
    dispatch(getUserForgetPasswordOTP({ email: email }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('otp: ', otp.join(''));
    setLoading(true);
    const pin = otp.join('');

    dispatch(userVerifyForgetPasswordOTP({ email, otpData, pin }, navigate, setLoading));
  };

  const isOtpComplete = otp.every(value => value !== '');

  return (
    <div className="h-screen relative">
      <div
        className="absolute left-0 top-0 flex-col justify-center items-center p-10 cursor-pointer"
        onClick={() => navigate('/')}
      >
        <img src={logo} alt="Logo" className="hidden md:block w-36 lg:w-[150px]" />
      </div>
      <div className="flex flex-col h-full justify-center py-12 text-center space-y-12">
        <div className="bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl flex flex-col gap-y-10">
          <div className="flex flex-col items-center justify-center text-center space-y-2">
            <div className="font-semibold text-3xl">{lang.generalOtpVerification}</div>
            <div className="flex flex-row text-sm font-medium text-gray-400">
              <p>
                {lang.generalEmail}: {email}
              </p>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-12">
              <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs space-x-2">
                {Array.from({ length: 6 }, (_, index) => (
                  <div key={index} className="w-16 h-16">
                    <input
                      className="w-full h-full text-center px-4 outline-none rounded-xl border border-gray-400 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                      type="text"
                      maxLength="1"
                      value={otp[index]}
                      onChange={(e) => focusNextInput(index, e.target.value)}
                      onKeyDown={(e) => focusPrevInput(index, e.key)}
                      ref={(el) => (inputsRef.current[index] = el)}
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-col space-y-5">
                <div className="flex justify-between items-center px-2">
                  <div className="flex items-center text-sm font-medium text-gray-500 space-x-1">
                    <p>{lang.verifyDidnt}</p>
                    <button type="button" onClick={handleResend} className="text-oppty-blue">
                      {lang.verifyResend}
                    </button>
                  </div>
                </div>
                <button
                  disabled={!isOtpComplete || loading}
                  type="submit"
                  className="w-full py-5 text-center disabled:bg-gray-400 text-sm font-semibold text-white bg-oppty-blue hover:bg-oppty-blue/90 rounded-xl border-none shadow-sm"
                >
                  {lang.generalVerify}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifyForgotPasswordOTP;
