import axios from 'axios';
import { ToastBar } from '../common/toastbar';

const apiUrl = process.env.REACT_APP_APIURL;
const localLang = localStorage.getItem('lan');

export const FETCH_ADMIN_LOGIN = 'FETCH_ADMIN_LOGIN';
export const FETCH_ADMIN_LOGOUT = 'FETCH_ADMIN_LOGOUT';
export const FETCH_ALL_CANDIDATES = 'FETCH_ALL_CANDIDATES';
export const FETCH_SEARCH_CANDIDATE = 'FETCH_SEARCH_CANDIDATE';
export const FETCH_ALL_EMPLOYERS = 'FETCH_ALL_EMPLOYERS';
export const FETCH_SEARCH_EMPLOYER = 'FETCH_SEARCH_EMPLOYER';
export const FETCH_ADMIN_DASHBOARD = 'FETCH_ADMIN_DASHBOARD';
export const FETCH_ALL_JOBS = 'FETCH_ALL_JOBS';

export const fetchAdminLogin = () => ({
    type: FETCH_ADMIN_LOGIN
});

export const fetchAdminLogout = () => ({
    type: FETCH_ADMIN_LOGOUT
});

export const fetchAdminDashboard = (data) => ({
    type: FETCH_ADMIN_DASHBOARD,
    payload: data
});

export const fetchAllCandidates = (data) => ({
    type: FETCH_ALL_CANDIDATES,
    payload: data
});

export const fetchSearchCandidate = (data) => ({
    type: FETCH_SEARCH_CANDIDATE,
    payload: data
});

export const fetchAllEmployers = (data) => ({
    type: FETCH_ALL_EMPLOYERS,
    payload: data
});

export const fetchSearchEmployer = (data) => ({
    type: FETCH_SEARCH_EMPLOYER,
    payload: data
});

export const fetchAllJobs = (data) => ({
    type: FETCH_ALL_JOBS,
    payload: data
})

export const adminLogin = (values, navigate, setAuthUser) => {
    const inputData = {
        userName: values.userName,
        password: values.password
    };
    console.log(inputData);
    return async (dispatch) => {
        try {
            const response = await axios.post(`${apiUrl}/admin/admin-login`, inputData);
            if (response.status === 200) {
                console.log('login data:', response.data);
                localStorage.setItem('token', response.data.token);
                dispatch(fetchAdminLogin());
                ToastBar.success(response.data.successMsg[0]);
                navigate('/admin-dashboard');
            }
        } catch (error) {
            // Handle error response
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
        }
    };
};

export const adminLogout = (navigate) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(
                `${apiUrl}/admin/admin-logout`,
                {},
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }
            );

            if (response.status === 200) {
                localStorage.removeItem('token');
                dispatch(fetchAdminLogout());
                ToastBar.success(response.data.successMsg[0]);
                navigate('/admin-login');
            }
        } catch (error) {
            // Handle error response
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.message);
            }
        }
    };
};

export const getAdminDashboard = (values) => {
    console.log('input: ', values);
    return async (dispatch) => {
        try {
            const response = await axios.get(`${apiUrl}/admin/get-admin-dashboard`, {
                params: { startDate: values?.startDate, endDate: values?.endDate },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });

            if (response.status === 200) {
                console.log('all candidates: ', response.data);
                dispatch(fetchAdminDashboard(response.data));
                return response.data;
            }
        } catch (error) {
            // Handle error response
            if (error.response && error.response.status === 500) {
                console.log(error.message);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.error(error.response.data.errorMsg[0]);
            }
            return { error: error.message };
        }
    };
};

export const getAllCandidates = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${apiUrl}/admin/get-all-candidates`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });

            if (response.status === 200) {
                console.log('all candidates: ', response.data);
                dispatch(fetchAllCandidates(response.data));
                return response.data;
            }
        } catch (error) {
            // Handle error response
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
            }
            return { error: error.message };
        }
    };
};

export const getSearchCandidate = (email) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${apiUrl}/admin/get-search-candidate`, {
                params: { email },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });

            if (response.status === 200) {
                console.log('Candidate: ', response.data);
                dispatch(fetchSearchCandidate(response.data));
                return response.data;
            }
        } catch (error) {
            // Handle error response
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.warning('No Candidate found');
            }
            return { error: error.message };
        }
    };
};

export const suspendCandidate = (userId) => {
    console.log('input value: ', userId);
    return async (dispatch) => {
        try {
            const response = await axios.post(
                `${apiUrl}/admin/suspend-candidate`,
                { userId },
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }
            );

            if (response.status === 200) {
                dispatch(getSearchCandidate(response.data.user.email));
                ToastBar.success(response.data.successMsg[0]);
                return response.data;
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const activateCandidate = (userId) => {
    console.log('input value: ', userId);
    return async (dispatch) => {
        try {
            const response = await axios.post(
                `${apiUrl}/admin/activate-candidate`,
                { userId },
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }
            );

            if (response.status === 200) {
                dispatch(getSearchCandidate(response.data.user.email));
                ToastBar.success(response.data.successMsg[0]);
                return response.data;
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const getAllEmployers = () => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${apiUrl}/admin/get-all-employers`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });

            if (response.status === 200) {
                console.log('all employers: ', response.data);
                dispatch(fetchAllEmployers(response.data));
                return response.data;
            }
        } catch (error) {
            // Handle error response
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
            }
            return { error: error.message };
        }
    };
};

export const getSearchEmployer = (email) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${apiUrl}/admin/get-search-employer`, {
                params: { email },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });

            if (response.status === 200) {
                console.log('Employer: ', response.data);
                dispatch(fetchSearchEmployer(response.data));
                return response.data;
            }
        } catch (error) {
            // Handle error response
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
                ToastBar.warning('No Employer found');
            }
            return { error: error.message };
        }
    };
};

export const activateEmployer = (employerId) => {
    console.log('input value: ', employerId);
    return async (dispatch) => {
        try {
            const response = await axios.post(
                `${apiUrl}/admin/activate-employer`,
                { employerId },
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }
            );

            if (response.status === 200) {
                dispatch(getSearchEmployer(response.data.employer.email));
                ToastBar.success(response.data.successMsg[0]);
                return response.data;
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const suspendEmployer = (employerId) => {
    console.log('input value: ', employerId);
    return async (dispatch) => {
        try {
            const response = await axios.post(
                `${apiUrl}/admin/suspend-employer`,
                { employerId },
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }
            );

            if (response.status === 200) {
                dispatch(getSearchEmployer(response.data.employer.email));
                ToastBar.success(response.data.successMsg[0]);
                return response.data;
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const unSuspendEmployer = (employerId) => {
    console.log('input value: ', employerId);
    return async (dispatch) => {
        try {
            const response = await axios.post(
                `${apiUrl}/admin/unsuspend-employer`,
                { employerId },
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }
            );

            if (response.status === 200) {
                dispatch(getSearchEmployer(response.data.employer.email));
                ToastBar.success(response.data.successMsg[0]);
                return response.data;
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg);
                ToastBar.error(error.response.data.errorMsg[0]);
            } else {
                console.log(error.message);
                ToastBar.error(error.message);
            }
            return { error: error.message };
        }
    };
};

export const getAllJobs = (title = '') => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${apiUrl}/admin/get-all-jobs`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                params: { title }
            });

            if (response.status === 200) {
                console.log('all jobs: ', response.data);
                dispatch(fetchAllJobs(response.data));
                return response.data;
            }
        } catch (error) {
            // Handle error response
            if (error.response && error.response.status === 500) {
                console.log(error.response.data.errorMsg[0]);
            } else {
                // Handle other errors
                console.log(error.message);
            }
            return { error: error.message };
        }
    };
};

export const resumeJob = (jobId, title) => {
    console.log('input value: ', jobId);
    return async (dispatch) => {
      try {
        const response = await axios.post(`${apiUrl}/admin/resume-job-posting`, { jobId }, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        });
  
        if (response.status === 200) {
          console.log('res: ', response.data);
          dispatch(getAllJobs(title));
          ToastBar.success(response.data.successMsg[0]);
          return response.data;
        }
      } catch (error) {
        // Handle error response
        if (error.response && error.response.status === 500) {
          console.log(error.response.data.errorMsg[0]);
          dispatch(getAllJobs(title));
        } else {
          // Handle other errors
          console.log(error.message);
          ToastBar.error(error.message);
        }
        return { error: error.message };
      }
    };
  };
  
  export const stopJob = (jobId, title) => {
    console.log('input value: ', jobId);
    return async (dispatch) => {
      try {
        const response = await axios.post(`${apiUrl}/admin/stop-job-posting`, { jobId }, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        });
  
        if (response.status === 200) {
          console.log('res: ', response.data);
          dispatch(getAllJobs(title));
          ToastBar.success(response.data.successMsg[0]);
          return response.data;
        }
      } catch (error) {
        // Handle error response
        if (error.response && error.response.status === 500) {
          console.log(error.response.data.errorMsg[0]);
          dispatch(getAllJobs(title));
        } else {
          // Handle other errors
          console.log(error.message);
          ToastBar.error(error.message);
        }
        return { error: error.message };
      }
    };
  };