import Footer from '../../../common/footer';
import Header from '../../../common/header';
import Educations from './educations';
import Experience from './experience';
import GeneralDetails from './generalDetails';
import Languages from './languages';
import Skills from './skills';
import EditProfileHeader from './editProfileHeader';
import { Element } from 'react-scroll';
import UploadCv from './uploadCv';
import SocialMedias from './socialMedias';
import Portfolios from './portfolios';
import Certifications from './certifications';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getCurrentUser } from '../../../actions/userAction';
import UploadVideo from './uploadVideo';
import Template from './template';
import Testimonial from './testimonial';

const EditProfile = () => {
  const dispatch = useDispatch();

  const [profileStrength, setProfileStrength] = useState(0);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  useEffect(() => {
    console.log('user: ', user);
    let newStrength = 0;
    if (user?.title) {
      newStrength += 20;
    }
    if (user?.skills?.length > 0) {
      newStrength += 16;
    }
    if (user?.workExperiences?.length > 0) {
      newStrength += 16;
    }
    if (user?.educations?.length > 0) {
      newStrength += 16;
    }
    if (user?.languages?.length > 0) {
      newStrength += 16;
    }
    if (user?.resume) {
      newStrength += 16;
    }
    setProfileStrength(newStrength);
  }, [user]);

  return (
    <div className="flex flex-col min-h-screen gap-y-4 lg:gap-y-8 justify-centers items-center">
      <Header />
      <div className='flex flex-col min-h-screen gap-y-4 lg:gap-y-8 max-w-[1200px]'>
      <EditProfileHeader profileStrength={profileStrength} />
      <Element name="generalDetails">
        <GeneralDetails />
      </Element>
      <Element name="skills">
        <Skills setProfileStrength={setProfileStrength} />
      </Element>
      <Element name="experience">
        <Experience setProfileStrength={setProfileStrength} />
      </Element>
      <Element name="educations">
        <Educations setProfileStrength={setProfileStrength} />
      </Element>
      <Element name="certifications">
        <Certifications />
      </Element>
      <Element name="portfolios">
        <Portfolios />
      </Element>
      <Element name="languages">
        <Languages setProfileStrength={setProfileStrength} />
      </Element>
      <Element name="socials">
        <SocialMedias />
      </Element>
      <Element name="uploadCv">
        <UploadCv setProfileStrength={setProfileStrength} />
      </Element>
      <Element name="uploadVideo">
        <UploadVideo />
      </Element>
      <Element name="selectTemplate">
        <Template />
      </Element>
      <Element name="testimonial">
        <Testimonial />
      </Element>
      </div>
      <Footer />
    </div>
  );
};

export default EditProfile;
