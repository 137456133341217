const SkillTile = ({ item, level }) => {
  return (
    <div className="px-7 py-2.5 bg-template8-green-1 rounded-3xl flex flex-col w-fit text-lg text-black">
      <span className="font-bold text-black text-lg">{item}</span>
      <span className="font-medium text-sm text-gray-500">{level}</span>
    </div>
  );
};

export default SkillTile;
