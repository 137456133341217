import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateApplicantJobStatus } from '../../actions/jobAction';
import { getLabelByValue } from '../../utils/functions';
import { statusOptions } from '../../constant/dropdownConstOptions';

const StatusConfirmationModal = ({ applicant, closeModal, status }) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language.lang);

  const onSubmit = async () => {
    const value = {
      applicant: applicant,
      newStatus: status
    };
    const result = await dispatch(updateApplicantJobStatus(value));
    if (result && !result.error) {
      closeModal();
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-30">
        <div className="bg-white flex flex-col w-[400px] px-5 md:px-10 py-10 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold text-center">
            {lang.generalAreYouSure} <span className="font-bold text-purple-600">{getLabelByValue(status, statusOptions)} ?</span>
          </h2>

          <div className="flex justify-center items-center gap-x-5 py-5">
            <button
              onClick={onSubmit}
              className="text-lg font-bold hover:bg-green-500 border-green-500 border-2 w-28 py-1 rounded-md text-green-500 hover:text-white"
            >
              {lang.generalYes}
            </button>
            <button
              onClick={closeModal}
              className="text-lg font-bold hover:bg-red-500 border-red-500 border-2 w-28 py-1 rounded-md text-red-500 hover:text-white"
            >
              {lang.generalNo}
              </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusConfirmationModal;
