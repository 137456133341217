import MyPortfolioSection from './MyPortfolioSection';
import AboutMeSection from './aboutMeSection';
import EducationSection from './educationSection';
import HeaderSection from './headerSection';
import Testimonial from './testimonial';
import Video from './video';
import WorkExperience from './workExperience';

const Template2 = ({ user }) => {
  return (
    <div className="flex flex-col w-full h-full bg-[#FAF7EF] font-raleway overflow-hidden">
      {user?.firstName && <HeaderSection user={user} />}
      <AboutMeSection user={user} />
      {(user?.educations?.length > 0 || user?.certifications?.length > 0) && <EducationSection user={user} />}
      {user?.workExperiences?.length > 0 && <WorkExperience user={user} />}
      {user?.portfolios?.length > 0 && <MyPortfolioSection user={user} />}
      {user?.testimonial?.length > 0 && <Testimonial user={user} />}
      {user?.video && <Video user={user} />}
    </div>
  );
};

export default Template2;
