import { useSelector } from 'react-redux';
import { ReactComponent as Ellipsis } from '../../assets/icons/ellipsis.svg';
// import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg';
// import { ReactComponent as Dribble } from '../../assets/icons/dribbble.svg';
// import { ReactComponent as Linkedin } from '../../assets/icons/linkedin.svg';
// import { ReactComponent as Youtube } from '../../assets/icons/youtube.svg';
import { ReactComponent as Pinterest } from '../../assets/icons/icons8-pinterest.svg';
import { ReactComponent as Linkedin } from '../../assets/icons/icons8-linkedin.svg';
import { ReactComponent as Github } from '../../assets/icons/icons8-github.svg';
import { ReactComponent as Facebook } from '../../assets/icons/icons8-facebook.svg';
import { ReactComponent as Instagram } from '../../assets/icons/icons8-insta.svg';
import { ReactComponent as TwitterX } from '../../assets/icons/icons8-twitterx.svg';
import { ReactComponent as Youtube } from '../../assets/icons/icons8-youtube.svg';
import { useState } from 'react';
import SocialsModal from './socialsModal';

const Socials = () => {
  const [socialModal, setSocialModal] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.employer);

  return (
    <div className="flex flex-col w-full px-5 py-6 bg-white rounded-md shadow-md">
      <div className="flex justify-between items-center pb-3">
        <div className="text-xl font-nunito font-semibold leading-normal text-center text-black">
          {lang.companySocials}
        </div>
        <div onClick={() => setSocialModal(true)} className="hover:shadow-lg cursor-pointer">
          <Ellipsis />
        </div>
      </div>
      <div className="flex flex-col gap-y-3">
        {employer?.socials?.map((social, index) => (
          <div className="h-9 flex bg-oppty-grey-17 rounded-xl items-center gap-x-3" key={index}>
            <div className="h-9 w-9 rounded-xl">
              {/* <Facebook className="w-full h-full" /> */}
              {social.social === 'Linkedin' && <Linkedin className="fill-blue-800 w-full h-full" />}
              {social.social === 'Github' && <Github className="fill-black w-full h-full" />}
              {social.social === 'Pinterest' && <Pinterest className="fill-red-800 w-full h-full" />}
              {social.social === 'Facebook' && <Facebook className="fill-blue-800 w-full h-full" />}
              {social.social === 'Instagram' && <Instagram className="fill-red-800 w-full h-full" />}
              {social.social === 'X' && <TwitterX className="fill-black w-full h-full" />}
              {social.social === 'Youtube' && <Youtube className="fill-black w-full h-full" />}
            </div>
            <a
              href={social?.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm font-nunito font-medium leading-normal text-black hover:text-blue-500"
            >
              {social?.link.length > 20 ? `${social.link.substring(0, 18)}...` : social.link}
            </a>
          </div>
        ))}
        {employer?.socials?.length <= 0 && (
          <div className="font-semibold text-sm text-center">{lang.companyAddSocial}</div>
        )}
      </div>
      {socialModal && <SocialsModal employer={employer} closeModal={() => setSocialModal(false)} />}
    </div>
  );
};

export default Socials;
