import { useSelector } from 'react-redux'
import JobCard from '../../../common/jobCard'

const MostRecentJobs = () => {
  const lang = useSelector((state) => state.language.lang)
  const recentJobs = useSelector((state) => state.public?.recentJobs) || []

  const trends = ['Sales', 'Customer Service', 'Marketing', 'Audit', 'Software']

  console.log('recent jobs: ', recentJobs)

  return (
    <div className="flex flex-col justify-start items-center w-full pt-8 md:pt-0 px-8 md:px-10">
      <div className="text-black text-center font-bold font-serif text-[20px] md:text-[55px] md:leading-[120%] md:tracking-[0.55px]">
        {lang.recentJobsTitlePart1} <span className=" text-oppty-orange-2">{lang.recentJobsTitleRecent}</span> {lang.recentJobsTitlePart2}
      </div>
      <div className="text-gray-400 text-center font-normal font-nunito md:text-[20px] md:leading-[160%] tracking-[0.55px] pb-5 md:pb-10">
        {lang.clientHereAreSome}
      </div>
      {/* <div className="flex flex-wrap items-center justify-center">
        <div className="border-2 bg-oppty-blue text-white font-plusJakarta text-xs md:text-xl font-semibold leading-5 py-1 md:py-6 px-4 md:px-8 mx-2 my-1 rounded-[10px] md:rounded-[20px]">
          {lang.generalNew}
        </div>
        {trends.map((trend, index) => (
          <div
            key={index}
            className="border-2 border-oppty-blue text-oppty-blue font-plusJakarta text-xs md:text-xl font-semibold leading-5 py-1 md:py-6 px-4 md:px-8 mx-2 my-1 rounded-[10px] md:rounded-[20px]"
          >
            {trend}
          </div>
        ))}
      </div> */}
      <div className="flex flex-wrap gap-4 py-8 md:pb-20 justify-center">
        {recentJobs.map((job, index) => {
          return (
            <JobCard
              key={index}
              logo={job?.employer?.companyLogo}
              organization={job?.employer?.companyName}
              title={job?.jobTitle}
              time={job?.createdDate}
              description={job?.jobDescription}
              id={job?._id}
            />
          )
        })}
      </div>
    </div>
  )
}

export default MostRecentJobs
