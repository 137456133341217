import TestimonialCard from './testimonialCard';

const Testimonial = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-5 lg:p-10 border-2 border-template3-blue-1 rounded-xl shadow-md">
      <div className="text-4xl font-bold">Testimonials</div>
      <div className="flex flex-wrap w-full gap-5 justify-center mt-10">
        {user?.testimonial?.map((testimonial, index) => {
          return <TestimonialCard key={index} testimonial={testimonial} />;
        })}
      </div>
    </div>
  );
};

export default Testimonial;
