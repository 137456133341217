import { Link } from 'react-router-dom';
import Header from '../../common/header';

const Employers = () => {
  const allEmployers = [
    { name: 'OppTy', employersRange: '10-50', location: 'Japan' },
    { name: 'Barclays', employersRange: '100-1000', location: 'Singapore' }
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div>All Employers</div>
      <div>
        {allEmployers.map((employer) => {
          return (
            <div>
              <Link to={employer.name}>{employer.name}</Link>
              <div>Location: {employer.location}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Employers;
