import { useDispatch, useSelector } from 'react-redux';
import LeftNavigationBar from '../../common/leftNavigationBar';
import MessageContainer from './messageContainer';
import Sidebar from './sideBar';
import { updateLeftNavBarToggleStatus } from '../../actions/generalAction';
import EmployerHeader from '../../common/employerHeader';
import { useState } from 'react';

const MessageHome = () => {
  const dispatch = useDispatch();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isCollapsed = useSelector((state) => state?.general?.leftNavBarToggle);
  const selectedConversation = useSelector((state) => state.message.selectedConversation);

  const toggleNavbar = () => dispatch(updateLeftNavBarToggleStatus({ leftNavBarToggle: !isCollapsed }));
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    dispatch(updateLeftNavBarToggleStatus({ leftNavBarToggle: false }));
  };
  return (
    // <div className='flex sm:h-[450px] md:h-[550px] rounded-lg overflow-hidden bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-0'>
    // 	<Sidebar />
    // 	<MessageContainer />
    // </div>
    <div className="flex flex-row min-h-screen gap-y-8 bg-white">
      <div
        className={`${
          isMobileMenuOpen ? 'block' : 'hidden lg:block'
        } fixed z-50 w-60 bg-white shadow-lg lg:w-60 lg:bg-transparent lg:shadow-none lg:block transition-transform duration-300`}
        style={{ transform: isMobileMenuOpen ? 'translateX(0)' : 'translateX(-100%) lg:translateX(0)' }}
      >
        <LeftNavigationBar
          isCollapsed={isCollapsed}
          toggleNavbar={toggleNavbar}
          toggleMobileMenu={toggleMobileMenu}
          isMobileMenuOpen={isMobileMenuOpen}
        />
      </div>

      <div
        className={`flex flex-col flex-grow min-h-screen
         ${isCollapsed ? 'ml-0 lg:ml-16' : 'lg:ml-60'}`}
      >
        <div className={`flex w-full fixed top-0 left-0 ${isCollapsed ? 'pl-0 lg:pl-16' : 'lg:pl-60'} z-40`}>
          <EmployerHeader toggleMobileMenu={toggleMobileMenu} />
        </div>
        <div className="flex justify-start px-0 lg:px-5 py-3 max-w-[1000px] mt-16 lg:mt-24 h-[calc(100vh-105px)] rounded-lg overflow-hidden bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-0">
          <div
            className={`${selectedConversation ? 'hidden lg:flex' : 'flex w-screen lg:w-fit p-5 lg:p-0 h-screen lg:h-full'}`}
          >
            <Sidebar />
          </div>
          <div className={`${selectedConversation ? 'w-full flex p-5 lg:p-0' : 'hidden lg:flex'}`}>
            <MessageContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MessageHome;
