import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const BarChartComponent = ({ data, xLabels }) => {
  return (
    <div className="flex flex-col items-center w-full max-w-screen-md h-full rounded-lg">
      <BarChart
        series={data?.map((job, index) => ({
          data: job?.jobs,
          label: job?.title,
          id: index,
          stack: 'total',
          valueFormatter: (value) => (value === 0 ? null : value)
        }))}
        xAxis={[
          {
            data: xLabels,
            scaleType: 'band',
            hideTooltip: true
          }
        ]}
        yAxis={[
          {
            tickLabelStyle: { display: 'none' }
          }
        ]}
        margin={{
          left: 10,
          right: 10
        }}
        slotProps={{ legend: { hidden: true } }}
      />
    </div>
  );
};

export default BarChartComponent;
