import axios from 'axios';
import { ToastBar } from '../common/toastbar';

const apiUrl = process.env.REACT_APP_APIURL;
const localLang = localStorage.getItem('lan');

export const FETCH_PUBLIC_PROFILE_SUCCESS = 'FETCH_PUBLIC_PROFILE_SUCCESS';

export const fetchPublicProfileSuccess = (data) => ({
  type: FETCH_PUBLIC_PROFILE_SUCCESS,
  payload: data
});

export const getPublicProfile = (publicId, setLoader) => {
  console.log('publicId param: ', publicId);
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/public/get-public-profile`, {
        params: { publicId: publicId }
      });

      if (response.status === 200) {
        console.log('res: ', response.data);
        dispatch(fetchPublicProfileSuccess(response.data));
        setLoader(false);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const submitTestimonial = (values, navigate, publicId) => {
  console.log('values param: ', values);
  console.log('publicId param: ', publicId);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/public/submit-testimonial`, values, {
        params: { publicId: publicId }
      });

      if (response.status === 200) {
        console.log('res: ', response.data);
        ToastBar.success(response.data?.successMsg[localLang]);
        navigate('/');
        // dispatch(fetchPublicProfileSuccess(response.data));
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data?.errorMsgMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};
