import { useSelector } from 'react-redux';
import star from '../../assets/icons/star.svg';
import suiteCase from '../../assets/icons/jobSuitcase.svg';
import remote from '../../assets/icons/world.svg';
import expLevel from '../../assets/icons/parent.svg';
import onSite from '../../assets/icons/building.svg';
import { useNavigate } from 'react-router-dom';
import { getLabelByValue } from '../../utils/functions';
import { jobTypeOptions, workPolicyOption } from '../../constant/dropdownConstOptions';

const AvailableJobCard = ({ job, location }) => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.language.lang);

  return (
    <div className="flex flex-col border p-4 rounded-xl">
      <div className="flex items-center">
        <div className="w-5/6 flex items-center gap-x-5">
          <div className="w-13 h-13 bg-oppty-pink-3 rounded flex justify-center items-center p-3">
            <img src={star} alt="star" />
          </div>
          <div className="flex flex-col gap-y-1">
            <div className="text-[22px] font-nunito font-bold text-black">{job?.jobTitle}</div>
            <div className="text-base font-nunito font-normal text-black/60">
              {job?.employer?.companyLocation || (location && location)}
            </div>
          </div>
        </div>
        <div className="w-1/6 flex justify-end">
          <button
            type="button"
            className="py-3 px-10 bg-black rounded text-white text-sm font-bold"
            // onClick={() => navigate(`/jobs/${job._id}`)}
            onClick={() => window.open(`/jobs/${job._id}`, '_blank')}
          >
            {lang.generalApplyNow}
          </button>
        </div>
      </div>
      <div
        className="font-nunito text-black/80 py-8 pl-5"
        dangerouslySetInnerHTML={{ __html: job?.jobDescription }}
      ></div>
      <div className="flex gap-x-4">
        <div className="flex gap-x-8 px-4 py-2 bg-oppty-grey-4 rounded-[60px] items-center">
          <img src={suiteCase} alt="jobType" className="w-6 h-6" />
          <div>{getLabelByValue(job?.jobEmploymentType, jobTypeOptions)}</div>
        </div>
        <div className="flex gap-x-8 px-4 py-2 bg-oppty-grey-4 rounded-[60px] items-center">
          <img src={job.employeeType === 'Remote' ? remote : onSite} alt="employeeType" className="w-6 h-6" />
          <div>{getLabelByValue(job?.workPolicy, workPolicyOption)}</div>
        </div>
        {/* <div className="flex gap-x-8 px-4 py-2 bg-oppty-grey-4 rounded-[60px] items-center">
          <img src={expLevel} alt="expLevel" className="w-6 h-6" />
          <div>Senior</div>
        </div> */}
      </div>
    </div>
  );
};

export default AvailableJobCard;
