import PortfolioCard from './portfolioCard';

const Portfolio = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-20 bg-white justify-center items-center relative">
      <div className='absolute bg-[#FFF6ED] w-full h-1/2 bottom-0'/>
      <div className="text-[#604E56] text-center pb-10">
        <div className="text-5xl md:text-[90px] leading-none font-extrabold">Portfolio</div>
        <div className="text-lg"> See my beautiful works.</div>
      </div>
      <div className="flex flex-wrap w-full gap-8 justify-center">
        {user?.portfolios?.map((portfolio, index) => {
          return <PortfolioCard key={index} portfolio={portfolio} />;
        })}
      </div>
    </div>
  );
};

export default Portfolio;
