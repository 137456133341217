import barclyas from '../assets/employersLogo/barclays.png';
import vector1 from '../assets/images/vector.png';
import vector2 from '../assets/images/vector2.png';
import vector3 from '../assets/images/vector3.png';
import vector4 from '../assets/images/vector4.png';
import vector5 from '../assets/images/vector5.png';

import {
  FETCH_EMPLOYER_LOGIN,
  FETCH_EMPLOYER_LOGOUT,
  FETCH_EMPLOYER_REQUEST,
  FETCH_EMPLOYER_SUCCESS,
  FETCH_EMPLOYER_INIT,
  FETCH_EMPLOYER_BY_ID,
  FETCH_ALL_INTERVIEWS,
  FETCH_DASHBOARD_DETAILS,
  FETCH_SEARCH_CANDIDATES
} from '../actions/employerAction';

const initialState = {
  loader: false,
  employerId: '',
  companyName: '',
  email: '',
  postal: '',
  mobile: '',
  areaCode: '',
  address: '',
  companyDescription: '',
  companyIndustry: '',
  companyLocation: '',
  companySize: '',
  companyWebsite: '',
  companyLogo: '',
  currentlyHiring: false,
  benefits: [],
  workPolicy: [],
  culture: [],
  role: '',
  avatar: '',
  socials: [],
  dashboard: {},
  jobs: [],
  interviews: [],
  searchCandidate: [],
  isNewLogin: null
};

const initialLoginState = false;

const employerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYER_REQUEST:
      return {
        ...state,
        loader: true
      };
    case FETCH_EMPLOYER_SUCCESS:
      return {
        ...state,
        employerId: action.payload?.employer._id,
        companyName: action.payload?.employer.companyName,
        email: action.payload?.employer.email,
        areaCode: action.payload?.employer?.areaCode,
        mobile: action.payload?.employer?.mobile,
        currentlyHiring: action.payload?.employer?.currentlyHiring,
        address: action.payload?.employer?.address,
        companyDescription: action.payload?.employer.companyDescription,
        companyIndustry: action.payload?.employer.companyIndustry,
        companyLocation: action.payload?.employer.companyLocation,
        postal: action.payload?.employer?.postal,
        companySize: action.payload?.employer.companySize,
        companyWebsite: action.payload?.employer.companyWebsite,
        companyLogo: action.payload?.employer.companyLogo,
        benefits: action.payload?.employer.benefits,
        workPolicy: action.payload?.employer.workPolicy,
        culture: action.payload?.employer.culture,
        role: action.payload?.employer.role,
        isNewLogin: action.payload?.isNewLogin,
        jobs: action.payload?.employer.jobs,
        images: action.payload?.employer.images,
        socials: action.payload?.employer.socials
      };
    case FETCH_EMPLOYER_INIT:
      return {
        ...state,
        employerId: '',
        companyName: '',
        email: '',
        role: ''
      };
    case FETCH_ALL_INTERVIEWS:
      return {
        ...state,
        interviews: action.payload?.jobsWithInterviews
      };
    case FETCH_DASHBOARD_DETAILS:
      return {
        ...state,
        dashboard: action.payload?.dashboardData
      };

    case FETCH_SEARCH_CANDIDATES:
      return {
        ...state,
        searchCandidate: action.payload?.users
      };

    default:
      return state;
  }
};

// export const employerLoginReducer = (state = initialLoginState, action) => {
//   switch (action.type) {
//     case FETCH_EMPLOYER_LOGIN:
//       return {
//         ...state,
//         isEmployerLogin: true
//       }

//     case FETCH_EMPLOYER_LOGOUT:
//       return {
//         ...state,
//         isEmployerLogin: false
//       }

//     default:
//       return state
//   }
// }

export const employerLoginReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYER_LOGIN:
      return true;

    case FETCH_EMPLOYER_LOGOUT:
      return false;

    default:
      return state;
  }
};

export default employerReducer;

const selectedEmployerInitialState = {
  hardcodeProfile: [barclyas],
  hardCodeImage: [vector1, vector2, vector3, vector4, vector5],
  employer: {},
  events: [
    {
      title: 'Meet & Greet',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam...Lorem ipsum dolor sit amet, consectetur adipiscing elit ipsum dolor sit amet ipsum dolor sit consectetur aliquam... '
    },
    {
      title: 'Open House',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam...Lorem ipsum dolor sit amet, consectetur adipiscing elit ipsum dolor sit amet ipsum dolor sit consectetur aliquam... '
    },
    {
      title: 'How to Apply for Summer Programme',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam...Lorem ipsum dolor sit amet, consectetur adipiscing elit ipsum dolor sit amet ipsum dolor sit consectetur aliquam... '
    },
    {
      title: 'Weekly Meeting',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam...Lorem ipsum dolor sit amet, consectetur adipiscing elit ipsum dolor sit amet ipsum dolor sit consectetur aliquam... '
    },
    {
      title: 'Open House',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam...Lorem ipsum dolor sit amet, consectetur adipiscing elit ipsum dolor sit amet ipsum dolor sit consectetur aliquam... '
    },
    {
      title: 'How to Apply for Summer Programme',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam...Lorem ipsum dolor sit amet, consectetur adipiscing elit ipsum dolor sit amet ipsum dolor sit consectetur aliquam... '
    },
    {
      title: 'Weekly Meeting',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam...Lorem ipsum dolor sit amet, consectetur adipiscing elit ipsum dolor sit amet ipsum dolor sit consectetur aliquam... '
    }
  ]
};

export const selectedEmployerReducer = (state = selectedEmployerInitialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYER_BY_ID:
      return {
        ...state,
        employer: action.payload?.employer
      };

    default:
      return state;
  }
};
