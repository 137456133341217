// import { Footer } from "flowbite-react"
// import Header from "../../common/header"
// import TermsBodyEnglish from "./termsBodyEnglish"
// import TermsBodyChinese from "./termsBodyChinese";

// const TermsOfService = () => {
//   const localLang = parseInt(localStorage.getItem('lan'), 10) || 0;
//   console.log('localLang:', localLang)

//   return(
//     <div className="flex flex-col min-h-screen bg-white">
//       <Header />
//       {localLang === 0 && <TermsBodyEnglish /> }
//       {localLang === 1 && <TermsBodyChinese /> }
//       {localLang === 2 && <TermsBodyEnglish /> }
//       <Footer />
//     </div>
//   )
// }

// export default TermsOfService

import React, { useState, useEffect } from 'react';
import Header from '../../common/header';
import TermsBodyEnglish from './termsBodyEnglish';
import TermsBodyChinese from './termsBodyChinese';
import TermsBodyMalay from './termsBodyMalay';
import Footer from '../../common/footer';

const TermsOfService = () => {
  const [localLang, setLocalLang] = useState(parseInt(localStorage.getItem('lan'), 10) || 0);

  useEffect(() => {
    const handleLocalLangUpdate = () => {
      const updatedLang = parseInt(localStorage.getItem('lan'), 10) || 0;
      setLocalLang(updatedLang);
    };

    const intervalId = setInterval(handleLocalLangUpdate, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex justify-center items-center">
        <div className="max-w-[1200px]">
          {localLang === 0 && <TermsBodyEnglish />}
          {localLang === 1 && <TermsBodyChinese />}
          {localLang === 2 && <TermsBodyMalay />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;
