import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { contactUsSchema } from '../../validations/validationSchemas';
import { submitContactUs } from '../../actions/publicAction';
import Spinner from '../../common/spinner';
import { useNavigate } from 'react-router-dom';

const ContactForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [loader, setLoader] = useState(false);
  const lang = useSelector((state) => state.language.lang);

  const formik = useFormik({
    initialValues: {
      email: '',
      fullName: '',
      message: ''
    },
    validationSchema: contactUsSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      setLoader(true);
      dispatch(submitContactUs(values, setLoader, resetForm, navigate));
    }
  });

  return (
    <div className="flex flex-col min-h-screen p-2 lg:p-0">
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 space-y-12">
        <div className="bg-white p-10 rounded-2xl shadow-md w-full max-w-md">
          <p className="text-oppty-black font-bold text-4xl text-center capitalize mb-8">{lang.sayHello}!</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <label htmlFor="fullName" className="block text-base font-medium text-gray-700">
                {lang.generalFullName}
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                placeholder={lang.generalFullName}
                className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300"
                onChange={formik.handleChange}
                value={formik.values.fullName}
              />
              {formik.touched.fullName && formik.errors.fullName ? (
                <div className="text-red-500 text-sm">{formik.errors.fullName}</div>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-base font-medium text-gray-700">
                {lang.createEmail}
              </label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder={lang.createEmail}
                className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 text-sm">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block text-base font-medium text-gray-700">
                {lang.generalMessage}
              </label>
              <div className="relative">
                <textarea
                  type="text"
                  id="message"
                  name="message"
                  rows={8}
                  placeholder={lang.generalMessage}
                  className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300 pr-10"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                />
                {formik.touched.message && formik.errors.message ? (
                  <div className="text-red-500 text-sm">{formik.errors.message}</div>
                ) : null}
              </div>
            </div>
            <button
              type="submit"
              disabled={loader}
              className="w-full bg-black text-white p-2 text-lg font-bold rounded-md hover:bg-gray-700 disabled:bg-gray-500"
            >
              {loader ? <Spinner /> : <span>{lang.generalSubmit}</span>}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
