import { useState } from 'react'
import { useSelector } from 'react-redux'

const FAQItem = ({ question, answer, index, openIndex, setOpenIndex }) => {
  const isOpen = index === openIndex

  const toggleAnswer = () => {
    setOpenIndex(isOpen ? null : index)
  }

  return (
    <div className="my-2 md:my-4">
      <div
        className={`cursor-pointer bg-oppty-grey-2 py-2 px-2 md:py-8 md:px-8 transition ease-in-out duration-300 ${
          isOpen ? 'bg-oppty-grey-2' : ''
        }`}
        onClick={toggleAnswer}
      >
        <div className="flex justify-between items-center">
          <div className="outfit-font text-[7px] md:text-[20px] lg:text-[23px] font-normal text-oppty-black-3 lg:leading-[30px]">
            {question}
          </div>
          <span className="text-[14px] md:text-[20px] lg:text-[28px]">{isOpen ? '-' : '+'}</span>
        </div>
      </div>
      <div
        className={`bg-oppty-grey-2 py-4 px-4 md:px-12 overflow-hidden transition-all ease-in-out duration-300 text-[7px] md:text-[16px] ${
          isOpen ? 'max-h-[1000px] opacity-100' : 'max-h-0 mb-[-30px] md:mb-0 opacity-0'
        }`}
        // style={{ maxHeight: isOpen ? '1000px' : '0', opacity: isOpen ? 1 : 0 }}
      >
        {answer}
      </div>
    </div>
  )
}

const TabPromote = () => {
  const lang = useSelector((state) => state.language.lang)
  const [openIndex, setOpenIndex] = useState(null)

  const faqData = [
    {
      question: lang.employeeHomeQues1,
      answer: 'Promote'
    },
    {
      question: lang.employeeHomeQues2,
      answer: 'Promote'
    },
    {
      question: lang.employeeHomeQues3,
      answer: 'Promote'
    },
    {
      question: lang.employeeHomeQues4,
      answer: 'Promote'
    },
    {
      question: lang.employeeHomeQues5,
      answer: 'Promote'
    },
    {
      question: lang.employeeHomeQues6,
      answer: 'Promote'
    },
    {
      question: lang.employeeHomeQues7,
      answer: 'Promote'
    }
  ]

  return (
    <div className="mx-auto w-full">
      {faqData.map((item, index) => (
        <FAQItem
          key={index}
          question={item.question}
          answer={item.answer}
          index={index}
          openIndex={openIndex}
          setOpenIndex={setOpenIndex}
        />
      ))}
    </div>
  )
}

export default TabPromote
