import WorkExperienceCard from './workExperienceCard';

const WorkExperience = ({ user }) => {
  return (
    <div className="w-full flex flex-col lg:flex-row p-10 lg:p-20 shadow-md bg-[#F9F9F9] border-x-[1px]">
      <div className="text-5xl font-bold lg:w-1/2 mb-5">Work Experience</div>
      <div className="flex flex-col w-full gap-y-5">
        {user?.workExperiences?.map((work, index) => {
          return <WorkExperienceCard key={index} work={work} />;
        })}
      </div>
    </div>
  );
};

export default WorkExperience;
