import WorkExperienceCard from './workExperienceCard';

const WorkExperience = ({ user }) => {
  return (
    <div className="w-full flex flex-col shadow-md bg-template8-gray-1 p-10">
      <div className="flex flex-col w-full gap-y-5 bg-[#1C1B18] p-10 rounded-2xl">
        <div className="text-4xl lg:text-5xl font-bold mb-12 text-white text-center">Work Experience</div>
        {user?.workExperiences?.map((work, index) => {
          return <WorkExperienceCard key={index} work={work} />;
        })}
      </div>
    </div>
  );
};

export default WorkExperience;
