import axios from 'axios'

const apiUrl = process.env.REACT_APP_APIURL

export const FETCH_LANGUAGE_REQUEST = 'FETCH_LANGUAGE_REQUEST'
export const FETCH_LANGUAGE_SUCCESS = 'FETCH_LANGUAGE_SUCCESS'
export const FETCH_LANGUAGE_FAILURE = 'FETCH_LANGUAGE_FAILURE'
// export const SET_LANGUAGE_INDEX = 'SET_LANGUAGE_INDEX'

export const fetchLanguageRequest = () => ({ type: FETCH_LANGUAGE_REQUEST })

export const fetchLanguageSuccess = (languageData) => ({
  type: FETCH_LANGUAGE_SUCCESS,
  payload: languageData
})

export const fetchLanguageFailure = (error) => ({
  type: FETCH_LANGUAGE_FAILURE,
  payload: error
})

export const languageLoader = (index) => {
  console.log('LOADER')
  return async (dispatch) => {
    dispatch(fetchLanguageRequest())

    try {
      const response = await axios.get(`${apiUrl}/lang/language?index=${index}`)
      console.log('res: ', response.data.language)

      dispatch(fetchLanguageSuccess(response.data.language))
    } catch (error) {
      dispatch(fetchLanguageFailure(error))
    }
  }
}