import profileDelete from '../assets/icons/trashCan.svg';

const TestimonialCard = ({ testimonial, onDelete }) => {
  return (
    <div className="flex border rounded-md border-gray-300 bg-oppty-grey-17 p-5 max-full relative">
      <div className="text-left">
        <div className="text-xl font-bold">
          {testimonial?.salutation} {testimonial?.fullName}
        </div>
        <div className="text-lg font-medium">
          {testimonial?.position} at <span className="italic">{testimonial?.organization}</span>
        </div>
        <div className="text-base text-justify mt-3">{testimonial?.testimonial}</div>
      </div>
      <button
        onClick={onDelete}
        className="absolute right-1 bottom-1 flex justify-center w-7 h-7 p-2 rounded-md hover:bg-red-600"
      >
        <img src={profileDelete} alt="profile icon" className="h-full w-full" />
      </button>
    </div>
  );
};

export default TestimonialCard;
