const ItemTile = ({ item, level }) => {
  return (
    <div className="px-4 py-1.5 bg-transparent flex flex-col w-fit border-[1px] border-template5-blue-1 text-sm">
      <span className="font-bold text-lg">{item}</span>
      <span className="font-medium text-xs text-gray-500">{level}</span>
    </div>
  );
};

export default ItemTile;
