const ActiveJobCard = ({ job }) => {
  return (
    <div className="flex flex-col border-[1px] p-6">
      <div className="w-12 h-12 mb-5">
        <img src={job.pic} alt="company" className="w-full h-full object-cover rounded-full" />
      </div>
      <div className="text-lg font-nunito font-semibold leading-normal text-black">{job.title}</div>
      <div className="flex gap-x-2 items-center">
        <div className="text-sm font-nunito font-normal text-oppty-grey-16">{job.company}</div>
        <div className="bg-oppty-grey-19 w-1 h-1 rounded-full"></div>
        <div className="text-sm font-nunito font-normal text-oppty-grey-19">{job.location}</div>
      </div>
      <div className="flex gap-x-2 py-5">
        <div className="text-xs font-nunito font-normal text-oppty-grey-16 bg-oppty-green-2 px-3 py-2 rounded-md">
          ${job.salaryMin} - ${job.salaryMax}
        </div>
        <div className="text-xs font-nunito font-normal text-oppty-grey-16 bg-oppty-green-2 px-3 py-2 rounded-md">
          {job.jobType}
        </div>
      </div>
      <div className="w-full h-1 bg-oppty-grey-3">
        <div className="w-[40%] h-full bg-oppty-green-6"></div>
      </div>
      <div className="text-xs font-nunito font-normal text-oppty-grey-19 pt-1">
        <span className="text-black font-semibold">5 Reviewed </span>out of 20 applicant
      </div>
    </div>
  );
};

export default ActiveJobCard;
