import { useDispatch, useSelector } from 'react-redux';
import salaryIcon from '../../../assets/icons/salary.svg';
import locationIcon from '../../../assets/icons/location.svg';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { saveJob } from '../../../actions/jobAction';
import { ToastBar } from '../../../common/toastbar';
import { getLabelByValue } from '../../../utils/functions';
import { salaryTypeOptions } from '../../../constant/dropdownConstOptions';
import DefaultPic from '../../../assets/images/defaultCompany.png';

const SavedJobCard = ({ job, user, isApplied }) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language.lang);
  console.log('job: ', job);

  const alreadySaved = user?.savedJobs?.some((savedJob) => savedJob._id === job._id);

  const handleSaveJob = () => {
    const exists = user?.savedJobs?.some((obj) => obj?._id === job?._id);
    const inputValue = {
      ...job,
      exist: exists
    };
    dispatch(saveJob(inputValue));
  };

  const handleApplyJob = () => {
    if (user?.userId) {
      const childWindow = window.open('/apply-job', '_blank');

      if (childWindow) {
        childWindow.onload = () => {
          const message = { type: 'JobData', data: job };
          childWindow.postMessage(message, window.location.origin);
        };
      } else {
        alert('Failed to open the new window');
      }
    } else {
      ToastBar.warning('You need to login to apply');
    }
  };

  return (
    <div className="flex flex-wrap w-full rounded-md p-5 justify-center items-center">
      <div className="flex flex-row w-1/4 flex-grow gap-x-2 flex-shrink-0 pr-3">
        <div className="h-12 w-12 flex-shrink-0">
          {job?.employer?.companyLogo ? (
            <img src={job?.employer?.companyLogo} alt="companyPic" className="object-cover h-full w-full rounded-md" />
          ) : (
            <img src={DefaultPic} alt="DefaultPic" className="object-cover h-full w-full" />
          )}
        </div>
        <div>
          <div className="text-xl font-nunito font-bold leading-normal text-black">{job?.jobTitle}</div>
          <div className="text-base font-nunito font-normal leading-normal text-black">
            {job?.employer?.companyName}
          </div>
        </div>
      </div>
      <div className="flex w-1/4 gap-x-3">
        <div className="h-10 w-10 bg-yellow-800 rounded-md">
          <img src={salaryIcon} alt="salary" className="object-fill h-full w-full p-2" />
        </div>
        <div className="flex flex-col">
          <div className="text-base font-nunito font-semibold leading-normal text-black">
            {job?.currencyType} {job?.minSalary} - {job?.currencyType} {job?.maxSalary}
          </div>
          <div className="text-sm font-nunito font-normal leading-normal text-oppty-grey-1 capitalize">
            {getLabelByValue(job?.salaryType, salaryTypeOptions)} {lang.generalSalary}
          </div>
        </div>
      </div>
      <div className="flex gap-x-3 w-48">
        <div className="h-10 w-10 bg-oppty-blue-6 rounded-md">
          <img src={locationIcon} alt="location" className="object-fill h-full w-full p-2" />
        </div>
        <div className="flex flex-col">
          <div className="text-lg font-nunito font-semibold leading-normal text-black">
            {job?.employer?.companyLocation}
          </div>
          <div className="text-sm font-nunito font-normal leading-normal text-oppty-grey-1">{lang.jobLocation}</div>
        </div>
      </div>
      <div className="flex w-1/4 gap-x-3 justify-end">
        <button
          disabled={isApplied}
          onClick={() => handleApplyJob()}
          className="bg-white border-[1px] text-lg font-bold rounded-md border-oppty-grey-18 px-4 h-12 hover:bg-oppty-blue-4 hover:text-white disabled:bg-black/60 disabled:text-white"
        >
          {isApplied ? lang.generalApplied : lang.generalApplyNow}
        </button>
        <div
          className="w-12 h-12 border-[1px] border-gray-300 p-1.5 hover:bg-blue-100 cursor-pointer"
          onClick={handleSaveJob}
        >
          <BookmarkIcon className={`h-full w-full ${alreadySaved && 'fill-blue-800'} stroke-blue-800 stroke-2`} />
        </div>
      </div>
    </div>
  );
};

export default SavedJobCard;
