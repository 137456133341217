import axios from 'axios';
import { ToastBar } from '../common/toastbar';
import { fetchEmployerSuccess, getOwnEmployerData } from './employerAction';
import {
  createJobStatusUpdateNotification,
  createJobInterviewUpdateNotification,
  createApplyJobNotification
} from './notificationAction';

const apiUrl = process.env.REACT_APP_APIURL;
const localLang = localStorage.getItem('lan');

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_ALL_JOBS_SUCCESS = 'FETCH_ALL_JOBS_SUCCESS';
export const FETCH_SELECTED_JOB_SUCCESS = 'FETCH_SELECTED_JOB_SUCCESS';
export const SET_APPLY_JOB_DETAILS = 'SET_APPLY_JOB_DETAILS';
export const FETCH_CANDIDATES_BY_JOB_SUCCESS = 'FETCH_CANDIDATES_BY_JOB_SUCCESS';

export const fetchUserSuccess = (data) => ({
  type: FETCH_USER_SUCCESS,
  payload: data
});

export const fetchAllJobsSuccess = (data) => ({
  type: FETCH_ALL_JOBS_SUCCESS,
  payload: data
});

export const fetchSelectedJob = (data) => ({
  type: FETCH_SELECTED_JOB_SUCCESS,
  payload: data
});

export const fetchCandidatesByJobSuccess = (data) => ({
  type: FETCH_CANDIDATES_BY_JOB_SUCCESS,
  payload: data
});

export const setApplyJobDetails = (data) => ({
  type: SET_APPLY_JOB_DETAILS,
  payload: data
});

export const addJob = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/job/postjob`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchAllJobsSuccess(response.data));
        dispatch(fetchEmployerSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const editJob = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/job/edit-job`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchAllJobsSuccess(response.data));
        dispatch(fetchEmployerSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getAllJob = (filter) => {
  console.log('filters action: ', filter);
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/job/getjobs`, {
        params: {
          datePosted: filter?.datePosted,
          jobType: filter?.jobType,
          workPolicy: filter?.workPolicy,
          salary: filter?.salary,
          experienceRequired: filter?.experienceRequired,
          jobTitle: filter?.jobTitle,
          companyLocation: filter?.companyLocation
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        dispatch(fetchAllJobsSuccess(response.data));
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getJobById = (values) => {
  const inputData = values;

  console.log('input param: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/job/getjobbyid`, {
        params: { _id: inputData }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchSelectedJob(response.data));
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const saveJob = (inputData) => {
  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/job/savejob`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchUserSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const applyJob = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/job/`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        // dispatch(fetchAllJobsSuccess(response.data));
        dispatch(createApplyJobNotification(values));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getApplicantsByJobId = (inputData) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/job/getapplicants`, {
        params: { jobId: inputData },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('getApplicantsByJobId: ', response.data);
        dispatch(fetchCandidatesByJobSuccess(response.data));
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const updateApplicantJobStatus = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/job/updateapplicantjobstatus`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('applicant status res: ', response.data);
        dispatch(getApplicantsByJobId(response.data?.jobId));
        //dispatch(fetchCandidatesByJobSuccess(response.data));
        dispatch(createJobStatusUpdateNotification(values.applicant));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const updateApplicantInterviewStatus = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/job/updateapplicantinterviewdate`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('applicant status res: ', response.data);
        dispatch(getApplicantsByJobId(response.data?.jobId));
        //dispatch(fetchCandidatesByJobSuccess(response.data));
        dispatch(createJobInterviewUpdateNotification(values.applicant));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const updateViewedApplicantStatus = (values) => {
  console.log('VIEWED');
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/job/updateviewedapplicantjobstatus`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('applicant status res: ', response.data);
        dispatch(getApplicantsByJobId(response.data?.jobId));
        //dispatch(fetchCandidatesByJobSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const addJobViewCount = (values) => {
  console.log('input value: ', values);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/job/addjobviewcount`, values);

      if (response.status === 200) {
        console.log('res: ', response.data);
        // dispatch(fetchAllJobsSuccess(response.data));
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const resumeJob = (values) => {
  console.log('input value: ', values);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/job/resumejobposting`, values, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('res: ', response.data);
        dispatch(getOwnEmployerData(response.data?.empId));
        ToastBar.success(response.data?.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error?.response?.data?.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const stopJob = (values) => {
  console.log('input value: ', values);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/job/stopjobposting`, values, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('res: ', response.data);
        dispatch(getOwnEmployerData(response.data?.empId));
        ToastBar.success(response.data?.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error?.response?.data?.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};
