import React, { useEffect, useState } from 'react';
import logo from '../assets/images/headerLogo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ToastBar } from './toastbar';
import { addSubscriber } from '../actions/publicAction';

// const localLang = localStorage.getItem('lan');

const Footer = ({ scrollToTabs }) => {
  const pathname = window.location.pathname;
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language.lang);
  const isUserLogin = useSelector((state) => state.userLogin);
  const isEmployerLogin = useSelector((state) => state.employerLogin);

  const [localLang, setLocalLang] = useState(parseInt(localStorage.getItem('lan'), 10) || 0);

  useEffect(() => {
    const handleLocalLangUpdate = () => {
      const updatedLang = parseInt(localStorage.getItem('lan'), 10) || 0;
      setLocalLang(updatedLang);
    };

    const intervalId = setInterval(handleLocalLangUpdate, 500); 

    return () => clearInterval(intervalId);
  }, []);

  const [subscriberEmail, setScriberEmail] = useState('');

  const handleChange = (event) => {
    setScriberEmail(event.target.value);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = () => {
    if (validateEmail(subscriberEmail)) {
      const inputValue = {
        email: subscriberEmail
      }
      dispatch(addSubscriber(inputValue))
    } else {
      ToastBar.error(['Invalid email address.', '电子邮件地址无效。', 'Alamat email tidak sah.'][localLang])
    }
  };

  return (
    <footer className="bg-oppty-black text-white w-full">
      <div className="container mx-auto">
        {!isUserLogin && !isEmployerLogin ? (
          <>
            <div className="flex flex-col lg:flex-row lg:justify-between justify-center items-center lg:py-5 py-3">
              <div className="text-white font-serif text-xl lg:text-4xl font-normal leading-[120%] tracking-wide text-start">
                {lang.generalStartWithOppty} -
                <br />
                {lang.generalShapeSmarter}
              </div>
              <a
                href={pathname === '/employer-landing' ? '/employer-sign-up' : '/candidate-sign-up'}
                className="bg-oppty-yellow lg:rounded-[20px] rounded-[15px] lg:px-12 lg:py-6 px-8 py-4 lg:mt-0 mt-4 text-black"
              >
                {lang.generalSignUpNow}
              </a>
            </div>
            <div class="border-t border-solid border-gray-300 lg:my-1"></div>
          </>
        ) : null}
        <div className="flex lg:flex-row flex-col justify-between pt-2">
          <div className="flex flex-col justify-center items-center lg:justify-center lg:items-start">
            <img src={logo} alt="Logo" className=" w-36 h-14" />
            <p className="pt-5 text-left">
              {lang.generalLandTheRightJob} <br /> {lang.generalCareerGoals}
            </p>
          </div>
          <div className="flex lg:flex-row flex-col space-x-20 text-start">
            <div className="flex lg:flex-col flex-row lg:space-y-2 space-x-5 lg:space-x-0 lg:mt-0 mt-5 justify-center">
              <a
                onClick={(e) => {
                  if(scrollToTabs){
                    e.preventDefault();
                    scrollToTabs();
                  }
                }}
                className="text-white text-base font-normal cursor-pointer"
              >
                {lang.generalFAQ}
              </a>
              <a href="/privacy-policy" className="text-white text-base font-normal">
                {lang.generalPrivacy}
              </a>
              <a href="/terms-of-service" className="text-white text-base font-normal">
                {lang.generalTermsOfUse}
              </a>
              <a href="/contact-us" className="text-white text-base font-normal">
                {lang.generalContact}
              </a>
            </div>
            <div className="hidden lg:flex lg:flex-col lg:justify-start lg:items-start">
              <div className="text-white font-nunito-sans text-xl font-normal text-start lg:mt-0 mt-5">
                {lang.generalSubscribeTo} <br />
                {lang.generalOurNewsletter}
              </div>
              <div className="flex items-center pt-3 space-x-2">
                <input
                  type="text"
                  placeholder={lang.generalEnterYourEmail}
                  value={subscriberEmail}
                  onChange={handleChange}
                  className="py-6 px-8 lg:rounded-[20px] lg:h-[72px] lg:w-[324px] h-[60px] w-[180px] border-none outline-none rounded-[15px] text-black bg-white"
                />
                <button type='button' onClick={() => handleSubmit()} className="flex items-center justify-center px-6 py-8 bg-oppty-yellow text-black lg:rounded-[20px] lg:w-[72px] lg:h-[72px] w-[60px] h-[60px] rounded-[15px]">
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile view email input */}
        <div className="lg:hidden flex flex-col justify-center items-center">
          <div className="text-white font-nunito-sans text-xl md:text-3xl font-normal text-start lg:mt-0 mt-5">
            {lang.generalSubscribeTo} <br />
            {lang.generalOurNewsletter}
          </div>
          <div className="flex items-center pt-8 space-x-2">
            <input
              type="text"
              placeholder={lang.generalEnterYourEmail}
              value={subscriberEmail}
              onChange={handleChange}
              className="py-6 px-8 lg:rounded-[20px] lg:h-[72px] lg:w-[324px] h-[60px] w-[180px] border-none outline-none rounded-[15px] text-black bg-white"
            />
            <button type='button' onClick={() => handleSubmit()} className="flex items-center justify-center px-6 py-6 bg-oppty-yellow text-black lg:rounded-[20px] lg:w-[72px] lg:h-[72px] w-[60px] h-[60px] rounded-[15px]">
              &gt;
            </button>
          </div>
        </div>
        <div className="text-base font-semibold text-left py-5 flex justify-center md:justify-start">
          {lang.generalCopyRight} {lang.generalAllRights}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
