import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTemplate } from '../../actions/generalAction';
import { useState } from 'react';
import TemplatePreviewModal from './templatePreviewModal';
import Template1Pic from '../../assets/templates/template1/template1.png';
import Template2Pic from '../../assets/templates/template2/template2.png';
import Template3Pic from '../../assets/templates/template3/template3.png';

const TemplateLists = () => {
  const dispatch = useDispatch();

  const [previewModal, setPreviewModal] = useState(false);

  const selectedTemplate = useSelector((state) => state.general?.selectedTemplate);

  const closeModal = () => setPreviewModal(false);

  const templateClickHandle = (template) => {
    console.log('template click: ', template);
    dispatch(setSelectedTemplate(template));
    setPreviewModal(true);
  };

  const templates = [
    { title: 'template1', label: 'Template 1', pic: Template1Pic },
    { title: 'template2', label: 'Template 2', pic: Template2Pic },
    { title: 'template3', label: 'Template 3', pic: Template3Pic }
  ];
  return (
    <div className="flex flex-col px-40 py-10 gap-y-20">
      <div className="text-3xl font-bold">Select the template</div>
      <div className="flex gap-x-20">
        {templates?.map((template, index) => {
          return (
            <div className="flex flex-col gap-y-5 cursor-pointer">
              <div
                key={index}
                onClick={() => templateClickHandle(template)}
                className="w-80 h-60 hover:shadow-lg flex justify-center items-center"
              >
                <img src={template?.pic} alt="templatePic" className="w-full h-full object-cover" />
              </div>
              <div className="text-2xl font-semibold">{template?.label}</div>
            </div>
          );
        })}
      </div>
      {previewModal && <TemplatePreviewModal template={selectedTemplate} closeModal={closeModal} />}
    </div>
  );
};

export default TemplateLists;
