const TermsBodyEnglish = () => {
  return (
    <body class="bg-white text-gray-800">
      <div class="container mx-auto p-14">
        <h1 class="text-3xl font-bold mb-4">Oppty.ai Terms of Service</h1>
        <p class="text-sm text-gray-600 mb-6">Last updated: August 14, 2024</p>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
          <p class="text-base leading-relaxed mb-4">
            By accessing or using our website and services, you agree to comply with these terms and our privacy policy.
            If you do not agree with any part of these terms, you may not use the website or services.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">2. Eligibility</h2>
          <h3 class="text-lg font-medium mb-1">2.1 Job Seekers:</h3>
          <p class="text-base leading-relaxed mb-4">
            To create a candidate profile or apply for jobs on Oppty.ai, you must be at least 18 years old. By using the
            website as a job seeker, you represent and warrant that you meet this age requirement and that any
            information provided in your profile or application is accurate, up-to-date, and complete.
          </p>
          <h3 class="text-lg font-medium mb-1">2.2 Employers:</h3>
          <p class="text-base leading-relaxed mb-4">
            Employers and recruiters using our services to post job listings or search for candidates must ensure that
            they have the authority to bind their company or organization to enforceable agreements.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">3. Account Registration and Security</h2>
          <h3 class="text-lg font-medium mb-1">3.1 Account Creation:</h3>
          <p class="text-base leading-relaxed mb-4">
            To access certain features of the services, including job postings and candidate searches, you may need to
            create an account. You agree to provide accurate, current, and complete information during the registration
            process and to update such information as necessary to keep it accurate, current, and complete.
          </p>
          <h3 class="text-lg font-medium mb-1">3.2 Account Security:</h3>
          <p class="text-base leading-relaxed mb-4">
            You are responsible for maintaining the confidentiality of your account login information and for all
            activities that occur under your account. You agree to notify us immediately of any unauthorized use of your
            account or any other breach of security.
          </p>
          <h3 class="text-lg font-medium mb-1">3.3 Account Termination:</h3>
          <p class="text-base leading-relaxed mb-4">
            We reserve the right to suspend or terminate your account if any information provided during registration or
            thereafter is found to be inaccurate, false, or misleading, or if you are found to be in violation of these
            terms.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">4. User Conduct</h2>
          <h3 class="text-lg font-medium mb-1">4.1 Job Seekers:</h3>
          <ul class="list-disc list-inside mb-4">
            <li>Use the services solely for the purpose of seeking employment opportunities.</li>
            <li>Ensure that all information in your profile and job applications is true, accurate, and current.</li>
            <li>Avoid applying for jobs on behalf of others or sharing your account with others.</li>
          </ul>
          <h3 class="text-lg font-medium mb-1">4.2 Employers:</h3>
          <ul class="list-disc list-inside mb-4">
            <li>Use the services solely for lawful purposes related to recruitment and employment.</li>
            <li>Post job listings that accurately describe the job and qualifications required.</li>
            <li>Do not engage in discriminatory practices in job postings or during the recruitment process.</li>
            <li>
              Do not require job seekers to participate in activities unrelated to filling legitimate employment
              opportunities.
            </li>
          </ul>
          <h3 class="text-lg font-medium mb-1">4.3 General Prohibitions:</h3>
          <ul class="list-disc list-inside mb-4">
            <li>
              Use the website or services for any unlawful purpose or in violation of any local, national, or
              international laws or regulations.
            </li>
            <li>
              Post, transmit, or share any defamatory, obscene, pornographic, offensive, or otherwise objectionable
              content.
            </li>
            <li>Engage in any harmful, fraudulent, deceptive, or misleading conduct.</li>
            <li>
              Attempt to interfere with, damage the integrity or security of, or decrypt any content transmitted to or
              from the servers running the website.
            </li>
            <li>
              Upload or transmit (or attempt to upload or transmit) viruses, malware, or any materials that interfere
              with the normal operation of the website or services.
            </li>
            <li>
              Collect, harvest, or disclose any information about other users, including personal data or contact
              details, without their consent.
            </li>
          </ul>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">5. Job Listings and Applications</h2>
          <h3 class="text-lg font-medium mb-1">5.1 Job Listings:</h3>
          <p class="text-base leading-relaxed mb-4">
            Employers are solely responsible for the content of their job listings. Oppty.ai does not guarantee the
            accuracy, completeness, or legality of job postings. We reserve the right to remove any job listings that
            violate these terms or are deemed inappropriate.
          </p>
          <h3 class="text-lg font-medium mb-1">5.2 Job Applications:</h3>
          <p class="text-base leading-relaxed mb-4">
            Job seekers are solely responsible for the content of their applications. Oppty.ai does not guarantee that
            applying for jobs through the website will result in an interview or employment.
          </p>
          <h3 class="text-lg font-medium mb-1">5.3 No Employment Guarantee:</h3>
          <p class="text-base leading-relaxed mb-4">
            Oppty.ai does not guarantee that job seekers will obtain employment or that employers will fill job
            vacancies through the use of our services.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">6. Intellectual Property</h2>
          <h3 class="text-lg font-medium mb-1">6.1 Ownership:</h3>
          <p class="text-base leading-relaxed mb-4">
            The content, design, graphics, and other materials on the website are owned by Oppty.ai or its licensors and
            are protected by intellectual property laws. You agree not to copy, distribute, modify, or create derivative
            works from the website's content without our prior written consent.
          </p>
          <h3 class="text-lg font-medium mb-1">6.2 Trademarks:</h3>
          <p class="text-base leading-relaxed mb-4">
            All trademarks, service marks, logos, and trade names displayed on the website are the property of Oppty.ai
            or their respective owners. Unauthorized use of any trademarks or logos is strictly prohibited.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">7. Privacy</h2>
          <p class="text-base leading-relaxed mb-4">
            Your privacy is important to us. Please review our Privacy Policy [link to Privacy Policy] to understand how
            we collect, use, and share your personal information, including information related to job applications and
            postings.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">8. Payments and Fees</h2>
          <h3 class="text-lg font-medium mb-1">8.1 Pricing and Payment:</h3>
          <p class="text-base leading-relaxed mb-4">
            Employers may be required to pay fees to post job listings or access certain premium features. You agree to
            pay all fees in accordance with the pricing and payment terms presented to you.
          </p>
          <h3 class="text-lg font-medium mb-1">8.2 Billing:</h3>
          <p class="text-base leading-relaxed mb-4">
            We may use third-party payment processors to charge you through your payment account associated with your
            account. The payment processing is subject to the terms, conditions, and privacy policies of the payment
            processor. We are not responsible for errors by the payment processor.
          </p>
          <h3 class="text-lg font-medium mb-1">8.3 Refunds:</h3>
          <p class="text-base leading-relaxed mb-4">
            Except as specified in our refund policy, all fees are non-refundable.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">9. Third-Party Services and Links</h2>
          <h3 class="text-lg font-medium mb-1">9.1 Third-Party Services:</h3>
          <p class="text-base leading-relaxed mb-4">
            The website and services may include links to third-party websites or services (“Third-Party Services”) that
            are not owned or controlled by Oppty.ai. We do not endorse or assume any responsibility for any Third-Party
            Services.
          </p>
          <h3 class="text-lg font-medium mb-1">9.2 Third-Party Links:</h3>
          <p class="text-base leading-relaxed mb-4">
            Our website may contain links to third-party websites or resources. You acknowledge and agree that Oppty.ai
            is not responsible or liable for the availability or accuracy of such websites or resources or the content,
            products, or services on or available from those websites or resources.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">10. Disclaimers and Limitation of Liability</h2>
          <h3 class="text-lg font-medium mb-1">10.1 Disclaimers:</h3>
          <p class="text-base leading-relaxed mb-4">
            The website and services are provided on an “as-is” and “as-available” basis without any warranties of any
            kind, either express or implied. We do not warrant that the website or services will be uninterrupted,
            error-free, or secure.
          </p>
          <h3 class="text-lg font-medium mb-1">10.2 Limitation of Liability:</h3>
          <p class="text-base leading-relaxed mb-4">
            To the fullest extent permitted by law, Oppty.ai and its affiliates will not be liable for any indirect,
            incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether direct
            or indirect, arising from your use of the website or services.
          </p>
          <h3 class="text-lg font-medium mb-1">10.3 Indemnification:</h3>
          <p class="text-base leading-relaxed mb-4">
            You agree to indemnify and hold harmless Oppty.ai, its affiliates, directors, officers, employees, and
            agents from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees,
            arising from your use of the website or services, your violation of these terms, or your infringement of
            others' rights.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">11. Termination</h2>
          <h3 class="text-lg font-medium mb-1">11.1 Termination by Oppty.ai:</h3>
          <p class="text-base leading-relaxed mb-4">
            We may terminate or suspend your access to the website and services at any time, with or without cause, and
            with or without notice. We reserve the right to refuse service to anyone at any time.
          </p>
          <h3 class="text-lg font-medium mb-1">11.2 Termination by You:</h3>
          <p class="text-base leading-relaxed mb-4">
            You may terminate your account at any time by following the instructions on the website. Upon termination,
            your right to use the services will immediately cease.
          </p>
          <h3 class="text-lg font-medium mb-1">11.3 Survival:</h3>
          <p class="text-base leading-relaxed mb-4">
            All provisions that by their nature should survive termination of these terms will continue to apply,
            including but not limited to ownership provisions, disclaimers of warranties, indemnification, and
            limitations of liability.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">12. Governing Law and Dispute Resolution</h2>
          <h3 class="text-lg font-medium mb-1">12.1 Governing Law:</h3>
          <p class="text-base leading-relaxed mb-4">
            These terms will be governed by and construed in accordance with the laws of Singapore, without regard to
            its conflict of law principles.
          </p>
          <h3 class="text-lg font-medium mb-1">12.2 Dispute Resolution:</h3>
          <p class="text-base leading-relaxed mb-4">
            Any disputes arising out of or related to these terms or the use of the website or services will be resolved
            through binding arbitration conducted in Singapore, but either party may seek injunctive or other equitable
            relief in a court of competent jurisdiction.
          </p>
          <h3 class="text-lg font-medium mb-1">12.3 No Class Actions:</h3>
          <p class="text-base leading-relaxed mb-4">
            You agree that any dispute will be resolved on an individual basis, and you will not bring or participate in
            any class action, consolidated action, or representative action.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">13. Modifications to Terms</h2>
          <p class="text-base leading-relaxed mb-4">
            We reserve the right to modify these terms at any time. We will notify you of any changes by posting the
            updated terms on the website and updating the “Last Updated” date. Your continued use of the website and
            services after any changes indicates your acceptance of the new terms.
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">14. Miscellaneous</h2>
          <h3 class="text-lg font-medium mb-1">14.1 Severability:</h3>
          <p class="text-base leading-relaxed mb-4">
            If any provision of these terms is found to be invalid or unenforceable, the remaining provisions will
            continue in full force and effect.
          </p>
        </section>
      </div>
    </body>
  );
};

export default TermsBodyEnglish;
