import { useSelector } from 'react-redux';

const JobsApplicantsStatus = () => {
  const lang = useSelector((state) => state.language.lang);
  const dashboardDetails = useSelector((state) => state.admin?.dashboardData);

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex flex-col border-[1px] p-4 gap-y-2">
        <div className="text-xl font-nunito font-bold text-black">Total Candidates</div>
        <div className="flex items-end gap-x-3">
          <div className="text-7xl font-nunito font-bold text-black">{dashboardDetails?.totalUsers}</div>
          <div className="text-xl font-nunito font-normal text-oppty-grey-19 mb-2">Candidates</div>
        </div>
      </div>
      <div className="flex flex-col border-[1px] p-4 gap-y-2">
        <div className="text-xl font-nunito font-bold text-black">Total Employers</div>
        <div className="flex items-end gap-x-3">
          <div className="text-7xl font-nunito font-bold text-black">{dashboardDetails?.totalEmployers}</div>
          <div className="text-xl font-nunito font-normal text-oppty-grey-19 mb-2">Employers</div>
        </div>
      </div>
      <div className="flex flex-col border-[1px] p-4 gap-y-2">
        <div className="text-xl font-nunito font-bold text-black">Total Jobs</div>
        <div className="flex items-end gap-x-3">
          <div className="text-7xl font-nunito font-bold text-black">{dashboardDetails?.totalJobs}</div>
          <div className="text-xl font-nunito font-normal text-oppty-grey-19 mb-2">Jobs</div>
        </div>
      </div>
    </div>
  );
};

export default JobsApplicantsStatus;
