import { useSelector } from 'react-redux';
import { ReactComponent as StatusUp } from '../../assets/icons/statusUpTriangle.svg';
import { ReactComponent as StatusDown } from '../../assets/icons/statusDownTriangle.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as Notepad } from '../../assets/icons/notepad.svg';
import { useEffect, useState } from 'react';
import BarChartComponent from './barChart';

const JobStatistic = () => {
  const [selectedTab, setSelectedTab] = useState('appliedCount');
  const [xLabels, setXLabels] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [yearlyCount, setYearlyCount] = useState({
    totalViewCount: 0,
    appliedCount: 0
  });

  const lang = useSelector((state) => state.language.lang);
  const dashboardDetails = useSelector((state) => state.employer?.dashboard);

  const transformData = (data, key) => {
    const months = Object.keys(data);
    const allJobs = {};

    months.forEach((month) => {
      data[month].forEach((job) => {
        if (!allJobs[job.jobTitle]) {
          allJobs[job.jobTitle] = Array(12).fill(0);
        }
      });
    });

    months.forEach((month, monthIndex) => {
      data[month].forEach((job) => {
        allJobs[job.jobTitle][monthIndex] = job[key];
      });
    });

    const result = Object.keys(allJobs).map((jobTitle) => ({
      jobs: allJobs[jobTitle],
      title: jobTitle
    }));

    return result;
  };

  function getTotalCounts(data) {
    const totalCounts = {
      totalViewCount: 0,
      appliedCount: 0
    };

    for (const month in data) {
      if (data.hasOwnProperty(month)) {
        data[month].forEach((job) => {
          totalCounts.totalViewCount += job?.viewsCount;
          totalCounts.appliedCount += job?.appliedCount;
        });
      }
    }

    return totalCounts;
  }

  useEffect(() => {
    let xLabels = dashboardDetails?.appliedJobsByMonth && Object.keys(dashboardDetails?.appliedJobsByMonth);
    const data =
      dashboardDetails?.appliedJobsByMonth && transformData(dashboardDetails?.appliedJobsByMonth, selectedTab);
    const yearlyCounts = getTotalCounts(dashboardDetails?.appliedJobsByMonth);
    setYearlyCount(yearlyCounts);
    setXLabels(xLabels);
    setBarChartData(data);
  }, [selectedTab, dashboardDetails]);

  console.log('dashboardDetails: ', dashboardDetails);
  console.log('barChartData: ', barChartData);

  return (
    <div className="border-[1px] w-full">
      <div className="flex justify-between items-center px-6 pt-6">
        <div>
          <div className="text-xl font-nunito font-bold leading-normal text-black">{lang.companyJobStatistics}</div>
        </div>
        {/* <div className="bg-black p-1">
          <button type="button" className="bg-black p-3 hover:bg-white text-sm font-bold text-white hover:text-black">
            {lang.generalWeek}
          </button>
          <button type="button" className="bg-black p-3 text-sm hover:bg-white font-bold text-white hover:text-black">
            {lang.generalMonth}
          </button>
          <button type="button" className="bg-black p-3 hover:bg-white font-bold text-white hover:text-black">
            {lang.generalYear}
          </button>
        </div> */}
      </div>
      <div className="flex flex-col w-full">
        <div className="border-b-[1px]">
          <div className="flex gap-x-10 pt-5 px-6">
            <button
              type="button"
              className={`text-base pb-1.5 font-semibold border-b-4 ${
                selectedTab === 'appliedCount'
                  ? 'text-oppty-blue border-oppty-blue rounded-b-md'
                  : 'border-transparent text-oppty-grey-19'
              } hover:text-black hover:border-oppty-blue hover:rounded-b-md`}
              onClick={() => setSelectedTab('appliedCount')}
            >
              {lang.companyJobsApplied}
            </button>
            <button
              type="button"
              className={`text-base pb-1.5 font-semibold border-b-4 ${
                selectedTab === 'viewsCount'
                  ? 'text-oppty-blue border-oppty-blue rounded-b-md'
                  : 'border-transparent text-oppty-grey-19'
              } hover:text-black hover:border-oppty-blue hover:rounded-b-md`}
              onClick={() => setSelectedTab('viewsCount')}
            >
              {lang.companyJobsView}
            </button>
          </div>
        </div>
        <div className="flex flex-wrap md:flex-nowrap gap-x-5 py-1 px-6">
          <div className="w-full md:w-[65%] h-[320px]">
            {barChartData && <BarChartComponent data={barChartData} lang={lang} xLabels={xLabels} />}
          </div>
          <div className="w-full md:w-[35%] flex flex-row md:flex-col gap-3">
            <div className="flex flex-col border-[1px] p-4 gap-y-2 w-1/2 md:w-full">
              <div className="flex justify-between items-center">
                <div className="text-lg font-nunito font-bold text-black md:text-base">{lang.companyJobsView}</div>
                <div className="bg-oppty-grey-15 rounded-full h-6 w-6 p-0.5 items-center justify-center">
                  <Eye className="w-full h-full" />
                </div>
              </div>
              <div>
                <div className="text-4xl font-nunito font-bold text-black">{yearlyCount?.totalViewCount}</div>
                <div className="flex gap-x-3 items-center">
                  {/* <div className="text-lg font-nunito font-semibold text-oppty-grey-19 md:text-xs">
                    {lang.companyThisWeek}
                  </div>
                  <div className="flex items-center">
                    <div className="text-lg font-nunito font-semibold text-oppty-purple-2 md:text-xs">6.4% </div>
                    <div>
                      <StatusUp />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="flex flex-col border-[1px] p-4 gap-y-2 w-1/2 md:w-full">
              <div className="flex justify-between items-center">
                <div className="text-lg font-nunito font-bold text-black md:text-base">{lang.companyJobsApplied}</div>
                <div className="bg-oppty-yellow rounded-full h-6 w-6 p-0.5 items-center justify-center">
                  <Notepad className="w-full h-full" />
                </div>
              </div>
              <div>
                <div className="text-4xl font-nunito font-bold text-black">{yearlyCount?.appliedCount}</div>
                {/* <div className="flex gap-x-3 items-center">
                  <div className="text-lg font-nunito font-semibold text-oppty-grey-19">{lang.companyThisWeek}</div>
                  <div className="flex items-center">
                    <div className="text-lg font-nunito font-semibold text-oppty-red-1">0.5% </div>
                    <div>
                      <StatusDown />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobStatistic;
