import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import GeneralTile from '../../common/generealTile';
import CheckboxGroup from '../../common/checkboxGroup';
import { benefitsData } from '../../constant/checkboxData';
import { updateEmployerBenefits } from '../../actions/employerAction';

const EmployerBenefits = () => {
  const localLang = localStorage.getItem('lan');
  const dispatch = useDispatch();

  const [addBenefit, setAddBenefit] = useState(false);
  const [benefitsCheckBoxData, setBenefitsCheckBoxData] = useState(benefitsData);

  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.employer);

  const checkBenefitsFromDB = () => {
    const updatedBenefits = benefitsCheckBoxData?.map((benefit) => {
      const isChecked = employer?.benefits?.some((data) => data.benefitsId === benefit.benefitsId);

      if (isChecked) {
        return { ...benefit, checked: true };
      } else {
        return benefit;
      }
    });

    setBenefitsCheckBoxData(updatedBenefits);
  };

  useEffect(() => {
    checkBenefitsFromDB();
  }, []);

  const handleBenefitsCheckboxChange = (item, isChecked) => {
    setBenefitsCheckBoxData((prevData) =>
      prevData.map((prevItem) =>
        prevItem.benefitsId === item.benefitsId ? { ...prevItem, checked: isChecked } : prevItem
      )
    );
    item.checked = isChecked;
    const updatedBenefits = isChecked
      ? [...formik.values.companyBenefits, item]
      : formik.values.companyBenefits.filter((benefit) => benefit.benefitsId !== item.benefitsId);

    formik.setValues({ ...formik.values, companyBenefits: updatedBenefits });
  };

  const initialValues = {
    companyBenefits: employer?.benefits || '',
    // companyWorkPolicy: employer?.workPolicy || ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      console.log('value: ', values);
      const result = await dispatch(updateEmployerBenefits(values));
      if (result && !result.error) {
        setAddBenefit(false);
      }
    }
  });

  return (
    <div className="flex flex-col justify-center pt-5 px-0 lg:px-8 rounded-md2 bg-white ">
      <div className="flex flex-row items-center gap-x-5">
        <div className="text-xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.generalBenefits}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {!addBenefit && (
          <button
            onClick={() => setAddBenefit(true)}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
          >
            + {lang.profileAdd}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 lg:px-5">
        <div className="flex flex-wrap gap-x-2 lg:gap-x-5 pb-7 gap-y-2">
          {employer?.benefits?.map((benefit, index) => {
            return <GeneralTile name={benefit.label[localLang]} uniqueKey={index} key={index} />;
          })}
        </div>
        {addBenefit && (
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="border rounded-md">
                <CheckboxGroup data={benefitsCheckBoxData} onChange={handleBenefitsCheckboxChange} localLang={localLang}/>
              </div>
              <div className="flex justify-end flex-row gap-x-2 pt-10 pb-5">
                <button
                  onClick={() => setAddBenefit(false)}
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
                >
                  {lang.profileCancel}
                </button>
                <button
                  type="submit"
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md"
                >
                  {lang.profileAdd}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployerBenefits;
