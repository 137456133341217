import LangTile from './langTile';
import SkillTile from './skillTile';

const AboutMe = ({ user }) => {
  return (
    <div className="w-full flex flex-col justify-between p-10 lg:p-20 shadow-md bg-white gap-x-10 border-r-[16px] border-template6-blue-1">
      <div className="text-5xl font-bold mb-20">About Me</div>
      {user?.aboutMe && (
        <div className="w-full pb-10 lg:pb-16 flex flex-col lg:flex-row border-b-[1px] border-gray-300">
          <div className="text-3xl font-bold underline w-full lg:w-1/2 text-template6-blue-1">Short Description</div>
          {/* <div className="text-base font-medium w-full lg:w-1/2 mt-1 pt-5 lg:pt-0">{user?.aboutMe}</div> */}
          <div className="w-full lg:w-1/2 mt-1 pt-5 lg:pt-0" dangerouslySetInnerHTML={{ __html: user?.aboutMe }}></div>
        </div>
      )}
      {user?.languages?.length > 0 && (
        <div className="w-full py-10 lg:py-16 flex flex-col lg:flex-row border-b-[1px] border-gray-300">
          <div className="text-3xl font-bold underline w-full lg:w-1/2 text-template6-blue-1">Languages</div>
          <div className="text-base font-medium w-full lg:w-1/2 pt-5 lg:pt-0 mt-1 flex flex-wrap gap-5">
            {user?.languages?.map((lang, index) => (
              <LangTile item={lang?.lang} level={lang?.level} key={index} />
            ))}
          </div>
        </div>
      )}
      {user?.skills?.length > 0 && (
        <div className="w-full py-10 lg:py-16 flex flex-col lg:flex-row border-b-[1px] border-gray-300">
          <div className="text-3xl font-bold underline w-1/2 text-template6-blue-1">Skills</div>
          <div className="text-base font-medium w-full lg:w-1/2 pt-5 lg:pt-0 mt-1 flex flex-wrap gap-5">
            {user?.skills?.map((skill, index) => (
              <SkillTile item={skill?.skill} level={skill?.level} key={index} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutMe;
