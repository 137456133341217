import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigation } from 'react-router-dom'
import { dataExampleLoader } from '../../actions/userAction'

const ExampleLoader = () => {
  const navigation = useNavigation()
  const dispatch = useDispatch()

  console.log('navigation.state: ', navigation.state)

  const data = useSelector((state) => state.user.data)
  console.log('data: ', data)

  useEffect(() => {
    dispatch(dataExampleLoader())
  }, [dispatch])

  if (navigation.state === 'loading') {
    return <h1 className="text-8xl">Loading......</h1>
  }

  return (
    <div>
      <h1 className="text-5xl mb-3">this is data fetch check</h1>
      <div className='mb-5'>
        {data &&
          data.map((user) => {
            return <div key={user.id}>Name: {user.firstName} {user.lastName}</div>
          })}
      </div>

      <Link className='text-2xl text-red-800' to="/">Home</Link>
    </div>
  )
}

export default ExampleLoader

// export const dataLoader = async () => {
//   try {
//     const res = await fetch("https://dog.ceo/api/breeds/image/random");
//     const dog = await res.json();
//     console.log('Data fetched successfully:', dog);
//     return dog.message;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// }
