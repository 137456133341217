const PortfolioCard = ({ portfolio }) => {
  return (
    <div
      className="p-10 w-96 lg:w-[400px] cursor-pointer transform transition-transform duration-300 hover:scale-110 hover:shadow-md"
      onClick={() => window.open(portfolio?.link)}
    >
      <img
        src={portfolio.image}
        alt="pic"
        className="w-96 lg:w-[400px] h-56 object-cover rounded-md border-[10px] border-template6-purple-1"
      />
      <div className="text-black/70 font-bold pt-5 text-xl">{portfolio?.highlight}</div>
    </div>
  );
};

export default PortfolioCard;
