import { ReactComponent as Facebook } from '../../../assets/icons/icons8-facebook.svg';
import { ReactComponent as Instagram } from '../../../assets/icons/icons8-insta.svg';
import { ReactComponent as Twitter } from '../../../assets/icons/icons8-twitterx.svg';
import { ReactComponent as Linkedin } from '../../../assets/icons/icons8-linkedin.svg';
import { ReactComponent as Github } from '../../../assets/icons/icons8-github.svg';
import { ReactComponent as Pinterest } from '../../../assets/icons/icons8-pinterest.svg';
import { ReactComponent as Youtube } from '../../../assets/icons/icons8-youtube.svg';
import { ChatBubbleOvalLeftIcon, MapPinIcon } from '@heroicons/react/24/outline';

const HeaderSection = ({ user }) => {
  const handleOpenLink = (link) => {
    console.log('open');

    if (!link.includes('http://') && !link.includes('https://')) {
      link = 'https://' + link;
    }

    window.open(link);
  };

  return (
    <div className="flex flex-col lg:flex-row w-full bg-template7-purple-4 relative min-h-80">
      <div className="w-full lg:w-1/2 p-10 lg:p-20">
        <div className="text-3xl lg:text-6xl font-semibold text-template6-purple-1">
          {user?.firstName} {user?.lastName}
        </div>
        <div className="underline text-3xl font-semibold text-template6-purple-1 mt-1">{user?.title}</div>
        <div className="flex flex-wrap gap-x-5 items-center mt-3 text-black">
          {user?.country && (
            <div className="flex gap-x-2 items-center">
              <MapPinIcon className="w-5 h-5" /> {user?.country}
            </div>
          )}
          {user?.languages?.length > 0 && (
            <div className="flex gap-x-2 items-center">
              <ChatBubbleOvalLeftIcon className="w-5 h-5" /> {user?.languages?.map((lang) => lang?.lang).join(', ')}
            </div>
          )}
        </div>
        {/* <div className="text-sm font-medium text-black py-7">{user?.aboutMe}</div> */}
        <div className="w-full py-7" dangerouslySetInnerHTML={{ __html: user?.aboutMe }}></div>
        <button
          disabled={!user?.resume}
          onClick={() => window.open(user?.resume?.resumeUrl)}
          className="bg-template7-purple-2 disabled:bg-gray-400 hover:bg-template7-purple-2/90 shadow-xl px-10 py-2.5 text-sm font-bold text-white rounded-2xl"
        >
          {user?.resume ? 'Download CV' : 'No CV Uploaded'}
        </button>
      </div>
      <div className="w-full lg:w-1/2 p-10 lg:p-20 flex justify-center lg:justify-end">
        <div className="flex flex-col h-fit pb-5 w-full max-w-[520px] relative bg-template7-purple-3 rounded-lg">
          <div
            className={`max-h-[530px] w-full rounded-lg flex justify-center items-end overflow-hidden border-[25px] border-template7-purple-3 ${user?.avatar ? 'bg-template7-purple-3' : 'bg-template7-purple-5'}`}
          >
            {user?.avatar ? (
              <img src={user?.avatar} alt="pic" className="w-full h-full object-cover rounded-lg" />
            ) : (
              <svg
                viewBox="0 0 444 482"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute p-5 lg:p-12 w-full h-full"
              >
                <path
                  d="M222 241C255.645 241 287.911 228.331 311.702 205.78C335.492 183.228 348.857 152.642 348.857 120.75C348.857 88.8577 335.492 58.2717 311.702 35.7204C287.911 13.1692 255.645 0.5 222 0.5C188.355 0.5 156.089 13.1692 132.298 35.7204C108.508 58.2717 95.1429 88.8577 95.1429 120.75C95.1429 152.642 108.508 183.228 132.298 205.78C156.089 228.331 188.355 241 222 241ZM176.708 286.094C79.0875 286.094 0 361.062 0 453.598C0 469.005 13.1813 481.5 29.4348 481.5H414.565C430.819 481.5 444 469.005 444 453.598C444 361.062 364.913 286.094 267.292 286.094H176.708Z"
                  fill="rgba(60, 84, 189)"
                  fill-opacity="0.12"
                />
              </svg>
            )}
          </div>
          <div className="flex gap-x-1.5 lg:gap-x-2 flex-wrap items-center px-5">
            {user?.socials?.map((social, index) => {
              return (
                <div key={index}>
                  {social?.social === 'Linkedin' && (
                    <Linkedin
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template7-purple-1 hover:fill-template7-purple-1/90 w-8 h-8 cursor-pointer"
                    />
                  )}
                  {social?.social === 'Facebook' && (
                    <Facebook
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template7-purple-1 hover:fill-template7-purple-1/90 w-8 h-8 cursor-pointer"
                    />
                  )}
                  {social?.social === 'Instagram' && (
                    <Instagram
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template7-purple-1 hover:fill-template7-purple-1/90 w-8 h-8 cursor-pointer"
                    />
                  )}
                  {social?.social === 'X' && (
                    <Twitter
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template7-purple-1 hover:fill-template7-purple-1/90 w-8 h-8 cursor-pointer"
                    />
                  )}
                  {social?.social === 'Github' && (
                    <Github
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template7-purple-1 hover:fill-template7-purple-1/90 w-8 h-8 cursor-pointer"
                    />
                  )}
                  {social?.social === 'Pinterest' && (
                    <Pinterest
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template7-purple-1 hover:fill-template7-purple-1/90 w-8 h-8 cursor-pointer"
                    />
                  )}
                  {social?.social === 'Youtube' && (
                    <Youtube
                      onClick={() => handleOpenLink(social.link)}
                      className="fill-template7-purple-1 hover:fill-template7-purple-1/90 w-10 h-10 cursor-pointer stroke-template7-purple-3"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
