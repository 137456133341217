import { useSelector } from 'react-redux';
import Footer from '../../../common/footer';
import Header from '../../../common/header';
import AccountSettings from './accountSettings';
import Notifications from './notifications';
import PrivacyAndSecurity from './privacyAndSecurity';
import Subscription from './subscription';

const Settings = () => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <div className="flex flex-col min-h-screen ">
      <Header />
      <div className="w-full flex items-center justify-center">
        <div className="max-w-[1200px] flex flex-col gap-y-8 w-full">
          <div className="flex flex-col justify-center py-1 lg:py-5 text-5xl font-bold">
            {lang.generalSettings}
          </div>
          <Subscription />
          <Notifications />
          <PrivacyAndSecurity />
          <AccountSettings />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Settings;
