import { useDispatch } from 'react-redux';
import Footer from './footer';
import Header from './header';
import { useEffect, useRef, useState } from 'react';
import { getArticleById } from '../actions/publicAction';
import { useParams } from 'react-router-dom';
import IframeResizer from 'iframe-resizer-react';
import SkeletonLoader from './skeletonLoader';

const ArticleDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  console.log('art id: ', id);

  const [article, setArticle] = useState(null);

  useEffect(() => {
    dispatch(getArticleById({ articleId: id }, setArticle));
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <div className="flex min-h-screen">
        {article ? <IframeResizer src={article?.url} className="min-w-full h-full" /> : <SkeletonLoader />}
      </div>
      <Footer />
    </div>
  );
};

export default ArticleDetailPage;
