import EmployerHeader from '../../common/employerHeader';
import LeftNavigationBar from '../../common/leftNavigationBar';
import DashboardFooterSection from './dashboardFooterSection';
import DashboardHeaderSection from './dashboardHeaderSection';
import DashboardMiddleSection from './dashboardMiddleSection';
import { useDispatch, useSelector } from 'react-redux';
import { updateLeftNavBarToggleStatus } from '../../actions/generalAction';
import { useEffect, useState } from 'react';
import { getDashboardDetails } from '../../actions/employerAction';

const EmployerDashboard = () => {
  const dispatch = useDispatch();

  const today = new Date();
  const sevenDaysBefore = new Date();
  sevenDaysBefore.setDate(today.getDate() - 7);

  const [dateRange, setDateRange] = useState({
    startDate: sevenDaysBefore,
    endDate: today
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isCollapsed = useSelector((state) => state?.general?.leftNavBarToggle);
  const toggleNavbar = () => dispatch(updateLeftNavBarToggleStatus({ leftNavBarToggle: !isCollapsed }));
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    dispatch(updateLeftNavBarToggleStatus({ leftNavBarToggle: false }));
  };
  useEffect(() => {
    dispatch(getDashboardDetails(dateRange));
  }, [dateRange]);

  console.log('isCollapsed: ', isCollapsed);
  console.log('isMobileMenuOpen: ', isMobileMenuOpen);

  return (
    // <div className="flex flex-row min-h-screen gap-y-8 bg-white">
    //   <div className="hidden lg:flex fixed z-50 w-0 lg:w-72">
    //     <LeftNavigationBar isCollapsed={isCollapsed} toggleNavbar={toggleNavbar} />
    //   </div>
    //   <div className={`flex flex-col flex-grow h-full min-h-screen ${isCollapsed ? 'ml-0 lg:ml-16' : 'lg:ml-72'}`}>
    //     <div className={`flex w-full fixed top-0 left-0 ${isCollapsed ? 'pl-0 lg:pl-16' : 'lg:pl-72'} z-40`}>
    //       <EmployerHeader toggleMobileMenu={toggleMobileMenu}/>
    //     </div>
    //     <div className="mt-20 z-30 h-full">
    //       <DashboardHeaderSection setDateRange={setDateRange} dateRange={dateRange} />
    //       <DashboardMiddleSection />
    //       <DashboardFooterSection />
    //     </div>
    //   </div>
    // </div>
    <div className="flex flex-row min-h-screen gap-y-8 bg-white">
      <div
        className={`${
          isMobileMenuOpen ? 'block' : 'hidden lg:block'
        } fixed z-50 w-60 bg-white shadow-lg lg:w-60 lg:bg-transparent lg:shadow-none lg:block transition-transform duration-300`}
        style={{ transform: isMobileMenuOpen ? 'translateX(0)' : 'translateX(-100%) lg:translateX(0)' }}
      >
        <LeftNavigationBar
          isCollapsed={isCollapsed}
          toggleNavbar={toggleNavbar}
          toggleMobileMenu={toggleMobileMenu}
          isMobileMenuOpen={isMobileMenuOpen}
        />
      </div>

      <div
        className={`flex flex-col flex-grow min-h-screen
         ${isCollapsed ? 'ml-0 lg:ml-16' : 'lg:ml-60'}`}
      >
        <div className={`flex w-full fixed top-0 left-0 ${isCollapsed ? 'pl-0 lg:pl-16' : 'lg:pl-60'} z-40`}>
          <EmployerHeader toggleMobileMenu={toggleMobileMenu} />
        </div>

        <div className="mt-20 z-30">
          <DashboardHeaderSection setDateRange={setDateRange} dateRange={dateRange} />
          <DashboardMiddleSection />
          <DashboardFooterSection />
        </div>
      </div>
    </div>
  );
};

export default EmployerDashboard;
