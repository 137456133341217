import WorkExperienceCard from './workExperienceCard';

const WorkExperience = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-20 shadow-md bg-white border-r-[16px] border-template6-blue-1">
      <div className="text-5xl font-bold lg:w-1/2 mb-10">Work Experience</div>
      <div className="flex flex-col w-full gap-y-5">
        {user?.workExperiences?.map((work, index) => {
          return <WorkExperienceCard key={index} work={work} />;
        })}
      </div>
    </div>
  );
};

export default WorkExperience;
