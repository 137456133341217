import CertificationCard from './certificationCard';

const Certification = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 border-2 border-template3-blue-1 rounded-xl shadow-md">
      <div className="text-4xl font-bold">Certification</div>
      <div className="flex flex-col">
        {user?.certifications?.map((certificate, index) => {
          return <CertificationCard key={index} certificate={certificate} />;
        })}
      </div>
    </div>
  );
};

export default Certification;
