import axios from 'axios';
import { ToastBar } from '../common/toastbar';

const apiUrl = process.env.REACT_APP_APIURL;
const localLang = localStorage.getItem('lan');

export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const MARK_AS_READ_SUCCESS = 'MARK_AS_READ_SUCCESS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export const fetchNotificationsSuccess = (data) => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: data
});

export const markAsReadSuccess = (notification) => ({
  type: MARK_AS_READ_SUCCESS,
  payload: notification
});

export const addNotification = (notification) => ({
  type: ADD_NOTIFICATION,
  payload: notification
});

export const fetchNotifications = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/notification/notifications`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        dispatch(fetchNotificationsSuccess(response.data));
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const markAsRead = (id) => {
  console.log('read id: ', id);
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${apiUrl}/notification/notifications/${id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );
      if (response.status === 200) {
        dispatch(markAsReadSuccess(response.data));
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const createJobStatusUpdateNotification = (notification) => {
  console.log('create noti');
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/notification/createjobstatusupdatenotification`, notification, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchNotifications());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error?.response?.data?.errorMsg[localLang]);
        console.error('Error creating notification:', error.message);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    };
  };
};

export const createJobInterviewUpdateNotification = (notification) => {
  console.log('create noti');
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/notification/createjobinterviewupdatenotification`, notification, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchNotifications());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
        console.error('Error creating notification:', error.message);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    };
  };
};

export const updateJobInterviewUpdateNotification = (notification) => {
  console.log('update interview noti');
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/notification/update-interview-notification`, notification, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchNotifications());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
        console.error('Error creating notification:', error.message);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    };
  };
};

export const createApplyJobNotification = (notification) => {
  console.log('create apply job noti');
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/notification/createapplyjobnotification`, notification, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchNotifications());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error?.response?.data?.errorMsg[localLang]);
        console.error('Error creating notification:', error.message);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const createMessageNotification = (sender) => {

  const senderId = sender;
  console.log('create noti');
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/notification/create-message-notification`, senderId, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });
      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchNotifications());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error?.response?.data?.errorMsg[localLang]);
        console.error('Error creating notification:', error.message);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

// export const sendUpdateJobStatusNotification = (values) => {
//     const inputData = values;

//     console.log('input value: ', inputData);
//     return async (dispatch) => {
//         try {
//             const response = await axios.post(`${apiUrl}/notification/sendnotification`, inputData, {
//                 headers: {
//                     Authorization: 'Bearer ' + localStorage.getItem('token')
//                 }
//             });

//             if (response.status === 200) {
//                 console.log(response.data)
//                 //   dispatch(fetchUserSuccess(response.data));
//                 ToastBar.success(response.data.successMsg[localLang]);
//             }
//         } catch (error) {
//             // Handle error response
//             if (error.response && error.response.status === 500) {
//                 console.log(error.response.data.errorMsg);
//                 ToastBar.error(error.response.data.errorMsg[localLang]);
//             } else {
//                 // Handle other errors
//                 console.log(error.message);
//                 ToastBar.error(error.message);
//             }
//         }
//     };
// };
