import { useSelector } from 'react-redux';
import notepadcard from '../../../assets/images/notepadcard.png';
import messageLetterCard from '../../../assets/images/messagelettercard.png';
import giftBoxCard from '../../../assets/images/giftboxcard.png';
import rocketCard from '../../../assets/images/rocketcard.png';
import worldCard from '../../../assets/images/worldcard.png';
import messageBlueCard from '../../../assets/images/messagebluecard.png';

const JoinHelpsRecruiters = () => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <div className="flex flex-col justify-start items-start w-full">
      <div className="outfit-font text-[16px] md:text-[40px] font-normal text-oppty-grey-1 md:leading-[50px] md:pb-4 md:px-[11%]">
        {lang.employeeHomeJoinHelps}
      </div>
      <div className="outfit-font text-[16px] md:text-[40px] font-normal text-oppty-black-3 md:leading-[50px] lg:pr-[270px] pb-5 md:pb-[60px] md:px-[11%]">
        {lang.employeeHomeDiscoverWhy}
      </div>
      <div className="flex flex-wrap justify-center gap-2 md:gap-7">
        <div className="shadow-card w-[30%] md:w-1/4 md:min-h-[379px] flex-shrink-0 rounded-xl p-4 md:p-10">
          <img src={notepadcard} alt="messageIcon" className="w-7 h-7 md:w-20 md:h-20" />
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-semibold pt-4 md:pt-10">
            {lang.employeeHomeCard4Title}
          </div>
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-normal pt-4 md:pt-10">
            {lang.employeeHomeCard4Content}
          </div>
        </div>
        <div className="shadow-card w-[30%] md:w-1/4 md:min-h-[379px] flex-shrink-0 rounded-xl p-4 md:p-10">
          <img src={messageLetterCard} alt="rocketIcon" className="w-6 h-8 md:w-16 md:h-20" />
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-semibold pt-4 md:pt-10">
            {lang.employeeHomeCard5Title}
          </div>
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-normal pt-4 md:pt-10">
            {lang.employeeHomeCard5Content}
          </div>
        </div>
        <div className="shadow-card w-[30%] md:w-1/4 md:min-h-[379px] flex-shrink-0 rounded-xl p-4 md:p-10">
          <img src={rocketCard} alt="rocketIcon" className="w-6 h-8 md:w-16 md:h-20" />
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-semibold pt-4 md:pt-10">
            {lang.employeeHomeCard6Title}
          </div>
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-normal pt-4 md:pt-10">
            {lang.employeeHomeCard6Content}
          </div>
        </div>
        <div className="shadow-card w-[30%] md:w-1/4 md:min-h-[379px] flex-shrink-0 rounded-xl p-4 md:p-10">
          <img src={giftBoxCard} alt="messageIcon" className="w-6 h-8 md:w-16 md:h-20" />
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-semibold pt-4 md:pt-10">
            {lang.employeeHomeCard7Title}
          </div>
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-normal pt-4 md:pt-10">
            {lang.employeeHomeCard7Content}
          </div>
        </div>
        <div className="shadow-card w-[30%] md:w-1/4 md:min-h-[379px] flex-shrink-0 rounded-xl p-4 md:p-10">
          <img src={worldCard} alt="rocketIcon" className="w-6 h-8 md:w-16 md:h-16 mt-2" />
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-semibold pt-4 md:pt-10">
            {lang.employeeHomeCard8Title}
          </div>
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-normal pt-4 md:pt-10">
            {lang.employeeHomeCard8Content}
          </div>
        </div>
        <div className="shadow-card w-[30%] md:w-1/4 md:min-h-[379px] flex-shrink-0 rounded-xl p-4 md:p-10">
          <img src={messageBlueCard} alt="rocketIcon" className="w-6 h-8 md:w-16 md:h-20" />
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-semibold pt-4 md:pt-10">
            {lang.employeeHomeCard9Title}
          </div>
          <div className="outfit-font text-oppty-black-3 text-[8px] md:text-[25px] font-normal pt-4 md:pt-10">
            {lang.employeeHomeCard9Content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinHelpsRecruiters;
