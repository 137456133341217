import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import ReactQuill from 'react-quill';
import {
  answerOptions,
  currencyTypeOption,
  jobExperienceOption,
  jobTypeOptions,
  questionOptions,
  workPolicyOption
} from '../../constant/dropdownConstOptions';
import DropdownMenuJob from '../../common/dropdownJob';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import skillOptions from '../../constant/skills.json';
import { Checkbox } from '@material-tailwind/react';
import GeneralTile from '../../common/generealTile';
import { addJob } from '../../actions/jobAction';
import { ClockIcon, CreditCardIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { addJobSchema } from '../../validations/validationSchemas';
import QuestionCard from './questionCard';
import DropdownMenuQuestion from '../../common/dropdownQuestion';
import { ToastBar } from '../../common/toastbar';
import { getLabelByValue } from '../../utils/functions';
import DropdownMenuJobLang from '../../common/dropdownJobLang';

const localLang = localStorage.getItem('lan');

const CreateJobForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeSalaryButton, setActiveSalaryButton] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState({
    question: null,
    preferredAnswer: null
  });
  const employer = useSelector((state) => state.employer);

  const handleClick = (buttonName) => {
    setActiveSalaryButton(buttonName);
    formik.setFieldValue('salaryType', buttonName);
  };

  const lang = useSelector((state) => state.language.lang);

  const handleJobDescriptionChange = (value) => {
    formik.setFieldValue('jobDescription', value);
  };

  const handleJobRequirementsChange = (value) => {
    formik.setFieldValue('jobRequirements', value);
  };

  const handleJobResponsibilitiesChange = (value) => {
    formik.setFieldValue('jobResponsibilities', value);
  };

  const handleJobEmploymentTypeSelect = ({ value, label }) => {
    formik.setFieldValue('jobEmploymentType', value);
  };

  const handleWorkPolicySelect = ({ value, label }) => {
    formik.setFieldValue('workPolicy', value);
  };

  const handleCurrencyTypeSelect = ({ value, label }) => {
    formik.setFieldValue('currencyType', value);
  };

  const handleExperienceRequiredSelect = ({ value, label }) => {
    formik.setFieldValue('experienceRequired', value);
  };

  useEffect(() => {
    if (selectedQuestion?.preferredAnswer && selectedQuestion?.question) {
      const questionExists = formik.values.questions.some((q) => q.question === selectedQuestion.question);

      if (!questionExists) {
        formik.setFieldValue('questions', [...formik.values.questions, selectedQuestion]);
      } else {
        ToastBar.warning('Question already exists');
      }

      setTimeout(() => {
        setSelectedQuestion({ question: null, preferredAnswer: null });
      }, 500);
    }

    if (formik.values.salaryType) {
      setActiveSalaryButton(formik.values.salaryType);
    }
  }, [selectedQuestion]);

  const initialValues = {
    isSalaryFixed: false,
    jobTitle: '',
    jobDescription: '',
    jobRequirements: '',
    jobResponsibilities: '',
    jobEmploymentType: '',
    experienceRequired: '',
    workPolicy: '',
    currencyType: 'SGD',
    salaryType: 'monthly',
    minSalary: '',
    maxSalary: '',
    skills: [],
    isNegotiable: false,
    questionnaire: 0,
    questions: [],
    employer: employer.employerId,
    companyIndustry: employer.companyIndustry
  };

  const formik = useFormik({
    initialValues,
    validationSchema: addJobSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      const result = await dispatch(addJob(values));
      if (result && !result.error) {
        // setEditGeneral(false);
        resetForm();
        navigate('/employer/job-listing');
      }
    }
  });

  const skillLoadOptions = (inputValue, callback) => {
    const filteredOptions = skillOptions
      .filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 10);

    callback(filteredOptions);
  };

  const handleDeleteSkill = (index) => {
    const deleteSkill = formik.values.skills[index];
    const updatedSkills = formik.values.skills.filter((_, i) => i !== index);

    console.log('deleteSkill: ', deleteSkill);
    formik.setFieldValue('skills', updatedSkills);
  };

  const onDeleteQuestion = (questionToDelete) => {
    const updatedQuestions = formik.values.questions.filter((question) => question !== questionToDelete);
    formik.setFieldValue('questions', updatedQuestions);
  };

  const handleQuestionCheckboxChange = (e) => {
    if (e.target.checked) {
      formik.setFieldValue('questionnaire', 1);
    } else {
      formik.setFieldValue('questionnaire', 0);
      formik.setFieldValue('questions', []);
    }
  };

  console.log('selectedQuestion: ', selectedQuestion);
  console.log('formik.values.questions: ', formik.values.questions);
  console.log('formik: ', formik.values);
  console.log('errors: ', formik.errors);

  return (
    <div className="flex flex-col w-full bg-white px-8 py-5 gap-y-8">
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full flex justify-between items-center">
          <div className="text-xl font-nunito font-bold leading-normal text-black">{lang.companyPostAJob}</div>
        </div>
        <div className="flex flex-col py-5 lg:py-10 gap-y-5">
          <div className="flex flex-col lg:flex-row items-start lg:items-center">
            <div className="w-96 text-base font-nunito font-semibold leading-normal text-black capitalize">
              {lang.jobTitle}
            </div>
            <div className="w-full pr-0 lg:pr-48">
              <input
                type="text"
                id="jobTitle"
                name="jobTitle"
                placeholder='e.g."Software Engineer"'
                onChange={formik.handleChange}
                value={formik.values.jobTitle}
                className="py-3 w-full leading-tight border focus:outline rounded-md border-gray-300 px-5"
              />
              {formik.touched.jobTitle && formik.errors.jobTitle ? (
                <div className="text-red-500 text-sm">{formik.errors.jobTitle}</div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center">
            <div className="w-96 text-base font-nunito font-semibold leading-normal text-black">
              {lang.generalJobDescription}
            </div>
            <div className="w-full pr-0 lg:pr-48">
              <ReactQuill theme="snow" value={formik.values.jobDescription} onChange={handleJobDescriptionChange} />
              {formik.touched.jobDescription && formik.errors.jobDescription ? (
                <div className="text-red-500 text-sm">{formik.errors.jobDescription}</div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center">
            <div className="w-96 text-base font-nunito font-semibold leading-normal text-black">
              {lang.generalResponsibilities}
            </div>
            <div className="w-full pr-0 lg:pr-48">
              <ReactQuill
                theme="snow"
                value={formik.values.jobResponsibilities}
                onChange={handleJobResponsibilitiesChange}
              />
              {formik.touched.jobResponsibilities && formik.errors.jobResponsibilities ? (
                <div className="text-red-500 text-sm">{formik.errors.jobResponsibilities}</div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center">
            <div className="w-96 text-base font-nunito font-semibold leading-normal text-black">
              {lang.jobRequirements}
            </div>
            <div className="w-full pr-0 lg:pr-48">
              <ReactQuill theme="snow" value={formik.values.jobRequirements} onChange={handleJobRequirementsChange} />
              {formik.touched.jobRequirements && formik.errors.jobRequirements ? (
                <div className="text-red-500 text-sm">{formik.errors.jobRequirements}</div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center">
            <div className="w-96 text-base font-nunito font-semibold leading-normal text-black">
              {lang.jobEmploymentType}
            </div>
            <div className="w-full pr-0 lg:pr-48">
              <DropdownMenuJobLang
                options={jobTypeOptions}
                onSelect={handleJobEmploymentTypeSelect}
                placeholder={lang.generalJobType}
              />
              {formik.touched.jobEmploymentType && formik.errors.jobEmploymentType ? (
                <div className="text-red-500 text-sm">{formik.errors.jobEmploymentType}</div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center">
            <div className="w-96 text-base font-nunito font-semibold leading-normal text-black">
              {lang.companyWorkPolicy}
            </div>
            <div className="w-full pr-0 lg:pr-48">
              <DropdownMenuJobLang
                options={workPolicyOption}
                onSelect={handleWorkPolicySelect}
                placeholder={lang.companyWorkPolicy}
              />
              {formik.touched.workPolicy && formik.errors.workPolicy ? (
                <div className="text-red-500 text-sm">{formik.errors.workPolicy}</div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center">
            <div className="w-96 flex flex-col">
              <div className=" text-base font-nunito font-semibold leading-normal text-black">{lang.generalSalary}</div>
              <div className="text-xs font-nunito font-normal leading-normal text-oppty-grey-19 pr-2">
                {lang.jobChooseHowYouPrefer}
              </div>
            </div>
            <div className="flex flex-col gap-y-2 w-full pr-0 lg:pr-48">
              <div className="w-full flex gap-x-4">
                <button
                  type="button"
                  className={`w-1/2 border-[1px] rounded-lg flex flex-col justify-center items-center py-8 ${
                    activeSalaryButton === 'monthly' ? 'bg-gray-300' : ''
                  }`}
                  onClick={() => handleClick('monthly')}
                >
                  <div className="w-8 h-8">
                    <CreditCardIcon />
                  </div>
                  <div className="text-base font-nunito font-normal leading-normal text-black">{lang.jobMonthly}</div>
                </button>
                <button
                  type="button"
                  className={`w-1/2 border-[1px] rounded-lg flex flex-col justify-center items-center py-8 ${
                    activeSalaryButton === 'hourly' ? 'bg-gray-300' : ''
                  }`}
                  onClick={() => handleClick('hourly')}
                >
                  <div className="w-8 h-8">
                    <ClockIcon />
                  </div>
                  <div className="text-base font-nunito font-normal leading-normal text-black">{lang.jobHourly}</div>
                </button>
              </div>
              {formik.touched.salaryType && formik.errors.salaryType ? (
                <div className="text-red-500 text-sm">{formik.errors.salaryType}</div>
              ) : null}
              <div className="w-full py-3">
                <DropdownMenuJob
                  options={currencyTypeOption}
                  onSelect={handleCurrencyTypeSelect}
                  value={formik.values.currencyType}
                />
                {formik.touched.currencyType && formik.errors.currencyType ? (
                  <div className="text-red-500 text-sm">{formik.errors.currencyType}</div>
                ) : null}
              </div>
              {formik.values.isSalaryFixed ? (
                <div className="w-full flex gap-x-4">
                  <div className="flex flex-col w-1/2">
                    <div className="text-xs font-nunito font-normal leading-normal uppercase  text-black pr-5">
                      {lang.generalFixedSalary}
                    </div>
                    <input
                      type="text"
                      id="fixedSalary"
                      name="minSalary"
                      onChange={formik.handleChange}
                      value={formik.values.minSalary}
                      className="py-3 w-full leading-tight border focus:outline rounded-md border-gray-300 px-5"
                    />
                    {formik.touched.minSalary && formik.errors.minSalary ? (
                      <div className="text-red-500 text-sm">* Fixed salary is required</div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="w-full flex gap-x-4">
                  <div className="flex flex-col w-1/2">
                    <div className="text-xs font-nunito font-normal leading-normal uppercase  text-black pr-5">
                      {lang.jobMinSalary}
                    </div>
                    <input
                      type="text"
                      id="minSalary"
                      name="minSalary"
                      onChange={formik.handleChange}
                      value={formik.values.minSalary}
                      className="py-3 w-full leading-tight border focus:outline rounded-md border-gray-300 px-5"
                    />
                    {formik.touched.minSalary && formik.errors.minSalary ? (
                      <div className="text-red-500 text-sm">{formik.errors.minSalary}</div>
                    ) : null}
                  </div>
                  <div className="flex flex-col w-1/2">
                    <div className="text-xs font-nunito font-normal leading-normal uppercase  text-black pr-5">
                      {lang.jobMaxSalary}
                    </div>
                    <input
                      type="text"
                      id="maxSalary"
                      name="maxSalary"
                      onChange={formik.handleChange}
                      value={formik.values.maxSalary}
                      className="py-3 w-full leading-tight border focus:outline rounded-md border-gray-300 px-5"
                    />
                    {formik.touched.maxSalary && formik.errors.maxSalary ? (
                      <div className="text-red-500 text-sm">{formik.errors.maxSalary}</div>
                    ) : null}
                  </div>
                </div>
              )}
              <div>
                <Checkbox
                  label={<div className="text-black text-xs font-normal">{lang.jobSalaryIsNego}</div>}
                  color="blue-gray"
                  text={lang.jobSalaryIsNego}
                  checked={formik.values.isNegotiable || false}
                  onChange={(e) => formik.setFieldValue('isNegotiable', e.target.checked)}
                  className="w-4 h-4"
                />
                <Checkbox
                  label={<div className="text-black text-xs font-normal">{lang.generalFixedSalary}</div>}
                  color="blue-gray"
                  text={lang.generalFixedSalary}
                  checked={formik.values.isSalaryFixed || false}
                  onChange={(e) => formik.setFieldValue('isSalaryFixed', e.target.checked)}
                  className="w-4 h-4"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center">
            <div className="w-96 text-base font-nunito font-semibold leading-normal text-black">
              {lang.jobExperienceRequired}
            </div>
            <div className="w-full pr-0 lg:pr-48">
              <DropdownMenuJob
                options={jobExperienceOption}
                onSelect={handleExperienceRequiredSelect}
                placeholder={lang.generalExperience}
              />
              {formik.touched.experienceRequired && formik.errors.experienceRequired ? (
                <div className="text-red-500 text-sm">{formik.errors.experienceRequired}</div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center">
            <div className="w-96 flex flex-col">
              <div className=" text-base font-nunito font-semibold leading-normal text-black">
                {lang.jobDesireSkills}
              </div>
              <div className="text-xs font-nunito font-normal leading-normal text-oppty-grey-19 pr-2">
                {lang.jobThisWillBeDisplayed}
              </div>
            </div>
            <div className="flex flex-col w-full pr-0 lg:pr-48">
              <div className="w-full">
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  value={skillOptions.find((option) => option.value === formik.values.skills)}
                  placeholder={lang.generalSearch}
                  onChange={(selectedOption) => {
                    const selectedValue = selectedOption || '';
                    console.log('selectedValue: ', selectedValue);
                    if (selectedValue) {
                      const isSkillAlreadyAdded = formik.values.skills.some(
                        (skill) => skill.value === selectedValue.value
                      );

                      if (!isSkillAlreadyAdded) {
                        formik.setFieldValue('skills', [...formik.values.skills, selectedValue]);
                      } else {
                        ToastBar.warning(['This skill already added', '此技能已添加', 'Kemahiran ini sudah ditambahkan'][localLang]);
                      }
                    }
                  }}
                  loadOptions={skillLoadOptions}
                  isDisabled={false}
                  isClearable={true}
                  isSearchable={true}
                  name="skills"
                  menuPlacement="top"
                  styles={{
                    control: (provided) => ({
                      ...provided
                      // height: 54
                    }),
                    input: (provided) => ({
                      ...provided,
                      boxShadow: 'none',
                      '& input': {
                        boxShadow: 'none !important',
                        outline: 'none !important'
                      }
                    })
                  }}
                />
                {formik.touched.skills && formik.errors.skills ? (
                  <div className="text-red-500 text-sm">{formik.errors.skills}</div>
                ) : null}
              </div>
              <div className="flex flex-wrap gap-x-4 pt-3">
                {formik.values?.skills?.map((skill, index) => {
                  return (
                    <GeneralTile
                      name={skill.label}
                      uniqueKey={index}
                      key={index}
                      onDelete={() => handleDeleteSkill(index)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center">
            <div className="w-96 text-base font-nunito font-semibold leading-normal text-black">
              {lang.jobDoYouWantQuestionnaire}?
            </div>
            <div className="w-full flex flex-col pr-0 lg:pr-48">
              <div className="-ml-3 -mt-2">
                <Checkbox
                  label={<div className="text-black text-sm font-normal">{lang.generalYes}</div>}
                  color="blue-gray"
                  checked={formik.values.questionnaire || false}
                  onChange={handleQuestionCheckboxChange}
                  className="w-6 h-6"
                />
              </div>
              {formik.values.questionnaire === 1 && (
                <div className="w-full flex flex-col lg:flex-row gap-x-1">
                  <div className="w-full lg:w-2/3">
                    <DropdownMenuQuestion
                      options={questionOptions}
                      value={getLabelByValue(selectedQuestion.question, questionOptions)}
                      onSelect={({ value }) => setSelectedQuestion({ ...selectedQuestion, question: value })}
                      placeholder={lang.generalSelectQuestion}
                    />
                  </div>
                  <div className="w-full lg:w-1/3">
                    <DropdownMenuQuestion
                      options={answerOptions}
                      value={getLabelByValue(selectedQuestion.preferredAnswer, answerOptions)}
                      onSelect={({ value }) => setSelectedQuestion({ ...selectedQuestion, preferredAnswer: value })}
                      placeholder={lang.generalSelectAnswer}
                    />
                  </div>
                </div>
              )}
              {formik.touched.questions && formik.errors.questions ? (
                <div className="text-red-500 text-sm">{formik.errors.questions}</div>
              ) : null}
              {formik.values?.questions?.length > 0 && (
                <div className="w-full flex flex-col gap-y-3 border-[1px] rounded-md border-gray-300 p-5 mt-5">
                  {formik.values.questions?.map((question, index) => (
                    <QuestionCard question={question} onDeleteQuestion={onDeleteQuestion} key={index} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-end flex-row gap-x-2 pb-5">
          <button
            onClick={() => navigate(`/employer/dashboard`)}
            type="button"
            className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
          >
            {lang.profileCancel}
          </button>
          <button
            disabled={!employer?.companyIndustry}
            type="submit"
            className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md disabled:bg-gray-500 disabled:text-white"
          >
            {lang.companyPostAJob}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateJobForm;
