import { useParams } from 'react-router-dom';
import Header from '../../common/header';
import Introduction from './introduction';
import OfficeTeam from './officeTeam';
import Benefits from './benefits';
import AvailableJobs from './availableJobs';
import Events from './events';
import Footer from '../../common/footer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getEmployerById } from '../../actions/employerAction';

const Employer = () => {
  const dispatch = useDispatch();

  const { employer } = useParams();

  useEffect(() => {
    dispatch(getEmployerById(employer));
  }, []);
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className='flex flex-col justify-center items-center w-full'>
        <div className="max-w-[1200px] bg-white rounded-xl">
          <Introduction />
          {/* <Events /> */}
          {/* <OfficeTeam /> */}
          <Benefits />
          <AvailableJobs />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Employer;
