// import DropdownMenuJob from '../../common/dropdownJob';
import { useSelector } from 'react-redux';
import DropdownMenuJobLang from '../../common/dropdownJobLang';
import { answerOptions, questionOptions } from '../../constant/dropdownConstOptions';
import { getLabelByValue } from '../../utils/functions';

const QuestionCard = ({ question, index, onChangeHandler }) => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <div className="flex items-center gap-x-4">
      <div className="text-lg">
        {index + 1}. {getLabelByValue(question?.question, questionOptions)}
      </div>
      <div>
        <DropdownMenuJobLang
          options={answerOptions}
          value={question?.answer}
          placeholder={lang.profileSelect}
          onSelect={onChangeHandler}
        />
      </div>
    </div>
  );
};

export default QuestionCard;
