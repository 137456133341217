import { LEFT_NAV_BAR_TOGGLE_STATUS, SET_CANDIDATE_SEARCH, SET_SELECTED_TEMPLATE } from '../actions/generalAction';

const initialState = {
  leftNavBarToggle: false,
  selectedTemplate: null,
  candidateFilter: {
    title: '',
    skills: [],
    country: ''
  }
};

export const generalReducer = (state = initialState, action) => {
  console.log('action.payload: ', action.payload);
  switch (action.type) {
    case LEFT_NAV_BAR_TOGGLE_STATUS:
      return {
        ...state,
        leftNavBarToggle: action.payload?.leftNavBarToggle
      };

    case SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload
      };

    case SET_CANDIDATE_SEARCH:
      return {
        ...state,
        candidateFilter: action.payload
      };

    default:
      return state;
  }
};

export default generalReducer;
