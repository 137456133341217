import { useSelector } from 'react-redux';
import recruiterPortalImg from '../../../assets/images/recruiter.png';
import ForwardIcon from '@mui/icons-material/Forward';
import { useNavigate } from 'react-router-dom';

const RecruitingPortal = () => {
  const navigate = useNavigate();

  const lang = useSelector((state) => state.language.lang);

  return (
    <div className="container">
      <div className="grid grid-cols-5 xl:gap-4 p-5">
        <div className="col-span-3">
          <div className="pb-[10px] md:pb-[15px] lg:pb-[40px] text-[25px] md:text-[40px] lg:text-[60px] xl:text-[80px] dm-serif-font font-medium md:leading-[40px] lg:leading-[58px] xl:leading-[96px] tracking-[-2.4px] leading-[25px] text-oppty-black">
            {lang.generalRecruitingPortal} <span className="text-oppty-blue">{lang.generalHireFaster}</span> {lang.generalFindThe}{' '}
            <span className="text-oppty-yellow-2">{lang.generalRightTalents}</span>
          </div>
          <div className="md:pr-[20%] pr-[80px] outfit-font text-[8px] md:text-[15px] lg:text-3xl">
            {lang.employeeHomeTakeYourBusiness}
          </div>
          <div className="pr-[60px] lg:pr-[38%] space-y-[2px] md:py-5 lg:py-10 py-1">
            <div className="flex flex-row items-center space-x-2 font-bold">
              <ForwardIcon
                sx={{
                  fontSize: 60,
                  '@media (max-width: 600px)': { fontSize: 10 },
                  '@media (min-width: 601px) and (max-width: 1024px)': { fontSize: 40 }
                }}
              />
              <div className="text-[8px] md:text-[15px] lg:text-[25px]">{lang.employeeHomeBullet1}</div>
            </div>
            <div className="flex flex-row items-center space-x-2 font-bold">
              <ForwardIcon
                sx={{
                  fontSize: 60,
                  '@media (max-width: 600px)': { fontSize: 10 },
                  '@media (min-width: 601px) and (max-width: 1024px)': { fontSize: 40 }
                }}
              />
              <div className="text-[8px] md:text-[15px] lg:text-[25px]">{lang.employeeHomeBullet2}</div>
            </div>
            {/* <div className="flex flex-row items-center space-x-2 font-bold">
              <ForwardIcon
                sx={{
                  fontSize: 60,
                  '@media (max-width: 600px)': { fontSize: 10 },
                  '@media (min-width: 601px) and (max-width: 1024px)': { fontSize: 40 }
                }}
              />
              <div className="text-[8px] md:text-[15px] lg:text-[25px]">{lang.employeeHomeBullet3}</div>
            </div> */}
          </div>
          <button
            onClick={() => navigate('/employer-sign-up')}
            className="w-24 lg:w-80 md:w-52 h-6 lg:h-16 md:h-10 flex-shrink-0 border-2 text-[8px] md:text-[12px] lg:text-[20px] rounded-md md:rounded-xl border-oppty-black-2 bg-oppty-black-2 text-white"
          >
            {lang.employeeHomeGetStartBtn}
          </button>
        </div>
        <div className="col-span-2 p-4">
          <img src={recruiterPortalImg} alt="Logo" />
        </div>
      </div>
    </div>
  );
};

export default RecruitingPortal;
