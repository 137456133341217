import React, { useState } from 'react';
import { useEffect } from 'react';
import { timestampToDate } from '../../../utils/functions';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EventDetailModal = ({ event, closeModal }) => {
  console.log('event:', event);

  const [value, setValue] = useState({
    date: null,
    time: null,
    interviewLink: null
  });

  const lang = useSelector((state) => state.language.lang);

  useEffect(() => {
    const dateObject = new Date(event?.start);

    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    const formattedTime = `${formattedHours}:${formattedMinutes}`;

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    console.log('Formatted date:', formattedDate);
    setValue({ ...value, date: formattedDate, time: formattedTime, interviewLink: event?.interviewLink });
  }, []);

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-30">
        <div className="bg-white flex flex-col w-[500px] px-5 md:px-10 py-10 rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">{event?.title}</h2>
            <button
              onClick={closeModal}
              className="text-lg font-bold hover:bg-red-500 px-2 rounded-md hover:text-white"
            >
              X
            </button>
          </div>
          <h2 className="text-lg font-semibold">{event?.desc}</h2>
          <div className="pt-5">
            <h2 className="text-lg font-semibold flex">
              <div className="font-bold w-14">{lang.generalStart}: </div>
              <div className="text-green-500">{timestampToDate(event?.start)}</div>
            </h2>
            <h2 className="text-lg font-semibold flex">
              <div className="font-bold w-14">{lang.generalEnd}: </div>
              <div className="text-red-500">{timestampToDate(event?.end)}</div>
            </h2>
            <h2 className="text-lg font-semibold flex">
              <div className="font-bold w-32">{lang.generalInterviewLink}: </div>
              {event?.interviewLink ? (
                <Link to={event?.interviewLink} target="_blank" className="text-blue-500 hover:text-blue-600">
                  {event?.interviewLink}
                </Link>
              ) : (
                <div className="text-gray-400">Please contact employer</div>
              )}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetailModal;
