import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import AsyncSelect from 'react-select/async';
import skillOptions from '../../constant/skills.json';
import countryOptions from '../../constant/countries.json';
import SearchTile from '../../common/searchTile';
import { fetchSearchCandidates, getCandidateSearch } from '../../actions/employerAction';
import { ToastBar } from '../../common/toastbar';
import { setCandidateSearch } from '../../actions/generalAction';
import { useEffect, useMemo, useState } from 'react';

const Search = ({ setClickedSearch }) => {
  const dispatch = useDispatch();

  const [newFilter, setNewFilter] = useState({});

  const lang = useSelector((state) => state.language.lang);
  const filter = useSelector((state) => state.general.candidateFilter);

  console.log('newFilter: ', newFilter);

  const initialValues = useMemo(
    () => ({
      title: filter?.title || '',
      skills: filter?.skills || [],
      country: filter?.country || ''
    }),
    [filter]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      dispatch(setCandidateSearch(values));
      const result = await dispatch(getCandidateSearch(values));
      if (!result?.errorMsg) {
        setClickedSearch(true);
      }
      console.log('submit result: ', result);
    }
  });

  useEffect(() => {
    setNewFilter(filter);
  }, [filter]);

  const skillLoadOptions = (inputValue, callback) => {
    const filteredOptions = skillOptions
      .filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 20);
    console.log('filteredOptions skill: ', filteredOptions);

    callback(filteredOptions);
  };

  const countryLoadOptions = (inputValue, callback) => {
    const filteredOptions = countryOptions
      .filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 20);

    console.log('filteredOptions: ', filteredOptions);
    callback(filteredOptions);
  };

  const handleDeleteSkill = (index) => {
    const deleteSkill = formik.values.skills[index];
    const updatedSkills = formik.values.skills.filter((_, i) => i !== index);

    console.log('deleteSkill: ', deleteSkill);
    formik.setFieldValue('skills', updatedSkills);
  };

  const clearFilter = () => {
    setClickedSearch(false);
    dispatch(
      setCandidateSearch({
        title: '',
        skills: [],
        country: ''
      })
    );
    formik.setValues({
      title: '',
      skills: [],
      country: ''
    });
    dispatch(fetchSearchCandidates([]));
  };

  const handleApply = () => {
    formik.submitForm();
  };

  return (
    <div className="flex flex-col justify-center p-2 lg:p-5">
      <div className="w-full flex flex-wrap justify-between gap-2 items-center">
        <div className="text-3xl font-bold">{lang.generalCandidateSearch}</div>
        <button
          type="button"
          onClick={() => handleApply()}
          className="text-bases font-bold bg-oppty-blue text-white px-3 py-3 rounded-md hover:shadow-lg"
        >
          {lang.generalApplyFilter}
        </button>
      </div>
      <form onSubmit={formik.handleSubmit} className="flex flex-row flex-wrap lg:flex-nowrap gap-x-5 gap-y-2 pt-5">
        <div className="flex w-full lg:w-1/3">
          <AsyncSelect
            cacheOptions
            defaultOptions
            value={skillOptions.find((option) => option.value === formik.values.skills)}
            onChange={(selectedOption) => {
              const selectedValue = selectedOption || '';
              if (formik.values.skills.length < 3) {
                if (selectedValue) formik.setFieldValue('skills', [...formik.values.skills, selectedValue]);
                // if (selectedValue) onChangeFilter(selectedValue, 'skills');
              } else {
                ToastBar.warning('You can select three skills');
              }
            }}
            loadOptions={skillLoadOptions}
            isDisabled={false}
            isClearable={true}
            isSearchable={true}
            name="skills"
            id="skills"
            menuPlacement="bottom"
            styles={{
              control: (provided) => ({
                ...provided,
                height: 45
              }),
              input: (provided) => ({
                ...provided,
                boxShadow: 'none',
                '& input': {
                  boxShadow: 'none !important',
                  outline: 'none !important',
                },
              }),
            }}
            placeholder={lang.profileSelectSkill}
            className="w-full"
          />
        </div>
        <div className="flex w-full lg:w-1/3">
          <input
            type="text"
            id="title"
            name="title"
            placeholder={lang.generalArticleSearchByTitle}
            onBlur={(e) => {
              formik.setFieldValue('title', e.target.value);
              // onChangeFilter(e.target.value, 'title');
              document.getElementById('title').value = '';
            }}
            className="py-2.5 w-full leading-tight border focus:outline rounded-md border-gray-300 px-2.5"
          />
        </div>
        <div className="flex w-full lg:w-1/3">
          <AsyncSelect
            cacheOptions
            defaultOptions
            value={countryOptions.find((option) => option.value === formik.values.country)}
            onChange={(selectedOption) => {
              const selectedValue = selectedOption || '';
              if (selectedValue) formik.setFieldValue('country', selectedValue.value);
              // if (selectedValue) onChangeFilter(selectedValue, 'country');
            }}
            loadOptions={countryLoadOptions}
            isDisabled={false}
            isClearable={true}
            isSearchable={true}
            name="countries"
            id="countries"
            menuPlacement="bottom"
            styles={{
              control: (provided) => ({
                ...provided,
                height: 45
              }),
              input: (provided) => ({
                ...provided,
                boxShadow: 'none',
                '& input': {
                  boxShadow: 'none !important',
                  outline: 'none !important',
                },
              }),
            }}
            placeholder={lang.generalSelectCountry}
            className="w-full"
          />
        </div>
      </form>
      {formik.values?.skills?.length > 0 || formik.values?.title || formik.values?.country ? (
        <div className="flex flex-wrap gap-3 pt-2 items-center">
          {formik.values?.skills?.map((skill, index) => {
            return (
              <SearchTile name={skill.label} uniqueKey={index} key={index} onDelete={() => handleDeleteSkill(index)} />
            );
          })}
          {formik.values?.title && (
            <SearchTile name={formik.values?.title} onDelete={() => formik.setFieldValue('title', '')} />
          )}
          {formik.values?.country && (
            <SearchTile name={formik.values?.country} onDelete={() => formik.setFieldValue('country', '')} />
          )}
          <div onClick={() => clearFilter()} className="text-sm font-semibold underline cursor-pointer">
            {lang.generalClearFilter}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Search;
