import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/headerLogo.svg'
import Header from '../../common/header'
import { useDispatch, useSelector } from 'react-redux'
import { verifyEmployerOtp } from '../../actions/employerAction'
import { useAuthContext } from '../../context/authContext';

const VerifyOTP = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const lang = useSelector((state) => state.language.lang)
  const { setAuthUser } = useAuthContext();

  const [otp, setOtp] = useState(Array(6).fill(''))
  const [timeLeft, setTimeLeft] = useState(300)
  const inputsRef = useRef([])

  const employerPreRegister = useSelector((state) => state.employerPreRegister)
  console.log('employerPreRegister: ', employerPreRegister)

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000)
      return () => clearTimeout(timer)
    } else {
      // Redirect when time expires
      navigate('/employersignup')
    }
  }, [timeLeft, navigate])

  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60)
    const seconds = timeLeft % 60
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  const focusNextInput = (index, value) => {
    const numericValue = value.replace(/[^0-9]/g, '') // Allow only numbers

    if (numericValue.length === 1 && index < 5) {
      inputsRef.current[index + 1].focus()
    }
    const newOtp = [...otp]
    newOtp[index] = numericValue
    setOtp(newOtp)
  }

  const focusPrevInput = (index, key) => {
    if (key === 'Backspace' && index > 0 && otp[index] === '') {
      inputsRef.current[index - 1].focus()
    }
  }

  const handleResend = () => {
    console.log('resend')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('otp: ', otp.join(''))
    const inputData = {
      email: employerPreRegister.email,
      password: employerPreRegister.password,
      companyName: employerPreRegister.companyName,
      pin: otp.join(''),
      otpData: employerPreRegister.otpData
    }
    await dispatch(verifyEmployerOtp(inputData, navigate, setAuthUser))

    // try {
    //   await dispatch(verifyOtp(inputData))
    //   await dispatch(fetchUserPreRegComplete())
    //   navigate('/login')
    // } catch (error) {
    //   console.error('OTP Verification failed:', error)
    // }
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-12 text-center space-y-12">
        <img src={logo} alt="Logo" className="mx-auto w-36 lg:w-[220px] lg:mt-[-10px]" />
        <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
          <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
            <div className="flex flex-col items-center justify-center text-center space-y-2">
              <div className="font-semibold text-3xl">
                <p>{lang.verifyEmail}</p>
              </div>
              <div className="flex flex-row text-sm font-medium text-gray-400">
                <p>
                  {lang.verifyCode} {employerPreRegister && employerPreRegister.email}
                </p>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-12">
                <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs space-x-2">
                  {Array.from({ length: 6 }, (_, index) => (
                    <div key={index} className="w-16 h-16">
                      <input
                        className="w-full h-full text-center px-4 outline-none rounded-xl border border-gray-400 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                        type="text"
                        maxLength="1"
                        value={otp[index]}
                        onChange={(e) => focusNextInput(index, e.target.value)}
                        onKeyDown={(e) => focusPrevInput(index, e.key)}
                        ref={(el) => (inputsRef.current[index] = el)}
                      />
                    </div>
                  ))}
                </div>
                <div className="flex flex-col space-y-5">
                  <div className="flex justify-between items-center px-2">
                    <div className="text-gray-600">
                      {timeLeft > 0 ? (
                        <span>
                          {lang.verifyTime}: {formatTime()}
                        </span>
                      ) : (
                        <span>Time expired</span>
                      )}
                    </div>

                    <div className="flex items-center text-sm font-medium text-gray-500 space-x-1">
                      <p>{lang.verifyDidnt}</p>
                      <button type="button" onClick={handleResend} className="text-blue-600">
                        {lang.verifyResend}
                      </button>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="w-full py-5 text-center text-sm text-white bg-blue-700 rounded-xl border-none shadow-sm"
                  >
                    {lang.verifyAccount}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyOTP
