const PrivacyBodyEnglish = () => {
  return (
    <div className="bg-white text-gray-800">
      <div className="container mx-auto p-14">
        <h1 className="text-3xl font-bold mb-4">Oppty.ai Privacy Policy</h1>
        <p className="text-sm text-gray-600 mb-6">Last Updated: 14 August 2024</p>

        <section className="mb-6">
          <p className="text-base leading-relaxed mb-4">
            Oppty.ai ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we
            collect, use, disclose, and safeguard your information when you visit our website [Oppty.ai URL] ("Website")
            and use our services ("Services"). By using the Website and Services, you agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">1. Information We Collect</h2>
          <p className="text-base leading-relaxed mb-4">
            We may collect the following types of information from users of our Website and Services:
          </p>
          <h3 className="text-lg font-medium mb-1">1.1 Personal Information:</h3>
          <p className="text-base leading-relaxed mb-4">
            When you register an account, create a profile, apply for jobs, or post job listings, we may collect
            personal information, including but not limited to:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Date of birth</li>
            <li>Gender</li>
            <li>Nationality</li>
            <li>Educational qualifications</li>
            <li>Work experience</li>
            <li>Resume/CV</li>
            <li>Job preferences</li>
            <li>Company information (for employers)</li>
            <li>Payment information (for paid services)</li>
          </ul>
          <h3 className="text-lg font-medium mb-1">1.2 Non-Personal Information:</h3>
          <p className="text-base leading-relaxed mb-4">
            We may also collect non-personal information that does not directly identify you, such as:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>IP address</li>
            <li>Browser type and version</li>
            <li>Operating system</li>
            <li>Referring website</li>
            <li>Pages viewed</li>
            <li>Date and time of access</li>
            <li>Device information</li>
          </ul>
          <h3 className="text-lg font-medium mb-1">1.3 Cookies and Tracking Technologies:</h3>
          <p className="text-base leading-relaxed mb-4">
            We use cookies and similar tracking technologies to track the activity on our Website and hold certain
            information. Cookies are files with a small amount of data that may include an anonymous unique identifier.
            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if
            you do not accept cookies, you may not be able to use some portions of our Services.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">2. How We Use Your Information</h2>
          <p className="text-base leading-relaxed mb-4">
            We use the information we collect for various purposes, including:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              Providing Services: To facilitate job matching between job seekers and employers, process applications,
              and manage your account.
            </li>
            <li>
              Communication: To communicate with you, including responding to your inquiries, sending updates,
              notifications, and providing customer support.
            </li>
            <li>
              Personalization: To personalize your experience on the Website by showing content, job listings, and
              advertisements that match your preferences.
            </li>
            <li>
              Analytics and Improvements: To monitor and analyze usage trends, track the effectiveness of our marketing
              efforts, improve our Website and Services, and develop new features.
            </li>
            <li>
              Compliance and Legal Obligations: To comply with legal obligations, resolve disputes, and enforce our
              agreements.
            </li>
            <li>
              Marketing: To send you promotional materials, newsletters, or other information about our Services that we
              believe may be of interest to you. You may opt-out of receiving these communications at any time by
              following the unsubscribe instructions provided in the communication.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">3. How We Share Your Information</h2>
          <ul className="list-disc list-inside mb-4">
            <li>
              With Employers: If you are a job seeker, we may share your information with employers and recruiters to
              facilitate the job application process.
            </li>
            <li>
              Service Providers: We may share your information with third-party service providers who perform services
              on our behalf, such as payment processing, data analysis, email delivery, hosting services, and customer
              service.
            </li>
            <li>
              Legal Requirements: We may disclose your information if required to do so by law or in response to valid
              requests by public authorities (e.g., a court or government agency).
            </li>
            <li>
              Business Transfers: If we are involved in a merger, acquisition, or asset sale, your information may be
              transferred as part of that transaction. We will notify you before your personal information is
              transferred and becomes subject to a different privacy policy.
            </li>
            <li>
              With Your Consent: We may share your information with other third parties with your consent or at your
              direction.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">4. Data Retention</h2>
          <p className="text-base leading-relaxed mb-4">
            We will retain your personal information only for as long as is necessary for the purposes set out in this
            Privacy Policy. We will retain and use your personal information to the extent necessary to comply with our
            legal obligations, resolve disputes, and enforce our legal agreements and policies.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">5. Security of Your Information</h2>
          <p className="text-base leading-relaxed mb-4">
            We take reasonable measures to protect the security of your personal information. However, no method of
            transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee its
            absolute security. You are responsible for maintaining the confidentiality of your account information.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">6. Your Rights and Choices</h2>
          <ul className="list-disc list-inside mb-4">
            <li>
              Access and Correction: You have the right to access and correct your personal information held by us. You
              can do so by logging into your account and updating your profile or by contacting us directly.
            </li>
            <li>
              Data Portability: You may request a copy of the personal data we hold about you in a structured, commonly
              used, and machine-readable format.
            </li>
            <li>
              Deletion: You may request that we delete your personal information, subject to certain exceptions provided
              by law. We will take reasonable steps to delete your information from our records, but we may need to
              retain certain information for legal or operational reasons.
            </li>
            <li>
              Opt-Out of Marketing Communications: You may opt-out of receiving marketing communications from us by
              following the unsubscribe instructions provided in the communication or by contacting us directly.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">7. Third-Party Links</h2>
          <p className="text-base leading-relaxed mb-4">
            Our Website may contain links to third-party websites. We are not responsible for the privacy practices or
            content of such websites. We encourage you to review the privacy policies of any third-party sites you
            visit.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">8. Children's Privacy</h2>
          <p className="text-base leading-relaxed mb-4">
            Our Services are not intended for individuals under the age of 18. We do not knowingly collect personal
            information from children under 18. If we become aware that we have collected personal information from a
            child under 18, we will take steps to delete such information.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">9. International Transfers</h2>
          <p className="text-base leading-relaxed mb-4">
            Your information may be transferred to, and maintained on, computers located outside of your state,
            province, country, or other governmental jurisdiction where the data protection laws may differ from those
            in your jurisdiction. By using our Services, you consent to the transfer of your information to Singapore
            and other jurisdictions where we operate.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">10. Changes to This Privacy Policy</h2>
          <p className="text-base leading-relaxed mb-4">
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on this page and updating the "Last Updated" date. You are advised to review this Privacy
            Policy periodically for any changes.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyBodyEnglish;
