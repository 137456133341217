import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSocketContext } from '../context/socketContext';
import { setMessages, updateUnreadCount } from '../actions/messageAction';
import notificationSound from "../assets/sounds/notification.mp3";
import { fetchMessages } from '../actions/messageAction';
import { updateNotificationUnreadCount } from '../actions/messageNotificationAction';

const useListenMessages = () => {
    const { socket } = useSocketContext();
    const dispatch = useDispatch();
    const currentId = useSelector((state) => state.currentId.currentId);

    useEffect(() => {
        
        const handleNewMessage = (newMessage) => {

            console.log("NEW MESSAGE")

            console.log(newMessage);

            // newMessage.shouldShake = true;
            // const sound = new Audio(notificationSound);
            // sound.play();
            dispatch(fetchMessages(newMessage.senderId));

            dispatch(updateUnreadCount(newMessage.senderId));

            if (newMessage.senderId != currentId) {

            dispatch(updateNotificationUnreadCount(newMessage.senderId));

            }
            
           // dispatch(setMessages((prevMessages) => [...prevMessages, newMessage]));
        };

        socket?.on("newMessage", handleNewMessage);

        return () => {
            socket?.off("newMessage");
        };
    }, [socket, dispatch]);
};

export default useListenMessages;
