import TestimonialCard from './testimonialCard';

const RecommendationsSection = ({ user }) => {
  return (
    <div className="flex flex-col w-full p-5 md:p-20 gap-y-12 bg-[#FFF6ED]">
      <div className="flex justify-between h-full items-center">
        <div className="text-5xl md:text-[90px] font-extrabold md:leading-[80px]">
          <div className="text-[#604E56]">What they say</div>
          <div className="text-[#F84A94]">about me</div>
        </div>
      </div>
      <div className="flex flex-wrap gap-5 w-full justify-center">
        {user?.testimonial?.map((testimonial, index) => {
          return <TestimonialCard key={index} testimonial={testimonial} />;
        })}
      </div>
    </div>
  );
};

export default RecommendationsSection;
