import { useSelector } from 'react-redux';

const JobsApplicantsStatus = () => {
  const lang = useSelector((state) => state.language.lang);
  const dashboardDetails = useSelector((state) => state.employer?.dashboard);

  return (
    <div className="flex flex-wrap lg:flex-nowrap flex-row lg:flex-col gap-3 mt-3 lg:mt-0">
      <div className="flex flex-col border-[1px] p-4 gap-y-2">
        <div className="text-xl font-nunito font-bold text-black">{lang.companyJobsActive}</div>
        <div className="flex items-end gap-x-3">
          <div className="text-3xl lg:text-7xl font-nunito font-bold text-black">{dashboardDetails?.activeJobs}</div>
          <div className="text-sm lg:text-xl font-nunito font-normal text-oppty-grey-19 mb-2">{lang.companyJobsActive}</div>
        </div>
      </div>
      <div className="flex flex-col border-[1px] p-4 gap-y-4">
        <div className="text-xl font-nunito font-black text-black">{lang.companyApplicantsSummary}</div>
        <div className="flex items-end gap-x-3">
          <div className="text-3xl lg:text-7xl font-nunito font-bold text-black">{dashboardDetails?.totalApplicants}</div>
          <div className="text-sm lg:text-xl font-nunito font-normal text-oppty-grey-19 mb-2">{lang.generalApplicant}</div>
        </div>
        {/* <div className="h-4 w-full flex">
          <div className="h-full w-[45%] bg-oppty-purple-2"></div>
          <div className="h-full w-[15%] bg-oppty-green-6"></div>
          <div className="h-full w-[10%] bg-oppty-blue-7"></div>
          <div className="h-full w-[5%] bg-oppty-yellow-4"></div>
          <div className="h-full w-[25%] bg-oppty-red-1"></div>
        </div>
        <div className="flex flex-wrap gap-y-2 pt-5">
          <div className="flex w-1/2 gap-x-1 items-center">
            <div className="w-5 h-5 bg-oppty-purple-2 rounded-lg"></div>
            <div className="text-base font-nunito font-medium text-oppty-grey-19">
              Full-Time: <span className="text-black">45</span>
            </div>
          </div>
          <div className="flex w-1/2  gap-x-1 items-center">
            <div className="w-5 h-5 bg-oppty-green-6 rounded-lg"></div>
            <div className="text-base font-nunito font-medium text-oppty-grey-19">
              Part-Time: <span className="text-black">24</span>
            </div>
          </div>
          <div className="flex w-1/2  gap-x-1 items-center">
            <div className="w-5 h-5 bg-oppty-blue-7 rounded-lg"></div>
            <div className="text-base font-nunito font-medium text-oppty-grey-19">
              Remote: <span className="text-black">22</span>
            </div>
          </div>
          <div className="flex w-1/2 gap-x-1 items-center">
            <div className="w-5 h-5 bg-oppty-yellow-4 rounded-lg"></div>
            <div className="text-base font-nunito font-medium text-oppty-grey-19">
              Internship: <span className="text-black">32</span>
            </div>
          </div>
          <div className="flex w-1/2  gap-x-1 items-center">
            <div className="w-5 h-5 bg-oppty-red-1 rounded-lg"></div>
            <div className="text-base font-nunito font-medium text-oppty-grey-19">
              Contract: <span className="text-black">30</span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default JobsApplicantsStatus;
