import { useSelector } from 'react-redux'
import goodByePic1 from '../../../assets/images/clientGoodBye1.jpeg'
import goodByePic2 from '../../../assets/images/clientGoodBye2.jpeg'
import goodByePic3 from '../../../assets/images/clientGoodBye3.jpeg'
import goodByePic3Layout from '../../../assets/borderLayouts/clientGoodBye3Layout.svg'
import goodByePic2Shape from '../../../assets/borderLayouts/clientGoodbye2Shape.svg'

const SayGoodbye = () => {
  const lang = useSelector((state) => state.language.lang)

  return (
    <div className="flex flex-col justify-start items-center w-full pb-8 md:pb-20 lg:pb-40 md:px-4 lg:px-24 px-8">
      <div className="text-black text-center font-bold font-nunito text-xl md:text-4xl lg:text-5xl xl:text-6xl leading-[120%] tracking-tighter w-full capitalize pb-10 md:pb-20">
        {/* {lang.clientSayGoodbye} */}
        {lang.goodbyeTitlePart1} <span className="text-oppty-orange-2">{lang.goodbyeTitleGoodbye}</span> {lang.goodbyeTitlePart2}
      </div>
      <div className="flex flex-row md:px-5 md:gap-x-8 xl:gap-x-40">
        <div className="flex flex-col md:gap-y-5 gap-y-3">
          <div className="w-10 h-10 md:w-[100px] md:h-[100px] bg-oppty-yellow text-black text-center font-archivo md:text-4xl font-semibold leading-[54px] tracking-[0.54px] flex justify-center items-center">
            1
          </div>
          <div className="text-black font-nunito text-xs md:text-2xl xl:text-4xl font-bold md:leading-[42px]">
            {lang.clientTransformCV}
          </div>
          <div className="text-black font-nunito text-xs md:text-xl xl:text-2xl font-normal md:leading-[33px]">
            {lang.clientOurSystem}
          </div>
        </div>
        <div className="relative">
          <div className="w-28 h-28 md:w-80 md:h-80 overflow-hidden rounded-br-full bg-oppty-yellow-3 absolute top-14 left-14 md:top-28 md:left-40 z-10"></div>
          <div className="w-36 h-36 md:w-96 md:h-96 overflow-hidden rounded-full relative z-20">
            <img src={goodByePic1} alt="goodByePic1" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
      <div className="flex flex-row md:px-5 md:gap-x-24 lg:gap-x-48 xl:gap-x-48 pt-14 md:pt-72 gap-x-6">
        <div className="relative">
          {/* <div className="w-0 h-0 border-t-[350px] border-l-[350px] border-r-[400px] border-b-transparent border-l-transparent border-t-transparent border-r-oppty-pink-1 absolute right-10 bottom-52 z-10"></div> */}
          <img
            src={goodByePic2Shape}
            alt="goodByePic2Shape"
            className="w-[150px] md:w-[400px] h-[80px] md:h-[500px] absolute top-[-20px] left-[-50px] md:top-[-200px] md:left-[-80px] z-1"
          />
          <div className="w-32 h-32 md:w-64 md:h-64 lg:w-96 lg:h-96 overflow-hidden rounded-[20px] md:rounded-[80px] relative top-4 left-[10px] md:left-16 lg:left-32 z-20">
            <img src={goodByePic2} alt="goodByePic2" className="w-full h-full object-cover" />
          </div>
        </div>
        <div className="flex flex-col md:gap-y-5 gap-y-3">
          <div className="w-10 h-10 md:w-[100px] md:h-[100px] bg-oppty-pink-2 text-black text-center font-archivo md:text-4xl font-semibold leading-[54px] tracking-[0.54px] flex justify-center items-center">
            2
          </div>
          <div className="text-black font-nunito text-xs md:text-2xl xl:text-4xl font-bold md:leading-[42px]">
            {lang.generalPersonalized}
          </div>
          <div className="text-black font-nunito text-xs md:text-xl xl:text-2xl font-normal md:leading-[33px]">
            {lang.generalAdvice}
          </div>
        </div>
      </div>
      <div className="flex flex-row md:px-5 md:gap-x-8 xl:gap-x-40 pt-14 md:pt-24 lg:pt-48">
        <div className="flex flex-col gap-y-3 md:gap-y-5">
          <div className="w-10 h-10 md:w-[100px] md:h-[100px] bg-oppty-green-2 text-black text-center font-archivo md:text-4xl font-semibold leading-[54px] tracking-[0.54px] flex justify-center items-center">
            3
          </div>
          <div className="text-black font-nunito text-xs md:text-2xl xl:text-4xl font-bold md:leading-[42px]">
            {lang.generalStandOut}
          </div>
          <div className="text-black font-nunito text-xs md:text-xl xl:text-2xl font-normal md:leading-[33px]">
            {lang.generalShine}
          </div>
        </div>
        <div className="relative">
          <img
            src={goodByePic3Layout}
            alt="layout3"
            className="w-[550px] h-[220px] absolute top-[10px] md:top-[210px] left-[-10px] md:left-[-30px] z-1"
          />
          <div className="w-36 h-36 md:w-96 md:h-96 overflow-hidden rounded-full relative z-20">
            <img src={goodByePic3} alt="goodByePic3" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SayGoodbye
