import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../../common/footer';
import Header from '../../../common/header';
import TabGeneral from './tabContent/tabGeneral';
import TabPromote from './tabContent/tabPromote';
import TabBuild from './tabContent/tabBuild';
import TabManage from './tabContent/tabManage';
import Tabs from './tabs';
import FindYourDream from './findYourDream';
import SayGoodbye from './sayGoodbye';
import MostRecentJobs from './mostRecentJobs';
import WeHelpJobSeekers from './weHelpJobseekers';
import SeeHowPlatform from './seeHowPlatform';
import SignUpFor from './signUpFor';
import CareerResources from './careerResources';
import { useEffect, useRef } from 'react';
import { getRecentJobs } from '../../../actions/publicAction';

const CandidateLanding = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language.lang);

  const tabsRef = useRef(null);

  const tabs = [
    { label: lang.employeeHomeTabGeneral, component: <TabGeneral /> },
    { label: lang.employeeHomeTabBuild, component: <TabBuild /> },
    { label: lang.employeeHomeTabPromote, component: <TabPromote /> },
    { label: lang.employeeHomeTabManage, component: <TabManage /> }
  ];

  useEffect(() => {
    dispatch(getRecentJobs());
  }, []);

  const scrollToTabs = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="flex flex-col min-h-screen min-w-[340px] justify-center items-center">
      <Header />
      <div className="flex flex-col items-center  w-full md:py-5 bg-white max-w-[1200px]">
        <FindYourDream />
        <SayGoodbye />
        <MostRecentJobs />
        <WeHelpJobSeekers />
        <SeeHowPlatform />
        {/* <SignUpFor /> */}
        {/* <CareerResources /> */}
        <div ref={tabsRef} className='mt-20'>
          <Tabs tabs={tabs} />
        </div>
      </div>
      <Footer scrollToTabs={scrollToTabs} />
    </div>
  );
};

export default CandidateLanding;
