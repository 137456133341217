const TermsBodyChinese = () => {
  return (
    <body class="bg-white text-gray-800">
      <div class="container mx-auto p-14">
        <h1 class="text-3xl font-bold mb-4">Oppty.ai 服务条款</h1>
        <p class="text-sm text-gray-600 mb-6">最后更新日期：2024年8月14日</p>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">1. 条款的接受</h2>
          <p class="text-base leading-relaxed mb-4">
            通过访问或使用我们的网站和服务，您同意遵守这些条款以及我们的隐私政策。如果您不同意这些条款的任何部分，您可能无法使用网站或服务。
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">2. 资格</h2>
          <h3 class="text-lg font-medium mb-1">2.1 求职者：</h3>
          <p class="text-base leading-relaxed mb-4">
            要在 Oppty.ai 上创建候选人资料或申请职位，您必须年满 18
            岁。通过将网站作为求职者使用，您声明并保证您符合此年龄要求，并且您在资料或申请中提供的任何信息都是准确、最新和完整的。
          </p>
          <h3 class="text-lg font-medium mb-1">2.2 雇主：</h3>
          <p class="text-base leading-relaxed mb-4">
            使用我们服务发布职位列表或搜索候选人的雇主和招聘人员必须确保他们有权代表其公司或组织签署具有约束力的协议。
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">3. 账户注册与安全</h2>
          <h3 class="text-lg font-medium mb-1">3.1 账户创建：</h3>
          <p class="text-base leading-relaxed mb-4">
            要访问服务的某些功能，包括职位发布和候选人搜索，您可能需要创建一个账户。您同意在注册过程中提供准确、最新和完整的信息，并在必要时更新这些信息以保持准确、最新和完整。
          </p>
          <h3 class="text-lg font-medium mb-1">3.2 账户安全：</h3>
          <p class="text-base leading-relaxed mb-4">
            您有责任维护账户登录信息的机密性，并对您的账户下发生的所有活动负责。您同意立即通知我们任何未经授权的账户使用或其他安全漏洞。
          </p>
          <h3 class="text-lg font-medium mb-1">3.3 账户终止：</h3>
          <p class="text-base leading-relaxed mb-4">
            如果在注册过程中或之后提供的信息被证明是不准确、虚假或误导性的，或者您发现违反这些条款的行为，我们保留暂停或终止您的账户的权利。
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">4. 用户行为</h2>
          <h3 class="text-lg font-medium mb-1">4.1 求职者：</h3>
          <ul class="list-disc list-inside mb-4">
            <li>仅将服务用于寻找就业机会。</li>
            <li>确保您资料和职位申请中的所有信息都是真实、准确和最新的。</li>
            <li>避免代表他人申请职位或与他人共享您的账户。</li>
          </ul>
          <h3 class="text-lg font-medium mb-1">4.2 雇主：</h3>
          <ul class="list-disc list-inside mb-4">
            <li>仅将服务用于与招聘和就业相关的合法目的。</li>
            <li>发布仅包含准确描述和资格的合法职位空缺。</li>
            <li>不在职位发布或招聘过程中进行任何歧视性行为。</li>
            <li>不要求求职者从事任何与填补合法就业机会无关的目的。</li>
          </ul>
          <h3 class="text-lg font-medium mb-1">4.3 一般禁止行为：</h3>
          <ul class="list-disc list-inside mb-4">
            <li>将网站或服务用于任何非法目的或违反任何地方、国家或国际法律或规定。</li>
            <li>发布、传输或共享任何诽谤、淫秽、色情、冒犯或其他令人反感的内容。</li>
            <li>从事有害、欺诈、欺骗或误导的行为。</li>
            <li>试图干扰、损害系统的完整性或安全，或解密任何传输到或从运行网站的服务器的内容。</li>
            <li>上传或传输（或试图上传或传输）病毒、恶意软件或任何干扰网站或服务正常运行的材料。</li>
            <li>在未获得同意的情况下收集、收获或披露任何关于其他用户的信息，包括个人数据或联系信息。</li>
          </ul>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">5. 职位列表和申请</h2>
          <h3 class="text-lg font-medium mb-1">5.1 职位列表：</h3>
          <p class="text-base leading-relaxed mb-4">
            雇主对其职位列表的内容负全部责任。Oppty.ai
            不保证职位发布的准确性、完整性或合法性。我们保留删除任何违反这些条款或被认为不适当的职位列表的权利。
          </p>
          <h3 class="text-lg font-medium mb-1">5.2 职位申请：</h3>
          <p class="text-base leading-relaxed mb-4">
            求职者对其申请的内容负全部责任。Oppty.ai 不保证通过网站申请职位将导致面试或就业。
          </p>
          <h3 class="text-lg font-medium mb-1">5.3 无就业保证：</h3>
          <p class="text-base leading-relaxed mb-4">
            Oppty.ai 不保证求职者会获得就业机会或雇主会通过使用我们的服务填补职位空缺。
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">6. 知识产权</h2>
          <h3 class="text-lg font-medium mb-1">6.1 所有权：</h3>
          <p class="text-base leading-relaxed mb-4">
            网站上的内容、设计、图形和其他材料归 Oppty.ai
            或其许可方所有，并受到知识产权法律的保护。您同意在未获得我们事先书面同意的情况下，不复制、分发、修改或创建网站内容的衍生作品。
          </p>
          <h3 class="text-lg font-medium mb-1">6.2 商标：</h3>
          <p class="text-base leading-relaxed mb-4">
            网站上显示的所有商标、服务标志、徽标和商号均为 Oppty.ai
            或其各自所有者的财产。未经授权使用任何商标或徽标是严格禁止的。
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">7. 隐私</h2>
          <p class="text-base leading-relaxed mb-4">
            您的隐私对我们非常重要。请查看我们的隐私政策 [链接到隐私政策]
            以了解我们如何收集、使用和共享您的个人信息，包括与职位申请和发布相关的信息。
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">8. 支付与费用</h2>
          <h3 class="text-lg font-medium mb-1">8.1 定价和付款：</h3>
          <p class="text-base leading-relaxed mb-4">
            雇主可能需要支付费用以发布职位列表或访问某些高级功能。您同意按照向您展示的定价和付款条款支付所有费用。
          </p>
          <h3 class="text-lg font-medium mb-1">8.2 账单：</h3>
          <p class="text-base leading-relaxed mb-4">
            我们可能使用第三方支付处理器通过与您的账户关联的支付账户向您收费。支付处理的过程受支付处理器的条款、条件和隐私政策的约束。我们对支付处理器的错误不承担责任。
          </p>
          <h3 class="text-lg font-medium mb-1">8.3 退款：</h3>
          <p class="text-base leading-relaxed mb-4">除非我们的退款政策另有说明，否则所有支付费用不予退款。</p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">9. 第三方服务与链接</h2>
          <h3 class="text-lg font-medium mb-1">9.1 第三方服务：</h3>
          <p class="text-base leading-relaxed mb-4">
            网站和服务可能包括指向不由 Oppty.ai
            拥有或控制的第三方网站或服务（“第三方服务”）的链接。我们不认可或承担任何第三方服务的责任。
          </p>
          <h3 class="text-lg font-medium mb-1">9.2 第三方链接：</h3>
          <p class="text-base leading-relaxed mb-4">
            我们的网站可能包含指向第三方网站或资源的链接。您承认并同意，Oppty.ai
            不对这些网站或资源的可用性或准确性以及这些网站或资源上的内容、产品或服务承担责任。
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">10. 免责声明与责任限制</h2>
          <h3 class="text-lg font-medium mb-1">10.1 免责声明：</h3>
          <p class="text-base leading-relaxed mb-4">
            网站和服务按“原样”和“可用”提供，不提供任何明示或暗示的担保。我们不保证网站或服务将不间断、无错误或安全。
          </p>
          <h3 class="text-lg font-medium mb-1">10.2 责任限制：</h3>
          <p class="text-base leading-relaxed mb-4">
            在法律允许的最大范围内，Oppty.ai
            及其附属公司不对因您使用网站或服务而产生的任何间接、附带、特殊、后果性或惩罚性损害，或任何利润或收入损失承担责任，无论是直接还是间接。
          </p>
          <h3 class="text-lg font-medium mb-1">10.3 赔偿：</h3>
          <p class="text-base leading-relaxed mb-4">
            您同意赔偿并使
            Oppty.ai、其附属公司、董事、高级职员、员工和代理人免于因您使用网站或服务、违反这些条款或侵犯他人权利而产生的任何索赔、责任、损害、损失和费用，包括合理的律师费用。
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">11. 终止</h2>
          <h3 class="text-lg font-medium mb-1">11.1 Oppty.ai 的终止：</h3>
          <p class="text-base leading-relaxed mb-4">
            我们可以在任何时间以任何原因、是否有原因、是否通知您，立即终止或暂停您对网站和服务的访问。我们保留在任何时间拒绝服务的权利。
          </p>
          <h3 class="text-lg font-medium mb-1">11.2 您终止：</h3>
          <p class="text-base leading-relaxed mb-4">
            您可以随时按照网站上的说明终止您的账户。终止后，您使用服务的权利将立即停止。
          </p>
          <h3 class="text-lg font-medium mb-1">11.3 生效：</h3>
          <p class="text-base leading-relaxed mb-4">
            所有根据其性质应在终止后继续有效的条款应继续有效，包括但不限于所有权条款、担保免责声明、赔偿和责任限制。
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">12. 适用法律与争议解决</h2>
          <h3 class="text-lg font-medium mb-1">12.1 适用法律：</h3>
          <p class="text-base leading-relaxed mb-4">这些条款应受新加坡法律的管辖并按其解释，不考虑其法律冲突原则。</p>
          <h3 class="text-lg font-medium mb-1">12.2 争议解决：</h3>
          <p class="text-base leading-relaxed mb-4">
            因这些条款或使用网站或服务引起的任何争议应通过在新加坡进行的具有约束力的仲裁解决，但任何一方可以在有管辖权的法院寻求禁令或其他公平救济。
          </p>
          <h3 class="text-lg font-medium mb-1">12.3 集体诉讼豁免：</h3>
          <p class="text-base leading-relaxed mb-4">
            您同意任何争议将以个别基础解决，您不得以原告或集体诉讼成员的身份提起集体诉讼、合并诉讼或代表诉讼。
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">13. 条款的修改</h2>
          <p class="text-base leading-relaxed mb-4">
            我们保留随时修改这些条款的权利。我们将通过在网站上发布更新的条款并更新“最后更新”日期来通知您这些更改。您在任何更改后继续使用网站和服务即表示您接受新的条款。
          </p>
        </section>

        <section class="mb-6">
          <h2 class="text-xl font-semibold mb-2">14. 其他</h2>
          <h3 class="text-lg font-medium mb-1">14.1 可分割性：</h3>
          <p class="text-base leading-relaxed mb-4">
            如果这些条款的任何条款被发现无效或不可执行，其余条款应继续完全有效。
          </p>
        </section>
      </div>
    </body>
  );
};

export default TermsBodyChinese;
