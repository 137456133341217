import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import UploadVideoCard from '../../../common/uploadVideoCard';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { uploadVideo, deleteVideo } from '../../../actions/userAction';

const UploadVideo = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [addUploadVideo, setAddUploadVideo] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragOver, setDragOver] = useState(false);
  const [loading, setLoading] = useState(false);

  const lang = useSelector((state) => state.language.lang);

  const handleDeleteVideo = (fileName) => {
    console.log('delete');
    dispatch(deleteVideo(fileName));
    setSelectedFile(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
    setDragOver(false);
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (selectedFile) {
      setLoading(true);

      const formData = new FormData();
      formData.append('file', selectedFile);

      dispatch(uploadVideo(formData, setAddUploadVideo, setLoading));
    }
  };

  return (
    <div
      id="uploadResume"
      className="flex flex-col justify-center py-5 lg:py-10 px-2 mx-2 lg:px-16 lg:mx-16 rounded-[20px] bg-white"
    >
      <div className="flex flex-row items-center gap-x-5">
        <div className="flex justify-center w-14 h-14 p-4 rounded-full bg-oppty-blue-5">
          {/* <img src={profileExperience} alt="profile icon" className="h-full w-full" /> */}
          <DocumentIcon className="h-full w-full stroke-blue-700" />
        </div>
        <div className="text-2xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.jobUploadVideo}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {!addUploadVideo && (
          <button
            onClick={() => setAddUploadVideo(true)}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
          >
            + {lang.profileAdd}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 pl-0 md:pl-16">
        <div className="flex flex-col gap-x-5 gap-y-2">
          {selectedFile && !addUploadVideo && (
            <UploadVideoCard video={user.video} onDelete={() => handleDeleteVideo(user.video.fileName)} />
          )}
          {user.video && !selectedFile && !addUploadVideo && (
            <UploadVideoCard video={user.video} onDelete={() => handleDeleteVideo(user.video.fileName)} />
          )}
        </div>
        {addUploadVideo && (
          <div className="flex flex-col mt-2">
            <div
              className={`drop-area ${dragOver ? 'highlight' : ''} bg-gray-100 flex flex-col justify-center items-center h-48 rounded-md w-full relative`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <DocumentIcon className="absolute top-10 stroke-gray-700 w-8 h-8" />
              <input type="file" onChange={handleFileChange} style={{ display: 'none' }} id="fileInput" />
              <div className="absolute text-lg font-bold">
                {selectedFile ? selectedFile?.name : <span>{lang.generalChooseFile}</span>}
              </div>
              <div
                className="cursor-pointer w-full flex items-center justify-center h-full"
                onClick={() => document.getElementById('fileInput').click()}
              >
                {!selectedFile && (
                  <button
                    type="submit"
                    className="px-5 py-1 text-xl rounded-xl bg-gray-800 text-white font-bold mt-24"
                    htmlFor="fileInput"
                    style={{ cursor: 'pointer' }}
                  >
                    + {lang.generalUpload}
                  </button>
                )}
              </div>
            </div>
            <div className="flex justify-end flex-row gap-x-2 py-4">
              <button
                onClick={() => {
                  setAddUploadVideo(false);
                  setSelectedFile(null);
                }}
                className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
              >
                {lang.profileCancel}
              </button>
              <button
                type="button"
                onClick={handleUpload}
                disabled={loading}
                className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white w-28 h-14 rounded-md disabled:bg-gray-500"
              >
                {loading ? (
                  <div className="w-full flex justify-center">
                    <div className="border-gray-300 h-10 w-10 animate-spin rounded-full border-8 border-t-blue-600" />
                  </div>
                ) : (
                  lang.generalUpload
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadVideo;
