import { useSelector } from 'react-redux';
import Footer from '../../../common/footer';
import Header from '../../../common/header';
import CardGroup from './cardGroup';
import FindPerfectPerson from './findPerfectPersion';
import HappyCustomers from './happyCustomers';
import JoinHelpsRecruiters from './joinHelpsRecruiters';
import MoreTopCandidates from './moreTopCandidate';
import RecruitingPortal from './recruitingPortal';
import TabGeneral from './tabContent/tabGeneral';
import Tabs from './tabs';
import ChroniclesHiring from './chroniclesHiring';
import FindPerfectTimeline from './findPerfectTimeline';
import TabBuild from './tabContent/tabBuild';
import TabPromote from './tabContent/tabPromote';
import TabManage from './tabContent/tabManage';
import { useRef } from 'react';

const EmployerLanding = () => {
  const lang = useSelector((state) => state.language.lang);

  const tabsRef = useRef(null)

  const tabs = [
    { label: lang.employeeHomeTabGeneral, component: <TabGeneral /> },
    { label: lang.employeeHomeTabBuild, component: <TabBuild /> },
    { label: lang.employeeHomeTabPromote, component: <TabPromote /> },
    { label: lang.employeeHomeTabManage, component: <TabManage /> }
  ];

  const scrollToTabs = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="flex flex-col min-h-screen justify-center items-center">
      <Header />
      <div className="flex flex-col items-center space-y-8 sm:space-y-20 px-6 md:px-0 bg-white pt-5 max-w-[1200px]">
        <RecruitingPortal />
        <CardGroup />
        <MoreTopCandidates />
        <div className="hidden md:flex md:w-full">
          <FindPerfectTimeline />
        </div>
        <HappyCustomers />
        <FindPerfectPerson />
        <JoinHelpsRecruiters />
        <div ref={tabsRef}>
          <Tabs tabs={tabs} />
        </div>
        <ChroniclesHiring />
      </div>
      <Footer scrollToTabs={scrollToTabs} />
    </div>
  );
};

export default EmployerLanding;
