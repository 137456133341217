import { useDispatch } from 'react-redux';
import Footer from '../../common/footer';
import Header from '../../common/header';
import AllCompanies from './allCompanies';
import { useEffect } from 'react';
import { getCompanyLogos } from '../../actions/publicAction';

const Companies = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyLogos());
  }, []);

  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div className='flex flex-col justify-center items-center'>
        <Header />
        <div className='w-full max-w-[1200px]'>
          <AllCompanies />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Companies;
