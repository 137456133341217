import Footer from '../../../common/footer';
import Header from '../../../common/header';
import CvWebsiteCard from './cvWebsiteCard';

const CvWebsite = () => {
  return (
    <div className="flex flex-col min-h-screen gap-y-8">
      <Header />
      <CvWebsiteCard />
      <Footer />
    </div>
  );
};

export default CvWebsite;
