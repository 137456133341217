import { useSelector } from 'react-redux';
import AvailableJobCard from '../../employer/availableJobCard';

const AvailableJobs = () => {
  const lang = useSelector((state) => state.language.lang);
  const job = useSelector((state) => state.selectedJob);

  return (
    <div className="flex flex-col justify-center pt-10 pb-32 px-16">
      <div className="text-4xl font-nunito font-bold text-black/70 pb-8">{lang.companyAvailableJob}</div>
      <div className="flex flex-col gap-y-6">
        {job?.otherJobs &&
          job?.otherJobs?.map((job) => {
            return <AvailableJobCard job={job} />;
          })}
      </div>
    </div>
  );
};

export default AvailableJobs;
