import axios from 'axios'
import { ToastBar } from '../common/toastbar';
//import { useNavigate } from 'react-router-dom'
//const navigate = useNavigate()

const localLang = localStorage.getItem('lan');
const apiUrl = process.env.REACT_APP_APIURL

export const FETCH_USER_PRE_REG_REQUEST = 'FETCH_USER_PRE_REG_REQUEST'
export const FETCH_USER_PRE_REG_ERROR = 'FETCH_USER_PRE_REG_ERROR'
export const FETCH_USER_PRE_REG_SUCCESS = 'FETCH_USER_PRE_REG_SUCCESS'
export const FETCH_USER_PRE_REG_DETAIL = 'FETCH_USER_PRE_REG_DETAIL'
export const FETCH_USER_PRE_REG_COMPLETE = 'FETCH_USER_PRE_REG_COMPLETE'

export const fetchUserPreRegRequest = () => ({ type: FETCH_USER_PRE_REG_REQUEST })
export const fetchUserPreRegComplete = () => ({ type: FETCH_USER_PRE_REG_COMPLETE })

export const fetchUserPreRegSuccess = (otpData) => ({
  type: FETCH_USER_PRE_REG_SUCCESS,
  payload: otpData
})

export const fetchUserPreRegDetail = (userPreRegData) => ({
  type: FETCH_USER_PRE_REG_DETAIL,
  payload: userPreRegData
})

export const fetchUserPreRegError = (error) => ({
  type: FETCH_USER_PRE_REG_ERROR,
  payload: error
})

export const submitRegister = (values, navigate) => {
  const inputData = { userCreate: values }

  return async (dispatch) => {
    try {
      dispatch(fetchUserPreRegRequest())
      const response = await axios.post(`${apiUrl}/user/createotp`, inputData)
      console.log('reg res: ', response)
      if (response.status == 200) {

        dispatch(fetchUserPreRegDetail(inputData.userCreate))
        dispatch(fetchUserPreRegSuccess(response.data))
        ToastBar.success(response.data.successMsg[localLang]);
        navigate('/verify-OTP')
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  }
}
