import { useSelector } from 'react-redux'
import empTimeline1 from '../../../assets/images/emptimeline1.png'
import empTimeline2 from '../../../assets/images/emptimeline2.png'
import empTimeline3 from '../../../assets/images/emptimeline3.png'
import empTimeline4 from '../../../assets/images/emptimeline4.png'
import empTimeline5 from '../../../assets/images/emptimeline5.png'

const FindPerfectTimeline = () => {
  const lang = useSelector((state) => state.language.lang)

  return (
    <div className="flex flex-col justify-center items-center text-center bg-oppty-blue-3 h-full p-10 w-full">
      <div className="outfit-font text-[14px] md:text-[40px] lg:text-[90px] font-normal text-oppty-black-3 lg:leading-[100px] lg:pb-9">
        {lang.employeeHomeFindThePerfect}
      </div>
      <div className="outfit-font text-[14px] md:text-[30px] lg:text-[36px] font-normal text-oppty-black-3 w-[100%] lg:leading-[46px]">
        {lang.employeeHomePostYourJob}
      </div>
      <div className="flex flex-col items-center justify-center py-10 w-full gap-14 lg:gap-28">
        <div className="flex flex-row w-full h-fit justify-center">
          <div className="w-[500px]">
            <img src={empTimeline1} alt="empTimeline1" />
          </div>
          <div className="w-2 border-2 bg-oppty-grey-3 mx-10 lg:mx-20 xl:mx-28 mb-[-150px]"></div>
          <div className="w-6 h-6 md:w-8 md:h-8 bg-black rounded-full text-white text-center flex justify-center items-center absolute">
            1
          </div>
          <div className="w-[500px] text-left">
            <div className="outfit-font text-[12px] md:text-[20px] lg:text-[40px] font-normal text-oppty-black-3 xl:leading-[50px] pb-[10px] md:pb-[17px]">
              {lang.employeeHomeCreateModern}
            </div>
            <div className="outfit-font text-[10px] md:text-[15px] lg:text-[24px] font-normal text-oppty-black-3 xl:leading-[34px] pb-[15px] md:pb-[30px]">
              {lang.employeeHomeQuicklyCreate}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-black-3 xl:leading-[33px]">
              {lang.employeeHomeBuildBetter}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-grey-1 xl:leading-[33px] pb-[10px] md:pb-[28px]">
              {lang.employeeHomeCreateJobAds}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-black-3 xl:leading-[33px]">
              {lang.employeeHomeGetCareerSite}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-grey-1 xl:leading-[33px] pb-[10px] md:pb-[28px]">
              {lang.employeeHomeListYourJobs}
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full h-fit justify-center">
          <div className="w-[500px] text-left">
            <div className="outfit-font text-[12px] md:text-[20px] lg:text-[40px] font-normal text-oppty-black-3 xl:leading-[50px] pb-[10px] md:pb-[17px]">
              {lang.employeeHomePostMultipleJobs}
            </div>
            <div className="outfit-font text-[10px] md:text-[15px] lg:text-[24px] font-normal text-oppty-black-3 xl:leading-[34px] pb-[15px] md:pb-[30px]">
              {lang.employeeHomeQuicklyCreate}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-black-3 xl:leading-[33px]">
              {lang.generalFreePosting}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-grey-1 xl:leading-[33px] pb-[10px] md:pb-[28px]">
              {lang.generalPostAds}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-black-3 xl:leading-[33px]">
              {lang.employeeHomePremiumReach}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-grey-1 xl:leading-[33px] md:pb-[28px]">
              {lang.employeeHomeHighlightJob}
            </div>
          </div>
          <div className="w-2 border-2 bg-oppty-grey-3 mx-10 lg:mx-20 xl:mx-28 mb-[-150px]"></div>
          <div className="w-6 h-6 md:w-8 md:h-8 bg-black rounded-full text-white text-center flex justify-center items-center absolute">
            2
          </div>
          <div className="w-[500px]">
            <img src={empTimeline2} alt="empTimeline2" />
          </div>
        </div>
        <div className="flex flex-row w-full h-fit justify-center">
          <div className="w-[500px]">
            <img src={empTimeline3} alt="empTimeline3" />
          </div>
          <div className="w-2 border-2 bg-oppty-grey-3 mx-10 lg:mx-20 xl:mx-28 mb-[-150px]"></div>
          <div className="w-6 h-6 md:w-8 md:h-8 bg-black rounded-full text-white text-center flex justify-center items-center absolute">
            3
          </div>
          <div className="w-[500px] text-left">
            <div className="outfit-font text-[12px] md:text-[20px] lg:text-[40px] font-normal text-oppty-black-3 xl:leading-[50px] pb-[10px] md:pb-[17px]">
              {lang.employeeHomeReceiveMore}
            </div>
            <div className="outfit-font text-[10px] md:text-[15px] lg:text-[24px] font-normal text-oppty-black-3 xl:leading-[34px] pb-[15px] md:pb-[30px]">
              {lang.employeeHomeMultipost}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-black-3 xl:leading-[33px]">
              {lang.employeeHomeFreePromotion}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-grey-1 xl:leading-[33px] pb-[10px] md:pb-[28px]">
              {lang.employeeHomePostYourJobMajor}
            </div>
            {/* <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-grey-1 xl:leading-[33px]">
              {lang.employeeHomePremiumReach}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-grey-1 xl:leading-[33px] pb-[10px] md:pb-[28px]">
              {lang.employeeHomeHighlightJob}
            </div> */}
          </div>
        </div>
        <div className="flex flex-row w-full h-fit justify-center">
          <div className="w-[500px] text-left">
            <div className="outfit-font text-[12px] md:text-[20px] lg:text-[40px] font-normal text-oppty-black-3 xl:leading-[50px] pb-[10px] md:pb-[17px]">
              {lang.employeeHomeManageCandidates}
            </div>
            <div className="outfit-font text-[10px] md:text-[15px] lg:text-[24px] font-normal text-oppty-black-3 xl:leading-[34px] pb-[15px] md:pb-[30px]">
              {lang.employeeHomeOurEasyToUse}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-black-3 xl:leading-[33px]">
              {lang.employeeHomeUseHiring}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-grey-1 xl:leading-[33px] pb-[10px] md:pb-[28px]">
              {lang.employeeHomeReviewYour}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-black-3 xl:leading-[33px]">
              {lang.employeeHomeTalkYour}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-grey-1 xl:leading-[33px] pb-[10px] md:pb-[28px]">
              {lang.employeeHomeRequestMore}
            </div>
          </div>
          <div className="w-2 border-2 bg-oppty-grey-3 mx-10 lg:mx-20 xl:mx-28 mb-[-150px]"></div>
          <div className="w-6 h-6 md:w-8 md:h-8 bg-black rounded-full text-white text-center flex justify-center items-center absolute">
            4
          </div>
          <div className="w-[500px]">
            <img src={empTimeline4} alt="empTimeline4" />
          </div>
        </div>
        <div className="flex flex-row w-full h-fit justify-center">
          <div className="w-[500px]">
            <img src={empTimeline5} alt="empTimeline5" />
          </div>
          <div className="w-2 border-2 bg-oppty-grey-3 mx-10 lg:mx-20 xl:mx-28 mb-[-80px]"></div>
          <div className="w-6 h-6 md:w-8 md:h-8 bg-black rounded-full text-white text-center flex justify-center items-center absolute">
            5
          </div>
          <div className="w-[500px] text-left">
            <div className="outfit-font text-[12px] md:text-[20px] lg:text-[40px] font-normal text-oppty-black-3 xl:leading-[50px] pb-[10px] md:pb-[17px]">
              {lang.employeeHomeMakePerfect}
            </div>
            <div className="outfit-font text-[10px] md:text-[15px] lg:text-[24px] font-normal text-oppty-black-3 xl:leading-[34px] pb-[15px] md:pb-[30px]">
              {lang.employeeHomeReduceHiring}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-black-3 xl:leading-[33px]">
              {lang.employeeHomeOneWorkflow}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-grey-1 xl:leading-[33px] pb-[10px] md:pb-[28px]">
              {lang.employeeHomeEasilyCollaborate}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-black-3 xl:leading-[33px]">
              {lang.employeeHomeEasierScreening}
            </div>
            <div className="outfit-font text-[9px] md:text-[13px] lg:text-[23px] font-normal text-oppty-grey-1 xl:leading-[33px] pb-[10px] md:pb-[28px]">
              {lang.employeeHomeFilterSearch}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FindPerfectTimeline
