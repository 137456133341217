import { Menu, MenuHandler, MenuList, MenuItem, Button, Input } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { useCountries } from 'use-react-countries';

const CountryCodeDropdown = ({ onSelect, value }) => {
  const { countries } = useCountries();
  const sortedCountries = countries.sort((a, b) => a.name.localeCompare(b.name));

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    console.log('val: ', value);
    const findCountryByAreaCode = () => {
      const country = countries.find((country) => country.countryCallingCode === `+${value}`);
      return country;
    };

    setSelectedCountry(findCountryByAreaCode());
  }, []);

  const handleAreaCodeSelect = (country) => {
    setSelectedCountry(country);
    const callingCode = country?.countryCallingCode.split('+')[1];
    onSelect(callingCode);
    setMenuOpen(false);
  };

  const handleInputChange = (e) => {
    setFilterValue(e.target.value);
  };

  const filteredCountries = sortedCountries.filter(
    ({ name, countryCallingCode }) =>
      name.toLowerCase().includes(filterValue.toLowerCase()) ||
      countryCallingCode.toLowerCase().includes(filterValue.toLowerCase())
  );

  return (
    <Menu
      dismiss={{
        itemPress: false
      }}
      open={menuOpen}
      handler={setMenuOpen}
      placement='bottom-start'
    >
      <MenuHandler>
        <Button
          ripple={false}
          variant="text"
          color="blue-gray"
          className="flex h-14 w-40 md:w-32 items-center gap-2 rounded-r-none border border-r-0 border-gray-300 pl-3"
        >
          <img
            src={selectedCountry?.flags?.svg}
            alt={selectedCountry?.name}
            className="h-4 w-4 rounded-full object-cover"
          />
          {selectedCountry !== null && selectedCountry?.countryCallingCode}
        </Button>
      </MenuHandler>
      <MenuList className="max-h-[20rem] w-[350px] overflow-y-auto">
        <input
          placeholder="Search by Country or Code"
          style={{ color: 'black', opacity: '1' }}
          className="placeholder-gray-500 w-full border-[1px] border-gray-500 rounded-md py-2 px-2 mb-5"
          value={filterValue}
          onChange={handleInputChange}
        />
        {filteredCountries.map((country, index) => {
          return (
            <MenuItem
              key={index}
              value={country?.name}
              className="flex items-center gap-2 bg-white"
              onClick={() => handleAreaCodeSelect(country)}
            >
              <img src={country?.flags.svg} alt={country?.name} className="h-5 w-5 rounded-full object-cover" />
              {country?.name} <span className="ml-auto">{country?.countryCallingCode}</span>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default CountryCodeDropdown;
