import axios from 'axios';
import { ToastBar } from '../common/toastbar';
import { fetchEmployerPreRegComplete } from './employerPreRegisterAction';
import { startProcessing, endProcessing } from './processingAction';
import { updateJobInterviewUpdateNotification } from './notificationAction';
import { getOtpData } from './publicAction';

const apiUrl = process.env.REACT_APP_APIURL;
const localLang = localStorage.getItem('lan');

export const FETCH_EMPLOYER_REQUEST = 'FETCH_EMPLOYER_REQUEST';
export const FETCH_EMPLOYER_SUCCESS = 'FETCH_EMPLOYER_SUCCESS';
export const FETCH_EMPLOYER_FAILURE = 'FETCH_EMPLOYER_FAILURE';
export const FETCH_EMPLOYER_LOGIN = 'FETCH_EMPLOYER_LOGIN';
export const FETCH_EMPLOYER_LOGOUT = 'FETCH_EMPLOYER_LOGOUT';
export const FETCH_EMPLOYER_INIT = 'FETCH_EMPLOYER_INIT';
export const FETCH_EMPLOYER_BY_ID = 'FETCH_EMPLOYER_BY_ID';
export const SET_CURRENT_EMPLOYER_ID = 'SET_CURRENT_EMPLOYER_ID';
export const SET_CURRENT_EMPLOYER_ID_NULL = 'SET_CURRENT_EMPLOYER_ID_NULL';
export const FETCH_ALL_INTERVIEWS = 'FETCH_ALL_INTERVIEWS';
export const FETCH_DASHBOARD_DETAILS = 'FETCH_DASHBOARD_DETAILS';
export const FETCH_SEARCH_CANDIDATES = 'FETCH_SEARCH_CANDIDATES';

export const fetchEmployerRequest = () => ({
  type: FETCH_EMPLOYER_REQUEST
});

export const fetchEmployerLogin = () => ({
  type: FETCH_EMPLOYER_LOGIN
});

export const fetchEmployerLogout = () => ({
  type: FETCH_EMPLOYER_LOGOUT
});

export const fetchEmployerInit = () => ({
  type: FETCH_EMPLOYER_INIT
});

export const fetchEmployerSuccess = (data) => ({
  type: FETCH_EMPLOYER_SUCCESS,
  payload: data
});

export const fetchEmployerError = (error) => ({
  type: FETCH_EMPLOYER_FAILURE,
  payload: error
});

export const fetchSelectedEmployer = (data) => ({
  type: FETCH_EMPLOYER_BY_ID,
  payload: data
});

export const setCurrentId = (id) => ({
  type: SET_CURRENT_EMPLOYER_ID,
  payload: id
});

export const setCurrentIdNull = () => ({
  type: SET_CURRENT_EMPLOYER_ID_NULL
});

export const fetchAllInterviews = (data) => ({
  type: FETCH_ALL_INTERVIEWS,
  payload: data
});

export const fetchDashboardDetails = (data) => ({
  type: FETCH_DASHBOARD_DETAILS,
  payload: data
});

export const fetchSearchCandidates = (data) => ({
  type: FETCH_SEARCH_CANDIDATES,
  payload: data
});

// export const fetchJobsByEmployerSuccess = (data) => ({
//   type: FETCH_JOBS_BY_EMPLOYER_SUCCESS,
//   payload: data
// });

export const verifyEmployerOtp = (values, navigate, setAuthUser) => {
  const inputData = {
    employerCreate: {
      companyName: values.companyName,
      email: values.email,
      password: values.password
    },
    pin: values.pin,
    otpData: values.otpData
  };

  console.log(inputData);

  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/verifyotp`, inputData);
      console.log('otp res: ', response);

      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        dispatch(fetchEmployerSuccess(response.data));
        dispatch(fetchEmployerPreRegComplete());
        dispatch(fetchEmployerLogin());
        dispatch(setCurrentId(response.data.employer._id));
        setAuthUser(response.data.employer._id);
        navigate('/employer/dashboard');
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const employerLogin = (values, navigate, setAuthUser) => {
  const inputData = {
    email: values.email,
    password: values.password
  };
  console.log(inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/employerlogin`, inputData);
      if (response.status === 200) {
        console.log('login data:', response.data);
        localStorage.setItem('token', response.data.token);
        dispatch(fetchEmployerSuccess(response.data));
        dispatch(fetchEmployerLogin());
        dispatch(setCurrentId(response.data.employer._id));
        setAuthUser(response.data.employer._id);
        ToastBar.success(response.data.successMsg[localLang]);
        navigate('/employer/dashboard');
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
        if (error.response.data.status === 0) {
          navigate('/not-activated');
        } else if (error.response.data.status === 3) {
          navigate('/suspended');
        }
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
    dispatch(endProcessing());
  };
};

export const googleEmployerLogin = (values, navigate, setAuthUser) => {
  const inputData = {
    googleCreate: {
      email: values.email,
      companyName: values.name,
      avatar: values.picture
    }
  };
  console.log(inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/googlelogin`, inputData);

      if (response.status === 200) {
        console.log(response.data);
        localStorage.setItem('token', response.data.token);
        dispatch(fetchEmployerSuccess(response.data));
        dispatch(fetchEmployerLogin());
        dispatch(setCurrentId(response.data.employer._id));
        setAuthUser(response.data.employer._id);
        ToastBar.success(response.data.successMsg[localLang]);
        navigate('/employer/dashboard');
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
        if (error.response.data.status === 0) {
          navigate('/not-activated');
        } else if (error.response.data.status === 3) {
          navigate('/suspended');
        }
      } else {
        // Handle other errors
        console.log('others');
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
    dispatch(endProcessing());
  };
};

export const employerLogout = (navigate, setAuthUser) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${apiUrl}/employer/logout`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
      );

      if (response.status === 200) {
        navigate('/employer-landing');
        setTimeout(() => {
          localStorage.removeItem('token');
          dispatch(fetchEmployerLogout());
          dispatch(fetchEmployerInit());
          setAuthUser(null);
          dispatch(setCurrentIdNull());
          ToastBar.success(response.data.successMsg[localLang]);
        }, 0);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const dataExampleLoader = () => {
  return async (dispatch) => {
    dispatch(fetchEmployerRequest());

    try {
      const response = await axios.get(`${apiUrl}/user`);
      console.log('res: ', response.data.users);

      dispatch(fetchEmployerSuccess({ users: response.data.users }));
    } catch (error) {
      dispatch(fetchEmployerError(error));
    }
  };
};

export const addEmployerGeneralDetails = (values, navigate) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/updategeneraldetails`, inputData, {
        //add correct url
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchEmployerSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const updateEmployerBenefits= (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/update-company-benefits`, inputData, {
        //add correct url
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchEmployerSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const updateEmployerWorkPolicy = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/update-company-workpolicy`, inputData, {
        //add correct url
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchEmployerSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const updateEmployerCulture = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/update-company-culture`, inputData, {
        //add correct url
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchEmployerSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getEmployerById = (values) => {
  const inputData = values;

  console.log('input param: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employer/getemployerbyid`, {
        params: { employerId: inputData },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('employer by id: ', response.data);
        dispatch(fetchSelectedEmployer(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getOwnEmployerData = (values) => {
  const inputData = values;

  console.log('input param: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employer/getemployerbyid`, {
        params: { employerId: inputData },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('employer by id: ', response.data);
        dispatch(fetchEmployerSuccess(response.data));
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getAllInterviews = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employer/getinterviewdates`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('interview res: ', response.data);
        dispatch(fetchAllInterviews(response.data));
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const updateInterview = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/job/updateinterview`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('interview status res: ', response.data);
        dispatch(getAllInterviews());
        dispatch(updateJobInterviewUpdateNotification(inputData));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
        
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const cancelInterview = (value) => {
  const inputData = value;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/cancel-interview`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('interview status res: ', response.data);
        dispatch(getAllInterviews());
        dispatch(updateJobInterviewUpdateNotification(inputData));
        ToastBar.success(response.data.successMsg[localLang]);
        return response.data;
        
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getDashboardDetails = (values) => {
  console.log('input: ', values);
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employer/getemployerdashboard`, {
        params: { startDate: values?.startDate, endDate: values?.endDate },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('dash res: ', response.data);
        dispatch(fetchDashboardDetails(response.data));
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const getCandidateSearch = (values) => {
  console.log('input: ', values);
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/employer/getcandiatesearch`, {
        params: { values },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('search candidate res: ', response.data);
        dispatch(fetchSearchCandidates(response.data));
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.warning(error.response.data.errorMsg[localLang]);
        return error.response.data;
      } else {
        // Handle other errors
        console.log(error.message);
      }
      return { error: error.message };
    }
  };
};

export const addImages = (formData, setImageSrcList, closeModal, setLoader) => {
  for (let pair of formData.entries()) {
    console.log('pic formData: ', pair[0], pair[1]);
  }
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/add-images`, formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchEmployerSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
        setImageSrcList([]);
        setLoader(false);
        closeModal();
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const imageDelete = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/delete-image`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        dispatch(fetchEmployerSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const addSocial = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/add-social`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('social res: ', response.data);
        dispatch(fetchEmployerSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const socialDelete = (values) => {
  const inputData = values;

  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/delete-social`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        dispatch(fetchEmployerSuccess(response.data));
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const employerEditProfilePic = (values, closeModal) => {
  const inputData = values;

  console.log('Emp profile input: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/employer/edit-company-logo`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(fetchEmployerSuccess(response.data));
        closeModal();
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

// export const fetchJobsByEmployer = (values) => {
//   const inputData = values;

//   console.log('fetch input: ', inputData);
//   return async (dispatch) => {
//     try {
//       const response = await axios.get(`${apiUrl}/job/getjobsbyemployer?employerId=${inputData}`, {
//         headers: {
//           Authorization: 'Bearer ' + localStorage.getItem('token')
//         }
//       });

//       if (response.status === 200) {
//         console.log(response.data);
//         dispatch(fetchJobsByEmployerSuccess(response.data));
//       }
//     } catch (error) {
//       if (error.response && error.response.status === 500) {
//         console.log(error.response.data.errorMsg);
//       } else {
//         console.log(error.message);
//       }
//       return { error: error.message };
//     }
//   };
// };

// export const updateEmployerCompanyCulture = (values, navigate) => {
//   const inputData = values;

//   console.log('input value: ', inputData);
//   return async (dispatch) => {
//     try {
//       const response = await axios.post(`${apiUrl}/employer/updatecompanybenefits`, inputData, {
//         //add correct url
//         headers: {
//           Authorization: 'Bearer ' + localStorage.getItem('token')
//         }
//       });

//       if (response.status === 200) {
//         console.log(response.data);
//         dispatch(fetchEmployerSuccess(response.data));
//         ToastBar.success(response.data.successMsg[localLang]);
//         navigate('/search-jobs');
//         return response.data;
//       }
//     } catch (error) {
//       if (error.response && error.response.status === 500) {
//         console.log(error.response.data.errorMsg);
//         ToastBar.error(error.response.data.errorMsg[localLang]);
//       } else {
//         console.log(error.message);
//         ToastBar.error(error.message);
//       }
//       return { error: error.message };
//     }
//   };
// };
