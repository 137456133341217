import { useSelector } from 'react-redux';
import { useState } from 'react';
import { BellIcon } from '@heroicons/react/24/outline';

const Notifications = () => {
  const [openSetting, setOpenSetting] = useState(false);

  const lang = useSelector((state) => state.language.lang);

  return (
    <div className="flex flex-col justify-center py-5 lg:py-10 px-2 mx-2 lg:px-16 lg:mx-16 rounded-[20px] bg-white">
      <div className="flex flex-row items-center gap-x-5">
        <div className="flex justify-center w-14 h-14 p-4 rounded-full bg-oppty-blue-5">
          <BellIcon className="h-full w-full stroke-oppty-blue" />
        </div>
        <div className="text-base font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.profileNotifications}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {!openSetting && (
          <button
            onClick={() => setOpenSetting(true)}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
          >
            {lang.profileManage}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 pl-16">
        {openSetting && <div>Notifications setting page</div>}
      </div>
    </div>
  );
};

export default Notifications;
