import { useDispatch, useSelector } from 'react-redux';
import opptyLogo from '../assets/images/headerLogo.svg';
import Home from '../dynamicIcon/home';
import { useState } from 'react';
import Message from '../dynamicIcon/message';
import Building from '../dynamicIcon/building';
import Doc from '../dynamicIcon/doc';
import Applicant from '../dynamicIcon/applicant';
import Calender from '../dynamicIcon/calender';
import Setting from '../dynamicIcon/setting';
import QuestionMark from '../dynamicIcon/questionMark';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/outline';
import { employerLogout } from '../actions/employerAction';
import { useAuthContext } from '../context/authContext';
import Article from '../dynamicIcon/article';
import InfoIcon from '../dynamicIcon/infoIcon';

const LeftNavigationBar = ({ isCollapsed, toggleNavbar, toggleMobileMenu, isMobileMenuOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setAuthUser } = useAuthContext();

  const [hoverState, setHoverState] = useState({
    homeButton: false,
    messageButton: false,
    profileButton: false,
    jobButton: false,
    applicantButton: false,
    scheduleButton: false,
    settingButton: false,
    helpButton: false,
    articleButton: false,
    privacyButton: false,
    termsButton: false
  });

  const lang = useSelector((state) => state.language.lang);

  const handleMouseEnter = (button) => {
    setHoverState((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        if (key !== button) {
          newState[key] = false;
        }
      });
      newState[button] = true;
      return newState;
    });
  };

  const handleMouseLeave = (button) => {
    setHoverState((prevState) => ({
      ...prevState,
      [button]: false
    }));
  };

  return (
    <div
      className={`flex flex-col ${
        isCollapsed ? 'w-20' : 'w-full'
      } h-full border-r-[2px] bg-white min-h-screen justify-between overflow-y-auto`}
    >
      <div>
        <div
          className={`flex flex-row ${isCollapsed ? 'justify-center px-5' : 'justify-between px-10'} items-center pt-5`}
        >
          {!isCollapsed && (
            <div className="w-28 h-16 cursor-pointer" onClick={() => navigate('/employer/dashboard')}>
              <img src={opptyLogo} alt="logo" className="h-full w-full" />
            </div>
          )}
          {isCollapsed ? (
            <button
              type="button"
              onClick={toggleNavbar}
              style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
              className="hidden lg:flex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
              </svg>
            </button>
          ) : (
            <button
              type="button"
              onClick={toggleNavbar}
              className="hidden lg:flex"
              style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
              </svg>
            </button>
          )}
          {!isMobileMenuOpen ? (
            <button
              type="button"
              onClick={toggleMobileMenu}
              style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
              className="flex lg:hidden"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
              </svg>
            </button>
          ) : (
            <button
              type="button"
              onClick={toggleMobileMenu}
              className="flex lg:hidden"
              style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
              </svg>
            </button>
          )}
        </div>
        <div className={`py-6 ${isCollapsed && 'mt-10'}`}>
          <div
            type="button"
            className={`flex gap-x-4 hover:bg-black group py-3 px-6 cursor-pointer ${
              window.location.pathname === '/employer/dashboard' && 'bg-black'
            }`}
            onMouseEnter={() => handleMouseEnter('homeButton')}
            onMouseLeave={() => handleMouseLeave('homeButton')}
            onClick={() => navigate('/employer/dashboard')}
          >
            <div className="w-5 h-5">
              <Home
                stroke="#7C8493"
                fill="none"
                className="h-full w-full"
                isHovered={hoverState.homeButton}
                hoverStroke="white"
              />
            </div>
            {!isCollapsed && (
              <div
                className={`font-nunito text-base font-medium leading-normal text-oppty-grey-19 group-hover:text-white ${
                  window.location.pathname === '/employer/dashboard' && 'text-white'
                }`}
              >
                {lang.generalDashboard}
              </div>
            )}
          </div>
          <div
            type="button"
            className={`flex gap-x-4 hover:bg-black group py-3 px-6 cursor-pointer ${
              window.location.pathname === '/employer/messages' && 'bg-black'
            }`}
            onMouseEnter={() => handleMouseEnter('messageButton')}
            onMouseLeave={() => handleMouseLeave('messageButton')}
            onClick={() => navigate('/employer/messages', { state: { receiverId: 'noId' } })}
          >
            <div className="w-5 h-5">
              <Message
                stroke="#7C8493"
                fill="none"
                className="h-full w-full"
                isHovered={hoverState.messageButton}
                hoverStroke="white"
              />
            </div>
            {!isCollapsed && (
              <div
                className={`font-nunito text-base font-medium leading-normal text-oppty-grey-19 group-hover:text-white ${
                  window.location.pathname === '/employer/messages' && 'text-white'
                }`}
              >
                {lang.generalMessages}
              </div>
            )}
          </div>
          <div
            type="button"
            className={`flex gap-x-4 hover:bg-black group py-3 px-6 cursor-pointer ${
              window.location.pathname === '/employer/profile' && 'bg-black'
            }`}
            onMouseEnter={() => handleMouseEnter('profileButton')}
            onMouseLeave={() => handleMouseLeave('profileButton')}
            onClick={() => navigate('/employer/profile')}
          >
            <div className="w-5 h-5">
              <Building
                stroke="#7C8493"
                fill="none"
                className="h-full w-full"
                isHovered={hoverState.profileButton}
                hoverStroke="white"
              />
            </div>
            {!isCollapsed && (
              <div
                className={`font-nunito text-base font-medium leading-normal text-oppty-grey-19 group-hover:text-white ${
                  window.location.pathname === '/employer/profile' && 'text-white'
                }`}
              >
                {lang.generalCompanyProfile}
              </div>
            )}
          </div>
          <div
            type="button"
            className={`flex gap-x-4 hover:bg-black group py-3 px-6 cursor-pointer ${
              window.location.pathname === '/employer/job-listing' && 'bg-black'
            }`}
            onMouseEnter={() => handleMouseEnter('jobButton')}
            onMouseLeave={() => handleMouseLeave('jobButton')}
            onClick={() => navigate('/employer/job-listing')}
          >
            <div className="w-5 h-5">
              <Doc
                stroke="#7C8493"
                fill="none"
                className="h-full w-full"
                isHovered={hoverState.jobButton}
                hoverStroke="white"
              />
            </div>
            {!isCollapsed && (
              <div
                className={`font-nunito text-base font-medium leading-normal text-oppty-grey-19 group-hover:text-white ${
                  window.location.pathname === '/employer/job-listing' && 'text-white'
                }`}
              >
                {lang.generalJobListing}
              </div>
            )}
          </div>
          <div
            type="button"
            className={`flex gap-x-4 hover:bg-black group py-3 px-6 cursor-pointer ${
              window.location.pathname === '/employer/schedule' && 'bg-black'
            }`}
            onMouseEnter={() => handleMouseEnter('scheduleButton')}
            onMouseLeave={() => handleMouseLeave('scheduleButton')}
            onClick={() => navigate('/employer/schedule')}
          >
            <div className="w-5 h-5">
              <Calender
                stroke="#7C8493"
                fill="none"
                className="h-full w-full"
                isHovered={hoverState.scheduleButton}
                hoverStroke="white"
              />
            </div>
            {!isCollapsed && (
              <div
                className={`font-nunito text-base font-medium leading-normal text-oppty-grey-19 group-hover:text-white ${
                  window.location.pathname === '/employer/schedule' && 'text-white'
                }`}
              >
                {lang.generalMySchedule}
              </div>
            )}
          </div>
          <div
            type="button"
            className={`flex gap-x-4 hover:bg-black group py-3 px-6 cursor-pointer ${
              window.location.pathname === '/employer/candidate-search' && 'bg-black'
            }`}
            onMouseEnter={() => handleMouseEnter('applicantButton')}
            onMouseLeave={() => handleMouseLeave('applicantButton')}
            onClick={() => navigate('/employer/candidate-search')}
          >
            <div className="w-5 h-5">
              <Applicant
                stroke="#7C8493"
                fill="none"
                className="h-full w-full"
                isHovered={hoverState.applicantButton}
                hoverStroke="white"
              />
            </div>
            {!isCollapsed && (
              <div
                className={`font-nunito text-base font-medium leading-normal text-oppty-grey-19 group-hover:text-white ${
                  window.location.pathname === '/employer/candidate-search' && 'text-white'
                }`}
              >
                {lang.generalCandidateSearch}
              </div>
            )}
          </div>
          <div
            type="button"
            className={`flex gap-x-4 hover:bg-black group py-3 px-6 cursor-pointer ${
              window.location.pathname === '/employer/articles' && 'bg-black'
            }`}
            onMouseEnter={() => handleMouseEnter('articleButton')}
            onMouseLeave={() => handleMouseLeave('articleButton')}
            onClick={() => navigate('/employer/articles')}
          >
            <div className="w-5 h-5">
              <Article
                stroke="#7C8493"
                fill="none"
                className="h-full w-full"
                isHovered={hoverState.articleButton}
                hoverStroke="white"
              />
            </div>
            {!isCollapsed && (
              <div
                className={`font-nunito text-base font-medium leading-normal text-oppty-grey-19 group-hover:text-white ${
                  window.location.pathname === '/employer/articles' && 'text-white'
                }`}
              >
                {lang.generalArticles}
              </div>
            )}
          </div>
        </div>
        {/* <div className="">
          {!isCollapsed && (
            <div className="font-nunito text-sm font-semibold leading-normal text-oppty-grey-19 pl-6">
              {lang.generalSettings}
            </div>
          )}
          <div className="py-6">
            <div
              type="button"
              className="flex gap-x-4 hover:bg-black group py-3 px-6 cursor-pointer"
              onMouseEnter={() => handleMouseEnter('settingButton')}
              onMouseLeave={() => handleMouseLeave('settingButton')}
            >
              <div className="w-5 h-5">
                <Setting
                  stroke="#7C8493"
                  fill="none"
                  className="h-full w-full"
                  isHovered={hoverState.settingButton}
                  hoverStroke="white"
                />
              </div>
              {!isCollapsed && (
                <div className="font-nunito text-base font-medium leading-normal text-oppty-grey-19 group-hover:text-white">
                  {lang.generalSettings}
                </div>
              )}
            </div>
            <div
              type="button"
              className="flex gap-x-4 hover:bg-black group py-3 px-6 cursor-pointer"
              onMouseEnter={() => handleMouseEnter('helpButton')}
              onMouseLeave={() => handleMouseLeave('helpButton')}
            >
              <div className="w-5 h-5">
                <QuestionMark
                  stroke="#7C8493"
                  fill="none"
                  className="h-full w-full"
                  isHovered={hoverState.helpButton}
                  hoverStroke="white"
                />
              </div>
              {!isCollapsed && (
                <div className="font-nunito text-base font-medium leading-normal text-oppty-grey-19 group-hover:text-white">
                  {lang.generalHelpCenter}
                </div>
              )}
            </div>
          </div>
        </div> */}
        <div className="">
          <div
            type="button"
            className="flex gap-x-4 hover:bg-black group py-3 px-6 cursor-pointer"
            onMouseEnter={() => handleMouseEnter('privacyButton')}
            onMouseLeave={() => handleMouseLeave('privacyButton')}
            onClick={() => navigate('/privacy-policy')}
          >
            <div className="w-5 h-5">
              <InfoIcon
                stroke="#7C8493"
                fill="none"
                className="h-full w-full"
                isHovered={hoverState.privacyButton}
                hoverStroke="white"
              />
            </div>
            {!isCollapsed && (
              <div className="font-nunito text-base font-medium leading-normal text-oppty-grey-19 group-hover:text-white">
                {lang.generalPrivacy}
              </div>
            )}
          </div>
          <div
            type="button"
            className="flex gap-x-4 hover:bg-black group py-3 px-6 cursor-pointer"
            onMouseEnter={() => handleMouseEnter('termsButton')}
            onMouseLeave={() => handleMouseLeave('termsButton')}
            onClick={() => navigate('/terms-of-service')}
          >
            <div className="w-5 h-5">
              <InfoIcon
                stroke="#7C8493"
                fill="none"
                className="h-full w-full"
                isHovered={hoverState.termsButton}
                hoverStroke="white"
              />
            </div>
            {!isCollapsed && (
              <div className="font-nunito text-base font-medium leading-normal text-oppty-grey-19 group-hover:text-white">
                {lang.generalTermsOfUse}
              </div>
            )}
          </div>
        </div>
        <div className="pt-5">
          <div
            type="button"
            className="flex gap-x-4 hover:bg-red-50 group py-3 px-6 group items-center cursor-pointer"
            onMouseEnter={() => handleMouseEnter('logoutButton')}
            onClick={() => dispatch(employerLogout(navigate, setAuthUser))}
          >
            <div className="w-5 h-5">
              <ArrowLeftStartOnRectangleIcon stroke="#7C8493" fill="none" className="group-hover:stroke-red-800" />
            </div>
            {!isCollapsed && (
              <div className="font-nunito text-base font-medium leading-normal text-oppty-grey-19 group-hover:text-red-800">
                {lang.generalLogout}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="py-5 px-6 flex items-center gap-x-4">
        <img
          src={employer?.avatar ? employer.avatar : defaultAvatar}
          alt="userPic"
          className="w-10 h-10 rounded-full"
        />
        {!isCollapsed && (
          <div>
            <div className="text-sm font-nunito font-normal leading-normal text-oppty-grey-1">{employer?.email}</div>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default LeftNavigationBar;
